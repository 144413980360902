export const formatURL = (url) => {
  if (url.includes("youtube")) {
    return url.replace("/watch?v=", "/embed/");
  } else if (url.includes("google")) {
    return url.replace("/view?usp=sharing", "/preview");
  } else if (url.includes("sharepoint")) {
    return url.replace("/stream.aspx", "/embed.aspx");
  } else {
    return url;
  }
};
