import classNames from 'classnames';
import { default as LoaderSpinner } from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { LoaderStyle } from './styled';
import VARIABLES from '../../../themes/variables';

export default function Loader({
  className,
  percentage,
  variant,
  spinnerColor,
  percentageLabel,
  hidePercentageLabel,
  type,
  height,
  width,
  size,
  ...props
}) {

  function renderTheme(variant) {
    switch (variant) {
      case 'upload': return (
        spinnerColor = 'white',
        type = 'TailSpin',
        'theme-upload'
      );
      default: return null;
    }
  }

  if (props.visible) {
    return (
      <LoaderStyle className={classNames(
        'loader-wrapper',
        renderTheme(variant),
        className
      )}>
        <div className="loader">
          <LoaderSpinner
            type={type}
            color={spinnerColor}
            height={height || size}
            width={width || size}
            visible={false}
            {...props}
          />
          {percentage !== null &&
            <div className="percentage">
              {
                !hidePercentageLabel &&
                <span className="percentage-label">{percentageLabel}</span>
              }
              {percentage}
            </div>
          }
        </div>
      </LoaderStyle>
    );
  }
  return null;
}

Loader.defaultProps = {
  spinnerColor: VARIABLES.COLOR.GREEN_2,
  percentageLabel: 'Task in progress...',
  percentage: null,
  type: 'Bars',
  size: 40,
};
