import styled from "styled-components";
import VARIABLES from "../../../../../themes/variables";
// Wrapper
// ============================================================
export const TitleCoporateDetailStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  .more {
    cursor: pointer;
  }
  /* Child element styles
  ------------------------------- */
  .block-activity-title {
    .title {
      margin-bottom: 15px;
      color: #000000;
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.64px;
    }

    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        color: #000000;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.28px;
      }
      
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  .icon-share-link {
    cursor: pointer;
  }

  @media (max-width: 1024.98px) {
    .block-activity-title {
      .title {
        font-size: 28px;
        line-height: 40px;
      }
    }
  }

  @media (max-width: 768.98px) {
  }

  @media (max-width: 600.98px) {
    .block-activity-title {
      .title {
        font-size: 24px;
        line-height: 35px;
      }
    }
  }

  @media (max-width: 425.98px) {
  }

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
