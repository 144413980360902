import classNames from 'classnames'
import React, { useState } from 'react'

import { CheckboxStyle } from './styled'
import {
  Image
} from '../index'

export default function Checkbox({
  className,
  children,
  // defaultChecked,
  onChange,
  id,
  type,
  name,
  checked,
  //isCheckedSub,
  value,
  disable,
  disabled,
  isDisable,
  isDisabled,
  isChecked,
  setChecked,
}){

  // const [isChecked, setChecked] = useState(false)

  return (
    <CheckboxStyle
        className={classNames(
          'checkbox',
          { 'is-checked': isChecked || checked },
          // { 'is-checked-sub': isCheckedSub },
          { 'is-radio-type': type === 'radio' },
          { 'is-disabled': disable || isDisable || disabled || isDisabled },
          className
        )}
      >
        <input
          className='real-input'
          type={type}
          // defaultChecked={defaultChecked}
          checked={onChange ? checked : isChecked}
          onChange={onChange ? onChange : () => setChecked(!isChecked)}
          id={id}
          name={name}
          value={value}
        />
        <label className='fake-input' htmlFor={id}>
          <span className='fake-checkbox'>
            <Image
              src='../asset/media/images/icons/icon-check-blue.svg' className='check-icon'
            />
          </span>
          <span className='fake-label'>{children}</span>
        </label>
      </CheckboxStyle>
  )
}

Checkbox.defaultProps = {
  type: 'checkbox',
  defaultChecked: false,
}

