import service from "./../service";

export const PagesShareholderMeetingDocument = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-shareholder-meeting-document', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ShareholderMeetingDocuments = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/shareholders-meeting-documents', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};