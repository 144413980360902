import OneReportComponent from "../components/Pages/OneReport";
import Auth from "../containerWarping/Auth";
const OneReport = () => {
  return (
    <Auth>
      <OneReportComponent />
    </Auth>
  );
};

export default OneReport;