import styled from "styled-components";
import VARIABLES from "../../../../themes/variables";

// Wrapper
// ============================================================
export const MenuItemStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .submenu {
    &.super {
      .menu-anchor {
        ${VARIABLES.TYPOGRAPHYS.BODY._6}
      }
    }
  }

  /* Child element styles
  ------------------------------- */
  .button {
    &.button-toggle-outline {
      ${VARIABLES.TYPOGRAPHYS.BODY._7}
      color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      padding: 8px 5px;
      white-space: nowrap;
      border: 0;
      border-bottom: 2px solid transparent;

      &.is-hide-desktop {
        display: none;
        padding: 0;
        margin: 0;
      }

      &.is-active {
        border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

        &:hover {
          border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER};
        }
      }
      
      &:hover {
        background-color: transparent;
        color: inherit;
        border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER};
      }
      .icon-chevron-down {
        display: none;
      }
    }
  }

  .main-menu-wrapper {
    width: 100vw;
    height: auto;
    /* max-height: 50vh; */
    /* min-height: 300px; */
    background: ${VARIABLES.COLOR.NEUTRAL8};
    /* display: flex; */
    position: absolute;
    top: 100%;
    left: 0;
    left: 50%;
    transform: translateX(-50%);

    .content-wrapper {
      display: flex;
    }

    .image-wrapper {
      width: 33%;
      max-width: 700px;

      .image-inner-wrapper {
        position: relative;
        width: 100%;
        padding-top: 61.7%;
        min-height: 100%;
      }

      img {
        position: absolute;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
      }
    }
  }

  .submenu-wrapper {
    padding: 30px;
    width: 100%;
  }

  .bg-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60vh;
    transform: translate(0, 50%);
    background-color: transparent;

  }

  .submenu-inner-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    max-width: 800px;
  }

  .submenu-col {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    flex-wrap: wrap;
    max-width: 400px;
  }

  .submenu-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;

    .submenu {
      max-width: 400px;
      width: 50%;
    }
  }

  .submenu {
    display: flex;
    align-items: flex-start;
    width: 100%;
    ${VARIABLES.TYPOGRAPHYS.TITLE._7}

    &.fake-anchor {
      padding: 8px 16px;
    }

    a {
      padding: 8px 16px;

      &:hover {
        color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      }
    }

    &.super {
      a {
        display: list-item;
        list-style-position: inside;
        padding-left: 25px;
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1100px) {
    width: 100%;
    z-index: 99;
    padding: 12px 0;
    border-bottom: 1px solid ${VARIABLES.COLOR.SECONDARY_DEFAULT};

    .button {
      &.button-toggle-outline {
        color: white;
        justify-content: flex-start;
        padding: 12px;
        border: 0;

        &.is-hide-desktop {
          display: block;
          padding: 12px;
        }

        &.is-active {
          border: 0;

          &:hover {
            border: 0;
          }
        }

        &:hover {
          border: 0;
          color: white;
        }
        .icon-chevron-down {
          display: block;
        }
      }
    }

    .main-menu-wrapper {
      position: relative;
      width: 100%;
      background-color: transparent;
      /* min-height: 0; */
      max-width: none;

      .image-wrapper {
        display: none;
      }

      .submenu-col {
        width: 100%;
        max-width: 100%;
      }

      .submenu-row {
        max-width: none;

        .submenu {
          max-width: none;

          a {
            width: 100%;
          }
        }
      }

      .submenu-wrapper {
        padding: 0 0 0 15px;
        display: flex;

        .submenu-inner-wrapper {
          flex-wrap: wrap;
          max-width: 100%;
          align-items: flex-start;
          align-content: flex-start;
          width: 100%;
        }

        .submenu {
          width: 100%;
          color: white;

          &.super {
            a {
              display: flex;
              padding: 8px 16px;
              width: 100%;
            }
          }

          &.fake-anchor {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      border: 0;
    }
  }

  @media (max-width: 1100.98px) {
    .button {
      &.button-toggle-outline {
        color: #FFFFFF !important;
      }
    }
  }
`;
