import styled from "styled-components";
import VARIABLES from "../../../../../themes/variables";

// Wrapper
// ============================================================
export const CardLatestReleasesPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: 100%;
  max-width: 350px;
  min-width: 350px;
  min-height: 315px;
  margin: 30px 0;
  padding-top: 80px;

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .card-latest-releases {
    position: relative;
    min-height: 340px;
    padding: 104px 20px 30px 20px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 6px 15px 21px 0px rgba(27, 59, 106, 0.5);

    .card-icon {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(55px, -95px);

      img {
        width: 190px;
        height: 190px;
      }
    }

    .card-name {
      position: relative;

      .name {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (max-width: 991.98px) {
          font-size: 20px;
        }
      }

      .sub-name {
        display: none;
      }

      &:hover {
        .sub-name {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(27, 59, 106, 0.8);
          color: white;
          width: 95%;
          padding: 15px;
          border-radius: 10px;
          transform: translate(0px, 55px);
        }
      }
    }

    .card-date {
      position: absolute;
      bottom: 97px;

      .date-text {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .document-file {
        display: flex;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        padding: 5px 0;

        .icon-document-file {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          margin-left: 16px;
        }
      }
    }

    .btn {
      &.card {
        position: absolute;
        bottom: 30px;
        right: 20px;
        margin-left: auto;
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1150px) {
    max-width: 290px;
    min-width: 290px;
    min-height: 255px;

    .card-latest-releases {
      min-height: 295px;
      padding: 75px 20px 30px 20px;

      .card-icon {
        img {
          width: 160px;
          height: 160px;
        }
      }

      .card-name {
        .name {
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 260px;
    min-width: 260px;
    min-height: 225px;
  }

  @media (max-width: 820px) {
    max-width: 230px;
    min-width: 230px;
    min-height: 295px;

    .card-latest-releases {
      min-height: 270px;
      padding: 60px 20px 30px 20px;

      .card-icon {
        transform: translate(50px, -80px);

        img {
          width: 140px;
          height: 140px;
        }
      }

      .card-name {
        .name {
          font-size: 18px;
        }
      }
    }
  }
  
  @media (max-width: 560px) {
    max-width: 290px;
    min-width: 290px;
    min-height: 255px;

    .card-latest-releases {
      min-height: 295px;
      padding: 75px 20px 30px 20px;

      .card-icon {
        transform: translate(55px, -95px);
        
        img {
          width: 160px;
          height: 160px;
        }
      }

      .card-name {
        .name {
          font-size: 20px;
        }
      }
    }
  }
`;
