import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../../constants/routes";
import { CookiePolicyStyle } from "./styled";
import { PageHeader } from "../../../Global";
import { ManagementEnvironmental } from "../../../../Service/API";
import { getBaseUploadUrl } from "../../../../Service/service";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useSelector, useDispatch } from "react-redux";
import { PagesCookiesPolicy } from "../../../../Service/policy/policy";

const CookiePolicyComponent = () => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const isHome = useSelector((state) => state.counter.isHome);

  useEffect(() => {
    dispatch(isHome ? { type: "STANDARD" } : { type: "INVESTOR" });
    const CookiesPolicy = async () => {
      const Response = await PagesCookiesPolicy();
      setData(Response?.data);
    };

    CookiesPolicy();
  }, []);

  return (
    <CookiePolicyStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          {
            url: isHome ? "/" : ROUTE_PATH.HOME_INVESTOR.LINK,
            text: translate["menu.home"],
          },
          { url: ROUTE_PATH.COOKIE_POLICY.LINK, text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="cookie-policy-content content-wrapper">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {data?.attributes?.content_description}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </CookiePolicyStyle>
  );
};

export default CookiePolicyComponent;
