import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const ProfileListStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: 372px;
  margin: 0 auto;
  
  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .first-last-name {
    padding-bottom: 15px;
    border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })};
    color: #000000;
  }

  .profile-picture {
    margin: 30px 0;
    position: relative;
    width: 100%;
    padding-top: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
      object-position: center top;
    }
  }

  .position {
    .job-title {
      margin-bottom: 10px;
      ${VARIABLES.TYPOGRAPHYS.BODY._2};
    }
  }

  .link {
    &.read-more {
      display: flex;
      color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      ${VARIABLES.TYPOGRAPHYS.BODY._7};
      width: fit-content;
      padding: 10px 10px 10px 0;
      background-color: transparent;
      border-color: transparent;

      .arrow-right {
        display: flex;
        margin-left: 1px;
        opacity: 0;
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 4000.98px) and (min-width: 2560.98px) {
    .first-last-name {
      font-size: 46px;
    }

    .position {
      .job-title {
        font-size: 36px;
      }
    }

    .link {
      &.read-more {
        font-size: 30px;
      }
    }
  }
  
  @media (max-width: 2559.98px) and (min-width: 2000.98px) {
    .first-last-name {
      font-size: 42px;
    }

    .position {
      .job-title {
        font-size: 32px;
      }
    }

    .link {
      &.read-more {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 850.98px) {
    max-width: 350px;
    width: 100%;
  }

  @media (max-width: 800.98px) {
    max-width: 300px;
    width: 100%;
  }
`;
