import CookiePolicyComponent from "../components/Pages/Privacy/CookiePolicy";
import Auth from "../containerWarping/Auth";
const CookiePolicy = () => {
  return (
    <Auth>
      <CookiePolicyComponent />
    </Auth>
  );
};

export default CookiePolicy;