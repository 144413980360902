import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const OneReportStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .annual-report {
    padding: 50px;

    div > .block-card-data-sheet {
      margin: auto;
    }

    .annual-report-search {
      margin-bottom: 43px;
      display: flex;
      justify-content: space-between;

      .search {
        max-width: 359px;
        width: 100%;
        margin-left: 40px;
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        
        .field-wrapper {
          .input-wrapper {
            .input {
              border-radius: 6px;
              ${VARIABLES.TYPOGRAPHYS.BODY._6}
            }
          }
        }
      }

      .select-wrapper {
        min-width: 155px;
        margin-left: 15px;
      }
    }

    .block-annual-report {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      gap: 40px;
      padding-bottom: 24px;
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 991.98px) {
    .annual-report {
      .block-annual-report {
        grid-template-columns: unset;
      }
    }
  }

  @media (max-width: 767.98px) {
    .annual-report {
      padding: 30px;

      .annual-report-search {
        .search {
          max-width: 250px;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 450.98px) {
    .annual-report {
      .annual-report-search {
        display: block;

        .search {
          margin-bottom: 16px;
          text-align: center;
        }

        .select-wrapper {
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }


  /* @media (max-width: 767.98px) {
    
  } */

  /* @media (max-width: 600.98px) {
    
  } */

  /* @media (max-width: 450.98px) {
    
  } */
`
