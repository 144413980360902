import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { ShareholderMeetingVDOStyle } from "./styled";
import { Input, PageHeader } from "../../Global";
import { useDispatch, useSelector } from "react-redux";
import { getBaseUploadUrl } from "../../../Service/service";
import CardVDO from "./components/CardVDO";
import SelectBox from "../../Global/SelectBox";
import ViewVdoDetail from "./components/ViewVdoDetail";
import {
  PagesShareholderMeetingVdo,
  ShareholderMeetingVdo,
} from "./../../../Service/shareholders";
import {
  scrollToTop,
  removeDuplicates,
  displayYearBuddhistEra,
  displayYear
} from "../../../helpers";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { langEn, langTh } from "../../../store/lang";

const ShareholderMeetingVDOComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const originPath = window.location.origin;
  const shareLink = `${originPath}${ROUTE_PATH.SHAREHOLDER_MEETING_VDO.LINK}`

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const type = params.get("type");

  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [content, setContent] = useState([]);
  const [date, setDate] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [isSelectDetail, setIsSelectDetail] = useState({
    isOpen: false,
    type: null,
    id: null,
    url: null,
  });
  const [selectedData, setSelectedData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const defaultOptionYears = [
    { value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date()) },
  ];
  const [optionYears, setOptionYears] = useState(defaultOptionYears);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const ShareholderMeetingVDOdata = async () => {
      try {
        const ResPage = await PagesShareholderMeetingVdo();
        setData(ResPage?.data);

        const payload = {
          "fields[0]": "date",
          "sort[date]": "desc"
        };
        const ResDate = await ShareholderMeetingVdo(payload);
        setDate(ResDate?.data);
        setIsMounted(true);

        handleCheckUrlParams();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    ShareholderMeetingVDOdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckUrlParams = () => {
    if (id) {
      setIsSelectDetail({
        ...isSelectDetail,
        isOpen: true,
        id,
        type: type || "introduction"
      })

      setSelectedData({
        ...selectedData,
        type: type || "introduction"
      })
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const updateContentByYear = async () => {
      const payload = {
        "filters[date][$gte]": `${selectedYear}-01-01`,
        "filters[date][$lte]": `${selectedYear}-12-31`,
        "sort[date]": "desc",
        "sort[id]": "desc",
        populate: "*",
      };
      const responseContent = await ShareholderMeetingVdo(payload);
      setContent(responseContent?.data);
    };

    selectedYear && updateContentByYear();
  }, [selectedYear]);

  const onChangeYear = (e) => {
    setSelectedYear(e.value);
  };

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({ value: e, label: lang === langTh ? displayYearBuddhistEra(e) : e })
    );

    setOptionYears(yearsData);
    setSelectedYear(defaultOptionYears[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleTopPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <ShareholderMeetingVDOStyle>
      {!isSelectDetail.isOpen && (
        <>
          <PageHeader
            pageHeaderImage={
              data?.attributes?.menu_image?.data?.attributes?.url &&
              BASE_URL_UPLOAD +
              data?.attributes?.menu_image?.data?.attributes?.url
            }
            breadcrumb={[
              { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
              { url: "#", text: translate["menu.home_investor_relation"] },
              { url: "#", text: translate["menu.published_document"] },
              { url: "#", text: translate["menu.activities_and_presentation_documents"] },
              {
                url: ROUTE_PATH.SHAREHOLDER_MEETING_VDO.LINK,
                text: `${translate["common.video"]}${lang === langEn ? " " : ""}${data?.attributes?.menu_text}`,
              },
            ]}
            title={`${translate["common.video"]}${lang === langEn ? " " : ""}${data?.attributes?.menu_text}`}
          />

          <div className="page-content-wrapper">
            <div className="page-content fluid">
              <div className="shareholder-meeting-vdo-content content-wrapper">
                <div className="shareholder-meeting-vdo-search">
                  <div className="search">
                    <Input
                      className="input-search"
                      inputSearch
                      type="text"
                      placeholder={translate["common.search_for_documents"]}
                      value={searchValue}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="select-wrapper">
                    <span>
                      {translate["common.year"]}
                      {isMounted && (
                        <SelectBox
                          defaultValue={optionYears[0]}
                          options={optionYears}
                          onChange={(e) => onChangeYear(e)}
                          isSearchable={false}
                        />
                      )}
                    </span>
                  </div>
                </div>

                <div
                  className={`vdo-list ${content?.length === 2 && "two-items"}`}
                >
                  <>
                    {content &&
                      content
                        ?.filter((item) =>
                          item?.attributes?.company_name
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase())
                        )
                        ?.map((item, index) => (
                          <CardVDO
                            setIsSelectDetail={(e) => {
                              setIsSelectDetail(e);
                              setSelectedData({ ...item, type: "opp-day" });
                              scrollToTop();
                            }}
                            key={index}
                            id={item?.id}
                            url={item?.attributes?.vdo_url}
                            title={
                              item?.attributes?.topic ||
                              item?.attributes?.company_name
                            }
                            business={item?.attributes?.business}
                            subTitle={
                              item?.attributes?.short_description
                            }
                            fileUrl={
                              item?.attributes?.document_file?.data?.attributes
                                ?.url
                            }
                            fileName={
                              item?.attributes?.document_file?.data?.attributes
                                ?.name
                            }
                            type="opp-day"
                          />
                        ))}
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isSelectDetail.isOpen && (
        <ViewVdoDetail
          fileData={selectedData?.attributes?.document_file?.data}
          data={isSelectDetail}
          shareLink={`${shareLink}`}
          onClose={(e) => {
            setIsSelectDetail(e);
            scrollToTop();
          }}
        />
      )}
      {!isSelectDetail.isOpen && (
        <div
          className="go-top-page"
          onClick={() => {
            handleTopPage();
          }}
        >
          <img
            src="../asset/media/images/icons/icon-chevron-up-white.svg"
            alt="go-top-page"
          />
        </div>
      )}
    </ShareholderMeetingVDOStyle>
  );
};

export default ShareholderMeetingVDOComponent;
