import CompanySnapshotComponent from "../components/Pages/CompanySnapshot";
import Auth from "../containerWarping/Auth";
const CompanySnapshot = () => {
  return (
    <Auth>
      <CompanySnapshotComponent />
    </Auth>
  );
};

export default CompanySnapshot;
