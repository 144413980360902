import classNames from 'classnames';
import { CardLatestReleasesPageStyle } from './styled';
import { Button } from '../../../../Global';
import { Link } from "react-router-dom";
import { scrollToTop } from '../../../../../helpers';
import { SaveFile } from './../../../../../Service/API';
import { getBaseUploadUrl } from './../../../../../Service/service';
import { useSelector } from 'react-redux';

const CardLatestReleasesComponent = ({
  className,
  cardIcon,
  cardName,
  cardDate,
  file,
  link,
}) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <CardLatestReleasesPageStyle
      className={classNames(
        'card-latest-releases-wrapper',
        className
      )}
    >
      <div className='card-latest-releases'>
        <div className='card-icon'>
          <img src={cardIcon} alt='icon-card-latest-releases' />
        </div>
        <div className='card-name'>
          <span className='name'>{cardName}</span>
          <span className='sub-name'>{cardName}</span>
        </div>
        <div className='card-date'>
          {
            cardDate && cardDate !== "Invalid Date" && (
              <p className='date-text'>{translate["home_investor.published_on"]} {cardDate}</p>
            )
          }
          <div className='document-file'
            onClick={() => {
              file && SaveFile(BASE_URL_UPLOAD + file?.url, file?.fileName || file?.name)
            }}
          >
            <img className='icon-document-file' src='../asset/media/images/icons/icon-documents-small-blue.svg' alt='icon-documents-small-blue' />
            {translate["home_investor.view_document"]}
          </div>
        </div>
        <div className='btn link read-more card'>
          {
            link && (
              <Link to={link} onClick={() => scrollToTop()}>
                <Button className="btn link read-more">
                  {translate["common.more"]}
                </Button>
              </Link>
            )
          }
          {
            !link && (
              <Button className="btn link read-more">
                {translate["common.more"]}
              </Button>
            )
          }
        </div>
      </div>
    </CardLatestReleasesPageStyle>
  );
};

export default CardLatestReleasesComponent;
