import styled from 'styled-components'
import VARIABLES from '../../../../themes/variables'

// Wrapper
// ============================================================
export const TermsAndConditionsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
 

  /* Child element styles
  ------------------------------- */

  .terms-and-conditions-content {
    padding: 45px 80px;
    ${VARIABLES.TYPOGRAPHYS.BODY._6}
    /* text-align: justify; */
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1024.98px) {
    .terms-and-conditions-content {
     
    }
  }

  @media (max-width: 768.98px) {
    .terms-and-conditions-content {
     
    }
  }

  @media (max-width: 425.98px) {
    .terms-and-conditions-content {
      padding: 30px 30px;
    }
  }

  @media (max-width: 375.98px) {
    .terms-and-conditions-content {
      
    }
  }

  @media (max-width: 320.98px) {
    .terms-and-conditions-content {
      
    }
  }
`
