import React, { useState, useEffect } from "react";
import { ViewVdoDetailStyle } from "./styled";
import { Button } from "../../../../Global";
import { formatURL } from "../../function/formatURL";
import { ShareholderMeetingVdoById } from "./../../../../../Service/API";
import { SaveFile } from "./../../../../../Service/API";
import { getBaseUploadUrl } from "./../../../../../Service/service";
import { displayDate, copyLink } from "./../../../../../helpers";
import { useSelector } from "react-redux";
import { FacebookShareButton, TwitterShareButton, LineShareButton } from "react-share";
import { ROUTE_PATH } from "../../../../../constants/routes";
import { Link } from 'react-router-dom';

function ViewVdoDetail({ data, onClose, fileData, shareLink }) {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [content, setContent] = useState({});
  useEffect(() => {
    const ViewVdoDetailCon = async () => {
      try {
        const ResPage = await ShareholderMeetingVdoById(data?.id);
        setContent(ResPage?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    ViewVdoDetailCon();
  }, []);

  return (
    <ViewVdoDetailStyle>
      <div className="view-vdo-detail-wrapper">
        <div className="vdo">
          <iframe
            src={content?.attributes?.vdo_url && formatURL(content?.attributes?.vdo_url)}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            title="vdo"
          ></iframe>
        </div>

        <div className="title">
          {content?.attributes?.topic}
        </div>

        <div className="sub-title-wrapper">
          <div className="date">{displayDate(content?.attributes?.date, 'DD/MM/YYYY')}</div>
          <div className="social-btn-save">
            <div className="social-group">
              <FacebookShareButton
                url={`${shareLink}?id=${data?.id}&type=${data?.type}`}
                title={content?.attributes?.topic}
                quote={content?.attributes?.topic}
                hashtag={[content?.attributes?.topic]}
              >
                <img
                  src="../asset/media/images/contents/icon-facebook.png"
                  alt="facebook share"
                />
              </FacebookShareButton>

              <TwitterShareButton
                url={`${shareLink}?id=${data?.id}&type=${data?.type}`}
                title={content?.attributes?.topic}
                hashtag={[content?.attributes?.topic]}
                via={content?.attributes?.topic}
              >
                <img
                  src="../asset/media/images/contents/icon-tweeter.png"
                  alt=""
                />
              </TwitterShareButton>

              <LineShareButton
                url={`${shareLink}?id=${data?.id}&type=${data?.type}`}
                title={content?.attributes?.topic}
              >
                <img
                  src="../asset/media/images/contents/icon-line.png"
                  alt=""
                />
              </LineShareButton>

              <span
                className="icon-share-link"
                onClick={() => copyLink(`${shareLink}?id=${data?.id}`)}
              >
                <img
                  src="../asset/media/images/contents/icon-link.png"
                  alt=""
                />
              </span>
            </div>
            <Button
              className="medium-secondary btn-save"
              onClick={()=>{
                if (fileData) {
                  SaveFile(BASE_URL_UPLOAD + fileData?.attributes?.url, fileData?.attributes?.name)
                }
              }}
            >
              <img
                className="icon icon-download"
                src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
                alt="icon-arrow-down-circle-white"
              />
              {translate["common.download_document"]}
            </Button>
          </div>
        </div>

        <div className="detail">
         {content?.attributes?.content_description}
        </div>

        <Link
          to={ROUTE_PATH.SHAREHOLDER_MEETING_VDO.LINK}
          onClick={() => {
            onClose({ isOpen: false });
          }}
        >
          <Button className="medium-secondary-outline btn-back">
            {translate["common.back"]}
          </Button>
        </Link>
      </div>
    </ViewVdoDetailStyle>
  );
}

export default ViewVdoDetail;
