import NewAndActivityCSRComponent from "../components/Pages/NewAndActivity/ActivityCSR";
import Auth from "../containerWarping/Auth";
const NewAndActivityCSR = () => {
  return (
    <Auth>
      <NewAndActivityCSRComponent />
    </Auth>
  );
};

export default NewAndActivityCSR;
