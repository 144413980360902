import { WorkWithUsPageOnlineRegisterStyle } from "./styled";
import { Input, Modal, Button, PageHeader } from "../../../Global";
import SelectBox from "../../../Global/SelectBox";
import Checkbox from "../../../Global/Checkbox";
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ROUTE_PATH } from "../../../../constants/routes";
import {
  JobRecruitments,
  ApplyWorks,
  PagesWorkWithUs,
} from "../../../../Service/API";
import service, { getBaseUploadUrl } from "../../../../Service/service";
import { scrollToTop, validateFile, validateEmail } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";

const WorkWithUsPageOnlineRegisterComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);

  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const position_id = params.get("position");

  const [dataPage, setDataPage] = useState(null);
  const [isChecked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    position: position_id,
    position_name: "",
    file: "",
  });
  const [fileAttached, setFileAttached] = useState();
  const [jobPositions, setJobPositions] = useState([]);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
  });
  const [isDisablePosition, setDisablePosition] = useState(false);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const Positions = async () => {
      const resPages = await PagesWorkWithUs();
      setDataPage(resPages?.data);

      const response = await JobRecruitments();
      setJobPositions(response?.data);

      if (position_id && response?.data) {
        const findData = response?.data?.find(
          (item) => item.id === Number(position_id)
        );
        if (findData) {
          setDisablePosition(true);
        }
        setFormData({
          ...formData,
          position_name: findData?.attributes?.job_position,
        });
      }
    };

    Positions();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      position: selectedOption.value,
      position_name: selectedOption.label,
    });
  };

  let option_position = [];
  for (let index = 0; index < jobPositions?.length; index++) {
    const position = jobPositions[index]?.attributes?.base_localtion ? `(${jobPositions[index]?.attributes?.base_localtion})` : '';
    option_position.push({
      value: jobPositions[index].id,
      label: `${jobPositions[index]?.attributes?.job_position} ${position}`,
    });
  }

  const handleFileChange = (e) => {
    const files = e?.target?.files[0];
    if (files) {
      if (validateFile(files)) {
        setFileAttached(files);
        setFormData({
          ...formData,
          file: files,
        });
      } else {
        alert(translate["online_register.message_error.invalid_file_maximum"]);
        e.target.value = null;
      }
    }
  };

  const validateFormData = () => {
    let valid = false;
    if (
      formData.position_name &&
      formData.firstName &&
      formData.lastName &&
      formData.phoneNumber &&
      formData.email
    ) {
      valid = true;
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFormData()) {
      alert(translate["online_register.message_error.invalid_information"]);
      return;
    }

    if (!validateEmail(formData.email)) {
      alert(translate["online_register.message_error.invalid_email"]);
      return;
    }

    if (!formData.file) {
      alert(translate["online_register.message_error.invalid_file"]);
      return;
    }

    if (!isChecked) {
      alert(translate["online_register.message_error.invalid_terms"]);
      return;
    }

    try {
      let fileId = null;
      if (fileAttached) {
        let formDataFile = new FormData();
        formDataFile.append("files", fileAttached);

        const upload = await service().uploadFile(formDataFile);
        fileId = upload.data[0]?.id;
      }

      const data = {
        name: formData.firstName,
        surname: formData.lastName,
        phone: formData.phoneNumber,
        email: formData.email,
        position: formData.position_name,
        file_attached: fileId,
        datetime: new Date().toISOString(),
      };

      const response = await ApplyWorks(data);
      if (response) {
        setOpenModal({ isOpen: true });
        handleResetData();
      }
    } catch (error) {
      console.error("Error submitting form", error);
      // Show an error message to the user
      alert(translate["online_register.message_error.submitting_form"]);
    }
  };

  const handleResetData = async () => {
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      position: position_id,
      position_name: "",
      file: "",
    });
    setChecked(false);
    setFileAttached(null);
    document.getElementById("file-upload").value = null;
  };

  const closeModal = (e) => {
    setOpenModal(e);
    if (position_id) {
      history.push(ROUTE_PATH.WORK_WITH_US_PAGE_INTERESTING_POSITION.LINK);
    }
  };

  let mockModal = [
    {
      id: 1,
      title: "ขอเชิญประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
      date: "วันจันทร์ที่ 24 เมษายน 2566 เวลา 14.00 น.",
      contact: "โดยประชุมผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
      img: "../../asset/media/images/icons/icon-warning-message-confirmed.svg",
      alt: "icon-warning-message-confirmed",
      test: translate["online_register.modal_form_success"],
    },
  ];

  return (
    <WorkWithUsPageOnlineRegisterStyle>
      <PageHeader
        pageHeaderImage={
          dataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD +
            dataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/work-with-us", text: translate["menu.work_with_us"] },
          {
            url: "/work-with-us/interesting-position",
            text: translate["menu.online_register"]
          },
        ]}
        title={translate["menu.online_register"]}
      />

      <form onSubmit={handleSubmit}>
        <div className="online-register">
          <div className="oneline-register-content">
            <div className="title">
              {dataPage?.attributes?.form_title}

              <div className="description"></div>
            </div>
            <div className="body">
              <div className="selection">
                <div className="label">
                  <div className="text">
                    {translate["online_register.job_position"]} <span> * </span>
                  </div>
                </div>
                <SelectBox
                  required
                  id="select-position"
                  defaultValue={option_position.find(
                    (option) => option.value === Number(position_id)
                  )}
                  value={option_position.find(
                    (option) => option.value === Number(position_id)
                  )}
                  placeholder={translate["common.select"]}
                  options={option_position}
                  onChange={handleSelectChange}
                  isDisabled={isDisablePosition}
                />
              </div>
              <div className="input">
                <div className="text-input">
                  <div className="box-input">
                    <Input
                      required
                      label={translate["online_register.first_name"]}
                      type="text"
                      placeholder={translate["online_register.first_name"]}
                      value={formData.firstName}
                      onChange={(e) =>
                        setFormData({ ...formData, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="text-input">
                  <div className="box-input">
                    <Input
                      required
                      label={translate["online_register.last_name"]}
                      type="text"
                      placeholder={translate["online_register.last_name"]}
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData({ ...formData, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="input">
                <div className="text-input">
                  <div className="box-input">
                    <Input
                      required
                      label={translate["online_register.phone_number"]}
                      type="text"
                      placeholder={translate["online_register.phone_number"]}
                      value={formData.phoneNumber}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="text-input">
                  <div className="box-input">
                    <Input
                      required
                      label={translate["online_register.email"]}
                      type="text"
                      placeholder={translate["online_register.email"]}
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="upload">
                <div className="file-input">
                  <Input
                    required
                    label={translate["online_register.resume_here"]}
                    type="file"
                    name="files"
                    onChange={handleFileChange}
                    id="file-upload"
                  />
                </div>
              </div>
              <div className="file">
                {translate["online_register.file_maximum"]}
              </div>
              <div className="form-condition-policy">
                <div className="condition-policy">
                  <Checkbox
                    required
                    className="checkbox-policy"
                    checked={isChecked}
                    onChange={() => setChecked(!isChecked)}
                    id={"id"}
                  />
                  <div className="policy-wrapper">
                    <p className="policy">
                      {translate["online_register.i_have_read"]}
                    </p>
                    <Link
                      className="link condition"
                      to={ROUTE_PATH.PRIVACY_CENTER.LINK}
                      onClick={scrollToTop}
                    >
                      {translate["online_register.personal_policy"]}
                    </Link>
                    <p className="policy">
                      {translate["online_register.in_every_respect"]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="button">
              <Link
                to={ROUTE_PATH.WORK_WITH_US_PAGE_INTERESTING_POSITION.LINK}
                onClick={() => scrollToTop()}
              >
                <Button
                  className="medium-danger-outline"
                  // onClick={() => applyWork(false)}
                >
                  {translate["online_register.back"]}
                </Button>
              </Link>
              <Button
                className="medium-secondary-outline"
                onClick={handleSubmit}
              >
                {translate["online_register.send_information"]}
              </Button>
            </div>
          </div>
        </div>
      </form>
      {/* Modal */}
      {openModal.isOpen && (
        <Modal
          modalWarningMessage
          children={mockModal[0].test}
          srcImage={mockModal[0].img}
          alt={mockModal[0].alt}
          onClose={(e) => {
            // setOpenModal(e);
            closeModal(e);
          }}
        />
      )}
    </WorkWithUsPageOnlineRegisterStyle>
  );
};
export default WorkWithUsPageOnlineRegisterComponent;
