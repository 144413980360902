const BREAKPOINTS = {
  // Bootstrap Break Point ref.
  MIN: {
    _1: '576px',
    _2: '768px',
    _3: '992px',
    _4: '1200px',
    _5: '1400px'
  },
  MAX: {
    _1: '575.98px',
    _2: '767.98px',
    _3: '991.98px',
    _4: '1199.98px',
    _5: '1399.98px'
  }
}

export default BREAKPOINTS
