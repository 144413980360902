import { decrypt, encrypt } from "./";

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, encrypt(value));
};

export const getLocalStorage = (name) => {
  if (typeof window !== "undefined") {
    const value = localStorage.getItem(name);
    if (value) {
      return decrypt(value);
    }
  }
  return null;
};

export const removeLocalStorage = (name) => {
  localStorage.removeItem(name);
};
