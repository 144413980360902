import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const FullProfileStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  &.mt5 {
    margin-top: 10px;
  }

   .profile-title {
    ${VARIABLES.TYPOGRAPHYS.TITLE._4({ size: 20 })};
   }

   .profile-description {
    ${VARIABLES.TYPOGRAPHYS.BODY._5}
   }

   .ul {
    margin: 0;
    padding: 0;
   }

  .block-full-profile {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

    .full-profile-left {
      .profile-picture {
        padding: 30px;

        .picture {
          position: relative;
          text-align: center;
          max-width: 410px;
          width: 100%;
          height: auto;
          margin: 0 auto;
          
          img {
            width: 100%;
            height: 100%;
            border-radius: 150px 0px 200px 200px;
            box-shadow: 20px 20px 20px 0px ${VARIABLES.COLOR.NEUTRAL12};
          }

          /* .picture-border {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 5px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            border-radius: 150px 0px 200px 200px;
            transform: translate(-10%,-7%);
          } */
        }
      }

      .first-last-name {
        display: flex;
        justify-content: center;

        .first-name {
          margin-right: 10px;
        }
        .first-name,
        .last-name {
          ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })};
        }
      }

      .job-title {
        text-align: center;

        .title {
          margin-top: 20px;
          padding: 0 20px;
          ${VARIABLES.TYPOGRAPHYS.BODY._2};
        }
      }
    }

    .full-profile-right {
      padding: 30px 0;
      font-size: 18px;
      overflow-y: auto;
      max-height: 880px;
      padding-right: 20px;
    }
  }

  .btn-go-back {
    margin: 40px auto 20px;
    width: 120px;
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width:1199.98px) {
    .block-full-profile {
      .full-profile-right {
        font-size: 17px;
      }
    }
  }

  @media (max-width:991.98px) {
    .block-full-profile {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      .full-profile-right {
        font-size: 16px;
      }
    }
  }

  @media (max-width:767.98px) {
    .block-full-profile {
      .full-profile-left {
        .profile-picture {
          .picture {
            height: 500px;
          }
        }
      }

      .full-profile-right {
        font-size: 15px;
      }
    }
  }

  @media (max-width:600.98px) {
    .block-full-profile {
      .full-profile-left {
        .profile-picture {
          .picture {
            max-width: 350px;
            /* height: 450px; */
            height: auto;
          }
        }
      }

      .full-profile-right {
        font-size: 14px;
      }
    }
  }
`
