import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const HistoricalPriceStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .historicalprice {
    display: flex;
    padding: 60px 66px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;

    .history-date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 9px;
      width: 100%;

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        @media (max-width: 630.98px) {
            margin: 0 auto;
          }
      }

      .block-title {
        width: 100%;
        .title {
          ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        }
      }
      .sub-title {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        white-space: nowrap;

        .sub-title-content {
          display: flex;
          align-items: center;
          gap: 12px;

          @media (max-width: 630.98px) {
            flex-direction: column;
            align-items: center;
          }

          .text-sub-title {
            ${VARIABLES.TYPOGRAPHYS.BODY._5};
          }
          .datetodate {
            display: flex;
            gap: 12px;
            align-items: center;
            .datepicker {
              .date-picker-wrapper {
                width: 356px;
              }
            }
          }

          .datepicker-wrapper {
            display: flex;
            align-items: center;
            width: 100%;

            .text-sub-title {
              margin: 0 20px;
              ${VARIABLES.TYPOGRAPHYS.BODY._5};
              color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            }
          }
        }
        .button-wrapper {
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
          /* @media (max-width: 760px) {
            width: 100%;
            display: flex;
            justify-content: end;
          } */
        }
        @media (max-width: 630.98px) {
          flex-direction: column;
          gap: 24px;
          .button-wrapper {
            width: 100%;
            display: flex;
            justify-content: end;
          }
        }
      }
    }
    .block-table {
      width: 100%;
      .table {
        overflow-y: auto;
      }
    }

    .table {
      margin: 0 auto;
      max-height: 400px;
      max-width: 100%;
      overflow: auto;
      .header-primary {
        position: sticky;
        top: 0;
        left: 0;
      }
    }
  }
  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
    .sub-title {
      display: flex !important;
      /* flex-direction: column !important; */
      /* justify-content: center!important; */
      /* align-items: flex-start!important; */
      /* gap: 24px !important; */
      .sub-title-content {
        display: flex !important;
        /* flex-direction: column !important; */
        justify-content: center !important;
        align-items: flex-start !important;
        /* gap: 12px !important; */
        width: 100%;

        .datetodate {
          .datepicker {
            .date-picker-wrapper {
              width: 425px !important;
            }
          }
        }
      }

      .block-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  @media (max-width: 768.98px) {
    .historicalprice {
      padding: 40px 20px !important;

      .block-title {
        .title {
          text-align: center;
        }
      }
      .sub-title {
        .sub-title-content {
          .datetodate {
            .datepicker {
              .date-picker-wrapper {
                width: 343px !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 425.98px) {
    .historicalprice {
      .sub-title {
        .sub-title-content {
          .datetodate {
            .datepicker {
              .date-picker-wrapper {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
