export const validateFile = (files) => {
  let valid = false
  const fileSize = (files.size / 1024) / 1024 // MB
  const fileType = files.name.split('.').pop()

  if ( fileSize <= 2 && ( fileType === 'doc' || fileType === 'docx' || fileType === 'pdf') ) {
    valid = true
  }

  return valid
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}