import NewAndActivityCorporateDetailComponent from "../components/Pages/NewAndActivity/CoporateDetail";
import Auth from "../containerWarping/Auth";
const NewAndActivityCorporateDetail = () => {
  return (
    <Auth>
      <NewAndActivityCorporateDetailComponent />
    </Auth>
  );
};

export default NewAndActivityCorporateDetail;
