import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const CompanyProfilePageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .company-profile {
    padding: 48px 15px;
    max-width: 1350px;
    margin: 0 auto;

    .header-content {
      text-align: center;
      position: relative;
      padding-bottom: 18px;

      .title {
        ${VARIABLES.TYPOGRAPHYS.HEADING._5};
        color: ${VARIABLES.COLOR.NEUTRAL1};
        line-height: 1.8;
      }

      .company-description {
        color: ${VARIABLES.COLOR.NEUTRAL3};
        margin-top: 16px;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 2px;
        background-color: ${VARIABLES.COLOR.PRIMARY_DEFAULT};
        bottom: 0;
        left: 50%;
        transform: translateX(-100%);
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 2px;
        background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        bottom: 0;
        left: 50%;
      }
    }

    .history-timeline {
      padding: 32px 0;

      .history-timeline-block {
        display: flex;
        padding-bottom: 48px;
        position: relative;

        .icon-and-year {
          flex: 0 0 50%;
          max-width: 50%;
          text-align: right;
          position: relative;
          padding-right: 23px;

          .icon-and-year-inner-wrapper {
            position: relative;
            width: 100%;
          }

          .icon {
            width: 100%;
            max-width: 155px;
            height: auto;
            margin-top: 40px;
            margin-right: 18%;
          }

          .year {
            color: white;
            text-align: center;
            border-radius: 32px;
            padding: 8px 24px;
            ${VARIABLES.TYPOGRAPHYS.BODY_5};
            position: absolute;
            top: 0;
            right: 0;
            background: rgb(9,53,138);
            background: -moz-linear-gradient(111deg, rgba(9,53,138,1) 5.47%, rgba(44,97,197,1) 67.718%, rgba(3,109,168,1) 100%);
            background: -webkit-linear-gradient(111deg, rgba(9,53,138,1) 5.47%, rgba(44,97,197,1) 67.718%, rgba(3,109,168,1) 100%);
            background: linear-gradient(111deg, rgba(9,53,138,1) 5.47%, rgba(44,97,197,1) 67.718%, rgba(3,109,168,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#09358a",endColorstr="#036da8",GradientType=1);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.40);
          }
        }

        .text-content {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 23px;
          text-align: left;

          .text-content-inner-wrapper {
            padding: 5px 0 5px 16px;
          }

          .date-time {
            ${VARIABLES.TYPOGRAPHYS.BODY._1};
            color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
            line-height: 1.8;
          }

          .detail {
            white-space: pre-line;
            ${VARIABLES.TYPOGRAPHYS.BODY._5};
            color: ${VARIABLES.COLOR.NEUTRAL3};
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 4px solid ${VARIABLES.COLOR.NEUTRAL6};
          background-color: white;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          top: 24px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 1px;
          height: calc(100%);
          background-image: url('../../asset/media/images/contents/border-dashed-bg.png');
          z-index: 1;
        }

        &.even {
          flex-direction: row-reverse;

          .icon-and-year {
            text-align: left;
            padding-right: 0;
            padding-left: 35px;
            /* 23 + half of circle = 23 + 12 */

            .icon {
              margin-right: 0;
              margin-left: 15%;
            }

            .year {
              right: initial;
              left: 0;
            }
          }

          .text-content {
            padding-left: 0;
            padding-right: 35px;
            /* 23 + half of circle = 23 + 12 */
            text-align: right;

            .text-content-inner-wrapper {
              padding-left: 0;
              padding-right: 16px;
            }
          }
        }
      }

      &>* {
        &:last-of-type {
          .history-timeline-block {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
    .company-profile {
      background-image: url('../../asset/media/images/contents/company-profile-bg-mobile.png');
      background-repeat: no-repeat;
      background-attachment: fixed;

      .history-timeline {
        .history-timeline-block {
          display: block;

          .icon-and-year {
            width: 100%;
            max-width: 100%;
            padding: 36px 0 0 0;

            .icon-and-year-inner-wrapper {
              display: flex;
              justify-content: center;
              align-items: flex-start;
            }

            .year {
              position: relative;
              width: auto;
              z-index: 3;
            }

            .icon {
              position: absolute;
              top: -15px;
              right: 10%;
              /* z-index: 2; */
              z-index: -1;
              width: 25%;
              max-width: 90px;
              margin: 0;
            }
          }

          .text-content {
            width: 100%;
            max-width: 100%;
            padding: 0;

            .text-content-inner-wrapper {
              padding: 10px 10%;
            }
          }

          &.even {
            .icon-and-year {
              padding: 36px 0 0 0;

              .icon {
                margin-left: 0;
              }
            }

            .text-content {
              text-align: left;
              padding-right: 0;

              .text-content-inner-wrapper {
                padding: 10px 10%;
              }
            }
          }
        }
      }
    }
  }
`
