import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const SubsidiaryAndAssociatedCompaniesPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .subsidiary-and-associated-companies {
    padding: 60px 15px;
    max-width: 1350px;
    margin: 0 auto;

    .desricption {
      text-align: center;
      ${VARIABLES.TYPOGRAPHYS.BODY._4}
    }

    .company-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 1048px;
      margin: 48px auto;
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  /* @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {

  } */
`
