import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const VisionAndMissionPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .vision-and-mission {
    padding: 72px 15px;
    max-width: 1160px;
    margin: 0 auto;
  }

  .content {
    width: 100%;
    display: flex;
    position: relative;
    padding-bottom: 4.6%;
    max-width: 1160px;
    overflow: hidden;

    .content-img-wrapper {
      position: absolute;
      padding: 10% 5% 0;
      display: flex;
      height: 80%;
      width: 100%;
      object-fit: cover;

      &.two {
        justify-content: end;
      }

      &::before {
        content: '';
        display: block;
        background-color: ${VARIABLES.COLOR.NEUTRAL5};
        border-radius: 100px 8px 8px 8px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 12%;
        height: 54%;
        max-width: 125px;
        max-height: 435px;
      }
    }

    .content-img {
      border-radius: 10px 139px 10px 10px;
      width: 100%;
      max-width: 720px;
      height: auto;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }

    .content-text {
      width: 68%;
      position: relative;
      z-index: 3;
      margin-top: 35%;
      margin-left: 32%;
      min-height: 35%;

      &.mission {
        margin-top: ${props => props.contentMission.isImage ? "35%" : ""};
      }

      &.vision {
        margin-top: ${props => props.contentVision.isImage ? "35%" : ""};
      }

      .content-text-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px 150px 20px 20px;
        background: rgb(8,80,120);
        background: -moz-linear-gradient(135deg, rgba(8,80,120,1) 5.47%, rgba(46,122,146,1) 42.98%, rgba(88,167,175,1) 65.89%, rgba(133,216,206,1) 87.25%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(135deg, rgba(8,80,120,1) 5.47%, rgba(46,122,146,1) 42.98%, rgba(88,167,175,1) 65.89%, rgba(133,216,206,1) 87.25%, rgba(255,255,255,1) 100%);
        background: linear-gradient(135deg, rgba(8,80,120,1) 5.47%, rgba(46,122,146,1) 42.98%, rgba(88,167,175,1) 65.89%, rgba(133,216,206,1) 87.25%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#085078",endColorstr="#ffffff",GradientType=1);
      }

      .text-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      .content-text-inside-wrapper {
        padding: 5.2% 8% 6%;
      }

      .header {
        ${VARIABLES.TYPOGRAPHYS.HEADING._2};
        color: white;
        display: inline-block;
        position: relative;
        padding-bottom: 7%;
        margin-bottom: 8%;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 14px);
          bottom: 0;
          height: 2px;
          background-color: white;
        }
      }

      .description {
        ${VARIABLES.TYPOGRAPHYS.BODY._3}
        color: white;
        overflow-x: hidden;
        overflow-y:auto;
        max-height: 500px;
        padding: 0 10px;
      }
    }

    &.mission,
    &:nth-child(2n) {
      .content-text {
        margin-left: 0;
      }

      .content-img {
        border-radius: 139px 10px 10px 10px;
      }

      .content-img-wrapper {
        right: 0;
        justify-content: end;

        &::before {
          content: '';
          border-radius: 8px 100px 8px 8px;
          right: 0;
          left: initial;
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._5}) {
    .vision-and-mission {
      padding: 65px 15px;
    }
  }

  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
    .vision-and-mission {
      padding: 55px 15px;
    }
  }

  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
    .vision-and-mission {
      padding: 45px 15px;
    }

    .content {
      .content-text {
        width: 75%;
        margin-top: 40%;
        margin-left: 25%;
      }
    }
  }

  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
    .vision-and-mission {
      padding: 35px 15px;
    }

    .content {
      .content-text {
        width: 82%;
        margin-top: 50%;
        margin-left: 18%;
      }
    }
  }

  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
    .vision-and-mission {
      padding: 25px 15px;
    }
  }
`
