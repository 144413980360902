import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { CorporateDocumentsStyle } from "./styled";
import { Button, PageHeader } from "../../Global";
import { SaveFile, corporateDocuments } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import { useDispatch, useSelector } from "react-redux";

const CorporateDocumentsComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [DataPage, setDataPage] = useState(null);
  const [Data, setData] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const corporateDocumentsData = async () => {
      const ResponsePage = await corporateDocuments("page-title");
      const ResponseContent = await corporateDocuments('', { "sort[createdAt]": "desc" });
      setDataPage(ResponsePage.data);
      setData(ResponseContent.data);
    };

    corporateDocumentsData();
  }, []);

  const CardCharter = ({ title, url, fileName }) => {
    return (
      <div className="card">
        <div className="card-header">
          <img
            className="logo"
            src="../asset/media/images/icons/icon-logo-BioTec-white.png"
            alt="header-card"
          />
        </div>
        <div className="card-body">
          <div className="card-body-content">
            <div>
              <img
                src="../asset/media/images/icons/icon-note-green.svg"
                alt="icon-note"
              />
            </div>
            <div className='card-name'>
              <span className='name'>{title}</span>
              <span className='sub-name'>{title}</span>
            </div>
          </div>
          <Button
            className="medium-secondary btn-card-download"
            onClick={() => {
              SaveFile(BASE_URL_UPLOAD + url, fileName);
            }}
          >
            <img
              className="icon-download"
              src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
              alt="icon"
            />
            <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>
              {translate["common.download_document"]}
            </span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <CorporateDocumentsStyle>
      <PageHeader
        pageHeaderImage={
          DataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD +
          DataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/", text: translate["menu.corporate_governances"] },
          {
            url: ROUTE_PATH.CORPORATE_DOCUMENTS.LINK,
            text: DataPage?.attributes.menu_text,
          },
        ]}
        title={DataPage?.attributes.menu_text}
      />

      <div className="background-page"></div>

      <div className="page-content-wrapper content-wrapper">
        <div className="page-content fluid">
          <div className="corporate-governance-document-content">
            <div className="card-wrapper">
              {Data?.map((e, i) => (
                <CardCharter
                  key={i}
                  title={e.attributes.file_title}
                  url={e.attributes.file.data.attributes.url}
                  fileName={e.attributes.file.data.attributes.name}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </CorporateDocumentsStyle>
  );
};

export default CorporateDocumentsComponent;
