import { ContactUsPageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import { Input, InputTextarea, PageHeader, Modal } from "../../Global";
import React, { useEffect, useState } from "react";
import { CtUs, PagesContactUs } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useDispatch, useSelector } from "react-redux";

const ContactUsComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    topic: "",
    message: "",
  });

  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
  });

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const IRdata = async () => {
      const Response = await PagesContactUs();
      setData(Response?.data);
    };

    IRdata();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        surname: formData.surname,
        phone: formData.phone,
        email: formData.email,
        topic: formData.topic,
        message: formData.message,
        datetime: new Date().toISOString(),
      };

      const response = await CtUs(payload);
      if (response) {
        setOpenModal({ isOpen: true });
        setFormData({
          name: "",
          surname: "",
          phone: "",
          email: "",
          topic: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error submitting form", error);
      alert(translate["contract_us.message_error.submitting_form"]);
    }
  };

  let mockModal = [
    {
      id: 1,
      title: "ขอเชิญประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
      date: "วันจันทร์ที่ 24 เมษายน 2566 เวลา 14.00 น.",
      contact: "โดยประชุมผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
      img: "../../asset/media/images/icons/icon-warning-message-confirmed.svg",
      alt: "icon-warning-message-confirmed",
      test: translate["contract_us.modal_form_success"],
    },
  ];

  return (
    <ContactUsPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          {
            url: ROUTE_PATH.CONTACT_US.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="investor-relations-contact">
          <div className="contact-location">
            <div className="contact">
              <p className="contact-title">
                {data?.attributes?.office_base_text}
              </p>
              <div className="contact-content">
                <img
                  className="icon icon-location"
                  src="../asset/media/images/icons/icon-location-white.svg"
                  alt="icon-location-white"
                />
                <p className="content-text">{data?.attributes?.address}</p>
              </div>
              <div className="contact-content">
                <img
                  className="icon icon-phone"
                  src="../asset/media/images/icons/icon-phone-white.svg"
                  alt="icon-phone-white"
                />
                <p className="content-text">
                  <ReactMarkdown
                    children={data?.attributes?.phone
                      ?.replaceAll(",", ",<br/>")}
                    rehypePlugins={[rehypeRaw]}>
                  </ReactMarkdown>
                </p>
              </div>
              <div className="contact-content">
                <img
                  className="icon icon-printer"
                  src="../asset/media/images/icons/icon-printer-white.svg"
                  alt="icon-printer-white"
                />
                <p className="content-text">
                  <ReactMarkdown
                    children={data?.attributes?.fax
                      ?.replaceAll(",", ",<br/>")}
                    rehypePlugins={[rehypeRaw]}>
                  </ReactMarkdown>
                </p>
              </div>
              <div className="contact-content">
                <img
                  className="icon icon-email"
                  src="../asset/media/images/icons/icon-email-white.svg"
                  alt="icon-email-white"
                />
                <p className="content-text">{data?.attributes?.email}</p>
              </div>
              <div className="contact-content google-maps">
                <span className="map">
                  <img
                    className="icon icon-map"
                    src="../asset/media/images/icons/icon-map-white.svg"
                    alt="icon-map-white"
                  />
                  <a
                    className="link content-text"
                    href={data?.attributes?.googlemap_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {translate["contract_us.google_map_link"]}
                  </a>
                </span>
              </div>
            </div>
            <div className="location">
              {
                data?.attributes?.googlemap_iframe?.includes("iframe")
                  ?
                  (
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={data?.attributes?.googlemap_iframe}
                    ></ReactMarkdown>
                  )
                  :
                  (
                    <iframe src={data?.attributes?.googlemap_iframe} title="contact us"></iframe>
                  )
              }
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="contact-form">
              <div className="title">{data?.attributes?.form_title}</div>
              <div className="sub-title">{data?.attributes?.description}</div>
              <div className="form">
                <span className="form-name-lastname">
                  <div className="form-name">
                    <Input
                      required
                      label={translate["contract_us.first_name"]}
                      type="text"
                      placeholder={translate["contract_us.first_name"]}
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-lastname">
                    <Input
                      required
                      label={translate["contract_us.last_name"]}
                      type="text"
                      placeholder={translate["contract_us.last_name"]}
                      value={formData.surname}
                      onChange={(e) =>
                        setFormData({ ...formData, surname: e.target.value })
                      }
                    />
                  </div>
                </span>
                <span className="form-ipone-email">
                  <div className="form-ipone">
                    <Input
                      required
                      label={translate["contract_us.phone_number"]}
                      type="text"
                      placeholder={translate["contract_us.phone_number"]}
                      value={formData.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-email">
                    <Input
                      required
                      label={translate["contract_us.email"]}
                      type="text"
                      placeholder={translate["contract_us.email"]}
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </span>
                <div className="form-toppic">
                  <Input
                    required
                    label={translate["contract_us.section"]}
                    type="text"
                    placeholder={translate["contract_us.section"]}
                    value={formData.topic}
                    onChange={(e) =>
                      setFormData({ ...formData, topic: e.target.value })
                    }
                  />
                </div>
                <div className="form-message">
                  <InputTextarea
                    required
                    label={translate["contract_us.message"]}
                    type="text"
                    placeholder={translate["contract_us.message"]}
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="btn-submit-message">
                <button
                  className="btn-inputform submit-message medium-secondary-outline"
                  type="submit"
                >
                  {translate["contract_us.send_message"]}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Modal */}
      {
        openModal.isOpen && (
          <Modal
            modalWarningMessage
            children={mockModal[0].test}
            srcImage={mockModal[0].img}
            alt={mockModal[0].alt}
            onClose={(e) => {
              setOpenModal(e);
            }}
          />
        )
      }
    </ContactUsPageStyle >
  );
};

export default ContactUsComponent;
