import { NewAndActivityCorporateNewStyle } from "./styled";
import {
  Button,
  PageHeader,
} from "../../../Global";
import SelectBox from "../../../Global/SelectBox";
// Slick slide
// https://www.npmjs.com/package/react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import CardImgAndText from "../Components/CardImgAndText";
import CardImg from "../Components/CardImg";
import CardText from "../Components/CardText";
import {
  PagesCorporateCSR,
  CorporateCSROfDate,
  CorporateCSROfYear,
} from "../../../../Service/policy/coporateNEWSandCSR/coporate";
import { getBaseUploadUrl } from "../../../../Service/service";
import OneCard from "../elements/CSR-NEWS/OneCard";
import TwoCard from "../elements/CSR-NEWS/TwoCard";
import { useDispatch, useSelector } from "react-redux";
import { langTh } from "../../../../store/lang";
import moment from "moment";
import { displayYearBuddhistEra, removeDuplicates, displayYear } from "../../../../helpers";

const goToTopPage = () => {
  window.scrollTo(0, 0);
};

const NewAndActivityCorporateNewComponent = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const [content, setContent] = useState(null);
  const [date, setDate] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [showAllContent, setShowAllContent] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const addYear = lang === langTh ? 543 : 0;

  const defaultOptionYears = [{ value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date()) }]
  const [optionYears, setOptionYears] = useState(defaultOptionYears);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = (date.getMonth() + 1) > 12 ? 12 : date.getMonth() + 1;
    const year = date.getFullYear() + addYear;
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const NewAndActivityCorporateNew = async () => {
      try {
        const ResponsePage = await PagesCorporateCSR();
        const ResponseDate = await CorporateCSROfDate();
        setData(ResponsePage?.data);
        setDate(ResponseDate?.data);
        setIsMounted(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    NewAndActivityCorporateNew();
  }, []);

  useEffect(() => {
    const updateContentByYear = async () => {
      const payload = {
        "sort[datetime]": "desc"
      }
      const responseContent = await CorporateCSROfYear(selectedYear, payload);
      setContent(responseContent?.data);
    };

    selectedYear && updateContentByYear();
  }, [selectedYear]);

  const onChangeYear = (e) => {
    setSelectedYear(e.value);
  };

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) =>
      years.push(new Date(e?.attributes?.datetime).getFullYear())
    );

    years = removeDuplicates(years);
    years?.map((e) => yearsData.push({ value: e, label: lang === langTh ? displayYearBuddhistEra(e) : e }));

    setOptionYears(yearsData);
    setSelectedYear(years[0]);
  }, [date]);

  const visibleContent = showAllContent ? content : content?.slice(0, 10);

  return (
    <NewAndActivityCorporateNewStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/about", text: translate["menu.news_and_activities"] },
          {
            url: "/news-activity/csr",
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="activity-page">
        <div className="new-activity-corporate">
          <div className="year">
            <span>
              {translate["common.year"]}
              {isMounted && (
                <SelectBox
                  defaultValue={optionYears[0]}
                  options={optionYears}
                  onChange={(e) => onChangeYear(e)}
                  isSearchable={false}
                />
              )}
            </span>
          </div>
          <div className="corporate corporate-first">
            {content?.length === 1 &&
              content?.map((e, i) => (
                <OneCard
                  page="csr"
                  image={
                    BASE_URL_UPLOAD +
                    e?.attributes?.preview_image?.data?.attributes?.url
                  }
                  date={formatDate(e?.attributes?.datetime)}
                  title={e?.attributes?.title}
                  detail={e?.attributes?.short_description}
                  id={e?.id}
                  key={i}
                />
              ))}
            <span className="two-card-centent">
              {content?.length === 2 &&
                content?.map((e, i) => (
                  <TwoCard
                    page="csr"
                    image={
                      BASE_URL_UPLOAD +
                      e?.attributes?.preview_image?.data?.attributes?.url
                    }
                    date={formatDate(e?.attributes?.datetime)}
                    title={e?.attributes?.title}
                    detail={e?.attributes?.short_description}
                    id={e?.id}
                    key={i}
                  />
                ))}
            </span>
          </div>
          {content?.length === 3 && (
            <>
              <OneCard
                page="csr"
                image={
                  BASE_URL_UPLOAD +
                  content[0]?.attributes?.preview_image?.data?.attributes?.url
                }
                date={formatDate(content[0]?.attributes?.datetime)}
                title={content[0]?.attributes?.title}
                detail={content[0]?.attributes?.short_description}
                id={content[0]?.id}
              />
              <div className="corporate-csr-three-content">
                {content.slice(1).map((e, i) => (
                  <TwoCard
                    page="csr"
                    image={
                      BASE_URL_UPLOAD +
                      e?.attributes?.preview_image?.data?.attributes?.url
                    }
                    date={formatDate(e?.attributes?.datetime)}
                    title={e?.attributes?.title}
                    detail={e?.attributes?.short_description}
                    id={e?.id}
                    key={i}
                  />
                ))}
              </div>
            </>
          )}
          {content?.length >= 4 && (
            <>
              <div className="corporate corporate-second">
                {visibleContent?.map((e, i) => (
                  <React.Fragment key={e?.id || i}>
                    {i === 0 && (
                      <CardImgAndText
                        page="csr"
                        key={i}
                        id={e?.id}
                        header={e?.header}
                        title={e?.attributes?.title}
                        detail={e?.attributes?.short_description}
                        image={
                          BASE_URL_UPLOAD +
                          e?.attributes?.preview_image?.data?.attributes?.url
                        }
                        date={formatDate(e?.attributes?.datetime)}
                      />
                    )}

                    {i === 1 && (
                      <CardImg
                        page="csr"
                        key={i}
                        id={e?.id}
                        image={
                          BASE_URL_UPLOAD +
                          e?.attributes?.preview_image?.data?.attributes?.url
                        }
                        date={formatDate(e?.attributes?.datetime)}
                        title={e?.attributes?.title}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>

              <div className="corporate corporate-third desktop">
                {visibleContent?.map((e, i) => (
                  <React.Fragment key={e?.id || i}>
                    {i === 2 && (
                      <CardImg
                        page="csr"
                        key={i}
                        id={e?.id}
                        image={
                          BASE_URL_UPLOAD +
                          e?.attributes?.preview_image?.data?.attributes
                            ?.formats?.thumbnail?.url
                        }
                        date={formatDate(e?.attributes?.datetime)}
                        title={e?.attributes?.title}
                      />
                    )}

                    {i === 3 && (
                      <CardImgAndText
                        page="csr"
                        key={i}
                        id={e?.id}
                        header={e?.header}
                        title={e?.attributes?.title}
                        detail={e?.attributes?.short_description}
                        image={
                          BASE_URL_UPLOAD +
                          e?.attributes?.preview_image?.data?.attributes?.url
                        }
                        date={formatDate(e?.attributes?.datetime)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>

              <div className="corporate corporate-third mobile">
                {visibleContent?.map((e, i) => (
                  <React.Fragment key={e?.id || i}>
                    {i === 2 && (
                      <CardImgAndText
                        page="csr"
                        key={i}
                        id={e?.id}
                        header={e?.header}
                        title={e?.attributes?.title}
                        detail={e?.attributes?.short_description}
                        image={
                          BASE_URL_UPLOAD +
                          e?.attributes?.preview_image?.data?.attributes?.url
                        }
                        date={formatDate(e?.attributes?.datetime)}
                      />
                    )}

                    {i === 3 && (
                      <CardImg
                        page="csr"
                        key={i}
                        id={e?.id}
                        image={
                          BASE_URL_UPLOAD +
                          e?.attributes?.preview_image?.data?.attributes
                            ?.formats?.thumbnail?.url
                        }
                        date={formatDate(e?.attributes?.datetime)}
                        title={e?.attributes?.title}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>

              <div className="block-item-content">
                {visibleContent?.map((e, i) => (
                  <React.Fragment key={e?.id || i}>
                    {i > 3 && (
                      <>
                        {i % 2 === 0 ? (
                          <CardText
                            page="csr"
                            key={i}
                            id={e?.id}
                            theme={"card-text-darkblue"}
                            date={formatDate(e?.attributes?.datetime)}
                            title={e?.attributes?.title}
                            detail={e?.attributes?.short_description}
                          />
                        ) : (
                          <CardText
                            page="csr"
                            key={i}
                            id={e?.id}
                            theme={"card-text-white"}
                            date={formatDate(e?.attributes?.datetime)}
                            title={e?.attributes?.title}
                            detail={e?.attributes?.short_description}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
          {content?.length > 10 && !showAllContent && (
            <div className="button-more">
              <Button
                className="medium-secondary-outline"
                onClick={() => setShowAllContent(true)}
              >
                {translate["common.see_more"]}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className="up"
        onClick={() => {
          goToTopPage();
        }}
      >
        <img
          src="../asset/media/images/icons/icon-chevron-up-white.png"
          alt=""
        />
      </div>
    </NewAndActivityCorporateNewStyle>
  );
};
export default NewAndActivityCorporateNewComponent;
