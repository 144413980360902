import classNames from "classnames";
import { CardDataSheetStyle } from "./styled";
import { SaveFile } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";

export default function CardDataSheet({
  className,
  id,
  cardImage,
  cardTitle,
  btnName,
  themeColor,
  file_name,
  file_url,
}) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  return (
    <CardDataSheetStyle
      className={classNames("card-data-sheet-wrapper", className)}
    >
      <div className="block-card-data-sheet">
        <div className="block-left">
          <div className="img-logo">
            <img
              src="../asset/media/images/contents/biotec-logo-font-blue.png"
              alt="logo-biotec"
            />
          </div>
          <img src={cardImage} alt={`financial-data-sheet${id}`} />
        </div>
        <div className="block-right">
          {cardTitle ? (
            <div className='title card-name'>
              <span className='name'>{cardTitle}</span>
              <span className={`sub-name sub-name-card-business ${themeColor}`}>{cardTitle}</span>
            </div>
          ) : (
            <div className="title">No title</div>
          )}

          <div className="btn-download-file">
            {btnName && (
              <div
                className={`file-download ${themeColor}`}
                onClick={() => {
                  SaveFile(BASE_URL_UPLOAD + file_url, file_name);
                }}
              >
                {themeColor ? (
                  <img
                    className="icon icon-download"
                    src={`../asset/media/images/icons/icon-download-${themeColor}.svg`}
                    alt={`icon-download-${themeColor}`}
                  />
                ) : (
                  <img
                    className="icon icon-download"
                    src={`../asset/media/images/icons/icon-download-green.svg`}
                    alt={`icon-download-green`}
                  />
                )}
                {btnName}
              </div>
            )}
          </div>
        </div>
      </div>
    </CardDataSheetStyle>
  );
}
