import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const HomeInvestorStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .canvasjs-chart-credit {
    display: none;
  }
  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }
  
  @keyframes mousescroll {
    0% { bottom: 50px; }
    50% { bottom: 20px; }
    100% { bottom: 50px; }
  }

  .mouse-scroll {
    position: absolute;
    bottom: 50px;
    left: 40px;
    animation: mousescroll 3s infinite;
    cursor: pointer;

    .btn-mouse-scroll {
      border-color: transparent;
      padding: 15px;
      background-color: transparent;
    }

    .img-mouse-scroll {
      width: 100%;
      height: auto;
    }

    @media (max-width: 800px) {
      left: 50vw;
      transform: translateX(-33px);
    }
  }

  .bg-sec-fourth-fifth {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../asset/media/images/contents/bg-home-ir.png");
    }
  }

  .bg-sec-fourth-seventh {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("../asset/media/images/contents/bg-home-ir-01.png");
    }
  }

  .block-banner-investor {
    position: relative;
    width: 100%;
    height: 100vh;
    margin-top: -120px;
    overflow: hidden;

    @media (max-width: 1100px) {
      margin-top: 0;
    }

    .title-wrapper {
      position: absolute;
      top: 15%;
      left: 50%;
      width: 100%;
      height: 600px;
      min-width: 200px;
      max-width: 1920px;
      transform: translate(-50%);
      z-index: 990;

      @media (max-width: 1100px) {
        top: 35%;
      }

      @media (max-width: 375px) {
        top: 20%;
      }
    }

    .video-banner,
    .img-banner {
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }

    .title {
      z-index: 2;
      position: absolute;
      top: 34%;
      left: 4%;
      color: ${VARIABLES.COLOR.NEUTRAL15};
      font-size: 64px;
      font-weight: 700;

      @media (max-width: 1100px) {
        top: 8%;
        left: 5%;
      }

      @media (max-width: 800px) {
        top: 25%;
        left: 5%;
        margin-left: -5%;
        width: 100%;
        text-align: center;
      }

      @media (max-width: 500px) {
        font-size: 55px;
      }

      @media (max-height: 430px) {
        top: -8%;
      }
    }
    .content-wrapper {
      z-index: 990;

      &.set-biotec {
        position: relative;
      }

      .block-set-biotec {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 24px 24px 16px 24px;
        width: 100%;
        max-width: 660px;
        border-radius: 24px 0px 0px 0px;
        background-color: rgba(210, 225, 254, 0.3);
        backdrop-filter: blur(10px);

        @media (max-width: 800px) {
          display: none;
        }

        .datas-chart-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &.last-item {
            margin-bottom: 0;
          }

          .first-item-left {
            .left {
              color: #ffffff;
              text-align: center;
              font-size: 34px;
              font-weight: 700;
              letter-spacing: 0.8px;
            }
          }

          .first-item-middle {
            color: #00204f;
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 0.56px;

            span {
              font-size: 24px;
            }
          }

          .first-item-right {
            font-size: 16px;

            .right {
              padding: 5px 16px;
              border-radius: 16px;
              color: #ffffff;
              background-color: ${VARIABLES.COLOR.SECONDARY_NEUTRAL1};
            }

            .right-minus {
              padding: 5px 16px;
              border-radius: 16px;
              color: #ffffff;
              background-color: ${VARIABLES.COLOR.RED1};
            }
          }

          .second-item-left,
          .second-item-middle,
          .second-item-right {
            .top {
              margin-bottom: 5px;
              color: #ffffff;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0.36px;
            }

            .button {
              color: #00204f;
              text-align: center;
              font-size: 22px;
              font-weight: 600;
              letter-spacing: 0.48px;
            }
          }

          .third-item-left {
            .left {
              color: #e9ecef;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0.28px;
            }
          }
        }
      }
    }
  }

  .home-investor {
    .block-section {
      position: relative;

      &.sec-first {
        padding: 25px;
        max-width: 600px;
        margin: 0 auto;
        display: none;

        @media (max-width: 800px) {
          display: block;
        }

        .block-set-biotec {
          position: relative;

          .title-set-biotec {
            color: #5eac1b;
            text-align: center;
            font-size: 34px;
            font-weight: 700;
            letter-spacing: 0.64px;
            margin-bottom: 10px;
          }

          .block-last-price {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 15px;
            max-width: 400px;
            margin: auto;
            margin-bottom: 20px;

            .last-price {
              color: #1b3b6a;
              text-align: center;
              font-size: 30px;
              font-weight: 700;
              letter-spacing: 0.56px;

              span {
                font-size: 20px;
              }
            }

            .right,
            .right-minus {
              color: #f8f9fa;
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0.24px;
            }

            .right {
              width: fit-content;
              padding: 5px 16px;
              border-radius: 16px;
              color: #ffffff;
              background-color: ${VARIABLES.COLOR.SECONDARY_NEUTRAL1};
            }

            .right-minus {
              width: fit-content;
              padding: 5px 16px;
              border-radius: 16px;
              color: #ffffff;
              background-color: ${VARIABLES.COLOR.RED1};
            }
          }

          .block-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 15px;
            border-bottom: 1px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            margin-bottom: 15px;

            .title-item {
              color: ${VARIABLES.COLOR.NEUTRAL1};
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0.32px;
            }

            .price-item {
              color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              font-size: 22px;
              font-weight: 500;
              letter-spacing: 0.4px;
            }
          }

          .block-updated-when {
            .updated-when {
              color: ${VARIABLES.COLOR.NEUTRAL10};
              text-align: center;
              font-size: 16px;
              font-weight: 300;
              letter-spacing: 0.28px;
            }
          }

          @media (max-width: 600px) {
            .title-set-biotec {
              font-size: 32px;
            }

            .block-last-price {
              .last-price {
                font-size: 28px;

                span {
                  font-size: 18px;
                }
              }

              .right,
              .right-minus {
                font-size: 12px;
              }
            }

            .block-item {
              .title-item {
                font-size: 16px;
              }

              .price-item {
                font-size: 20px;
              }
            }

            .block-updated-when {
              .updated-when {
                font-size: 14px;
              }
            }
          }

          @media (max-width: 350px) {
            .block-item {
              display: block;
              text-align: center;

              .title-item {
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      &.sec-second {
        margin-top: 50px;
        padding: 10px 40px;

        @media (max-width: 800px) {
          margin-top: 25px;
        }

        &:before {
          position: absolute;
          content: "";
          bottom: -80px;
          left: -60px;
          width: 40%;
          height: 70%;
          border-radius: 50%;
          background: linear-gradient(0.25turn, #0e78be 80%, #81c341 20%);
          opacity: 0.5;
          filter: blur(130px);
        }
      }

      .section-left {
        padding: 10px 25px;
        border-radius: 15px;
        background-color: ${VARIABLES.COLOR.NEUTRAL7};
        transform: translate(0, -35%);

        .block-charts {
          position: relative;

          .chart {
            width: 100%;
            height: auto;

            div {
              max-height: 170px;

              &:first-child {
                margin-bottom: 15px;
              }
            }
          }

          .block-latest-price {
            position: absolute;
            display: flex;
            top: 0;
            right: -15px;
            transform: translateX(190px);

            .icon-latest-price {
              margin-right: 10px;
              width: 35px;
              height: 35px;
            }

            .text-latest-price {
              font-size: 24px;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }

      .section-right {
        padding-left: 22px;

        .block-datas-chart {
          .datas-chart-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;

            .first-item-left {
              .left {
                font-size: 40px;
                font-weight: 700;
                color: ${VARIABLES.COLOR.SECONDARY_NEUTRAL1};
              }
            }

            .first-item-middle {
              .middle {
                font-size: 40px;
                font-weight: 700;
                color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }
            }

            .first-item-right {
              .right {
                padding: 5px 16px;
                border-radius: 16px;
                color: #ffffff;
                background-color: ${VARIABLES.COLOR.GREEN};
              }
              .right-minus {
                padding: 5px 16px;
                border-radius: 16px;
                color: #ffffff;
                background-color: ${VARIABLES.COLOR.RED1};
              }
            }

            .second-item-left,
            .second-item-middle,
            .second-item-right {
              padding: 0 10px 0 0;

              .top {
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 8px;
              }

              .button {
                color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                font-size: 24px;
                font-weight: 500;
              }
            }

            .third-item-left {
              .left {
                font-size: 14px;
                font-weight: 400;
                color: ${VARIABLES.COLOR.NEUTRAL10};
              }
            }

            .fourth-item-right {
              margin-left: auto;
            }
          }
        }
      }

      .stock-market-news {
        position: relative;
        display: flex;
        width: 100%;
        margin-bottom: 30px;

        .frame-ann {
          width: 100%;
          max-width: 428px;
          padding-right: 10px;

          .ann-header {
            .title {
              position: relative;
              font-size: 48px;
              font-weight: 500;
              padding-bottom: 15px;
              margin-bottom: 15px;

              .rectangle {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 80px;
                height: 4px;
                background: linear-gradient(
                  109deg,
                  #082a6b 0%,
                  #093383 32.29%,
                  #118c33 76.04%,
                  #afe53c 100%
                );
              }
            }

            .ann-des {
              .des-text {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }
        }

        .slide-ann-frame {
          width: 70%;
          margin: 0 auto;
          /* padding-left: 15px; */
          flex-direction: column;

          .slick-slider {
            .slick-list {
              .slick-track {
                .slick-current {
                  div {
                    .slider-item {
                      .card-ann-wrapper {
                        background-color: ${VARIABLES.COLOR.NEUTRAL17};
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .important-financial-information {
        margin-top: 25px;
        padding: 45px 40px;
        animation: mymove2 5s infinite;
        background-size: cover;
        background-repeat: no-repeat;

        @keyframes mymove2 {
          0% {
            background-image: url("../asset/media/images/contents/bg-important-financial-information-1.png");
          }
          25% {
            background-image: url("../asset/media/images/contents/bg-important-financial-information-2.png");
          }
          50% {
            background-image: url("../asset/media/images/contents/bg-important-financial-information-3.png");
          }
          75% {
            background-image: url("../asset/media/images/contents/bg-important-financial-information-4.png");
          }
          100% {
            background-image: url("../asset/media/images/contents/bg-important-financial-information-1.png");
          }
        }

        .title-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 50px;

          .title-text {
            ${VARIABLES.TYPOGRAPHYS.HEADING._6}
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          }
        }

        .block-information {
          display: flex;
          justify-content: space-between;
          padding: 26px;
          border-radius: 20px;
          background-color: #fafafb;
          box-shadow: 6px 6px 30px 0px rgba(225, 235, 255, 0.5);

          .information-left,
          .information-middle,
          .information-right {
            display: flex;
            align-items: center;

            .icon {
              width: 92px;
              height: 80px;
            }

            .information {
              margin-left: 10px;

              .top-text {
                margin-bottom: 5px;
                font-size: 24px;
                font-weight: 500;
                color: ${VARIABLES.COLOR.NEUTRAL10};
              }

              .bottom-text {
                font-size: 24px;
                font-weight: 700;
                color: ${VARIABLES.COLOR.NEUTRAL18};
              }
            }
          }

          @media (max-width: 800.98px) {
            display: block;
            width: fit-content;
            margin: auto;
          }
        }

        .block-charts {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
          width: 100%;
          margin: 52px 0;
          @media (max-width: 800.98px) {
            display: block;
          }
          .charts-service-income,
          .charts-total-assets,
          .charts-net-profit {
            text-align: center;
            width: 100%;
            max-width: 330px;
            padding: 22px 30px;
            margin: 0 auto;
            background-color: #ffffff;
            border-radius: 15px;
            box-shadow: 0px 10px 20px -10px #d9d9d9;

            @media (max-width: 1100.98px) {
              max-width: 230px;
            }

            @media (max-width: 800.98px) {
              max-width: 330px;
              margin: 25px auto;
            }

            .charts {
              .service-income,
              .total-assets,
              .net-profit {
                & > div {
                  height: 400px !important;
                }

                @media (max-width: 1100.98px) {
                  & > div {
                    width: 200px;
                    height: 350px !important;
                  }
                  canvas {
                    width: 200px;
                    height: auto;
                  }
                }

                @media (max-width: 800.98px) {
                  & > div {
                    width: unset;
                  }
                  canvas {
                    width: unset;
                    height: unset;
                  }
                }
              }
            }

            .charts-title {
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: 500;
              color: #000000;
            }

            .sub-title {
              font-size: 14px;
              font-weight: 500;
              color: ${VARIABLES.COLOR.NEUTRAL3};
            }
          }
        }

        .block-stable {
          display: flex;
          justify-content: space-around;
          padding: 25px 40px;

          .stable-left,
          .stable-middle,
          .stable-right {
            display: flex;
            align-items: center;

            .icon {
              width: 70px;
              height: 70px;
            }

            .stable {
              margin-left: 25px;

              .top-text {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 5px;
              }

              .bottom-text {
                font-size: 28px;
                font-weight: 700;
                color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
              }
            }
          }

          @media (max-width: 800.98px) {
            display: block;

            .stable-left,
            .stable-middle,
            .stable-right {
              display: flex;
              justify-content: center;
              width: 100%;
              margin: 25px 0;
            }
          }
        }
      }

      .blcok-latest-releases {
        padding: 60px;

        .title {
          color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        .block-card {
          display: grid;
          gap: 15px;
          grid-template-columns: 1fr 1fr 1fr;
          justify-items: center;
        }
      }

      .block-investor-kits {
        padding: 60px;

        .investor-kits {
          display: flex;
          overflow: hidden;

          .investor-kits-content {
            width: 50%;
            padding: 0 85px;
            align-self: center;

            .title {
              font-size: 48px;
              font-weight: 700;
              margin-bottom: 15px;
            }

            .description {
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 50px;
            }

            .btn-download-wrapper {
              .btn-download {
                /* max-width: 180px; */
                max-width: fit-content;

                &:hover {
                  img {
                    border-radius: 12px;
                    background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
                  }
                }
              }
            }
          }

          .investor-kits-img {
            position: relative;
            width: 50%;
            padding: 25px;

            .block-image-content {
              position: relative;
              margin: auto;
              width: 100%;
              height: 100%;
              max-width: 387px;
              max-height: 494px;

              .image-content {
                position: relative;
                width: 100%;
                height: 100%;
                min-height: 400px;
                border-radius: 18px;
                fill: lightgray 50% / cover no-repeat;
                filter: drop-shadow(0px 0px 17px rgba(27, 59, 106, 0.5));
                z-index: 1;
              }

              .bg-white {
                position: absolute;
                padding: 18px;
                border-radius: 10px;
                background-color: #ffffff;
                filter: drop-shadow(0px 0px 24px #193969);
                max-width: 95px;
                max-height: 85px;
                z-index: 1;

                &.left {
                  top: 0;
                  left: 0;
                  transform: translate(-40px, 60px);
                }

                &.right {
                  bottom: 0;
                  right: 0;
                  transform: translate(50px, -40px);
                }

                .icon-pie-chart {
                  width: 50px;
                  height: 50px;
                }

                .icon-graph-up-arrow {
                  width: 50px;
                  height: 50px;
                }
              }

              .bg-blue {
                position: absolute;
                top: -40px;
                left: -70px;
                width: 84%;
                height: 100%;
                border-radius: 28px;
                opacity: 0.8;
                transform: rotate(155deg);
                background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }

              @media (max-width: 763.98px) {
                max-width: 240px;

                .image-content {
                  min-height: 300px;
                }

                .bg-white {
                  &.left {
                    max-height: 66px;

                    .icon-pie-chart {
                      width: 30px;
                      height: 30px;
                    }
                  }

                  &.right {
                    max-height: 66px;
                    right: 15px;

                    .icon-graph-up-arrow {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }

              @media (max-width: 430.98px) {
                max-width: 180px;

                .image-content {
                  min-height: 200px;
                }

                .bg-white {
                  &.left {
                    max-height: 56px;

                    .icon-pie-chart {
                      width: 20px;
                      height: 20px;
                    }
                  }

                  &.right {
                    max-height: 56px;
                    right: 15px;

                    .icon-graph-up-arrow {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }

                .bg-blue {
                  top: -20px;
                  left: -40px;
                  width: 84%;
                  height: 100%;
                  border-radius: 28px;
                }
              }
            }
          }
        }
      }

      .block-schedule-ir-activities {
        padding: 60px;

        .schedule-ir-activities {
          display: flex;
          overflow: hidden;

          .schedule-ir-activities-img {
            position: relative;
            width: 50%;
            padding: 25px;
            transform: translateY(-60px);

            .block-image-content {
              position: relative;
              margin: auto;
              width: 100%;
              height: 100%;
              max-width: 387px;
              max-height: 494px;

              .image-content {
                position: relative;
                width: 100%;
                height: 100%;
                min-height: 400px;
                border-radius: 250px 20px 20px 20px;
                fill: lightgray 50% / cover no-repeat;
                filter: drop-shadow(0px 0px 17px rgba(27, 59, 106, 0.5));
              }

              .knife-shape {
                position: absolute;
                top: 0;
                left: 20px;
                width: 70%;
                height: 61%;
                border-radius: 20px 220px 0px 0px;
                background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }

              .circle-shape {
                position: absolute;
                bottom: 50px;
                right: -70px;
                width: 32%;
                height: 25%;
                border-radius: 50%;
                background-color: ${VARIABLES.COLOR.PRIMARY_LIGHT};
              }

              .leaf-shape {
                position: absolute;
                bottom: 0;
                right: -120px;
                width: 55%;
                height: 25%;
                border-radius: 120px 15px;
                background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }
            }
          }

          .schedule-ir-activities-content {
            width: 50%;
            padding: 0 15px;

            .block-schedule {
              .title {
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 20px;
                color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }

              .card-activities {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .card-left {
                  color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

                  .card-left-title {
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 13px;
                  }

                  .card-date,
                  .card-times {
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 13px;

                    .icon-date,
                    .icon-times {
                      width: 14px;
                      height: 14px;
                      margin-right: 13px;
                    }
                  }
                }

                .card-right {
                  margin: auto 0;

                  .btn-download-wrapper {
                    .btn-download {
                      min-height: 30px;
                      padding: 0 19px;

                      img {
                        width: 18px;
                        height: 18px;
                      }

                      span {
                        font-size: 12px;
                        font-weight: 400;
                      }

                      &:hover {
                        img {
                          border-radius: 12px;
                          background-color: #5eac1b;
                        }
                      }
                    }
                  }
                }
              }

              .block-btn-schedule {
                margin-top: 15px;

                .btn {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }

      .block-contact-investor-relations {
        &.home-investor {
          padding: 60px;
          position: relative;
          overflow-x: hidden;

          .contact-investor-relations {
            display: flex;

            .contact-investor-relations-content {
              width: 50%;
              padding: 0 85px;
              align-self: center;

              .title {
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 15px;
              }

              .description {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 50px;
              }

              .btn-download-wrapper {
                .btn-download {
                  /* max-width: 180px; */
                  max-width: fit-content;

                  &:hover {
                    img {
                      border-radius: 12px;
                      background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
                    }
                  }
                }
              }
            }

            .contact-investor-relations-img {
              position: relative;
              width: 50%;
              padding: 25px;

              .block-image-content {
                position: relative;
                margin: auto;
                width: 100%;
                height: 100%;
                max-width: 387px;
                max-height: 494px;

                .image-content {
                  position: relative;
                  width: 100%;
                  height: 100%;
                  min-height: 400px;
                  border-radius: 10px 150px;
                  box-shadow: -10px 10px 23px 0px rgba(2, 47, 134, 0.5);
                  object-fit: cover;
                }

                .circle-shape {
                  position: absolute;
                  bottom: 65px;
                  right: -75px;
                  width: 30%;
                  height: 23.5%;
                  border-radius: 50%;
                  background-color: ${VARIABLES.COLOR.BLUE};
                }

                .leaf-shape {
                  position: absolute;
                  bottom: 0;
                  right: -120px;
                  width: 59%;
                  height: 28%;
                  border-radius: 120px 15px;
                  background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                }
              }
            }
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  .btn {
    &.link {
      &.read-more {
        display: flex;
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        ${VARIABLES.TYPOGRAPHYS.BODY._7};
        width: fit-content;
        padding: 10px 10px 10px 0;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .count-number {
    &.home-ir {
      margin: 15px 0;

      &.second {
        display: none;
      }

      .count-current {
        padding-right: 8px;
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        ${VARIABLES.TYPOGRAPHYS.HEADING._1};
      }

      .count-total {
        color: #565656;
        letter-spacing: 2px;
        ${VARIABLES.TYPOGRAPHYS.TITLE._7};
      }
    }
  }

  .button-ann-group {
    &.home-ir {
      display: flex;
      gap: 24px;

      &.second {
        display: none;
      }

      .button-ann-next {
        user-select: none;
        width: 56px;
        height: 56px;
        border-radius: 8px;
        background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      }

      .button-ann-prev {
        user-select: none;
        width: 56px;
        height: 56px;
        border-radius: 8px;
        background-color: ${VARIABLES.COLOR.NEUTRAL17};
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1366px) {
    .count-number {
      &.home-ir {
        &.second {
          display: unset;
        }
      }
    }

    .button-ann-group {
      &.home-ir {
        &.second {
          display: flex;
        }
      }
    }

    .block-section {
      &.sec-second {
        .stock-market-news {
          display: block;

          .frame-ann,
          .slide-ann-frame {
            width: 100%;
            max-width: 100%;

            .ann-header {
              .count-number,
              .button-ann-group {
                &.home-ir {
                  &.first {
                    display: none;
                  }
                }
              }
            }
          }

          .slide-ann-frame {
            .slick-slider {
              padding: 40px 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    .block-section {
      &.sec-fifth,
      &.sec-sixth,
      &.sec-seventh {
        .block-investor-kits {
          padding: 40px;
        }
      }

      &.sec-sixth {
        .block-schedule-ir-activities {
          .schedule-ir-activities {
            flex-direction: column-reverse;

            .schedule-ir-activities-img,
            .schedule-ir-activities-content {
              margin: auto;
            }

            .schedule-ir-activities-img {
              transform: unset;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1150px) {
    .block-section {
      &.sec-fourth {
        .blcok-latest-releases {
          padding: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .block-section {
      /* &.sec-second {
        .stock-market-news {
          .slide-ann-frame {
            max-width: 80%;
          }
        }
      } */

      &.sec-third {
        .important-financial-information {
          /* .block-information {
            display: block;
            width: fit-content;
            margin: auto;
          } */

          /* .block-charts {
            display: block;

            .charts-service-income,
            .charts-total-assets,
            .charts-net-profit {
              margin: 25px auto;
            }
          } */

          /* .block-stable {
            display: block;

            .stable-left,
            .stable-middle,
            .stable-right {
              display: flex;
              justify-content: center;
              width: 100%;
              margin: 25px 0;
            }
          } */
        }
      }

      &.sec-fifth {
        .block-investor-kits {
          .investor-kits {
            display: block;

            .investor-kits-content {
              width: 100%;
              margin-bottom: 15px;
            }

            .investor-kits-img {
              width: 100%;
              margin-top: 120px;
            }
          }
        }
      }

      &.sec-seventh {
        .block-contact-investor-relations {
          &.home-investor {
            .contact-investor-relations {
              display: block;

              .contact-investor-relations-content,
              .contact-investor-relations-img {
                width: 100%;
              }

              .contact-investor-relations-img {
                margin-top: 80px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .block-section {
      &.sec-second {
        .stock-market-news {
          .slide-ann-frame {
            margin: auto;
          }
        }
      }

      &.sec-third {
        .important-financial-information {
          .block-information {
            .information-left,
            .information-middle,
            .information-right {
              margin: 15px 0;
            }
          }
        }
      }

      &.sec-fourth {
        .blcok-latest-releases {
          .block-card {
            justify-items: center;
            grid-template-columns: 1fr 1fr;
          }
        }
      }

      &.sec-sixth {
        .block-schedule-ir-activities {
          .schedule-ir-activities {
            .schedule-ir-activities-img,
            .schedule-ir-activities-content {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .block-section {
      &.sec-second {
        /* margin-top: 0 !important; */

        .stock-market-news {
          .frame-ann {
            .ann-header {
              .title {
                font-size: 35px;
              }

              .ann-des {
                display: none;

                .des-text {
                  font-size: 16px;
                }
              }
            }
          }

          .slide-ann-frame {
            .slick-slider {
              padding: 0;
              padding-top: 20px;
            }
          }
        }
      }

      &.sec-third {
        .important-financial-information {
          .block-information {
            .information-left,
            .information-middle,
            .information-right {
              .icon {
                width: 60px;
                height: 60px;
              }

              .information {
                .top-text {
                  font-size: 20px;
                }

                .bottom-text {
                  font-size: 20px;
                }
              }

              .block-stable {
                .stable-left,
                .stable-middle,
                .stable-right {
                  .icon {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .block-stable {
            padding: 0 30px;

            .stable-left,
            .stable-middle,
            .stable-right {
              margin: 25px 0;

              .icon {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }

      &.sec-fifth {
        .block-investor-kits {
          .investor-kits {
            .investor-kits-content {
              padding: 0 15px;

              .title {
                font-size: 36px;
              }
            }

            /* .investor-kits-img {
              .block-image-content {
                max-width: 240px;

                .image-content {
                  min-height: 300px;
                }

                .bg-white {
                  &.left {
                    max-height: 66px;

                    .icon-pie-chart {
                      width: 30px;
                      height: 30px;
                    }
                  }

                  &.right {
                    max-height: 66px;
                    right: 15px;

                    .icon-graph-up-arrow {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }
            } */
          }
        }
      }

      &.sec-sixth {
        .block-schedule-ir-activities {
          padding: 15px 30px;

          .schedule-ir-activities {
            .schedule-ir-activities-img {
              .block-image-content {
                max-width: 317px;
                transform: translateX(-20px);

                .image-content {
                  min-height: 300px;
                }

                .circle-shape {
                  right: -45px;
                }

                .leaf-shape {
                  right: -80px;
                }
              }
            }

            .schedule-ir-activities-content {
              .block-schedule {
                .title {
                  font-size: 28px;
                }

                .card-activities {
                  .card-left {
                    .card-left-title {
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.sec-seventh {
        .block-contact-investor-relations {
          &.home-investor {
            padding: 15px 45px;

            .contact-investor-relations {
              .contact-investor-relations-content {
                padding: 0;

                .title {
                  font-size: 36px;
                }
              }

              .contact-investor-relations-img {
                .block-image-content {
                  max-width: 317px;

                  .image-content {
                    min-height: 300px;
                  }

                  .circle-shape {
                    right: -45px;
                  }

                  .leaf-shape {
                    right: -80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .block-section {
      &.sec-fourth {
        .blcok-latest-releases {
          .title {
            text-align: center;
          }

          .block-card {
            justify-items: center;
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }

  @media (max-width: 430px) {
    .block-banner-investor {
      .title {
        top: 30%;
        font-size: 38px;
      }
    }

    .home-investor {
      .block-section {
        &.sec-third {
          .important-financial-information {
            padding: 30px 15px;
          }
        }

        &.sec-fourth {
          .blcok-latest-releases {
            padding: 30px 15px;
          }
        }

        &.sec-fifth {
          .block-investor-kits {
            padding: 30px 15px;
          }
        }

        &.sec-sixth {
          .block-schedule-ir-activities {
            .schedule-ir-activities {
              .schedule-ir-activities-img {
                width: 85%;
              }

              .schedule-ir-activities-content {
                .block-schedule {
                  .card-activities {
                    display: block;

                    .card-right {
                      .btn-download-wrapper {
                        max-width: 200px;
                        margin: 20px auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.sec-seventh {
          .block-contact-investor-relations {
            &.home-investor {
              padding: 30px 15px;

              .contact-investor-relations {
                .contact-investor-relations-img {
                  max-width: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomeInvestorStyle;
