const COLOR = {
  PRIMARY_DEFAULT: '#022F86',
  PRIMARY_HOVER: '#1246A9',
  PRIMARY_MEDIUM: '#086BFF',
  PRIMARY_LIGHT: '#C3D8FF',
  PRIMARY_DARKBLUE: '#1B3B6A',
  PRIMARY_DARKBLUE_HOVER: '#092143',

  SECONDARY_DEFAULT: '#5EAC1B',
  SECONDARY_HOVER: '#AFE53C',
  SECONDARY_MEDIUM: '#CDED88',
  SECONDARY_LIGHT: '#F0FCE1',
  SECONDARY_NEUTRAL1: '#81C341',

  NEUTRAL1: '#111925',
  NEUTRAL2: '#14243E',
  NEUTRAL3: '#787E87',
  NEUTRAL4: '#A6AEB4',
  NEUTRAL5: '#CFD5DA',
  NEUTRAL6: '#E8EAEF',
  NEUTRAL7: '#F8F8FA',
  NEUTRAL8: '#FAFAFB',
  NEUTRAL9: '#667085',
  NEUTRAL10: '#6C757D',
  NEUTRAL11: '#CED4DA',
  NEUTRAL12: '#D9D9D9',
  NEUTRAL13: '#343A40',
  NEUTRAL14: '#E9ECEF',
  NEUTRAL15: '#F8F9FA',
  NEUTRAL16: '#F7F9FF',
  NEUTRAL17: '#ECF2FE',
  NEUTRAL18: '#495057',
  NEUTRAL19: '#DEE2E6',
  NEUTRAL20: '#212529',

  GRADIENT_BLUE_GREEN: 'linear-gradient(110deg, rgba(8,42,107,1) 0%, rgba(9,51,131,1) 50%, rgba(17,140,51,1) 70%, rgba(175,229,60,1) 100%)',
  GRADIENT_BLUE: 'linear-gradient(110deg, rgba(9,53,138,1) 0%, rgba(44,97,197,1) 70%, rgba(3,109,168,1) 100%)',
  GRADIENT_GREEN: 'linear-gradient(110deg, rgba(94,172,27,1) 0%, rgba(5,187,110,1) 70%, rgba(20,191,109,1) 100%)',

  ORANGE1: '#FB722E',
  RED1: '#DC3545',
  BLUE: '#0E78BE',
  GREEN: '#146C43',

};

export default COLOR
