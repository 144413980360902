import styled from "styled-components";
import VARIABLES from "../../../../themes/variables";

// Wrapper
// ============================================================
export const PrivacyCenterStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */

  .privacy-centerStyle-content {
    padding: 45px 80px;
    ${VARIABLES.TYPOGRAPHYS.BODY._6}
    /* text-align: justify; */
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
    .privacy-centerStyle-content {
    }
  }

  @media (max-width: 768.98px) {
    .privacy-centerStyle-content {
    }
  }

  @media (max-width: 425.98px) {
    .privacy-centerStyle-content {
      padding: 30px 30px;
    }
  }

  @media (max-width: 375.98px) {
    .privacy-centerStyle-content {
    }
  }

  @media (max-width: 320.98px) {
    .privacy-centerStyle-content {
    }
  }
`;
