import styled from 'styled-components'
// import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const MainLayoutStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */
  &.is-homepage {
    margin-top: 0;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
