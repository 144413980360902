import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const MessageFromChairmanPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .message-from-chairman {
    max-width: 1350px;
    margin: 0 auto;
    background-image: url("../../asset/media/images/contents/message-from-chairman-content-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 15px;

    .quote-and-img {
      display: flex;
      max-width: 95%;
      margin: 0 auto;
      align-items: center;

      .quote-and-chairman-name {
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
        color: black;

        .quote {
          ${VARIABLES.TYPOGRAPHYS.HEADING._1}
          padding-right: 2vw;

          &::before {
            content: "";
            position: relative;
            background-image: url("../../asset/media/images/contents/quote-chairman-page.png");
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-flex;
            width: 6vw;
            height: 6vw;
            max-width: 85px;
            max-height: 85px;
            min-width: 42px;
            min-height: 42px;
            margin-right: 1vw;
          }
          &::after {
            content: "";
            position: relative;
            background-image: url("../../asset/media/images/contents/quote-chairman-page-end.png");
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-flex;
            width: 2vw;
            height: 2vw;
            max-width: 85px;
            max-height: 85px;
            // min-width: 42px;
            // min-height: 42px;
            margin-right: 1vw;
          }
        }

        .chairman-name {
          margin-top: 10%;
          ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })}
        }

        .chairman-position {
          ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })}
        }
      }

      .img-wrapper {
        flex: 0 0 660px;
        max-width: 660px;
        height: auto;
        text-align: right;

        .pic-frame {
          position: relative;
          .circle {
            width: 172px;
            height: 172px;
            position: absolute;
            bottom: 54%;
            border-radius: 50%;
            background-color: ${VARIABLES.COLOR.NEUTRAL12};
            left: 18px;
            z-index: -1;
          }
          .shape-back {
            position: absolute;
            bottom: 6px;
            width: 100%;
            height: 70%;
            border-radius: 183px 8px 139px 8px;
            z-index: -1;
            background-image: linear-gradient(
              141deg,
              #fff 10%,
              #6082b6 52.08%,
              #005a9c 100%
            );
          }
          .img {
            width: 100%;
            height: auto;
            max-width: 450px;
            max-height: 645px;
            border-bottom-right-radius: 139px 139px;
          }
        }

        @media (max-width: 1380.98px) {
          flex: 0 0 560px;
          max-width: 560px;
          .pic-frame {
            .img {
              max-width: 400px;
              height: auto;
            }
          }
        }

        @media (max-width: 1140.98px) {
          flex: 0 0 460px;
          max-width: 460px;
          .pic-frame {
            .img {
              max-width: 350px;
              height: auto;
            }
          }
        }

        @media (max-width: 960.98px) {
          flex: 0 0 400px;
          max-width: 400px;
          .pic-frame {
            .shape-back {
              border-radius: 100px 8px 100px 8px;
            }
            .img {
              border-bottom-right-radius: 100px 100px;
              max-width: 300px;
              height: auto;
            }
          }
        }

        @media (max-width: 820.98px) {
          flex: 0 0 350px;
          max-width: 350px;
          .pic-frame {
            .img {
              max-width: 250px;
              height: auto;
            }
          }
        }

        @media (max-width: 720.98px) {
          flex: 0 0 300px;
          max-width: 300px;
          .pic-frame {
            .circle {
              width: 102px;
              height: 102px;
              left: 9px;
            }
            .shape-back {
              border-radius: 80px 8px 80px 8px;
            }
            .img {
              border-bottom-right-radius: 80px 80px;
              max-width: 200px;
              height: auto;
            }
          }
        }

        @media (max-width: 620.98px) {
          flex: 0 0 280px;
          max-width: 280px;
          .pic-frame {
            .shape-back {
              border-radius: 70px 8px 70px 8px;
            }
            .img {
              border-bottom-right-radius: 70px 70px;
              max-width: 180px;
              height: auto;
            }
          }
        }

        @media (max-width: 575.98px) {
          flex: 0 0 660px;
          max-width: 660px;
          .pic-frame {
            position: relative;
            .circle {
              width: 172px;
              height: 172px;
              left: 18px;
            }
            .shape-back {
              border-radius: 80px 8px 80px 8px;
            }
            .img {
              width: 100%;
              height: auto;
              max-width: 450px;
              max-height: 645px;
              border-bottom-right-radius: 80px 80px;
            }
          }
        }
      }
    }

    .content {

      
      .content-inner {
        max-height: 110px;
        line-height: 30px;
        overflow: hidden;
        /* max-height: 110px;
        line-height: 30px;
        overflow: hidden;
        position: relative;
        &::after {
          position: relative;
          bottom: 0;
          right: 0px;
          content: '...';
        } */
      }
    

      ${VARIABLES.TYPOGRAPHYS.BODY._4}
      /* text-align: justify; */
      padding: 0 40px;
      @media (max-width: 440.98px) {
        padding: 0 20px;
      }

      p {
        gap: 4vw;
        align-content: flex-start;
        align-items: flex-start;

        img {
          height: auto;
          max-width: 40%;
        }
      }

      .read-more {
        cursor: pointer;
        color: ${VARIABLES.COLOR.BLUE};
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
    .message-from-chairman {
      .quote-and-img {
        flex-wrap: wrap;
        flex-direction: column-reverse;

        .quote-and-chairman-name {
          text-align: center;
          padding: 20px 0;

          .quote {
            font-size: 40px;

            &:before {
              filter: grayscale(5);
            }

            &::after {
              content: "";
              position: relative;
              background-image: url("../../asset/media/images/contents/quote-chairman-page.png");
              background-repeat: no-repeat;
              background-size: contain;
              display: inline-flex;
              width: 4vw;
              height: 4vw;
              max-width: 65px;
              max-height: 65px;
              min-width: 24px;
              min-height: 24px;
              margin-left: 1vw;
              transform: scaleX(-1);
              filter: grayscale(5);
            }
          }

          .chairman-name {
            margin-top: 25px;
            font-size: 24px;
          }
          
          .chairman-position {
            font-size: 24px;
          }
          
        }

        .quote-and-chairman-name,
        .img-wrapper {
          width: 100%;
          flex: 1 1 100%;
          max-width: 100%;
        }
      }

      .content {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 320.98px) {
    .message-from-chairman {
      .quote-and-img {
        .img-wrapper {
          .pic-frame {
            position: relative;
            .circle {
              width: 100px;
              height: 100px;
              bottom: 50%;
              left: 12px;
            }
            .shape-back {
              position: absolute;
              bottom: 6px;
              width: 100%;
              height: 65%;
              border-radius: 100px 8px 100px 8px;
            }
            .img {
              width: 100%;
              height: auto;
              max-width: 450px;
              border-bottom-right-radius: 100px 100px;
            }
          }
        }
      }
    }
  }
`;
