import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'


// Wrapper
// ============================================================
export const ThemeOurBusinessStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  .our-business-wrapper {
    .our-business-title {
      .title-have-image {
        margin-top: 60px;
        padding: 60px 65px 30px 65px;

        .title {
          justify-content: center;
          display: flex;
          margin-bottom: 60px;
          color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          ${VARIABLES.TYPOGRAPHYS.HEADING._3}

          img {
            margin-right: 20px;
          }
        }
        .title-detail-wrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .title-detail {
            margin-right: 24px;
            ${VARIABLES.TYPOGRAPHYS.BODY._5}
            width: 100%;
          }
        }
      }
      .title-no-image {
        padding: 60px 65px 30px 65px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          img {
            margin-right: 20px;
          }
          ${VARIABLES.TYPOGRAPHYS.HEADING._2}
        }
        .title-detail {
          ${VARIABLES.TYPOGRAPHYS.BODY._5}
          width: 100%;
        }
      }
    }
    .service-business-content {
      margin-top: 46px;
      padding-bottom: 19px;
      
      .service-business-content-title {
        text-align: center;
        ${VARIABLES.TYPOGRAPHYS.HEADING._6}
        margin-bottom: 48px;
      }
      .service-business-list {
        /* display: flex;
        flex-wrap: wrap;
        margin: auto 100px 0 100px;
        gap: 80px; */
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 30px;

        @media (max-width: 870.98px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 720.98px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 480.98px) {
          grid-template-columns: 1fr;
        }
      }
    }

    .product-business-content {
      .product-wrapper {
        padding: 60px 65px 30px 65px;
        display: flex;
        gap: 100px;
        .name-product {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          ${VARIABLES.TYPOGRAPHYS.HEADING._6}
          img {
            margin-right: 20px;
          }
        }
        .product-detail-wrapper {
          
          .name-product-detail {
            display: flex;
            justify-content: start;
            align-items: center;
            ${VARIABLES.TYPOGRAPHYS.TITLE._1}
            img {
              margin-right: 20px;
            }
          }
          .product-detail {
            width: 100%;
            ${VARIABLES.TYPOGRAPHYS.BODY._5}
          }
        }
      }
    }

    .our-business-outline {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-outline {
        padding: 24px 88px;
        ${VARIABLES.TYPOGRAPHYS.BODY._5}
      }
      .btn-visit {
        width: 140px;
        white-space: nowrap;
        margin-bottom: 60px;
      }
    }
  }

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1100.98px) {
    .our-business-wrapper {
      .product-business-content {
        .product-wrapper {
          padding: 0 50px; 
        }
      }
    }
  }

  @media (max-width: 1024.98px) {
    .our-business-wrapper {
      .product-business-content {
        .product-wrapper {
          padding: unset; 
        }
      }
    }
  }

  @media (max-width: 920.98px) {
    .our-business-wrapper {

      .our-business-title {
        .title-have-image {
          margin-top: 0px;
          padding: 30px 35px 30px 35px;

          .title {
            justify-content: center;
            display: flex;
            margin-bottom: 30px;
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            ${VARIABLES.TYPOGRAPHYS.HEADING._3}

            img {
              margin-right: 20px;
            }
          }
          .title-detail-wrapper {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            .title-detail {
              margin-top: 24px;
              margin-right: unset;
              ${VARIABLES.TYPOGRAPHYS.BODY._5}
              width: 100%;
            }
          }
        }
        .title-no-image {
          padding: 30px 65px 30px 65px;
          flex-direction: column;
        }
      }

      .service-business-content {
        margin-top: 46px;
        padding-bottom: 19px;
        
        .service-business-content-title {
          text-align: center;
          ${VARIABLES.TYPOGRAPHYS.HEADING._6}
          margin-bottom: 48px;
        }
        .service-business-list {
          /* display: grid;
          grid-template-columns: 1fr 1fr; */
        }
      }

      .product-business-content {
        .product-wrapper {
          margin: 30px 30px 80px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 40px;
          .name-product {
            display: flex;
            justify-content: center;
            align-items: center;
            ${VARIABLES.TYPOGRAPHYS.HEADING._6}
            img {
              margin-right: 20px;
            }
          }
          .product-detail-wrapper {
            
            .name-product-detail {
              display: flex;
              justify-content: start;
              align-items: center;
              ${VARIABLES.TYPOGRAPHYS.TITLE._1}
              img {
                margin-right: 20px;
              }
            }
            .product-detail {
              width: 100%;
              ${VARIABLES.TYPOGRAPHYS.BODY._5}
            }
          }
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .our-business-wrapper {
      .service-business-content {
        .service-business-list {
          justify-items: center;
          margin: 0 15px;
          gap: 15px;
        }
      }
    }
  }

  @media (max-width: 425.98px) {
    .our-business-wrapper {
      background-color: white;
      .our-business-title {
        .title-no-image {
          padding: 30px 35px 30px 35px;
          flex-direction: column;
        }
      }

      .service-business-content {
        .service-business-list {
          /* display: grid;
          grid-template-columns: 1fr 1fr;
          margin: 0 auto;
          gap: 15px; */
        }
      }

      .product-business-content {
        .product-wrapper {
          margin: 30px 30px 80px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 40px;
          .name-product {
            display: flex;
            justify-content: center;
            align-items: center;
            ${VARIABLES.TYPOGRAPHYS.HEADING._6}
            img {
              margin-right: 20px;
            }
          }
          .product-detail-wrapper {
            
            .name-product-detail {
              display: flex;
              justify-content: start;
              align-items: center;
              ${VARIABLES.TYPOGRAPHYS.TITLE._1}
              img {
                margin-right: 20px;
              }
            }
            .product-detail {
              width: 100%;
              ${VARIABLES.TYPOGRAPHYS.BODY._5}
            }
          }
        }
      }

      .our-business-outline {
        .content-outline {
          padding: 24px 30px;
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .our-business-wrapper {
      .service-business-content {
        .service-business-list {
          gap: 10px;
        }
      }
    }
  }
`
