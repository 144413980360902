import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const HomePageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */

  .home-page {
    .block-section {
      &.home-banner {
        padding: 120px 0;
        position: relative;
        height: 980px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /* background-image: url("../asset/media/images/contents/bg-bio-wording.jpg"); */

        &.bg-00 {
          background-image: url("../asset/media/images/contents/bg-text-bio.png");
          /* background-position: left; */
        }

        &.bg-01 {
          background-image: url("../asset/media/images/contents/bg-leaf-new.png");
          background-position: left;

          @media (max-width: 1024.98px) {
            .bg-banner-img-leaf {
              width: unset;
              height: unset;
            }
          }

          /* .bg-banner-img {
            display: none;
          } */
        }

        .bg-banner-img-leaf {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center left;
        }

        .bg-banner-img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-top: 120px;
          width: 100%;
          height: auto;

          @media (max-width: 1100.98px) {
            padding-top: 60px;
          }

          @media (max-width: 800.98px) {
            top: 30%;
          }
        }

        .block-home-banner-content {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0 9%;
          z-index: 1;

          .slider-home-banner {
            height: 100%;
            align-items: center;
            display: flex;

            .slick-list {
              display: flex;
              width: 100%;
              max-width: 628px;
              left: 0;
              padding-bottom: 12px;

              .title {
                color: #ffffff;
                ${VARIABLES.TYPOGRAPHYS.HEADING._1};
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
              }

              .description {
                color: #ffffff;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
                font-size: 18px;
                font-weight: 400;
                line-height: 30px; /* 166.667% */
                letter-spacing: 0.36px;
              }
            }

            .slick-track {
              display: flex;
              place-content: center;
              align-items: center;
            }

            .slick-dots {
              width: auto;
              right: 0;
              bottom: 50%;

              & > ul {
                display: flex;
                flex-direction: column;

                & > li {
                  position: relative;

                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    height: 100%;
                    right: -20px;
                    border: 0px solid white;
                    background-color: white;
                  }

                  &.slick-active {
                    &::after {
                      width: 3px;
                    }
                  }
                }
              }

              .slick-active {
                font-size: 32px;
                height: auto;
              }
            }

            .slick-slide div {
              align-items: baseline;
            }

            .slick-slide div div span {
              margin-top: unset;
              width: unset;
              font-size: unset;
              font-family: unset;
              font-weight: unset;
              color: unset;
              text-transform: unset;
              text-align: left;
            }

            .slick-slide.slick-active.slick-current {
              transform: unset;
            }

            .slick-next {
              display: none !important;
            }

            .slick-prev {
              display: none !important;
            }
          }
        }

        .block-set-biotec {
          position: absolute;
          bottom: 0;
          display: flex;
          width: 100%;
          justify-content: center;
          z-index: 1;

          .block-bg {
            display: flex;
            border-radius: 20px 20px 0px 0px;
            background-color: ${VARIABLES.COLOR.NEUTRAL16};
            margin: 0 18px;

            @media (max-width: 800.98px) {
              margin: 0;
            }

            .set-biotec-item {
              width: fit-content;
              margin: 10px 0;
              padding: 28px 24px;
              text-align: center;
              justify-content: center;
              align-self: center;
              border-right: 1px solid ${VARIABLES.COLOR.NEUTRAL14};
              @media (max-width: 820.98px) {
                padding: 10px;
              }

              .title {
                color: #000080;
                font-size: 16px;
                font-weight: 500;

                @media (max-width: 1024.98px) {
                  font-size: 14px;
                }
              }

              .update-information {
                margin-top: 15px;
                color: #212529;
                font-size: 20px;
                font-weight: 500;

                @media (max-width: 1024.98px) {
                  font-size: 16px;
                }
              }

              &:first-child {
                height: 100%;
                margin: 0;
                padding: 35px 9px;
                border-radius: 20px 0 0 0;
                border-right: 0;
                background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
                @media (max-width: 820.98px) {
                  padding: 10px;
                }

                .title {
                  font-size: 24px;
                  font-weight: 500;
                  color: #ffffff;
                }

                .update-information {
                  margin-top: 15px;
                  font-size: 14px;
                  font-weight: 300;
                  color: #ffffff;
                }
              }

              &:last-child {
                border-right: 0;
                border-radius: 0 20px 0 0;
              }
            }
          }
        }
      }

      &.home-our-business {
        width: 100%;
        background-image: url("../../asset/media/images/contents/our-business-bg-home.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .our-business {
          .our-business-title {
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            font-size: 48px;
            font-weight: 700;
            padding-top: 25px;
            margin-bottom: 15px;
            margin-left: 61px;
          }

          .blok-card-our-business {
            padding: 25px 0;
            overflow: hidden;

            @media (max-width: 719.98px) {
              padding: 0;
            }

            .card-our-business {
              position: relative;

              .card-body {
                position: relative;
                display: flex;
                padding: 35px;
                margin-bottom: 40px;
                z-index: 1;
                background-color: transparent;

                &.left {
                  .card-content {
                    margin: 15px 25px 15px 0;
                  }
                }

                &.right {
                  flex-direction: row-reverse;

                  .card-content {
                    margin: 15px 0 15px 25px;
                  }
                }

                .card-content {
                  color: #082a6b;
                  margin-right: 20px;
                  padding: 26px;
                  border-radius: 20px;
                  background: rgba(255, 255, 255, 0.4);
                  box-shadow: 0px 0px 86px 0px rgba(255, 255, 255, 0.5);
                  max-width: 700px;

                  p {
                    margin: 0;
                  }

                  .content-title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 25px;
                    font-size: 40px;
                    font-weight: 700;

                    .icon {
                      margin-right: 10px;
                    }
                  }

                  .content-text {
                    color: #082a6b;
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 0.48px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                  }

                  .button-read-more {
                    margin-top: 20px;
                    font-size: 18px;
                  }
                }

                .card-img-right {
                  position: relative;
                  min-width: 423px;
                  min-height: 433px;

                  .img-card {
                    width: 100%;
                    height: 100%;
                    max-width: 500px;
                    object-fit: cover;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
                  }

                  .bg-img-card {
                    position: absolute;
                    top: 0;
                    width: 300%;
                    height: 115%;
                    z-index: -1;

                    &.left {
                      left: 0;
                      transform: translate(-84%,-30px);
                      border-radius: 0px 15px 15px 0px;
                    }

                    &.right {
                      right: 0;
                      transform: translate(84%,-30px);
                      border-radius: 15px 0 0 15px;
                    }

                    @media (max-width: 767.98px) {
                      &.left {
                        transform: translate(-84%,-20px);
                      }

                      &.right {
                        transform: translate(84%,-20px);
                      }
                    }

                    @media (max-width: 430.98px) {
                      &.left {
                        transform: translate(-84%,-15px);
                      }

                      &.right {
                        transform: translate(84%,-15px);
                      }
                    }
                  }

                  &.left {
                    .img-card {
                      border-radius: 6px 74px;
                    }
                  }
                  
                  &.right {
                    .img-card {
                      border-radius: 74px 6px;
                    }
                  }
                }

                @media (max-width: 991.98px) {
                  &.left,
                  &.right {
                    .card-content {
                      max-width: 427px;

                      .content-title {
                        font-size: 34px;

                        .icon {
                          width: 10px;
                          max-height: 50px;
                        }
                      }
                    }

                    .content-text {
                      font-size: 20px;
                    }

                    .card-img-right {
                      min-width: unset;
                      min-height: unset;
                    }
                  }
                }

                @media (max-width: 767.98px) {
                  &.left,
                  &.right {
                    flex-direction: column-reverse;
                    padding-top: 35px;
                    margin-bottom: 0;

                    .card-content {
                      max-width: 100%;
                      margin: 0;
                      margin-top: 20px;
                    }

                    .card-img-right {
                      display: flex;
                      justify-content: center;

                      .img-card {
                        max-width: 80%;
                        max-height: 337px;
                      }
                    }
                  }
                }

                @media (max-width: 465.98px) {
                  &.left,
                  &.right {
                    .card-content {
                      .content-title {
                        font-size: 28px;
                        margin-bottom: 15px;
                      }

                      .content-text {
                        font-size: 16px;
                      }

                      .button-read-more {
                        max-width: 150px;
                        font-size: 14px;
                      }
                    }

                  }
                }

                @media (max-width: 465.98px) {
                  &.left,
                  &.right {
                    .card-content {
                      .content-title {
                        font-size: 24px;
                      }
                    }

                    .card-img-right {
                      .img-card {
                        max-width: 90%;
                      }
                    }
                  }
                }

                @media (max-width: 465.98px) {
                  &.left,
                  &.right {
                    .card-img-right {
                      .img-card {
                        max-width: 90%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.annoucements-bg {
        background: url(${(props) =>
    props.bgImgNews ||
    "../../asset/media/images/contents/annoucements-bg.jpg"});
        /* background-attachment: fixed; */
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        overflow: hidden;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;

        .stock-market-news {
          position: relative;
          display: flex;
          width: 100%;
          margin-bottom: 30px;
          padding: 60px 40px 15px 40px;

          .frame-ann {
            width: 100%;
            max-width: 428px;
            padding: 10px 25px 30px 25px;
            margin-bottom: 30px;
            border-radius: 20.8px;
            background: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(13.000000953674316px);

            .ann-header {
              .title {
                position: relative;
                font-size: 48px;
                font-weight: 500;
                padding-bottom: 15px;
                margin-bottom: 15px;

                p {
                  margin: 0px;
                  margin-top: 15px;
                }

                .rectangle {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 80px;
                  height: 4px;
                  background: linear-gradient(
                    109deg,
                    #082a6b 0%,
                    #093383 32.29%,
                    #118c33 76.04%,
                    #afe53c 100%
                  );
                }
              }

              .ann-des {
                .des-text {
                  color: #14243e;
                  font-size: 20px;
                  font-weight: 400;
                  text-shadow: 0 0 1px #000000;
                }
              }
            }
          }

          .slide-ann-frame {
            width: 70%;
            padding-left: 15px;
            flex-direction: column;

            .slick-slider {
              .slick-list {
                .slick-track {
                  .slick-current {
                    div {
                      .slider-item {
                        .card-ann-wrapper {
                          background-color: ${VARIABLES.COLOR.NEUTRAL17};
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.background-sec-doc-lastest-news {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        background: url("../../asset/media/images/contents/bg-download-document.jpg");
        background-size: cover;

        .download-doc-container {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 60px 30px;
          flex-direction: column;
          align-items: center;
          gap: 40px;
          text-align: center;

          .header-download-doc {
            display: flex;
            width: 100%;
            max-width: 1309px;
            justify-content: space-between;
            align-items: center;

            .download-title {
              display: flex;
              justify-content: space-between;
              width: 100%;
              color: #ffffff;
              text-align: center;
              ${VARIABLES.TYPOGRAPHYS.HEADING._2({ weight: 500, size: 32 })}
            }

            .more-button {
              display: flex;
              padding: 8px 16px;
              align-items: center;

              .text-more {
                ${VARIABLES.TYPOGRAPHYS.HEADING._7}
                letter-spacing: 0.32px;
                margin-right: 10px;
              }
            }

            @media (max-width: 800.98px) {
              .download-title {
                flex-direction: column;
                width: fit-content;
                margin: 0 auto;

                .read-more-wrapper {
                  .read-more {
                    color: white;
                  }
                }
              }
            }
          }

          .slide-download-doc-container {
            width: 100%;

            .slick-slide {
              & > div {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                & > div {
                  span {
                    margin-top: 10px;
                    width: inherit;
                    text-align: center;
                    font-size: 0.75rem;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #111;
                  }
                }
              }

              &.slick-active {
                &.slick-current {
                  position: relative;
                  z-index: 999;
                  transform: scale(1.1);
                  transition: 0.25s;
                }
              }

              .image {
                object-fit: cover;
                border-radius: 2px;
                min-width: 150px !important;
                width: 80%;
                max-width: 300px;
              }
            }

            div {
              .slide-position {
                margin-top: -150px;

                &.one {
                  .slick-slider {
                    .slick-list {
                      .slick-track {
                        .slick-slide {
                          & > div {
                            & > div {
                              display: flex !important;
                              justify-content: center !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &.two {
                  .slick-slider {
                    .slick-list {
                      .slick-track {
                        .slick-slide {
                          & > div {
                            & > div {
                              display: flex !important;
                              justify-content: center !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .slick-slider {
                  &.center {
                    .slick-dots {
                      bottom: -40px;

                      .slick-active {
                        button {
                          &::before {
                            content: "";
                            top: 7px;
                            width: 20px;
                            height: 6px;
                            border-radius: 4px;
                            background-color: #000080;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .slide-bg-download-doc {
            width: 100%;
            max-width: 1028px;
            height: 232px;
            flex-shrink: 0;
            border-radius: 20px;
            background: url(${(props) =>
    props.bgImgReportFiles ||
    "../../asset/media/images/contents/download-document-bg-slide.png"});
            margin: auto;
            background-position: center;
          }

          @media (max-width: 800px) {
            padding-left: 0;
            padding-right: 0;
            .slide-download-doc-container {
              .slide-bg-download-doc {
                border-radius: 0;
              }
            }
          }
        }

        .container-lastest-sec {
          display: flex;
          padding: 60px;

          .content-description-lastest-news {
            .description-lastest-sec-container {
              .description-lastest-sec {
                position: relative;
                padding: 0 30px;
                max-width: 330px;
                width: 100%;

                .header-topic {
                  color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                  font-size: 32px;
                  font-weight: 500;
                }

                .lastest-sec-content {
                  color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                  font-size: 16px;
                  font-weight: 400;
                }

                .more-button-latest-sec {
                  margin-top: calc(100% - 160px);
                }
              }
            }
          }

          .pic-and-description {
            display: flex;

            .pic-lastest-sec-container {
              max-width: 330px;
              padding: 0 15px;

              .content-img {
                overflow: hidden;
                max-width: 300px;
                max-height: 382px;
                border-radius: 30px;

                img {
                  object-fit: cover;
                  object-position: center center;
                  border-radius: 30px;
                  transition: all 1s ease;
                  max-width: 300px;
                  min-width: 300px;
                  max-height: 382px;
                  min-height: 382px;

                  &:hover {
                    transform: scale(1.5, 1.5);
                  }
                }
              }

              .content-inside {
                font-size: 14px;
                font-weight: 500;
                color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }

              .content-descriptopn {
                font-size: 18px;
                font-weight: 500;
                color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }
            }
          }
        }
      }

      &.csr-activity {
        display: flex;
        width: 100%;
        height: auto;
        padding: 60px 30px;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .csr-container {
          display: flex;
          width: 100%;
          max-width: 1309px;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff;

          .csr-news {
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 48px;
          }
        }

        .csr-slide-container {
          width: 100%;
          height: 100%;

          &.one {
            div > .csr-card-wrapper {
              margin: auto;
            }
          }

          &.two {
            div > img {
              max-width: 595px;
              max-height: 347px;
            }

            div > .csr-card-wrapper {
              margin: auto;
            }

            .slick-slide {
              transform: unset !important;
            }
          }

          #btn-prev-corporate-csr {
            position: absolute;
            bottom: -30px;
            left: 20%;
            z-index: 2;

            @media (max-width: 640.98px) {
              left: -20px;
              bottom: 50%;
            }
          }

          #btn-next-corporate-csr {
            position: absolute;
            top: -30px;
            right: 20%;
            z-index: 2;

            @media (max-width: 640.98px) {
              right: -20px;
              top: unset;
              bottom: 50%;
            }
          }

          .slick-slide {
            transition: 0.25s;
            transform: scale(0.8);

            .slick-list {
              .slick-track {
                .slick-slide {
                  &.slick-current {
                    div {
                      .block-news-card {
                        .csr-card-wrapper {
                          .csr-card-container {
                            .img-news {
                              img {
                                max-width: 595px;
                                max-height: 347px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            & > div {
              display: flex !important;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              & > div {
                span {
                  margin-top: 10px;
                  width: inherit;
                  text-align: center;
                  font-size: 0.75rem;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: 600;
                  text-transform: uppercase;
                  color: #111;
                }
              }
            }

            &.slick-active {
              &.slick-current {
                position: relative;
                z-index: 999;
                transform: scale(1.1);

                .card-details {
                  opacity: 1;
                  z-index: 1;
                }

                div {
                  .block-news-card {
                    .csr-card-wrapper {
                      .csr-card-container {
                        .img-news {
                          img {
                            max-width: 595px;
                            max-height: 347px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .image {
              object-fit: cover;
              border-radius: 2px;
              min-width: 150px !important;
            }
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  .button-read-more {
    max-width: 180px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 18px;
      max-width: 180px;
      border-radius: 100px;
      color: #ffffff;
      border: 1px solid #ffffff;

      .read-more-text {
        margin-right: 10px;
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    &.white {
      &:hover {
        a {
          color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          border: 1px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          background-color: #ffffff;
        }

        .icon {
          filter: brightness(0);
        }
      }
    }

    &.blue {
      a {
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        border: 1px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      }

      .icon {
        width: 12px;
        height: 12px;
      }

      &:hover {
        a {
          color: #ffffff;
          border: 1px solid #ffffff;
          background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        }

        .icon {
          filter: brightness(12);
        }
      }
    }
  }

  .btn {
    &.link {
      &.read-more {
        display: flex;
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        ${VARIABLES.TYPOGRAPHYS.BODY._7};
        width: fit-content;
        padding: 10px;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .count-number {
    &.home-com {
      margin: 15px 0;

      &.second {
        display: none;
      }

      .count-current {
        padding-right: 8px;
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        ${VARIABLES.TYPOGRAPHYS.HEADING._1};
      }

      .count-total {
        color: #565656;
        letter-spacing: 2px;
        ${VARIABLES.TYPOGRAPHYS.TITLE._7};
      }
    }
  }

  .button-ann-group {
    &.home-com {
      display: flex;
      gap: 24px;

      &.second {
        display: none;
      }

      .button-ann-next {
        user-select: none;
        width: 56px;
        height: 56px;
        border-radius: 8px;
        background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      }

      .button-ann-prev {
        user-select: none;
        width: 56px;
        height: 56px;
        border-radius: 8px;
        background-color: ${VARIABLES.COLOR.NEUTRAL17};
        border: 1px solid ${VARIABLES.COLOR.PRIMARY_MEDIUM};
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1920.98px) and (min-width: 1500.98px) {
    .home-page {
      .block-section {
        &.csr-activity {
          .csr-slide-container {
            .slick-slider {
              .prev {
                /* top: 380px !important; */
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366.98px) {
    .home-page {
      .block-section {
        &.annoucements-bg {
          .stock-market-news {
            .slide-ann-frame {
              width: 70%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1279.98px) {
    .home-page {
      .block-section {
        &.home-our-business {
        }

        &.background-sec-doc-lastest-news {
          .container-lastest-sec {
            padding: 60px 30px;

            .pic-and-description {
              .pic-lastest-sec-container {
                max-width: 280px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024.98px) {
    .home-page {
      .block-section {
        @media (max-width: 800.98px) {
          &.home-banner {
            height: 100%;
            padding: 90px 0 0 0;

            .bg-banner-img {
              /* height: 60%; */
            }

            .block-home-banner-content {
              .slick-slider {
                &.slider-home-banner {
                  .slick-list {
                    max-width: 100%;

                    .slick-track {
                      .slick-slide {
                        padding-bottom: 240px;
                      }
                    }
                  }

                  .slick-dots {
                    bottom: 0;
                  }
                }
              }
            }

            .block-set-biotec {
              position: relative;
              display: block;

              .block-bg {
                display: block;
                border-radius: 0;
                margin-top: 60px;

                .set-biotec-item {
                  position: relative;
                  display: flex;
                  width: 100%;
                  margin: 0;
                  padding: 20px 24px;
                  align-items: center;
                  justify-content: space-between;
                  border-radius: 0 !important;
                  border-right: 0;

                  &:before {
                    position: absolute;
                    left: 24px;
                    bottom: 0;
                    content: "";
                    width: calc(100% - 48px);
                    height: 1px;
                    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                  }

                  &:first-child {
                    display: block;

                    &:before {
                      height: 0px;
                    }
                  }

                  &:last-child {
                    &:before {
                      height: 0px;
                    }
                  }

                  .update-information {
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        &.home-our-business {
        }

        &.annoucements-bg {
          .stock-market-news {
            display: block;

            .frame-ann {
              max-width: 100%;

              .ann-header {
                .ann-des {
                  .des-text {
                    margin: 20px 0 40px 0;
                  }
                }
                .count-number {
                  &.home-com {
                    &.first {
                      display: none;
                    }
                  }
                }

                .button-ann-group {
                  &.home-com {
                    &.first {
                      display: none;
                    }
                  }
                }
              }
            }

            .slide-ann-frame {
              width: 100%;
            }

            .count-number {
              &.home-com {
                &.second {
                  display: unset;
                }
              }
            }

            .button-ann-group {
              &.home-com {
                &.second {
                  display: flex;
                }
              }
            }
          }
        }

        &.background-sec-doc-lastest-news {
          .container-lastest-sec {
            display: block;

            .content-description-lastest-news {
              .description-lastest-sec-container {
                .description-lastest-sec {
                  max-width: 100%;

                  .more-button-latest-sec {
                    margin-top: 0;
                    margin-bottom: 25px;
                  }
                }
              }
            }

            .pic-and-description {
              overflow-x: auto;
              justify-content: center;
            }
          }
        }

        &.csr-activity {
          .csr-slide-container {
            .slick-slider {
              .prev {
                /* top: 330px !important;
                left: 310px !important; */
              }

              .next {
                /* right: 320px !important; */
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 800.98px) {
    .home-page {
      .block-section {
        &.home-banner {
          overflow: hidden;

          .bg-banner-img {
            /* width: 130%; */
          }

          .block-home-banner-content {
            padding: 0 6%;

            .slick-slider {
              &.slider-home-banner {
                .slick-list {
                  .slick-track {
                    .slick-slide {
                      padding-bottom: 130px;
                    }
                  }
                }
              }
            }
          }
        }

        &.home-our-business {
        }

        &.background-sec-doc-lastest-news {
          .container-lastest-sec {
            display: block;

            .pic-and-description {
              justify-content: start;

              .pic-lastest-sec-container {
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }

        &.csr-activity {
          .csr-slide-container {
            .slick-slider {
              .prev {
                /* top: 290px !important;
                left: 230px !important; */
              }

              .next {
                /* right: 260px !important; */
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .home-page {
      .block-section {
        &.home-our-business {
        }

        &.annoucements-bg {
          .stock-market-news {
            display: block;
            padding: 30px 40px 15px 40px;

            .frame-ann {
              max-width: 100%;

              .ann-header {
                .title {
                  font-size: 33px;
                  margin-bottom: 45px;
                }

                .ann-des {
                  display: none;
                }
              }
            }
          }
        }

        &.csr-activity {
          .csr-slide-container {
            .slick-slider {
              .prev {
                /* top: 130px !important;
                left: 80px !important; */
              }

              .next {
                /* top: 190px !important;
                right: 100px !important; */
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 420.98px) {
    .home-page {
      .block-section {
        &.home-our-business {
        }
      }
    }
  }

  @media (max-width: 393.98px) {
    .home-page {
      .block-section {
        &.home-our-business {
        }
      }
    }
  }

  /* @media (max-width: 1440.98px) {
  } */
`;

export default HomePageStyle;
