import classNames from 'classnames'
import { ImageStyle } from './styled'

export default function Image({
  src,
  alt,
  isCircle,
  className,
}) {
  return (
    <ImageStyle className={classNames(
        'image-wrapper',
        {'is_circle': isCircle},
        className
      )}
    >
      <img
        className='image'
        src={src}
        alt={alt}
      />
    </ImageStyle>
  )
}

// Picture.defaultProps = {
//   width: 20,
//   height: 20,
// }
