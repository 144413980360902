import styled from 'styled-components'
// import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const ManagementStructurePageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .management-structure {
    max-width: 1350px;
    margin: 0 auto;
    padding: 25px 15px;
  }

  .management-structure-chart {
    width: 100%;

    .img {
      width: 100%;
      cursor: zoom-in;
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
