import React from 'react'
import { OneImageStyle } from './styled'

function OneImage({ image }) {
  return (
    <OneImageStyle>
      <div className='container-wrapper'>
        <div className='shadow-border'></div>
        <div className="image">
          <img src={image} alt="main" />
        </div>
      </div>
    </OneImageStyle>
  )
}

export default OneImage