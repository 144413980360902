import React, { useEffect, useState } from "react";
import { ChannelsReceivingComplaintsPageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import {
  Button,
  Checkbox,
  Input,
  InputTextarea,
  PageHeader,
  Modal,
} from "../../Global";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import CardPolicyComplaintProtection from "./component/CardPolicyComplaintProtection";
import {
  PagesCorruptionReported,
  CorruptionReportedPolicyFiles,
  CorruptionReported,
} from "../../../Service/API";
import service, { getBaseUploadUrl } from "../../../Service/service";
import { validateFile, scrollToTop, validateEmail } from "./../../../helpers";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ChannelsReceivingComplaintsComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const [reportPolicy, setReportPolicy] = useState(null);
  const [anonymous, setAnonymous] = useState(true);
  const [accept, setAccept] = useState(false);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
  });

  const [formData, setFormData] = useState({
    anonymous: false,
    name: "",
    surname: "",
    email: "",
    topic: "",
    message: "",
    file: "",
  });
  const [reportData, setReportData] = useState(null);
  const [file, setFile] = useState(false);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const CorruptionReported = async () => {
      const Response = await PagesCorruptionReported();
      setData(Response?.data);
      setReportData(Response?.data?.attributes?.report_image?.data);
    };

    const ReportedPolicy = async () => {
      const Response = await CorruptionReportedPolicyFiles({ "sort[createdAt]": "desc" });
      setReportPolicy(Response?.data);
    };

    CorruptionReported();
    ReportedPolicy();
  }, []);

  const handleAnonymousChange = (checked) => {
    setAnonymous(checked);
    if (checked) {
      setFormData({ ...formData, name: "", surname: "", email: "" });
    }
  };

  const handleFileChange = (e) => {
    const files = e?.target?.files[0];
    if (files) {
      if (validateFile(files)) {
        setFile(files);
      } else {
        alert(
          translate[
            "channels_receiving_complaints.message_error.invalid_file_maximum"
          ]
        );
        e.target.value = null;
      }
    }
  };

  const validateFormData = () => {
    let valid = false;
    if (anonymous) {
      if (formData.topic && formData.message) {
        valid = true;
      }
    } else {
      if (
        formData.name &&
        formData.surname &&
        formData.email &&
        formData.topic &&
        formData.message
      ) {
        valid = true;
      }
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFormData()) {
      alert(
        translate[
          "channels_receiving_complaints.message_error.invalid_information"
        ]
      );
      return;
    }

    if (!validateEmail(formData.email) && !anonymous) {
      alert(
        translate["channels_receiving_complaints.message_error.invalid_email"]
      );
      return;
    }

    if (!accept) {
      alert(
        translate["channels_receiving_complaints.message_error.invalid_terms"]
      );
      return;
    }

    try {
      let fileId = null;
      if (file) {
        let formDataFile = new FormData();
        formDataFile.append("files", file);

        const upload = await service().uploadFile(formDataFile);
        fileId = upload.data[0]?.id;
      }

      const data = {
        identify_checked: anonymous,
        name: formData.name,
        surname: formData.surname,
        email: anonymous ? null : formData.email,
        topic: formData.topic,
        details: formData.message,
        file_attached: fileId,
        datetime: new Date().toISOString(),
      };

      const response = await CorruptionReported(data);
      if (response) {
        setOpenModal({ isOpen: true });
        handleResetData();
      }
    } catch (error) {
      alert(
        translate["channels_receiving_complaints.message_error.submitting_form"]
      );
    }
  };

  const handleResetData = async () => {
    setAnonymous(false);
    setFormData({
      anonymous: false,
      name: "",
      surname: "",
      email: "",
      topic: "",
      message: "",
      file: "",
    });
    setAccept(false);
    document.getElementById("file-upload").value = null;
  };

  let dataMockA = [
    {
      id: 1,
      title: "ขอเชิญประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
      date: "วันจันทร์ที่ 24 เมษายน 2566 เวลา 14.00 น.",
      contact: "โดยประชุมผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
      img: "../../asset/media/images/icons/icon-warning-message-complaints.svg",
      alt: "icon-warning-message-confirmed",
      test: translate["channels_receiving_complaints.modal_form_success"],
    },
  ];

  return (
    <ChannelsReceivingComplaintsPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/", text: translate["menu.corporate_governances"] },
          {
            url: ROUTE_PATH.CHANNELS_RECEIVING_COMPLAINTS.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="channels-receiving-complaints">
          <div className="content-wrapper">
            <p className="caption">
              <span className="text">{data?.attributes?.note}</span>
            </p>

            <div className="block-complainant">
              <div className="bg-complainant">
                <img
                  src={
                    reportData?.attributes?.url &&
                    BASE_URL_UPLOAD + reportData?.attributes?.url
                  }
                  alt="bg-complainant"
                />
              </div>
              <div className="complainant-details">
                <p className="complainant-title">
                  {data?.attributes?.report_title}
                </p>
                <div className="complainant-content">
                  <div className="icon icon-person">
                    <img
                      src="../asset/media/images/icons/icon-person-two-white.svg"
                      alt="icon-person-two-white"
                    />
                  </div>
                  <div className="complaint-recipient">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {data?.attributes?.report_details?.replaceAll(
                        "\n",
                        "<br/>"
                      )}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>

            <div className="policy-complaint-protection">
              <p className="title">{data?.attributes?.policy_text}</p>
              <div className="card-centent">
                {reportPolicy?.map((e, i) => {
                  return (
                    <CardPolicyComplaintProtection
                      key={i}
                      iconHead="../asset/media/images/icons/icon-home-green.svg"
                      cardName={e?.attributes?.title}
                      file={e?.attributes?.file?.data?.attributes}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="contact-form">
            <div className="content-wrapper">

            
            <div className="title">{data?.attributes?.form_title}</div>
            <div className="sub-title">{data?.attributes?.note}</div>

            <div className="form">
              <div className="radio-anonymous">
                <Checkbox
                  type="radio"
                  id="anonymous"
                  name="anonymous"
                  checked={anonymous}
                  children={
                    translate["channels_receiving_complaints.anonymous"]
                  }
                  onChange={(e) => {
                    handleAnonymousChange(e.target.checked);
                  }}
                />

                <Checkbox
                  type="radio"
                  id="anonymous2"
                  name="anonymous"
                  checked={!anonymous}
                  children={
                    translate["channels_receiving_complaints.identify_myself"]
                  }
                  onChange={(e) => {
                    handleAnonymousChange(!e.target.checked);
                  }}
                />
              </div>
              <span className="form-name-lastname">
                <div className="form-name">
                  <Input
                    required={anonymous ? false : !formData.name}
                    label={
                      translate["channels_receiving_complaints.first_name"]
                    }
                    type="text"
                    placeholder={
                      translate["channels_receiving_complaints.first_name"]
                    }
                    disabled={anonymous}
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-lastname">
                  <Input
                    required={anonymous ? false : !formData.surname}
                    label={translate["channels_receiving_complaints.last_name"]}
                    type="text"
                    placeholder={
                      translate["channels_receiving_complaints.last_name"]
                    }
                    disabled={anonymous}
                    value={formData.surname}
                    onChange={(e) =>
                      setFormData({ ...formData, surname: e.target.value })
                    }
                  />
                </div>
              </span>
              <div className="form-email">
                <Input
                  required={anonymous ? false : !validateEmail(formData.email)}
                  // required={anonymous ? false : !formData.email}
                  label={translate["channels_receiving_complaints.email"]}
                  type="email"
                  placeholder={translate["channels_receiving_complaints.email"]}
                  disabled={anonymous}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
              <div className="form-toppic">
                <Input
                  required={!formData.topic}
                  label={translate["channels_receiving_complaints.section"]}
                  type="text"
                  placeholder={
                    translate["channels_receiving_complaints.section"]
                  }
                  value={formData.topic}
                  onChange={(e) =>
                    setFormData({ ...formData, topic: e.target.value })
                  }
                />
              </div>
              <div className="form-message">
                <InputTextarea
                  required={!formData.message}
                  label={translate["channels_receiving_complaints.detail"]}
                  type="text"
                  placeholder={
                    translate["channels_receiving_complaints.detail"]
                  }
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
              </div>

              <div className="block-upload-input">
                <Input
                  className="file-input"
                  label={
                    translate[
                      "channels_receiving_complaints.additional_documents"
                    ]
                  }
                  type="file"
                  onChange={handleFileChange}
                  id="file-upload"
                />
                <div className="remark-file">
                  {translate["channels_receiving_complaints.file_maximum"]}
                </div>
              </div>
              <div className="specification-condition">
                <Checkbox
                  id="condition"
                  type="checkbox"
                  className="condition"
                  label="เอกสารเพิ่มเติม"
                  checked={accept}
                  onChange={() => setAccept(!accept)}
                />
                <p>
                  {
                    translate[
                      "channels_receiving_complaints.i_have_read_privacy_center"
                    ]
                  }
                  <Link
                    className="link condition"
                    to={ROUTE_PATH.PRIVACY_CENTER.LINK}
                    onClick={scrollToTop}
                  >
                    <span> Privacy Center</span>
                  </Link>
                </p>
              </div>
            </div>
            <div className="btn-submit-message">
              <Button
                type="submit"
                className="submit-message medium-secondary-outline"
                onClick={handleSubmit}
              >
                {translate["channels_receiving_complaints.send_message"]}
              </Button>
            </div>
          </div>
          </div>
        </form>
      </div>

      {/* Modal */}
      {openModal.isOpen && (
        <Modal
          modalWarningMessage
          children={dataMockA[0].test}
          srcImage={dataMockA[0].img}
          alt={dataMockA[0].alt}
          onClose={(e) => {
            setOpenModal(e);
          }}
        />
      )}
    </ChannelsReceivingComplaintsPageStyle>
  );
};

export default ChannelsReceivingComplaintsComponent;
