import classNames from 'classnames';
import { InputStyle } from './styled';
import Button from '../Button';

export default function Input({
  className,
  label,
  value,
  type,
  id,
  name,
  placeholder,
  isInline,
  disable,
  isDisable,
  disabled,
  isDisabled,
  readOnly,
  isReadOnly,
  onChange,
  defaultValue,
  minLength,
  min,
  maxLength,
  max,
  onFocus,
  onKeyDown,
  onKeyPress,
  inputMsg,
  required,
  inputSearch,
}) {
  return (
    <InputStyle
      className={classNames(
        'field-wrapper',
        {
          'is-inline': isInline,
          'is-readonly': isReadOnly || readOnly,
          'is-disabled': disable || disabled || isDisable || isDisabled,
          'is-type-number': type === 'number',
          'required': required,
        },
        className
      )}
    >
      {label && (
        <label className='field-label' htmlFor={id}>
          {label}
        </label>
      )}
      <div className='input-wrapper'>
        <input
          className='input'
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disable || disabled || isDisable || isDisabled}
          value={value}
          readOnly={readOnly || isReadOnly}
          onChange={onChange}
          minLength={minLength}
          min={min}
          max={max}
          maxLength={maxLength}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          defaultValue={defaultValue}
          required={required}
        />
        { inputSearch && (
          <Button className='btn-icon'>
            <img src='../asset/media/images/icons/icon-search.svg' />
          </Button>
        )}
        {inputMsg && (
          <div className='input-msg' htmlFor={id}>
            {label}
          </div>
        )}
      </div>
    </InputStyle>
  );
}

