import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { SocialResponsibilityReportStyle } from "./styled";
import { PageHeader } from "../../Global";
import {
  CsrReportFiles,
  PagesCsrReport,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import CardElement from "./Components/CardElement";
import { useDispatch, useSelector } from "react-redux";

const SocialResponsibilityReportComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const SocialResponsibility = async () => {
      const ResponsePage = await PagesCsrReport();
      setData(ResponsePage?.data);

      const payload = {
        "sort[date]": "desc",
        "sort[id]": "desc",
      }
      const ResponseContent = await CsrReportFiles(payload);
      setContent(ResponseContent?.data);
    };

    SocialResponsibility();
  }, []);

  return (
    <SocialResponsibilityReportStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/", text: translate["menu.sustainable_development"] },
          {
            url: ROUTE_PATH.SOCIAL_RESPONSIBILITY_REPORT.LINK,
            text: translate["menu.social_responsibility_report"],
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="social-responsibility-report-content content-wrapper">
            <div className="card-wrapper">
              {content
                ? content.map((e, i) => (
                  <CardElement
                    key={i}
                    title={e?.attributes?.file_name}
                    img={
                      e?.attributes?.image?.data?.attributes?.url
                        ? BASE_URL_UPLOAD + e?.attributes?.image?.data?.attributes?.url
                        : "../asset/media/images/contents/social-responsibility-report.png"
                    }
                    urlFile={
                      e?.attributes?.file?.data?.attributes?.url
                    }
                    fileName={
                      e?.attributes?.file?.data?.attributes?.name
                    }
                  />
                ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </SocialResponsibilityReportStyle>
  );
};

export default SocialResponsibilityReportComponent;
