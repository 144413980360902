import { InvestorRelationsCalendarPageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import {
  PageHeader,
  TD,
  TH,
  TR,
  Table,
  TableBody,
  TableHeader,
} from "../../Global";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import SelectBox from "../../Global/SelectBox";
import { SaveFile } from "./../../../Service/API";
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  PagesShareholderIrCalendar,
  ShareholderIrEvents,
} from "./../../../Service/shareholders";
import {
  addDays,
  displayDate,
  displayDateBuddhistEra,
  displayYear,
  displayYearBuddhistEra,
  exportIcsFile,
  removeDuplicates
} from "./../../../helpers";

// https://www.npmjs.com/package/react-calendar
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import moment from "moment";
import { langTh } from "../../../store/lang";

const InvestorRelationsCalendarComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const dispatch = useDispatch();

  const dateNow = new Date();
  const next14Day = addDays(dateNow, 13);

  const [data, setData] = useState({});
  const [content, setContent] = useState([]);
  const [contentCalendar, setContentCalendar] = useState([]);
  const [calendarDate, setCalendarDate] = useState(null);

  const [date, setDate] = useState(null);
  const defaultOptionYears = [
    { value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date()) },
  ];
  const [optionYears, setOptionYears] = useState();
  const [selectedYear, setSelectedYear] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  const slidRef = useRef();

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    try {
      const ShareholderIrCalendar = async () => {
        const resPage = await PagesShareholderIrCalendar();
        setData(resPage?.data);

        // Calendar
        const payload = {
          "filters[event_date][$gte]": `${displayDate(dateNow, "YYYY-MM-DD")}`,
          "filters[event_date][$lte]": `${displayDate(
            next14Day,
            "YYYY-MM-DD"
          )}`,
          "sort[event_date]": "asc",
          "sort[id]": "asc",
        };
        const responseContent = await ShareholderIrEvents(payload);
        setContentCalendar(responseContent?.data);

        // Table
        const payloadDate = {
          "sort[event_date]": "asc",
          "sort[id]": "asc",
        };
        const resDate = await ShareholderIrEvents(payloadDate);
        setDate(resDate?.data);
        setIsMounted(true);
      };

      ShareholderIrCalendar();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateContentByYear = async () => {
      const payloadTable = {
        "filters[event_date][$gte]": `${selectedYear}-01-01`,
        "filters[event_date][$lte]": `${selectedYear}-12-31`,
        "sort[event_date]": "asc",
        "sort[id]": "asc",
      };
      const responseTable = await ShareholderIrEvents(payloadTable);
      setContent(responseTable?.data);
    };

    selectedYear && updateContentByYear();
  }, [selectedYear]);

  useEffect(() => {
    if (contentCalendar) {
      setCalendarDate(contentCalendar[0]?.attributes?.event_date);
    }
  }, [contentCalendar]);

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.event_date).getFullYear()));

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({ value: e, label: lang === langTh ? displayYearBuddhistEra(e) : e })
    );

    setOptionYears(yearsData);
    setSelectedYear(defaultOptionYears[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onChangeYear = (e) => {
    setSelectedYear(e?.value);
  };

  const onChangeSlide = (index) => {
    const event_date = contentCalendar[index]?.attributes?.event_date;
    setCalendarDate(new Date(event_date));
  };

  const onChangeCalendar = (e) => {
    if (contentCalendar && contentCalendar?.length > 0) {
      const getIndex = contentCalendar?.findIndex(r => r?.attributes?.event_date === displayDate(e, "YYYY-MM-DD"));
      if (getIndex > -1) {
        slidRef.current.slickGoTo(getIndex);
      }
    }
  }

  const currentDate = new Date();
  let years = [];
  years.push({
    value: currentDate.getFullYear(),
    label: lang === langTh ? displayYearBuddhistEra(currentDate) : currentDate.getFullYear(),
  });

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slickGoTo: 2,
  };

  const handleGenerateIcs = (data) => {
    const date = new Date(data.attributes?.event_date)
    const month = (date.getMonth() + 1) > 12 ? 12 : date.getMonth() + 1;
    const eventData = {
      start: [date.getFullYear(), month, date.getDate()],
      // duration: { hours: 2, minutes: 30 },
      title: data.attributes?.event_name,
      description: data.attributes?.event_detail,
    };
    exportIcsFile(eventData, eventData.title);
  };

  return (
    <InvestorRelationsCalendarPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "/", text: translate["menu.home_investor_relation"] },
          { url: "/", text: translate["menu.information_for_shareholders"] },
          {
            url: ROUTE_PATH.INVESTOR_RELATIONS_CALENDAR.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="investor-relations-calendar content-wrapper">
          {/* Calendar */}
          <div className="calendar-details">
            <div className="calendar">
              <Calendar
                value={calendarDate}
                onChange={onChangeCalendar}
                locale={lang}
                tileClassName={
                  ({ date }) => {
                    if (date >= dateNow && date <= next14Day) {
                      const findDate = contentCalendar.findIndex(r => r?.attributes?.event_date === displayDate(date, "YYYY-MM-DD"));
                      return findDate > -1 ? "react-calendar__tile--hasActive" : "";
                    } else {
                      return "";
                    }
                  }
                }
              />
            </div>
            <div className="details">
              {
                (!contentCalendar || contentCalendar?.length === 0) && (
                  <div className="no-data" key="no-data">
                    <p>{translate["investor_relations_calendar.no_meeting"]}</p>
                  </div>
                )
              }
              {
                (contentCalendar && contentCalendar?.length > 0) && (
                  <Slider
                    ref={slidRef}
                    className="slider-details"
                    {...settings}
                    afterChange={onChangeSlide}
                  >
                    {
                      contentCalendar?.map((e, i) => {
                        return (
                          <div className="slider-details-items" key={i}>
                            <div className="block-details-items-left">
                              <div className="items-date">
                                <div className="date-bg-blue">
                                  <p>
                                    {displayDate(e?.attributes?.event_date, "DD")}
                                  </p>
                                  <p>
                                    {displayDate(e?.attributes?.event_date, "MM")}/
                                    {
                                      lang === langTh ? displayYearBuddhistEra(e?.attributes?.event_date) : displayYear(e?.attributes?.event_date)
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className="items-text">
                                <p className="items-text-title">
                                  {e?.attributes?.event_name}
                                </p>
                                <ReactMarkdown
                                  children={e?.attributes?.event_detail?.replaceAll(
                                    "\n",
                                    "<br/>"
                                  )}
                                  rehypePlugins={[rehypeRaw]}
                                ></ReactMarkdown>
                                <button
                                  className="btn btn-add-calendar"
                                  onClick={() => handleGenerateIcs(e)}
                                >
                                  <img
                                    className="icon-add-calendar"
                                    src="../asset/media/images/icons/icon-calendar-week.svg"
                                    alt="icon-add-calendar"
                                  />
                                  {
                                    translate[
                                    "investor_relations_calendar.add_to_calendar"
                                    ]
                                  }
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                )
              }

            </div>
          </div>

          {/* Table */}
          <div className="table-calendar-details">
            <div className="select-wrapper">
              <span>{translate["common.year"]}</span>
              {isMounted && (
                <SelectBox
                  // placeholder={defaultOptionYears[0]?.label}
                  defaultValue={optionYears[0]}
                  options={optionYears}
                  onChange={(e) => onChangeYear(e)}
                  isSearchable={false}
                />)}
            </div>
            <div className="table-calendar">
              <Table className="split-row-color split-color-primary">
                <TableHeader className="header-primary">
                  <TR>
                    <TH className="table-calendar-date">{translate["investor_relations_calendar.date"]}</TH>
                    <TH className="table-calendar-event unset-uppercase">{translate["investor_relations_calendar.event"]}</TH>
                    <TH className="table-calendar-detail unset-uppercase">{translate["investor_relations_calendar.detail"]}</TH>
                    <TH className="table-calendar-btn"></TH>
                  </TR>
                </TableHeader>
                <TableBody>
                  {content &&
                    content?.map((item, i) => {
                      const file = item?.attributes?.file?.data;
                      return (
                        <TR key={i}>
                          <TD className="table-calendar-date">
                            {
                              lang === langTh ? displayDateBuddhistEra(item?.attributes?.event_date) : displayDate(item?.attributes?.event_date, "D MMM YYYY")
                            }
                          </TD>
                          <TD className="table-calendar-event">
                            {item?.attributes?.event_name}
                          </TD>
                          <TD className="table-calendar-detail">
                            {item?.attributes?.event_detail}
                          </TD>
                          <TD className="table-calendar-btn">
                            {
                              file?.attributes?.url &&
                              <div
                                className="file-download"
                                onClick={() => {
                                  file?.attributes?.url &&
                                    SaveFile(
                                      BASE_URL_UPLOAD + file?.attributes?.url,
                                      file?.attributes?.name
                                    );
                                }}
                              >
                                <img
                                  className="icon icon-download"
                                  src="../asset/media/images/icons/icon-download-green.svg"
                                  alt="icon-download-green"
                                />
                                {translate["common.download_document"]}
                              </div>
                            }
                          </TD>
                        </TR>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </InvestorRelationsCalendarPageStyle>
  );
};

export default InvestorRelationsCalendarComponent;
