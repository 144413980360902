import styled from "styled-components";
import VARIABLES from "../../../../../themes/variables";

// Wrapper
// ============================================================
export const CardPolicyComplaintProtectionStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  max-width: 476px;
  padding: 28px 34px;
  border-radius: 6px;
  margin: 0 24px 24px 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  background-color: white;

  /* Child element styles
  ------------------------------- */
  .card-head {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .card-body {
    /* ${VARIABLES.TYPOGRAPHYS.BODY._3}; */
    font-weight: 500;
    font-size: 1.25rem;
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    margin-bottom: 25px;

    .card-name {
      position: relative;

      .name {
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (max-width: 991.98px) {
          font-size: 20px;
        }
      }

      .sub-name {
        display: none;
      }

      &:hover {
        .sub-name {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(	94, 172, 27, 0.8);
          color: white;
          width: 150%;
          padding: 15px;
          border-radius: 10px;
          transform: translate(0, 55px);
          z-index: 990;
        }
      }
    }
  }

  .card-footer {
    .file-download {
      cursor: pointer;
      display: flex;
      min-width: 170px;
      /* max-width: 180px; */
      width: fit-content;
      margin-right: 40px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      padding: 6px 11px;
      border-radius: 6px;
      border: 1px solid ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};

      .icon {
        margin-right: 8px;
      }

      &:hover {
        .icon {
          transition: 1s;
          transform: translate(0, 5px);
        }
      }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  /* @media (max-width: 1024.98px) {
    
  } */

  /* @media (max-width: 768.98px) {
   
  } */

  @media (max-width: 430.98px) {
    padding: 28px;
  }

  /* @media (max-width: 375.98px) {
   
  } */

  /* @media (max-width: 320.98px) {
   
  } */
`;
