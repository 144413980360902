import React, { useState, useEffect } from 'react';
import { ROUTE_PATH } from '../../../constants/routes';
import { SustainableDevelopmentGoalsStyle } from './styled';
import { PageHeader } from '../../Global';
import { SustainabilityTarget } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useDispatch, useSelector } from 'react-redux';

const SustainableDevelopmentGoalsComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [DataPage, setDataPage] = useState(null);
  const [Data, setData] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const getSustainabilityTarget = async () => {
      const ResponsePage = await SustainabilityTarget('page-title');
      const ResponseContent = await SustainabilityTarget('', { "sort[createdAt]": "desc" });
      setDataPage(ResponsePage.data);
      setData(ResponseContent.data);
    };

    getSustainabilityTarget();
  }, []);

  const ContentElement = ({ image, title, content, index }) => {
    return (
      <div className='content fluid' key={index}>
        <div className="content-section">
          {index % 2 === 0 && (
            image && (
              <img
                className='shape-image'
                src={image && BASE_URL_UPLOAD + image}
                alt="sustainable-development-goals"
              />
            )
          )}
          <div className="dialog">
            <div className="dialog-title">
              {title}
            </div>
            <div className="dialog-content">
              <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
            </div>
          </div>
          {index % 2 !== 0 && (
            image && (
              <img
                className='shape-image'
                src={image && BASE_URL_UPLOAD + image}
                alt="sustainable-development-goals"
              />
            )
          )}
        </div>
      </div>
    )
  }

  return (
    <SustainableDevelopmentGoalsStyle>

      <PageHeader
        pageHeaderImage={
          DataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + DataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: '/', text: translate["menu.home"] },
          { url: '/', text: translate["menu.sustainable_development"] },
          { url: ROUTE_PATH.SUSTAINABLE_DEVELOPMENT_GOALS.LINK, text: DataPage?.attributes?.menu_text }
        ]}
        title={DataPage?.attributes?.menu_text}
      />

      <div className="background-page"></div>

      <div className="page-content-wrapper">
        <div className='page-content fluid'>
          <div className="sustainable-development-goals-content content-wrapper">

            <div className='content fluid'>
              <div className='title-content'>
                <div className="title">
                  {DataPage?.attributes?.title}
                </div>
                <div className="sub-title">
                  <ReactMarkdown
                    children={DataPage?.attributes?.short_description}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
                <div className="image-group">
                  {DataPage?.attributes?.banner_photo?.data && DataPage?.attributes?.banner_photo?.data?.map((e, i) => {
                    if (i < 3) {
                      return (
                        <img
                          className='image-title'
                          key={i}
                          src={BASE_URL_UPLOAD + e?.attributes?.url}
                          alt={`sustainable-${i}`}
                        />
                      )
                    } else {
                      return ""
                    }
                  }
                  )}
                </div>
              </div>
            </div>

            {Data?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <ContentElement
                    index={i}
                    image={e?.attributes?.image?.data?.attributes?.url}
                    title={e?.attributes?.title}
                    content={e?.attributes?.short_description}
                  />
                </React.Fragment>
              )
            })}

          </div>
        </div>
      </div>
    </SustainableDevelopmentGoalsStyle>
  );
};

export default SustainableDevelopmentGoalsComponent;
