import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../../constants/routes";
import { ManagementDiscussionAnalysisStyle } from "./styled";
import { PageHeader, CardDataSheet } from "../../../Global";
import SelectBox from "../../../Global/SelectBox";
import { getBaseUploadUrl } from "../../../../Service/service";
import {
  PagesFinancialManagementAnalyze,
  FinancialManagementAnalysisFiles,
} from "../../../../Service/financialAnnualReport/managementDiscusionAnalysis";
import { removeDuplicates } from "./../../../../helpers";
import moment from "moment";
import { langTh } from "../../../../store/lang";

const ManagementDiscussionAnalysisComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [date, setDate] = useState(null);
  const [content, setContent] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const ManagementDiscussionAnalysisData = async () => {
      try {
        const ResPage = await PagesFinancialManagementAnalyze();
        setData(ResPage?.data);

        const payloadDate = {
          "sort[date]": "desc",
        };
        const ResDate = await FinancialManagementAnalysisFiles(payloadDate);       
        setDate(ResDate?.data);

        setIsMounted(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    ManagementDiscussionAnalysisData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateContentByYear = async () => {
      const payload = {
        "filters[date][$gte]": `${selectedYear}-01-01`,
        "filters[date][$lte]": `${selectedYear}-12-31`,
        "sort[quarters]": "asc"
      };
      const responseContent = await FinancialManagementAnalysisFiles(payload);
      setContent(responseContent?.data);
    };

    selectedYear && updateContentByYear();
  }, [selectedYear]);

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    const addYear = lang === langTh ? 543 : 0;
    years?.map((e) =>
      yearsData.push({ value: e, label: (e + addYear).toString() })
    );

    setOptionYears(yearsData);
    onChangeYear(yearsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  let img =
    "../asset/media/images/contents/card-img-management-discussion-analysis.png";

  const onChangeYear = (e) => {
    setSelectedYear(e?.value);
  };

  return (
    <ManagementDiscussionAnalysisStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.financial_information"] },
          { url: "#", text: translate["menu.financial_information_document"] },
          {
            url: ROUTE_PATH.MANAGEMENT_DISCUSSION_ANALYSIS.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="page-content-wrapper content-wrapper">
        <div className="page-content fluid">
          <div className="management-discussion-analysis">
            <div className="management-discussion-analysis-title">
              <div className="title">{data?.attributes?.menu_text}</div>
              <div className="select-wrapper">
                <span>{translate["common.year"]}</span>
                {isMounted && (
                  <SelectBox
                    placeholder={optionYears[0]?.label}
                    defaultValue={optionYears[0]}
                    options={optionYears}
                    onChange={(e) => onChangeYear(e)}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>

            {optionYears &&
              optionYears?.map((item, index) => {
                let titleYear = "";
                const getContent = content?.filter(
                  (e) => moment(e?.attributes?.date).year() === item?.value
                );
                if (getContent && getContent.length > 0) {
                  titleYear = (
                    <div className="title-year" key={index}>
                      {translate["common.year"]} {item.label}
                    </div>
                  );
                }

                return (
                  <React.Fragment key={index}>
                    {titleYear}
                    <div
                      className={
                        getContent && getContent.length > 0
                          ? "block-management-discussion-analysis"
                          : ""
                      }
                    >
                      {getContent?.map((e, i) => {
                        return (
                          <CardDataSheet
                            key={i}
                            id={e?.id}
                            cardImage={img}
                            cardTitle={e?.attributes?.file_name}
                            btnName={translate["common.download_document"]}
                            file_name={
                              e?.attributes?.file?.data?.attributes?.name
                            }
                            file_url={
                              e?.attributes?.file?.data?.attributes?.url
                            }
                          />
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </ManagementDiscussionAnalysisStyle>
  );
};

export default ManagementDiscussionAnalysisComponent;
