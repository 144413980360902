import React, { useEffect, useState } from "react";
import { CompanyPolicyPageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import { PageHeader, CardDownloadFile } from "../../Global";
import {
  PagesCorporateGovernance,
  CorporateGovernanceLists,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import { useDispatch, useSelector } from "react-redux";
import { displayDate, displayYear, displayYearBuddhistEra } from "../../../helpers";
import { langTh } from "../../../store/lang";

const CompanyPolicyComponent = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [dataPage, setDataPage] = useState(null);
  const [dataContent, setDataContent] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const CompanyPolicy = async () => {
      const Response = await PagesCorporateGovernance();
      const ResponseContent = await CorporateGovernanceLists(
        {
          "sort[date]": "desc",
          "sort[id]": "desc"
        }
      );
      setDataPage(Response?.data);
      setDataContent(ResponseContent?.data);
    };

    CompanyPolicy();
  }, []);

  return (
    <CompanyPolicyPageStyle>
      <PageHeader
        pageHeaderImage={
          dataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD +
            dataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/", text: translate["menu.corporate_governances"] },
          {
            url: ROUTE_PATH.COMPANY_POLICY.LINK,
            text: dataPage?.attributes?.menu_text,
          },
        ]}
        title={dataPage?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="company-policy content-wrapper">
          <div className="company-policy-title">
            {dataPage?.attributes?.short_description}
          </div>
          {dataContent
            ? dataContent.map((d) => {
                const formattedDate = `${displayDate(d?.attributes?.date, "DD/MM")}/${lang === langTh ? displayYearBuddhistEra(new Date(d?.attributes?.date)) : displayYear(d?.attributes?.date)}`;
                return (
                  <CardDownloadFile
                    key={d?.id}
                    id={d?.id}
                    title={d?.attributes?.file_title}
                    date={formattedDate}
                    fileName={d?.attributes?.file?.data?.attributes?.name}
                    url={d?.attributes?.file?.data?.attributes?.url}
                    btnName={translate["common.download_document"]}
                  />
                );
              })
            : null}
        </div>
      </div>
    </CompanyPolicyPageStyle>
  );
};

export default CompanyPolicyComponent;
