import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const SetAnnouncementsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    min-height: 645px;
  }
  
  .set-announcements {
    padding: 60px;

    .set-announcements-description {
      text-align: center;
       margin: 0;
       padding: 0 0 40px 0;
      ${VARIABLES.TYPOGRAPHYS.BODY._6}
    }

    .set-announcements-search,
    .set-announcements-footer {
      margin-bottom: 43px;
      display: flex;
      justify-content: space-between;

      .search {
        max-width: 359px;
        width: 100%;
        margin-left: 40px;
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        
        .field-wrapper {
          .input-wrapper {
            .input {
              border-radius: 6px;
              ${VARIABLES.TYPOGRAPHYS.BODY._6}
            }
          }
        }
      }

      .select-wrapper {
        min-width: 155px;
        margin-left: 15px;
      }
    }

    a {
      .card-file-download-wrapper {
        .block-file-download {
          &:hover {
            background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER};
            .block-left {
              .name {
                .data-set-announcements {
                  color: #FFFFFF;
                }
              }

              .date {
                .title-set-announcements {
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }
    }
  }
  
 
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 767.98px) {
    .set-announcements {
      padding: 30px;

      .set-announcements-search {
        .search {
          max-width: 250px;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .set-announcements {
      .set-announcements-description,
      .set-announcements-search {
        display: none;
      }
    }
  }

  @media (max-width: 450.98px) {
    .set-announcements {
      .set-announcements-search {
        display: block;

        .search {
          margin-bottom: 16px;
        }

        .select-wrapper{
          text-align: center;
        }
      }

      .set-announcements-footer {
        display: block;

        .search {
          margin-bottom: 16px;
        }

        .select-wrapper-page {
          text-align: center;
        }
      }
    }
  }
`
