import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const InputStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: auto;
  flex-direction: column;

  /* Child element styles
  ------------------------------- */
  .label {
    color: black;
    flex: 0 0 150px;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;

    .input {
      height: 40px;
      line-height: 39px;
      font-size: 15px;
      color: black;
      border-radius: 3px;
      border: 1px solid #999999;
      width: 100%;
      padding: 0 10px;
      outline-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

      ::placeholder {
        color: ${VARIABLES.COLOR.NEUTRAL10};
        ${VARIABLES.TYPOGRAPHYS.BODY._6};
        font-family: 'Anuphan';
      }

      ::focus-visible {
        border-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      }
    }

    .btn-icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      background: transparent;
      padding: 0 10px;
      height: 100%;
      border-color: transparent;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-disabled {
    opacity: 0.7;
  }

   &.is-inline {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &.is-readonly {
    opacity: 0.7;
  }

  &.required {
    .field-label {
      &:after {
        content: '*';
        margin-left: 3px;
        width: 8px;
        color: ${VARIABLES.COLOR.RED1};
      }
    }
  }

  .textarea-wrapper {
    .textarea {
      &:focus-visible {
        border-color: red;
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
