import React, { useEffect } from "react";
import classNames from "classnames";
import Grid from "../../Layouts/Grid";
import Button from "../../Global/Button";
import ButtonsUiPrimaryAbout from "./MaterialUiAbout/ButtonsUiPrimaryAbout";

import { useDispatch } from "react-redux";

const AboutComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "STANDARD" });
  }, []);

  return (
    <Grid>
      <div className={classNames("grid-item")}>
        <h1>Global About</h1>
        <Button>Global About</Button>
      </div>
      <div className={classNames("grid-item")}>
        <h1>MaterialUiAbout</h1>
        <ButtonsUiPrimaryAbout>MaterialUiAbout</ButtonsUiPrimaryAbout>
      </div>
    </Grid>
  );
};

export default AboutComponent;
