import service from "./service";

export const PagesWorkWithUs = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/tb-pages-work-with-us', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const JobRecruitments = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/job-recruitments', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ApplyWorks = async (payload) => {
  try {
    const response = await service().post('/apply-works', { data: payload })
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};