import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const SustainableDevelopmentGoalsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .background-page {
    background-image: url("../asset/media/images/contents/background-sustainability1.png");
    background-size: 100%;
    background-position: center;
    margin-top: -100vh;
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  .sustainable-development-goals-content {
    .title-content {
      padding: 60px 142px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1}
      }

      .sub-title {
        line-height: 1.8;
        /* text-align: justify; */
        ${VARIABLES.TYPOGRAPHYS.BODY._6}
      }

      .image-group {
        margin-top: 24px;
        display: flex;
        gap: 24px;

        .image-title {
          border-radius: 6px;
          width: 315px;
          height: 136px;
          object-fit: cover;
          object-position: center center;
        }
      }
    }

    .content-section {
      padding: 60px 142px;
      display: flex;
      gap: 48px;

      .shape-image {
        border-radius: 200px 8px;
        min-width: 706px;
        max-width: 706px;
        min-height: 383px;
        max-height: 383px;
        object-fit: cover;
      }

      .dialog {
        text-align: left;

        .dialog-title {
          /* Title/Medium 32 */
          ${VARIABLES.TYPOGRAPHYS.TITLE._1}
          margin-bottom: 16px;
        }

        .dialog-content {
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1440.98px) {
    .sustainable-development-goals-content {
      .content-section {
        .shape-image {
          max-width: 600px;
          min-width: 600px;
        }
      }
    }
  }

  @media (max-width: 1250.98px) {
    .sustainable-development-goals-content {
      .content-section {
        .shape-image {
          max-width: 500px;
          min-width: 500px;
        }
      }
    }
  }

  @media (max-width: 1180.98px) {
    .sustainable-development-goals-content {
      .title-content {
        padding: 30px 34px;
        .image-group {
          flex-direction: column;
        }
      }
      .content-section {
        padding: 30px 34px;
        .shape-image {
          border-radius: 130px 8px;
          max-width: 460px;
          min-width: 460px;
          max-height: 336px;
          min-height: 336px;
        }
      }
    }
  }

  @media (max-width: 850.98px) {
    .sustainable-development-goals-content {
      background-color: white;

      .content:nth-child(even) {
        background-color: ${VARIABLES.COLOR.NEUTRAL8};
      }
      .content:nth-child(odd) {
        background-color: white;
      }

      .content-section {
        align-items: center;
        padding: 30px 34px;
        flex-direction: column;

        .shape-image {
          border-radius: 50px 0px;
          min-width: 460px;
          max-width: 460px;
          min-height: 336px;
          max-height: 336px;
        }
      }
    }
  }

  @media (max-width: 560.98px) {
    .sustainable-development-goals-content {
      .content-section {
        .shape-image {
          min-width: 356px;
          max-width: 356px;
          min-height: 256px;
          max-height: 256px;
        }
      }
    }
  }

  @media (max-width: 425.98px) {
    .sustainable-development-goals-content {
      .content-section {
        .shape-image {
          min-width: 300px;
          max-width: 300px;
          min-height: 200px;
          max-height: 200px;
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .sustainable-development-goals-content {
      .title-content {
        .image-group {
          .image-title {
            /* width: 280px;
            height: auto; */
          }
        }
      }

      .content-section {
        padding: 30px 34px;

        .shape-image {
          min-width: 200px;
          max-width: 200px;
          min-height: 180px;
          max-height: 180px;
        }
      }
    }
  }

  @media (max-width: 320.98px) {
    .sustainable-development-goals-content {
      .title-content {
        .image-group {
          .image-title {
            width: 240px;
            /* height: auto; */
          }
        }
      }

      .content-section {
        padding: 30px 34px;

        .shape-image {
          min-width: 180px;
          max-width: 180px;
          min-height: 160px;
          max-height: 160px;
        }
      }
    }
  }
`;
