import service from "./../service";

export const PagesCompanyProfile = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    }
    const response = await service().get('/pages-company-profile', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CompanyHistory = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    }
    const response = await service().get('/company-histories', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};