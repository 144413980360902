import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'
import { size } from 'styled-system'

// Wrapper
// ============================================================
export const ChannelsReceivingComplaintsPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .channels-receiving-complaints {
    position: relative;
    padding: 0 60px;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    .caption {
      
      padding: 40px 10px;
      text-align: center;
      ${VARIABLES.TYPOGRAPHYS.BODY._4};
      .text {
        display: none;
        @media (max-width: 425.98px) {
          display: block;
        }
      }
    }

    

    .block-complainant {
      position: relative;
      width: 100%;
      height: 437px;

      .bg-complainant {
        position:  absolute;
        top: 0;
        right: 0;
        height: 100%;
        
        img {
          width: 100%;
          height: 100%;
        }
      }

      .complainant-details {
        position: absolute;
        top: 20%;
        left: 0;
        width: 75%;
        padding: 45px 50px;
        border-radius: 8px 8px 150px 8px;
        color: #FFFFFF;
        background: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

        .complainant-title {
          margin-bottom: 30px;
          ${VARIABLES.TYPOGRAPHYS.BODY._1};
        }

        .complainant-content {
          display: flex;

          .icon {
            &.icon-person {
              margin-right: 20px;
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      @media (max-width: 425.98px) {
        position: absolute;
        top: 140px;
        margin-right: -20px;
        margin-left: -20px;
        .bg-complainant {
          left: 30px;
          height: calc(100% - 30px);
          
          img {
            border-radius: 8px 0px 0px 8px;
            width: 100%;
            height: 436px;
            object-fit: cover;
            object-position: center;
          }
        }
        .complainant-details {
          top: 50%;
          left: 0;
          width: 75%;
          padding: 45px 50px;
          border-radius: 0px 8px 150px 0px;
        }
      }
    }

    .policy-complaint-protection {
      display: flex;
      padding: 60px 0;
      justify-content: center;

      .title {
        max-width: 280px;
        ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })};
      }

      .card-centent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: fit-content;

        @media (max-width: 767.98px) {
          width: 100%;
          grid-template-columns: unset;
        }
      }

      @media (max-width: 425.98px) {
        margin-top: 420px;
      }
    }

  }

  .contact-form {
    padding: 61px 118px;
    background-color: ${VARIABLES.COLOR.NEUTRAL7};

    .title {
      text-align: center;
      ${VARIABLES.TYPOGRAPHYS.TITLE._1};
    }

    .sub-title {
      text-align: center;
      margin-top: 15px;
      ${VARIABLES.TYPOGRAPHYS.BODY._4};
      color: ${VARIABLES.COLOR.NEUTRAL10};
    }

    .radio-anonymous {
      margin: 25px 0;

      .checkbox  {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 400;
      }
    }
    
    .form {
      margin: 30px auto auto auto;
      max-width: 1000px;
      
      .form-name-lastname,
      .form-ipone-email {
        display: flex;
      }

      .form-name,
      .form-lastname,
      .form-email,
      .form-toppic,
      .form-message {
        width: 100%;
        margin-bottom: 15px;

        .field-wrapper {
          .input-wrapper ,
          .textarea-wrapper{
            margin: 6px 0;

            .input,
            .textarea {
              border-color: ${VARIABLES.COLOR.NEUTRAL11};
              border-radius: 6px;
            }
          }

          .field-label {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      .form-name {
        margin-right: 15px;
      }

      .block-upload-input {
        .field-wrapper {
          .field-label {
            font-size: 18px;
            font-weight: 500;
          }
        }

        .remark-file {
          font-size: 16px;
          font-weight: 300;
        }

        .file-input {
          input {
            background-color: white;
          }
        }
      }

      .specification-condition {
        display: flex;
        margin: 20px 0;

        p {
          font-size: 16px;
          font-weight: 400;

          span {
            font-weight: 500;
          }
        }
      }
    }

    .btn-submit-message {
      display: flex;
      justify-content: center;
      
      .submit-message {
        max-width : 150px;
        min-width: 116px;
        ${VARIABLES.TYPOGRAPHYS.BODY._7};
        
        &:hover {
          background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1336.98px) {
    .channels-receiving-complaints {
      .block-complainant {
        .bg-complainant {
          width: 80%;
        }
      }
    }
  }

  @media (max-width: 1279.98px) {
    .channels-receiving-complaints {
      .policy-complaint-protection {
        .title {
          max-width: 380px;
        }

        .card-centent {
          justify-items: center;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .channels-receiving-complaints {
      .block-complainant {
        height: 367px;
      }
    }

    .contact-form {
       padding: 61px;
    }
  }
  
  @media (max-width: 1100.98px) {
     .channels-receiving-complaints {
      .block-complainant {
        height: 278px;

        .complainant-details {
          /* top: 5%; */
          /* left: 0; */
          /* width: 90%; */
          padding: 25px;
        }
      }

      .policy-complaint-protection {
        display: block;
        padding: 30px 0;

        .title {
           max-width: 100%;
           margin: 30px 0;
           text-align: center;
        }

        .card-centent {
          justify-items: center;
          /* grid-template-columns:unset; */
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .channels-receiving-complaints {
      padding: 0 20px;
    }
  }

  @media (max-width: 500.98px) {
    .channels-receiving-complaints {
      .block-complainant {
        height: 278px;

        .complainant-details {
          width: 90%;
        }
      }
    }
  }
  
`
