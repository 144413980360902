import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const CardDataSheetStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .block-card-data-sheet {
    position: relative;
    display: flex;
    padding: 15px;
    max-width: 625px;
    width: 100%;
    min-height: 257px;
    border-radius: 6px;
    box-shadow: 2px 6px 15px 0px  ${VARIABLES.COLOR.NEUTRAL12};
    
    
    .block-left {
      position: relative;
      max-width: 190px;
      width: 100%;
      padding-top: 18%;
      margin-right: 25px;
      
      .img-logo {
        position: absolute;
        background-color: #FFFFFF;
        top: 0;
        right: 0;
        width: 50%;
        height: 40px;
        padding: 10px 16px;
        border-radius: 0px 6px 0px 24px;
        z-index: 9;
        
        img {
          padding: 10px 16px;
          width: 100%;
        }
      }
      
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }

    .block-right {
      .title {
        position: relative;
        font-size: 20px;
        font-weight: 500;
        padding: 5px 20px;
        margin: 36px 0;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-size: cover;
          background-repeat: no-repeat;
          transform: rotate(180deg);
          width: 6px;
          height: 33px;
          background-image: url('../asset/media/images/icons/icon-line-blue-green.svg');
        }
      }

      .card-name {
        position: relative;

        .name {
          margin-bottom: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;

          @media (max-width: 991.98px) {
            font-size: 20px;
          }
        }

        .sub-name {
          display: none;
        }

        &:hover {
          .sub-name {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(	94, 172, 27, 0.8);
            color: white;
            width: 110%;
            padding: 15px;
            border-radius: 10px;
            transform: translate(0, 55px);
            z-index: 990;

            &.blue{
              background-color: rgba(27, 59, 106, 0.8);
            }
          }
        }
      }

      .file-download {
        position: absolute;
        bottom: 0;
        transform: translate(0, -28px);
        cursor: pointer;
        display: flex;
        min-width: 170px;
        /* max-width: 180px; */
        width: fit-content;
        margin-right: 40px;
        color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        font-size: 16px;
        font-weight: 400;
        padding: 6px 11px;
        border-radius: 6px;
        border: 1px solid transparent;
        background: transparent;

        &.blue {
          color: ${VARIABLES.COLOR.BLUE};

          &:hover {
            border-color: ${VARIABLES.COLOR.BLUE};
            background: ${VARIABLES.COLOR.PRIMARY_LIGHT};
          }
        }

        .icon {
          margin-right: 8px;
        }

        &:hover {
          border-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          background: ${VARIABLES.COLOR.SECONDARY_LIGHT};
        }
      }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 600.98px) {
    .block-card-data-sheet {
      display: block;
      padding: 30px;
      width: 100%;
      min-height: 400px;

      .block-left {
        max-width: 260px;
        padding-top: 80%;
        margin: auto;
      
        .img-logo {
          height: 45px;
        }
      }

      .block-right {
        .title {
          margin: 30px 0;
        }
      }
    }
  }

  /* @media (max-width: 800.98px) {
    
  } */
`
