import React, { useState, useEffect } from "react";
import { FinancialHighlightStyle } from "./styled";
import {
  PageHeader,
  TD,
  TH,
  TR,
  Table,
  TableBody,
  TableHeader,
} from "../../Global";
import { useDispatch, useSelector } from "react-redux";
import CanvasJSReact from "@canvasjs/react-charts";
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  PagesFinancialHighlight,
  GetFinancialData,
} from "./../../../Service/financials";
import { ROUTE_PATH } from "../../../constants/routes";
import moment from "moment";
import { displayNumber, sortArray } from "../../../helpers";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { langTh } from "../../../store/lang";

const FinancialHighlightComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    FinancialHighlight();
    FinancialData();
  }, []);

  const FinancialHighlight = async () => {
    try {
      const resPage = await PagesFinancialHighlight();
      setData(resPage?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const FinancialData = async () => {
    try {
      const resPage = await GetFinancialData();
      if (resPage) {
        const dataPoints = {
          totalRevenue: [],
          totalAsset: [],
          totalLiability: [],
          netProfit: [],
        };

        const colors = ["#C3D8FF", "#54A3D7", "#0E78BE", "#1246A9", "#1B3B6A"];
        const CN = 0.001;
        const addYear = lang === langTh ? 543 : 0;
        const datas = resPage.result.shareholder.map((defaultData, index) => {
          if (index < 5) {
            const asOfDate = moment(defaultData.asOfDate);
            const year = index === 4
              ? `${defaultData.quarter}/${(asOfDate.year() + addYear).toString()}`
              : `${(asOfDate.year() + addYear).toString()}`
              ;
  
            // const totalIncome = defaultData.totalAsset * CN;
            const totalAsset = defaultData.totalAsset * CN;
            const totalLiability = defaultData.totalLiability * CN;
            const equity = defaultData.equity * CN;
            const paidupCapital = defaultData.paidupCapital * CN;
            const totalRevenue = defaultData.totalRevenue * CN;
            const netProfit = defaultData.netProfit * CN;
            const eps = defaultData.eps;
  
            // dataPoints.totalIncome.push({...setDataPoint(year, totalIncome), color: colors[index]});
            dataPoints.totalRevenue.push({...setDataPoint(year, totalRevenue), color: colors[index]});
            dataPoints.totalAsset.push({...setDataPoint(year, totalAsset), color: colors[index]});
            dataPoints.totalLiability.push({...setDataPoint(year, totalLiability), color: colors[index]});
            dataPoints.netProfit.push({...setDataPoint(year, netProfit), color: colors[index]});
  
            return {
              defaultData,
              totalAsset,
              totalLiability,
              equity,
              paidupCapital,
              totalRevenue,
              netProfit,
              eps,
              year: defaultData.year + addYear,
              formatDate: `${translate["financial_highlights.annual_budget"]} ${year.slice(-2)} <br/> ${asOfDate.format(
                "DD"
              )}/${asOfDate.format("MM")}/${year}`,
            };
          }
        });

        // min, max total revenue
        const maxTotalRevenue = sortArray([...dataPoints.totalRevenue], "y", "desc")[0].y + 200;
        const minTotalRevenue = sortArray([...dataPoints.totalRevenue], "y", "asc")[0].y + (-100);
        dataPoints.maxTotalRevenue = maxTotalRevenue;
        dataPoints.minTotalRevenue = minTotalRevenue > 0 ? 0 : minTotalRevenue;

        // min, max total asset
        const maxTotalAsset = sortArray([...dataPoints.totalAsset], "y", "desc")[0].y + 200;
        const minTotalAsset = sortArray([...dataPoints.totalAsset], "y", "asc")[0].y + (-100);
        dataPoints.maxTotalAsset = maxTotalAsset;
        dataPoints.minTotalAsset = minTotalAsset > 0 ? 0 : minTotalAsset;

        // min, max total liability
        const maxTotalLiability = sortArray([...dataPoints.totalLiability], "y", "desc")[0].y + 200;
        const minTotalLiability = sortArray([...dataPoints.totalLiability], "y", "asc")[0].y + (-100);
        dataPoints.maxTotalLiability = maxTotalLiability;
        dataPoints.minTotalLiability = minTotalLiability > 0 ? 0 : minTotalLiability;

        // min, max net profit
        const maxNetProfit = sortArray([...dataPoints.netProfit], "y", "desc")[0].y + 200;
        const minNetProfit = sortArray([...dataPoints.netProfit], "y", "asc")[0].y + (-100);
        dataPoints.maxNetProfit = maxNetProfit;
        dataPoints.minNetProfit = minNetProfit > 0 ? 0 : minNetProfit;

        setChartData(dataPoints);
        setList(datas);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const setDataPoint = (year, num) => {
    const y = Number(num.toFixed(2));
    return {
      label: year,
      y,
      indexLabel: displayNumber(y),
    };
  };

  const options = {
    axisX: {
      lineThickness: 0,
      tickLength: 0,
      labelFontSize: 11,
      labelFontWeight: "bold",
      labelFontColor: "#8492A4",
    },
    axisY: {
      lineThickness: 0,
      tickLength: 0,
      labelFormatter: () => "",
      gridColor: "#e0e0e0",
      gridThickness: 0,
      stripLines: [
        {
          value: 0,
          showOnTop: true,
          color: "gray",
          thickness: 2
        }
      ]
    },
  };

  const totalRevenueOption = {
    ...options,
    axisY: {
      ...options.axisY,
      maximum: chartData?.maxTotalRevenue,
      minimum: chartData?.minTotalRevenue,
      stripLines: [
        {
          ...options.axisY.stripLines[0],
          thickness: 2
        }
      ],
    },
    data: [
      {
        indexLabelFontColor: "#263238",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: chartData?.totalRevenue,
        backgroundColor: "transparent",
      },
    ],
  };

  const totalAssetOption = {
    ...options,
    axisY: {
      ...options.axisY,
      maximum: chartData?.maxTotalAsset,
      minimum: chartData?.minTotalAsset,
      stripLines: [
        {
          ...options.axisY.stripLines[0],
          thickness: 2
        }
      ],
    },
    data: [
      {
        indexLabelFontColor: "#000",
        // indexLabelFontColor: "#263238",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: chartData?.totalAsset,
        backgroundColor: "transparent",
      },
    ],
  };

  const totalLiabilityOption = {
    ...options,
    axisY: {
      ...options.axisY,
      maximum: chartData?.maxTotalLiability,
      minimum: chartData?.minTotalLiability,
    },
    data: [
      {
        indexLabelFontColor: "#000",
        // indexLabelFontColor: "#263238",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: chartData?.totalLiability,
        backgroundColor: "transparent",
      },
    ],
  };

  const equityOption = {
    ...options,
    axisY: {
      ...options.axisY,
      maximum: chartData?.maxNetProfit,
      minimum: chartData?.minNetProfit,
      stripLines: [
        {
          ...options.axisY.stripLines[0],
          thickness: 1
        }
      ],
    },
    data: [
      {
        indexLabelFontColor: "#000",
        // indexLabelFontColor: "#263238",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: chartData?.netProfit,
        backgroundColor: "transparent",
      },
    ],
  };

  const ths = list.map((d, index) =>
    index < 5 && (
      <TH key={index} className="financial-highlight date">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          children={d.formatDate}
        ></ReactMarkdown>
      </TH>
    )
  );

  const totalAssetTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.totalAsset)?.toFixed(2))}
      </TD>
    )
  );

  const totalLiabilityTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.totalLiability)?.toFixed(2))}
      </TD>
    )
  );

  const equityTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.equity)?.toFixed(2))}
      </TD>
    )
  );

  const paidupCapitalTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.paidupCapital)?.toFixed(2))}
      </TD>
    )
  );

  const totalRevenueTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.totalRevenue)?.toFixed(2))}
      </TD>
    )
  );

  const netProfitTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.netProfit)?.toFixed(2))}
      </TD>
    )
  );

  const epsTds = list.map((d, index) =>
    index < 5 && (
      <TD key={index} className="text-center">
        {displayNumber(Number(d.eps)?.toFixed(2))}
      </TD>
    )
  );

  return (
    <FinancialHighlightStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "/", text: translate["menu.home_investor_relation"] },
          { url: "/", text: translate["menu.financial_information"] },
          {
            url: ROUTE_PATH.FINANCIAL_HIGHLIGHTS.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="financialhighlight content-wrapper">
        {chartData ? (
          <div className="box-content">
            <div className="block-charts">
              <div className="charts-service-income">
                <p className="charts-title">{translate["financial_highlights.total_income"]}</p>
                <p className="sub-title">({translate["financial_highlights.million_baht"]})</p>
                <div className="charts">
                  <div className="service-income">
                    <CanvasJSChart options={totalRevenueOption} />
                  </div>
                </div>
              </div>
              <div className="charts-total-assets">
                <p className="charts-title">{translate["financial_highlights.total_assets"]}</p>
                <p className="sub-title">({translate["financial_highlights.million_baht"]})</p>
                <div className="charts">
                  <div className="total-assets">
                    <CanvasJSChart options={totalAssetOption} />
                  </div>
                </div>
              </div>
              <div className="charts-net-profit">
                <p className="charts-title">{translate["financial_highlights.net_profit"]}</p>
                <p className="sub-title">({translate["financial_highlights.million_baht"]})</p>
                <div className="charts">
                  <div className="net-profit">
                    <CanvasJSChart options={equityOption} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="top-list">
          <div className="list-table">
            <div className="text-head-table">{translate["financial_highlights.financial_information"]}</div>
            <div className="block-table">
              <div className="table-wrapper">
                <Table className="table split-row-color split-color-primary">
                  <TableHeader className="header-primary">
                    <TR>
                      <TH className="financial-highlight name">
                        {translate["financial_highlights.important_financial_accounts"]}
                      </TH>
                      {ths}
                    </TR>
                  </TableHeader>
                  <TableBody>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.total_assets"]}</TD>
                      {totalAssetTds}
                    </TR>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.total_debt"]}</TD>
                      {totalLiabilityTds}
                    </TR>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.shareholder_equity"]}</TD>
                      {equityTds}
                    </TR>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.value_of_paid_up_shares"]}</TD>
                      {paidupCapitalTds}
                    </TR>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.total_income"]}</TD>
                      {totalRevenueTds}
                    </TR>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.net_profit"]}</TD>
                      {netProfitTds}
                    </TR>
                    <TR>
                      <TD className="text-left">{translate["financial_highlights.earnings_per_share_baht"]}</TD>
                      {epsTds}
                    </TR>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FinancialHighlightStyle>
  );
};

export default FinancialHighlightComponent;
