import SocialDimensionManagementComponent from "../components/Pages/SocialDimensionManagement";
import Auth from "../containerWarping/Auth";
const socialDimensionManagement = () => {
  return (
    <Auth>
      <SocialDimensionManagementComponent />
    </Auth>
  );
};

export default socialDimensionManagement;
