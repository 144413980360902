
import HistoricalPriceComponent from "../components/Pages/HistoricalPrice";
import Auth from "../containerWarping/Auth";
const HistoricalPrice = () => {
  return (
    <Auth>
      <HistoricalPriceComponent/>
    </Auth>
  );
};

export default HistoricalPrice;