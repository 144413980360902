import AnnualReportComponent from "../components/Pages/FinancialDataSheet/AnnualReport";
import Auth from "../containerWarping/Auth";
const AnnualReport = () => {
  return (
    <Auth>
      <AnnualReportComponent />
    </Auth>
  );
};

export default AnnualReport;
