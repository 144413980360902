import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const InputTextareaStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: auto;
  flex-direction: column;

  /* Child element styles
  ------------------------------- */
  .label {
    color: black;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .textarea-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
  }

  .textarea {
    border-radius: 6px;
    border: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
    background-color: white;
    flex: 1 1 auto;
    min-width: 350px;
    max-width: 100%;
    min-height: 62px;
    max-height: 70vh;
    font-size: 16px;
    color: black;
    padding: 8px 14px;
    resize: vertical;
    outline-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

    ::-webkit-input-placeholder {
      color: ${VARIABLES.COLOR.NEUTRAL10};
      ${VARIABLES.TYPOGRAPHYS.BODY._6};
      font-family: 'Anuphan';
    }

    :-ms-input-placeholder {
      color: ${VARIABLES.COLOR.NEUTRAL10};
      ${VARIABLES.TYPOGRAPHYS.BODY._6};
      font-family: 'Anuphan';
    }

    ::placeholder {
      color: ${VARIABLES.COLOR.NEUTRAL10};
      ${VARIABLES.TYPOGRAPHYS.BODY._6};
      font-family: 'Anuphan';
    }
    
    ::focus-visible {
      border-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    }

  }

  /* Modifiers
  ------------------------------- */
  &.required {
    .field-label {
      &:after {
        content: '*';
        margin-left: 3px;
        width: 8px;
        color: ${VARIABLES.COLOR.RED1};
      }
    }
  }

  /* State
  ------------------------------- */
  &.is-disabled {
    opacity: 0.7;
  }

  &.is-read-only {
    opacity: 0.7;
  }

  /* Media queries
  ------------------------------- */
  @media all and (max-width: 1600.98px) {
    .textarea {
      min-width: 0;
      font-size: 16px;
      min-height: 109px;
    }
  }

  @media all and (max-width: 1440.98px) {
    .textarea {
      font-size: 15px;
      min-height: 102px;
    }
  }

  @media all and (max-width: 1366.98px) {
    .textarea {
      font-size: 14px;
      min-height: 95px;
    }
  }

  @media all and (max-width: 1280.98px) {
    .textarea {
      font-size: 13px;
      padding: 7px 10px;
    }
  }

  @media all and (max-width: 1024.98px) {
    .textarea {
      font-size: 12px;
    }
  }
`
