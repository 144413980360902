import moment from "moment";
import { isDate, isNumber, isString } from "../helpers";

export const displayDate = (date, format = "DD MMM YYYY") => {
  if (isDate(date)) {
    return moment(date).format(format);
  }
  return date;
};

export const displayYear = (date, format = "YYYY") => {
  if (isDate(date)) {
    return moment(date).format(format);
  }
  return date;
};

export const displayTime = (date, format = "HH:mm:ss") => {
  if (isDate(date)) {
    return moment(date).format(format);
  }
  return date;
};

export const displayTimeEn = (date) => {
  if (isDate(date)) {
    let current = new Date(date);
    return current.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  }
  return date;
};

export const displayUTCTime = (date) => {
  if (isDate(date)) {
    let date1 = new Date(date);
    date1.toUTCString();
    Math.floor(date1.getTime() / 1000);

    let date2 = new Date( date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate(), date1.getUTCHours(), date1.getUTCMinutes(), date1.getUTCSeconds() );
    date2.toUTCString();
    Math.floor(date2.getTime());

    const getHours = new Date(date2).getHours();
    const getMinutes = new Date(date2).getMinutes();
    // const getSeconds = new Date(date2).getSeconds();

    return `${getHours.toString().padStart(2, '0')}.${getMinutes.toString().padStart(2, '0')}`;
  }
  return date;
};

export const displayFullDateBuddhistEra = (date) => {
  if (isDate(date)) {
    const setDate = new Date(date);
    return setDate.toLocaleDateString("th-TH", {
      day: "numeric",
      month: "long",
      year: "numeric",
      weekday: "long",
    });
  }
  return date;
};

export const displayDateBuddhistEra = (date, isFullMonth = false) => {
  if (isDate(date)) {
    const setDate = new Date(date);
    return setDate.toLocaleDateString("th-TH", {
      day: "numeric",
      month: isFullMonth ? "long" : "short",
      year: "numeric",
    });
  }
  return date;
};

export const displayDayBuddhistEra = (date) => {
  if (isDate(date)) {
    const setDate = new Date(date);
    return (setDate.getFullYear() + 543)?.toString();
  } else if (isNumber(date)) {
    return (date + 543)?.toString();
  }
  return date;
};

export const displayYearBuddhistEra = (date) => {
  if (isDate(date)) {
    const setDate = new Date(date);
    return (setDate.getFullYear() + 543)?.toString();
  } else if (isNumber(date)) {
    return (date + 543)?.toString();
  }
  return date;
};

export const displayQuarter = (date) => {
  if (isDate(date)) {
    const month = moment(date).month();
    if (month >= 1 && month <= 3) {
      return 1;
    } else if (month >= 4 && month <= 6) {
      return 2;
    } else if (month >= 7 && month <= 9) {
      return 3;
    } else {
      return 4;
    }
  }

  return 0;
};

export const displayNumber = (num) => {
  if (isNumber(num)) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (isString(num)) {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num;
};

export const displayFileSizeMB = (fileSize) => {
  if (isNumber(fileSize)) {
    return fileSize / 1024;
  }

  return fileSize;
};

export const dynamicColors = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

