import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const SustainableDevelopmentPolicyStyle = styled.div`
  /* Parent styles
  ------------------------------- */

    background-image: url('../../asset/media/images/contents/bg-layout-left-bottom.png');
    background-size: cover;

  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  .select__indicator {
    color: #000000;
  }

  .sustainable-development-policy-content {
    .content-title {
      padding: 60px 65px;
      
      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1}
      }
      .description {
        margin-top: 16px;
        margin-bottom: 24px;
        text-indent: 80px;
        line-height: 1.8;
        ${VARIABLES.TYPOGRAPHYS.BODY._6}

      }
    }

    .btn-download {
      width: 200px;

      &:hover {
        color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        background: ${VARIABLES.COLOR.SECONDARY_LIGHT};

        .icon-download {
          border-radius: 12px;
          border-color:transparent;
          background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        }
      }
    }

    .btn-card-download {
      width: 155px;
      ${VARIABLES.TYPOGRAPHYS.BODY._11}

      &:hover {
        color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        background: ${VARIABLES.COLOR.SECONDARY_LIGHT};

        .icon-download {
          border-radius: 12px;
          border-color:transparent;
          background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        }
      }
    }

    .content-section {
      background-color: ${VARIABLES.COLOR.NEUTRAL7};
      padding: 60px 100px;

      .select-wrapper {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: flex-end;
        padding-right: 12px;
        margin-bottom: 24px;
        span {
          ${VARIABLES.TYPOGRAPHYS.BODY._4}
          margin-bottom: 8px;
          margin-right: 8px;
        }
      }

      .card-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
        gap: 50px;
        justify-items: center;

        .card {
          padding-top: 26px;
          display: flex;
          justify-content: center;
          width: 390px;
          height: 170px;
          border-radius: 20px;
          overflow: hidden;
          color: white;
          background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

          .card-content {
            width: 320px;
            height: 124px;
            .card-title-wrapper {
              display: flex;
              align-items: center;
              height: 60px;
              margin-bottom: 15px;

              img {
                width: 24px;
                height: 24px;
                margin-right: 16px;
              }
              .card-title{
                height: auto;
                width: 100%;
                ${VARIABLES.TYPOGRAPHYS.TITLE._11}
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }
  

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    .sustainable-development-policy-content {
      .content-section {

        .card-wrapper {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
          gap: 40px;
          justify-items: center;
          }
      }
    }
  }
  
  @media (max-width: 1024.98px) {
    .sustainable-development-policy-content {
      .content-section {
        .select-wrapper {
          span {
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  @media (max-width: 768.98px) {
    .sustainable-development-policy-content {
      .content-section {
        padding: 30px 38px;
      }
    }
  }

  @media (max-width: 430.98px) {
    .sustainable-development-policy-content {
      .content-title {
        background-color: white;
        padding: 40px;
      }
      .content-section {
        padding: 30px;

        .select-wrapper {
          padding-right: 0px;
          span {
            display: none;
          }
        }

        .card-wrapper {
          grid-template-columns: unset;
          gap: 30px;

          .card {
            width: 100%;
            border-radius: 6px;
            padding: 26px 0;

            .card-content {
              padding: 0 20px;
              width: 100%;
              height: 104px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .sustainable-development-policy-content {
      .content-section {
        padding: 30px 38px;

        .select-wrapper {
          padding-right: 0px;
        }

        .card-wrapper {
          .card {
            width: 100%;
            border-radius: 6px;

            .card-content {
              width: 100%;
              height: 104px;
            }
          }
        }
      }
    }
  }
`
