import moment from 'moment';

export const isString = (value) => {
  return 'string' === typeof value;
};

export const isNumber = (value) => {
  return 'number' === typeof value;
};

export const isArray = (arr) => {
  return Array.isArray(arr);
};

export const isDateObject = (value) => {
  return value instanceof Date;
};

export const isDate = (value) => {
  if (isDateObject(value)) {
    return true;
  } else if (isString(value) && value !== '') {
    let date = moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid();
    let dateUTC = moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid();
    let dateUTC2 = moment(
      value,
      'YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZ',
      true
    ).isValid();
    let dateUTC3 = moment(
      value,
      'YYYY-MM-DDTHH:mm:ss.SSSSSSSSS',
      true
    ).isValid();
    let dateDay = moment(value, 'YYYY-MM-DD', true).isValid();
    return date || dateUTC || dateUTC2 || dateUTC3 || dateDay;
  }
  return false;
};
