import React from 'react';
import classNames from 'classnames';
import { default as VARIABLES } from '../../../themes/variables';
import Select, { components } from 'react-select';
//https://react-select.com/home

import { SelectStyle } from './styled';

/**
 * Button description
 * - ...
 */

const Option = (props) => {
  return <components.Option className={props.data.className} {...props} />;
};

const customStyles = {

  control: (base, state) => ({
    ...base,
    whiteSpace: 'nowrap',
    boxShadow: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: 0,
    borderColor: state.isFocused ? VARIABLES.COLOR.NEUTRAL6 : VARIABLES.COLOR.NEUTRAL6,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: VARIABLES.COLOR.NEUTRAL6,
    },
    height: '42px',
    '@media only screen and (min-width: 1601px)': {
      ...base['@media only screen and (min-width: 1601px)'],
      minWidth: '110px',
    },
  }),

  option: (styles, { isSelected }) => {
    return {
      ...styles,
      transition: '0.6s',
      cursor: 'pointer',
      borderRadius: '10px',
      backgroundColor: isSelected ? VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER : 'white',
      '&:hover': {
        borderRadius: '10px',
        color: 'white',
        backgroundColor: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
      },
    }
  },

  menuList: (styles) => {
    return {
      ...styles,
      borderRadius: '10px',
      paddingLeft: '8px',
      paddingRight: '8px',
      boxShadow: `0 0 10px 5px ${VARIABLES.COLOR.NEUTRAL12}`,
      maxHeight: '200px',
      '&::-webkit-scrollbar-thumb': {
        background: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
      },
      'scroll-behavior': 'smooth',
    }
  },

  menuPortal: (styles) => ({ ...styles, zIndex: "1000" }),
}

export default function SelectBox({ className, ...props }) {
  return (
    <SelectStyle className={classNames(
      'select-control',
      className
    )}>
      <Select
        components={{ Option }}
        styles={customStyles}
        menuPortalTarget={document.querySelector('body')}
        {...props}
        classNamePrefix="select"
      />
    </SelectStyle>
  );
}
