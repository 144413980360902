import AuditCommitteeComponent from "../components/Pages/AuditCommittee";
import Auth from "../containerWarping/Auth";
const AuditCommittee = () => {
  return (
    <Auth>
      <AuditCommitteeComponent />
    </Auth>
  );
};

export default AuditCommittee;