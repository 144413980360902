import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const InvestorRelationsCalendarPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .unset-uppercase {
    text-transform: unset;
  }

  .investor-relations-calendar {
    .calendar-details {
      position: relative;
      display: flex;
      padding: 30px 60px;

      .calendar {
        position: relative;
        margin-right: 60px;
        width: 100%;
        height: auto;
        border-radius: 6px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

        .react-calendar {
          width: 100%;
          height: 100%;
        }
      }

      .details {
        position: relative;
        width: 100%;
        max-width: 65%;
        padding-left: 15px;
        padding-bottom: 35px;
        background-color: #f0fce1;
        border-radius: 6px;

        .no-data {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
        }

        .slider-details {
          .slick-list {
            .slick-track {
              .slick-slide {
                .slider-details-items {
                  padding: 60px 30px;

                  .block-details-items-left {
                    display: flex;

                    .items-date {
                      padding: 30px;

                      .date-bg-blue {
                        max-width: 90px;
                        padding: 14px 17px;
                        text-align: center;
                        border-radius: 6px;
                        background-color: #1b3b6a;

                        p {
                          &:first-child {
                            color: #ffffff;
                            font-size: 32px;
                            font-weight: 500;
                          }

                          &:last-child {
                            color: #ffffff;
                            font-size: 14px;
                            font-weight: 500;
                          }
                        }
                      }
                    }

                    .items-text {
                      .items-text-title {
                        color: #000000;
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 15px;
                      }

                      .items-text-details {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 30px;
                        margin-bottom: 15px;
                      }

                      .btn {
                        &.btn-add-calendar {
                          display: flex;
                          padding: 6px 12px;
                          margin-top: 15px;
                          color: #1b3b6a;
                          border-radius: 100px;
                          border: 1px solid #1b3b6a;
                          background-color: #f0fce1;
                          cursor: pointer;

                          .icon-add-calendar {
                            margin-right: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .table-calendar-details {
      padding: 30px 60px;

      .select-wrapper {
        text-align: end;
        margin-bottom: 25px;
      }

      .table-calendar {
        position: relative;
        overflow-x: auto;

        .table {
          .thead {
            .table-row {
              .table-column-head {
                font-size: 18px;
              }

              .table-calendar-date {
                width: 205px;
                text-align: center;
              }

              .table-calendar-event {
                width: 292px;
                white-space: break-spaces;
                text-align: left;
              }

              .table-calendar-detail {
                width: 469px;
                white-space: break-spaces;
                text-align: left;
              }

              .table-calendar-btn {
                text-align: center;
                width: 240px;
              }
            }
          }

          .tbody {
            .table-row {
              .table-calendar-date {
                text-align: center;
                padding: 30px 14px;
              }

              .table-calendar-event {
                width: 292px;
                white-space: break-spaces;
                text-align: left;
              }

              .table-calendar-detail {
                width: 469px;
                white-space: break-spaces;
                text-align: left;
              }

              .table-calendar-btn {
                text-align: center;
                width: 240px;
              }
            }
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  .file-download {
    margin: auto;
    cursor: pointer;
    display: flex;
    min-width: 170px;
    /* max-width: 180px; */
    width: fit-content;
    margin-right: 40px;
    color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    ${VARIABLES.TYPOGRAPHYS.BODY._6}
    padding: 6px 11px;
    border-radius: 6px;
    border: 1px solid transparent;
    background: transparent;

    .icon {
      margin-right: 8px;
    }

    &:hover {
      border-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      background: ${VARIABLES.COLOR.SECONDARY_LIGHT};
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1366.98px) {
    .investor-relations-calendar {
      .calendar-details {
        .details {
          .slider-details {
            .slick-list {
              .slick-track {
                .slick-slide {
                  div {
                    padding: 0 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1198.98px) {
    .investor-relations-calendar {
      .calendar-details {
        .details {
          .slider-details {
            .slick-list {
              .slick-track {
                .slick-slide {
                  div {
                    padding: 0 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .investor-relations-calendar {
      .calendar-details {
        display: block;
        padding: 30px;

        .details {
          margin: 25px 0;
          max-width: 100%;

          .slider-details {
            .slick-list {
              .slick-track {
                .slick-slide {
                  div {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }

      .table-calendar-details {
        padding: 30px;
      }
    }
  }
  @media (max-width: 600.98px) {
    .investor-relations-calendar {
      .calendar-details {
        .details {
          .slider-details {
            .slick-list {
              .slick-track {
                .slick-slide {
                  div {
                    .slider-details-items {
                      padding: 30px;
                      .block-details-items-left {
                        display: block;

                        .items-date {
                          padding: 0;
                          margin-bottom: 25px;

                          .date-bg-blue {
                            padding: 25px 17px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
