import OtherDocumentsComponent from "../components/Pages/OtherDocuments";
import Auth from "../containerWarping/Auth";
const OtherDocuments = () => {
  return (
    <Auth>
      <OtherDocumentsComponent />
    </Auth>
  );
};

export default OtherDocuments;