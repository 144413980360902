import styled from 'styled-components';
import VARIABLES from '../../../themes/variables';

// Wrapper
// ============================================================
export const ModalStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  /* Child element styles
  ------------------------------- */
  .bg-onclick {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.4s ease;
    z-index: 1;
  }

  .btn-close {
    &.banner {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      transform: translate(-100%, 100%);
      z-index: 1;

      &:hover {
        background-color: transparent;
        color: transparent;
      };
    }
  }

  .modal-banner {
    position: relative;
    z-index: 1;
    
    .modal-banner-content {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 80vw;
      
      .modal-content {
        .modal-banner {
          width: 100%;
          max-height: 80vh;
          border-radius: 12px;
        }
      }
    }
  }

  .modal-announcement {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    
    .modal-announcement-content {
      position: relative;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      max-width: 1010px;
      max-height: 600px;
      padding: 15px 25px;
      margin: auto;
      border-radius: 12px;
      box-shadow: 0px 10px 20px -10px ${VARIABLES.COLOR.NEUTRAL12};
      
      &.bg-modal-content {
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../asset/media/images/contents/bg-modal-announcement.png');
      }

      .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        padding: 0;
        border: none;
        transform: translate(-100%, 100%);

        &:hover {
          background-color: transparent;
          color: transparent;
        }
      }

      .modal-body {
        position: relative;
        text-align: center;

        .modal-logo {
          margin: 55px 0 35px 0;

          .icon-logo {
            max-width: 200px;
            max-height: 60px;
            width: 100%;
            height: 100%;
          }
        }

        .slick-slider {
          .slick-dots {
            bottom: -40px;

            li {
              width: 15px;
              
              button {
                &:before {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .modal-content {
          margin-bottom: 26px;

          .content {
            margin-bottom: 26px;

            &.title {
              color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              font-size: 34px;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            &.date {
              font-size: 24px;
              font-weight: 400;
            }

            &.contact {
              font-size: 22px;
              font-weight: 500;
              margin-bottom: 26px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          .link {
            &.more-details {
              ${VARIABLES.TYPOGRAPHYS.BODY._7};
              margin-bottom: 26px;
              border-radius: 100px;
              color: #FFFFFF;
              border: 1px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};;
              background: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              padding: 6px 18px;
            }
          }
        }
      }
    }
  }

  .modal-warning-message {
    position: relative;
    
    .modal-warning-message-content {
      position: relative;
      z-index: 1;
      width: 70vw;
      height: 70vh;
      max-width: 980px;
      max-height: 540px;
      border-radius: 20px;
      background-color: #FFFFFF;
      margin: auto;

      &.bg-modal-content {
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../asset/media/images/contents/bg-modal-warning-message.png');
      }

      .modal-body {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        .modal-logo {
          /* padding-top: 40px; */
          margin: auto;
          max-width: 305px;

          .icon {
            width: 100%;
            height: 100%;
          }
        }

        .modal-warning-message-text {
          font-size: 24px;
          font-weight: 500;
          /* margin-bottom: 40px; */
        }

        .btn-close {
          width: 60px;
          height: 40px;
          margin: auto;
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */
  &.is-open-modal {
    z-index: 999;
    transition: all ${VARIABLES.TRANSITIONS.FAST} ease-out;
  }

  &.is-close-modal {
    display: none;
    transition: all ${VARIABLES.TRANSITIONS.FAST} ease-out;
  }

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    .modal-announcement {
      .modal-announcement-content {
        .modal-body {
          .modal-logo {
            margin: 35px;
          }

          .slick-slider {
            .slick-dots {
              bottom: -25px;
            }
          }

          .modal-content {
            .content {
              &.title {
                font-size: 32px;
              }

              &.date {
                font-size: 24px;
              }

              &.contact {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 1200.98px) {
    .modal-announcement {
      .modal-announcement-content {
        width: 90vw;
        height: 80vh;
      }
    }

    .modal-warning-message {
      .modal-warning-message-content {
        .modal-body {
          .modal-logo {
            /* padding-top: 20px; */
          }

          .modal-warning-message-text {
            /* margin-bottom: 20px; */
          }
        }
      }
    }
  }

  @media (max-width: 1024.98px) {
    .modal-announcement {
      .modal-announcement-content {
        .modal-body {
          .modal-content {
            .content {
              &.title {
                font-size: 30px;
              }

              &.date {
                font-size: 22px;
              }

              &.contact {
                font-size: 20px;
              }
            }

            .link {
              &.more-details {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 820.98px) {
    .modal-announcement {
      .modal-announcement-content {
        width: 90vw;
        height: 90vh;

        .modal-body {
          .modal-logo {
            margin: 85px 0 40px 0;

            .icon-logo {
              max-width: 175px;
            }
          }

          .modal-content {
            margin-bottom: 20px;

            .content {
              &.title,
              &.date,
              &.contact {
                margin-bottom: 20px;
              }

              &.title {
                font-size: 28px;
              }

              &.date {
                font-size: 20px;
              }

              &.contact {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    
    .modal-warning-message {
      .modal-warning-message-content {
        width: 90vw;
        height: 90vw;
      }
    }
  }

  @media (max-width: 600.98px) {
    .modal-banner {
      .btn-close {
        transform: translate(-50%, 50%);
      }
    }

    .modal-announcement {
      .modal-announcement-content {
        .modal-body {
          .modal-logo {
            margin: 85px 0 35px 0;

            .icon-logo {
              max-width: 175px;
            }
          }

          .modal-content {
            .content {
              &.title {
                font-size: 26px;
              }

              &.date {
                font-size: 18px;
              }

              &.contact {
                font-size: 16px;
              }
            }

            .link {
              &.more-details {
                font-size: 12px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }

    .modal-warning-message {
      .modal-warning-message-content {
        .modal-body {
          .modal-logo {
            /* padding-top: 40px; */
            max-width: 250px;
          }

          .modal-warning-message-text {
            font-size: 20px;
            /* margin-bottom: 40px; */
          }
        }
      }
    }
  }

  @media (max-width: 414.98px) {
    .modal-announcement {
      .modal-announcement-content {
        .modal-body {
          .modal-content {
            margin-bottom: 16px;

            .content {
              &.title,
              &.date,
              &.contact {
                margin-bottom: 16px;
              }

              &.title {
                font-size: 24px;
              }

              &.date {
                font-size: 16px;
              }

              &.contact {
                font-size: 14px;
              }
            }

            .link {
              &.more-details {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }

    .modal-warning-message {
      .modal-warning-message-content {
        .modal-body {
          .modal-logo {
            max-width: 150px;
          }

          .modal-warning-message-text {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-height: 430.98px) {
    .modal-announcement {
      .modal-announcement-content {
        /* max-height: 300px; */
        .modal-body {

          .modal-logo {
            max-width: 150px;
            margin: 20px auto !important;
          }
          .modal-content {
            margin-bottom: 16px;

            .content {
              &.title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }

              &.title,
              &.date,
              &.contact {
                margin-bottom: 16px;
              }

              &.title {
                font-size: 24px;
              }

              &.date {
                font-size: 16px;
              }

              &.contact {
                font-size: 14px;
              }
            }

            .link {
              &.more-details {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
`
