// import React, { Fragment } from 'react';
import ClassNames from 'classnames';
import {
  TableStyle,
  TableRowStyle,
  TableColumnStyle,
  TableColumnHeadStyle,
  TableBodyStyle,
  TableHeaderStyle,
  TableFooterStyle,
} from './styled';

import {
  Image
} from '../index'

export function TableBody({
  children,
  className,
  ...props
}) {
  return (
    <TableBodyStyle
      {...props}
      className={ClassNames(
        'tbody',
        className
      )}
    >
      {children}
    </TableBodyStyle>
  );
}

export function TableHeader({
  children,
  className,
  ...props
}) {
  return (
    <TableHeaderStyle
      {...props}
      className={ClassNames(
        'thead',
        className
      )}
    >
      {children}
    </TableHeaderStyle>
  );
}

export function TableFooter({
  children,
  ...props
}) {
  return (
    <TableFooterStyle {...props} className='tfoot'>
      {children}
    </TableFooterStyle>
  );
}

function renderSortClass(sortBy) {
  switch (sortBy) {
    case 'desc':
      return 'is-desc';
    case 'descending':
      return 'is-desc';
    case 'asc':
      return 'is-asc';
    case 'ascending':
      return 'is-asc';
    default:
      break;
  }
}

export function TH({
  className,
  children,
  id,
  isSortable,
  sortBy,
  onClick,
  ...props
}) {
  return (
    <TableColumnHeadStyle
      {...props}
      className={ClassNames(
        'table-column-head',
        { 'is-sortable': isSortable },
        renderSortClass(sortBy),
        className
      )}
      onClick={isSortable ? onClick : undefined}
      id={id}
    >
      {isSortable
        ? (
          <div>
            <span>
              {children}
            </span>
            <Image src='images/icons/icon-triangle-down-primary.svg' className='sort-icon' />
          </div>
        )
        : children

    }
    </TableColumnHeadStyle>
  );
}

export function TD({
  className,
  children,
  id,
  ...props
}) {
  return (
    <TableColumnStyle
      {...props}
      className={ClassNames('table-column', className)}
      id={id}
    >
      {children}
    </TableColumnStyle>
  );
}

export function TR({
  className,
  children,
  id,
  ...props
}) {
  return (
    <TableRowStyle
      {...props}
      className={ClassNames(
        'table-row', className
      )}
      id={id}
    >
      {children}
    </TableRowStyle>
  );
}

export default function Table({
  className,
  children,
  id,
  ...props
}) {
  return (
    <TableStyle
      {...props}
      className={ClassNames(
        'table', className
      )}
      id={id}
    >
      {children}
    </TableStyle>
  );
}

TH.defaultProps = {
  isSortable: false,
};
