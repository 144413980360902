import service from "./../service";

export const PageCorporateNEWS = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/page-corporate-news', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateNEWS = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/corporate-news', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateNEWSbyID = async (id) => {
  try {
    const payload = {
      params: {
        populate: "*",
        "filters[id]": id
      }
    }
    const response = await service().get('/corporate-news', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateOfDate = async () => {
  try {
    const payload = {
      params: {
        "fields[0]": "datetime"
      }
    }
    const response = await service().get('/corporate-news', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateOfYear = async (year) => {
  try {
    const payload = {
      params: {
        "filters[datetime][$gt]": `${year - 1}-12-31`,
        "filters[datetime][$lt]": `${year + 1}-01-01`,
        populate: "*"
      }
    }
    const response = await service().get('/corporate-news', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesCorporateCSR = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/tb-pages-corporate-csr', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateCSR = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/corporate-csr', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateCSROfYear = async (year) => {
  try {
    const payload = {
      params: {
        "filters[datetime][$gt]": `${year - 1}-12-31`,
        "filters[datetime][$lt]": `${year + 1}-01-01`,
        populate: "*"
      }
    }
    const response = await service().get('/corporate-csr', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateCSROfDate = async () => {
  try {
    const payload = {
      params: {
        "fields[0]": "datetime"
      }
    }
    const response = await service().get('/corporate-csr', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateCSRbyID = async (id) => {
  try {
    const payload = {
      params: {
        "filters[id]": id,
        populate: "*"
      }
    }
    const response = await service().get('/corporate-csr', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
