import classNames from "classnames";
import { FullProfileStyle } from "./styled";
import Button from "../Button";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useSelector } from "react-redux";

export default function FullProfile({
  className,
  id,
  firstName,
  lastName,
  profilePicture,
  jobTitle,
  resumeDetails,
  onClose,
}) {
  const translate = useSelector((state) => state.lang.translate);

  return (
    <FullProfileStyle className={classNames("full-profile-wrapper", className)}>
      <div className="block-full-profile">
        <div className="full-profile-left">
          <div className="profile-picture">
            <div className="picture">
              <img src={profilePicture} alt={`profile-img${id}`} />
              {/* {profilePicture && (
                  <div className='picture-border'>
                  </div>
                )} */}
            </div>
          </div>
          <div className="first-last-name">
            <p className="first-name">{firstName}</p>
            <p className="last-name">{lastName}</p>
          </div>
          <div className="job-title">
            <p className="title">{jobTitle}</p>
          </div>
        </div>

        <div className="full-profile-right">
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={resumeDetails} />
        </div>
      </div>
      <Button
        onClick={() => {
          onClose({ isOpen: false });
        }}
        className="medium-secondary btn-go-back"
      >
        {translate["common.back"]}
      </Button>
    </FullProfileStyle>
  );
}
