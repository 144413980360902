import service from "./../service";

export const PagesOtherDocuments = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-other-document', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const OtherDocumentFiles = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/other-documents-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
