import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Breadbrumb Wrapper Style
// ============================================================
export const BreadcrumbStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  flex-wrap: wrap;

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`

// Breadbrumb Item Style
// ============================================================
export const BreadcrumbItemStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  ${VARIABLES.TYPOGRAPHYS.BODY._6}
  color: white;

  /* Child element styles
  ------------------------------- */
  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: ${VARIABLES.COLOR.SECONDARY_HOVER};
    }
  }

  &::after {
    content: "/";
    position: relative;
    margin: 0 8px;
    color: white;
  }

  /* Modifiers
  ------------------------------- */
  &:last-child {
    a {
      pointer-events: none;
      color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      ${VARIABLES.TYPOGRAPHYS.TITLE._7}
    }

    &::after {
      display: none;
      margin: 0;
    }
  }
  
  &:not(:first-child) {
    a {
      pointer-events: none;
    }
    &:hover {
      color: unset;
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
