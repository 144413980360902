import service from "./../service";

export const PagesShareholderMeetingVdo = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-shareholder-meeting', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ShareholderMeetingVdo = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/shareholder-meeting-vdos', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ShareholderMeetingVdoById = async (id) => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get(`/shareholder-meeting-vdos/${id}`, payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};