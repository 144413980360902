import BusinessStructureComponent from "../components/Pages/BusinessStructure";
import Auth from "../containerWarping/Auth";
const BusinessStructure = () => {
  return (
    <Auth>
      <BusinessStructureComponent />
    </Auth>
  );
};

export default BusinessStructure;
