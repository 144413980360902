import classNames from "classnames";
import { Fragment } from "react";
import { CompanyItemStyle } from './styled'
import { getBaseUploadUrl } from "./../../../../Service/service";

// Animate on Scroll
// https://github.com/dbramwell/react-animate-on-scroll
import ScrollAnimation from 'react-animate-on-scroll';

export default function CompanyItemComponent({
  id,
  className,
  src,
  alt,
  companyName,
  companySymbol,
  onClick,
}) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <CompanyItemStyle
      className={classNames(
        'company-item',
        className
      )}
      id={id}
      onClick={onClick}
    >

      <div className="img-wrapper">
        <ScrollAnimation
          animateIn='fadeInUp'
          animateOnce={true}
        >
          <div className="img-inner-wrapper">
            {
              src === undefined || src === BASE_URL_UPLOAD + 'undefined' ?
                <Fragment>
                  <img
                    src='../../asset/media/images/contents/company-none.png'
                    className='company-img'
                    alt='not found'
                  />

                  <div className="company-name-with-img">
                    {companySymbol}
                  </div>
                </Fragment>
                :
                <img
                  className='company-img'
                  alt={alt}
                  src={src}
                />
            }
          </div>
        </ScrollAnimation>
      </div>

      <ScrollAnimation
        animateIn='fadeIn'
        animateOnce={true}
        delay={1000}
        className='company-name-warpper'
      >
        <div className='company-name'>
          <div className="company-name-bg">
          </div>

          <div className="text">
            {companyName}
          </div>
        </div>
      </ScrollAnimation>

    </CompanyItemStyle >
  )
}

CompanyItemComponent.defaultProps = {
  alt: 'company image',
  src: undefined,
  companyName: ''
}