
import DavidendPolicyComponent from "../components/Pages/DavidendPolicy";
import Auth from "../containerWarping/Auth";
const DavidendPolicy = () => {
  return (
    <Auth>
      <DavidendPolicyComponent />
    </Auth>
  );
};

export default DavidendPolicy;