import classNames from 'classnames'
import { MainLayoutStyle } from './styled'
import { useLocation } from 'react-router-dom';

export default function MainLayout({
  id,
  className,
  children,
}) {

  const location = useLocation()

  return (
    <MainLayoutStyle
      className={classNames(
        'main-layout',
        { 'is-homepage': location.pathname === '/' },
        className
      )}
      id={id}
    >
      {children}
    </MainLayoutStyle>
  )
};
