import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const StockPricePageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .canvasjs-chart-credit {
    display: none;
  }

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .stock-price {
    .stock-price-title {
      display: flex;
      justify-content: space-between;
      padding: 30px 60px;
      align-items: center;

      .title-left {
        font-size: 32px;
        font-weight: 500;
      }

      .title-right {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .stock-price-charts {
      padding: 30px 60px;
    }

    .stock-price-description {
      display: flex;
      padding: 30px 60px;

      .description-left {
        text-align: center;
        padding: 28px 30px;
        border-radius: 12px 0px 0px 12px;
        background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        width: 100%;
        max-width: 340px;

        .left-first {
          color: #FFFFFF;
          font-size: 32px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .left-second {
          color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          font-size: 28px;
          font-weight: 700;
          margin-bottom: 8px;
        }
        
        .left-third {
          color: ${VARIABLES.COLOR.NEUTRAL15};
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 15px;
        }

        .left-fourth {
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 8px;
        }
      }

      .description-right {
        align-self: center;
        padding: 28px 30px;
        width: 100%;
        border-radius: 0px 12px 12px 0px;
        background-color: ${VARIABLES.COLOR.NEUTRAL16};

        .description-right-top {
          position: relative;
          display: flex;
          padding-bottom: 30px;

          &:before {
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: ${VARIABLES.COLOR.NEUTRAL19};
          }

          .top-left {
            display: flex;
            width: 50%;

            .top-first,
            .top-second {
              position: relative;
              padding: 5px;
              text-align: center;
              width: 100%;

              p {
                &:first-child {
                  color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                  font-size: 18px;
                  font-weight: 700;
                  margin-bottom: 10px;
                }
                
                &:last-child {
                  font-size: 20px;
                  font-weight: 400;
                }
              }

              &:before {
                position: absolute;
                content: '';
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: ${VARIABLES.COLOR.NEUTRAL19};
              }
            }
          }

          .top-right {
            display: flex;
            width: 50%;
           
            .top-first,
            .top-second {
              position: relative;
              padding: 5px;
              text-align: center;
              width: 100%;

              p {
                &:first-child {
                  color:${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                  font-size: 18px;
                  font-weight: 700;
                  margin-bottom: 10px;
                }
                
                &:last-child {
                  font-size: 20px;
                  font-weight: 400;
                }
              }

            }
            
            .top-first {
              &:before {
                position: absolute;
                content: '';
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: ${VARIABLES.COLOR.NEUTRAL19};
              }
            }
          }
        }
        
        .description-right-bottom {
          display: flex;
          justify-content: space-between;
          padding-top: 30px;
          text-align: center;

          .right-bottom-first {
            position: relative;
            width: 50%;

            p {
              &:first-child {
                color:${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
              }
              
              &:last-child {
                font-size: 20px;
                font-weight: 400;
              }
            }

            &:before {
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              width: 1px;
              height: 100%;
              background-color: ${VARIABLES.COLOR.NEUTRAL19};
            }
          }

          .right-bottom-second {
            width: 50%;
            
            p {
              &:first-child {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
                color:${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              }
              
              &:last-child {
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 991.98px) {
    .stock-price {
      .stock-price-title {
        display: block;
        text-align: center;

        .title-left {
          margin-bottom: 15px;
        }
      }

      .stock-price-description {
        display: block;
        
        .description-left {
          max-width: unset;
          border-radius: 12px 12px 0 0;
        }

        .description-right {
          border-radius: 0 0 12px 12px;

          .description-right-top {
            display: block;

            .top-left {
              width: 100%;
              margin-bottom: 15px;

              .top-second {
                &::before {
                  display: none;
                }
              }
            }

            .top-right {
              width: 100%;
            }
          }

          .description-right-bottom {
            .right-bottom-first,
            .right-bottom-second {
              width: 100%;
              padding: 12px 0;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 600.98px) {
    .canvasjs-chart-canvas {
      width: 100%;
      aspect-ratio: unset;
    }
    
    .stock-price {
      .stock-price-charts {
        padding: 30px 20px;
        overflow-x: scroll;
        direction: rtl;
        margin: 0 30px;

        & > div {
          width:600px !important;
        }

      }
      
      .stock-price-description {
        .description-right {
          .description-right-top {
            &:before {
              display: none;
            }
            
            padding: 0;

            .top-left {
              display: block;
              margin: 0;
              
              .top-first {
                &:before {
                  display: none;
                }

                border-bottom: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
                padding: 15px 0;
              }
              
              .top-second {
                border-bottom: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
                padding: 15px 0;
              }
            }

            .top-right {
              display: block;

              .top-first {
                &:before {
                  display: none;
                }
                border-bottom: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
                padding: 15px 0;
              }

              .top-second {
                border-bottom: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
                padding: 15px 0;
              }
            }
          }

          .description-right-bottom {
            display: block;
            padding: 0;

            .right-bottom-first {
              &:before {
                display: none;
              }

              border-bottom: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
              padding: 15px 0;
            }

            .right-bottom-second {
              padding: 15px 0;
            }
          }
        }
      }
    }
  }
`
