import styled from 'styled-components';
import VARIABLES from '../../../themes/variables';

// Wrapper
// ============================================================
export const LoaderStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${VARIABLES.COLOR.OVERLAY_2};
  z-index: 9999999;

  /* Child element styles
  ------------------------------- */
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & > div {
      text-align: center;
    }
  }

  .percentage {
    color: ${VARIABLES.COLOR.GREEN_2};
  }

  .percentage-label {
    margin-right: 10px;
  }

  /* Modifiers
  ------------------------------- */
  &.theme-upload {
    background-color: ${VARIABLES.COLOR.OVERLAY_1};

    .percentage {
      color: white;
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`;
