import HomeComponent from "../components/Pages/Home";
import Auth from "../containerWarping/Auth";
const Home = () => {
  return (
    <Auth>
      <HomeComponent />
    </Auth>
  );
};

export default Home;
