import classNames from 'classnames';
import { CardDownloadFileStyle } from './styled';
import { SaveFile } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";

export default function CardDownloadFile({
  className,
  btnName,
  id,
  title,
  date,
  disable,
  isDisable,
  disabled,
  isDisabled,
  dateSetAa,
  titleSetAa,
  url,
  fileName
}) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <CardDownloadFileStyle
      className={classNames(
        'card-file-download-wrapper',
        {
          'is-disabled': disable || disabled || isDisable || isDisabled,
        },
        className
      )}
    >  
      <div key={id} className='block-file-download'>
        <div className='block-left'>
          <div className='name'>
            {title}
            { dateSetAa && (
              <span className='data-set-announcements'>
                {dateSetAa}
              </span>
            )}
          </div>
          <div className='date'>
            {date}
            { titleSetAa && (
              <span className='title-set-announcements'>
                {titleSetAa}
              </span>
            )}
          </div>
        </div>
        <div className='block-right'>
          { btnName && (
            <div
              className='file-download'
              onClick={()=>{
                SaveFile(BASE_URL_UPLOAD + url, fileName)
              }}
            >
              <img className='icon icon-download' src='../asset/media/images/icons/icon-download-green.svg' alt='icon-download-green'/>
              {btnName}
            </div>
          )}
        </div>
      </div>
    </CardDownloadFileStyle>
  );
}

