import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'

// Wrapper
// ============================================================
export const CardOneReportPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  padding: 24px 30px;
  max-width: 685px;
  width: 100%;

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .block-card-one-report {
    padding: 24px 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    @media (max-width: 960.98px) {
      padding: 12px 12px;
    }

    @media (max-width: 800.98px) {
      padding: 24px 30px;
    }

    .card-one-report-content {
      .icon {
        width: 42px;
        height: 34px;
        @media (max-width: 960.98px) {
          width: 32px;
          height: 24px;
        }
        @media (max-width: 800.98px) {
          width: 42px;
          height: 34px;
        }
      }

      .name,
      .date {
        text-align: start;
        margin: 15px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        ${VARIABLES.TYPOGRAPHYS.BODY._10}
      }

      .download-button-container {
        cursor: pointer;
        max-width: 187px;
        max-height: 33px;
        border: 1px solid  transparent;

        .dowmload-button {
          width: 100%;
          height: 100%;
        }

        &:hover {
          border-radius: 6px;
          border: 1px solid ${VARIABLES.COLOR.BLUE};
          background-color: ${VARIABLES.COLOR.PRIMARY_LIGHT};
        }
      }
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 600.98px) {
    padding: 24px 15px;
  }
`
