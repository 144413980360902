import React, { useEffect, useState } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { BoardDirectorsStyle } from "./styled";
import { PageHeader, ProfileList, FullProfile } from "../../Global";
import { directorPersonnels, pagesDirector } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import { useDispatch, useSelector } from "react-redux";

const BoardDirectorsComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const [dataPage, setDataPage] = useState(null);
  const [selectDirector, setSelectDirector] = useState({
    isOpen: false,
    id: null,
    firstName: null,
    lastName: null,
    profilePicture: null,
    jobTitle: null,
    resumeDetails: null,
  });

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const DirectorPersonnels = async () => {
      const res = await directorPersonnels();
      setData(res.data);
    };

    const PagesDirector = async () => {
      const res = await pagesDirector();
      setDataPage(res.data);
    };

    DirectorPersonnels();
    PagesDirector();
  }, []);

  return (
    <BoardDirectorsStyle>
      { !selectDirector.isOpen && (
        <>
          <PageHeader
            pageHeaderImage={
              dataPage?.attributes?.menu_image?.data?.attributes?.url &&
              BASE_URL_UPLOAD + dataPage?.attributes?.menu_image?.data?.attributes?.url
            }
            breadcrumb={[
              { url: '/', text: translate["menu.home"] },
              { url: "/", text: translate["menu.corporate_governances"] },
              {
                url: ROUTE_PATH.BOARD_DIRECTORS.LINK,
                text: dataPage?.attributes?.menu_text,
              },
            ]}
            title={dataPage?.attributes?.menu_text}
          />

          <div className="page-content-wrapper">
            <div className="page-content fluid">
              <div className="board-directors">
                <div className="block-board-directors">
                  {data?.map((d) => {
                    return (
                      <ProfileList
                        key={d?.id}
                        firstName={d?.attributes?.first_name}
                        lastName={d?.attributes?.last_name}
                        profilePicture={
                          BASE_URL_UPLOAD +
                          d?.attributes?.photo?.data?.attributes?.url
                        }
                        id={d?.id}
                        jobTitle={d?.attributes?.position}
                        resumeDetails={d?.attributes?.resume_details}
                        onSelect={(e) => {
                          setSelectDirector(e);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      { selectDirector.isOpen && (
        <div className="page-content-wrapper">
          <div className="page-content fluid">
            <div className="board-directors">
              <FullProfile
                id={selectDirector.id}
                firstName={selectDirector.firstName}
                lastName={selectDirector.lastName}
                profilePicture={selectDirector.profilePicture}
                jobTitle={selectDirector.jobTitle}
                resumeDetails={selectDirector.resumeDetails}
                onClose={(e) => {
                  setSelectDirector(e);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </BoardDirectorsStyle>
  );
};

export default BoardDirectorsComponent;
