import BORDERRADIUSES from './borderradiuses'
import BORDERWIDTHS from './borderwidths'
import BREAKPOINTS from './breakpoints'
import COLOR from './color'
import ELEMENTSSIZE from './elementssize'
import TRANSITIONS from './transitions'
import TYPOGRAPHYS from './typographys'
import ZINDEXS from './zindexs'

const VARIABLES = {
  BORDERRADIUSES,
  BORDERWIDTHS,
  BREAKPOINTS,
  COLOR,
  ELEMENTSSIZE,
  TRANSITIONS,
  TYPOGRAPHYS,
  ZINDEXS
}

export default VARIABLES
