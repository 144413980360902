import React from "react";
import { TitleCoporateDetailStyle } from "./styled";
import { copyLink } from "../../../../../helpers";
import { FacebookShareButton, TwitterShareButton, LineShareButton } from "react-share";

function TitleCoporateDetail({
  title,
  date,
  id,
  shareLink,
  shareHashtag
}) {
  // const openInNewTab = (url) => {
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  //   if (newWindow) newWindow.opener = null
  // }
  return (
    <TitleCoporateDetailStyle>
      <div className="block-activity-title">
        <div className="title">
          {title}
        </div>
        <div className="link">
          <div className="left">{date}</div>
          <div className="right">
            <FacebookShareButton
              url={shareLink}
              title={title}
              quote={title}
              hashtag={shareHashtag}
            >
              <img
                src="../asset/media/images/contents/icon-facebook.png"
                alt="facebook share"
              />
            </FacebookShareButton>

            <TwitterShareButton
              url={shareLink}
              title={title}
              hashtag={[shareHashtag]}
              via={title}
            >
              <img
                src="../asset/media/images/contents/icon-tweeter.png"
                alt=""
              />
            </TwitterShareButton>

            <LineShareButton
              url={shareLink}
              title={title}
            >
              <img
                src="../asset/media/images/contents/icon-line.png"
                alt=""
              />
            </LineShareButton>

            <span className="icon-share-link" onClick={() => copyLink(shareLink)}>
              <img
                src="../asset/media/images/contents/icon-link.png"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
    </TitleCoporateDetailStyle>
  );
}

export default TitleCoporateDetail;
