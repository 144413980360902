import styled from 'styled-components'
import VARIABLES from '../../../../themes/variables'

// Wrapper
// ============================================================
export const CompanyItemStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  flex: 0 0 28.33%;
  max-width: 28.33%;
  width: 28.33%;
  margin: 0 2.5% 40px;
  position: relative;
  cursor: pointer;

  /* Child element styles
  ------------------------------- */
  .img-wrapper {
    width: 89%;

    .img-inner-wrapper {
      width: 100%;
      padding-top: 118%;
      position: relative;
      border-radius: 6px 100px 6px 6px;
      overflow: hidden;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

      .company-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .company-name-with-img {
        position: absolute;
        top: 7%;
        ${VARIABLES.TYPOGRAPHYS.BODY._3}
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        left: 10%;
        max-width: 75%;
        width: 100%;
        z-index: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .company-name-warpper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .company-name {
    border-radius: 0px 12px 12px 0px;
    position: relative;
    overflow: hidden;
    position: absolute;
    width: 100%;
    min-height: 61px;
    left: 0;
    top: 70%;
    padding: 4px 6px;
    color: white;
    ${VARIABLES.TYPOGRAPHYS.BODY._7}

    .company-name-bg {
      opacity: 0.9;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    }

    .text {
      position: relative;
      z-index: 1;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
    .img-wrapper {
      .img-inner-wrapper {
        border-radius: 6px 80px 6px 6px;

        .company-name-with-img {
          top: 5%;
          left: 5%;
          max-width: 65%;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
    .img-wrapper {
      .img-inner-wrapper {
        border-radius: 6px 60px 6px 6px;
      }
    }

    .company-name {
      top: 65%;
    }
  }

  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
    flex: 0 0 45%;
    max-width: 45%;
    width: 45%;
    margin: 0 2.5% 25px;
    position: relative;
    margin: 0 2.5% 25px;

    .img-wrapper {
      .img-inner-wrapper {
        border-radius: 6px 80px 6px 6px;
      }
    }

    .company-name {
      top: 70%;
    }
  }
`
