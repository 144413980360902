import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const TableStyle = styled.table`
  /* Parent styles
  ------------------------------- */
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;

  /* Child element styles
  ------------------------------- */
  .table-column,
  .table-column-head {
  }

  /* Modifiers
  ------------------------------- */
  &.split-row-color {
    &.split-color-primary {
      .table-row {
        .table-column {
          background-color: ${VARIABLES.COLOR.NEUTRAL7};
          color: black;
        }

        &:nth-child(2n - 1) {
          .table-column {
            background-color: white;
            color: black;
          }
        }
      }
    }
    .table-row {
      .table-column {
        background-color: ${VARIABLES.COLOR.SECONDARY};
        color: ${VARIABLES.COLOR.WHITE};
      }

      &:nth-child(2n - 1) {
        .table-column {
          background-color: white;
          color: ${VARIABLES.COLOR.PRIMARY};
        }
      }
    }

    &.contrast {
      .table-row {
        .table-column {
          background-color: white;
          color: ${VARIABLES.COLOR.PRIMARY};
        }

        &:nth-child(2n - 1) {
          .table-column {
            background-color: ${VARIABLES.COLOR.SECONDARY};
            color: ${VARIABLES.COLOR.WHITE};
          }
        }
      }
    }
  }

  &.yellow-primary {
    .table-row {
      .table-column,
      .table-header {
        background-color: ${VARIABLES.COLOR.YELLOW_1};
        color: ${VARIABLES.COLOR.BLACK};
      }
    }
  }

  &.yellow-secondary {
    .table-row {
      .table-column,
      .table-header {
        background-color: ${VARIABLES.COLOR.YELLOW_2};
        color: ${VARIABLES.COLOR.BLACK};
      }
    }
  }

  &.header-freeze {
    /* .table-row {
      &::after {
        content: '';
        display: block;
        width: 10px;
        background-color: ;
      }
    } */

    & ~ .scrolling-content {
      overflow-y: scroll;
      width: calc(100% + 10px);
      margin-right: -10px;
      height: 100%;

      /* &.split-row-color {
        .table-row {
          &:nth-child(2n) {
            .table-column {
              background-color: ;

              &.actions {
                .link {
                  &:hover {
                    background-color: ;
                  }

                  &.no-hover {
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2n - 1) {
            .table-column {
              background-color: white;

              &.actions {
                .link {
                  &:hover {
                    background-color: ${VARIABLES.COLOR.GRAY_1};
                  }

                  &.no-hover {
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      } */
    }
  }

  &.fixed-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: scroll;
    flex: auto 1;
    width: auto;

    .table-row {
      display: inline-flex;
      //flex: 1 0 auto;
    }

    & > .thead {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      position: sticky;
      top: 0;

      &.header,
      .header-group {
        z-index: 3;
      }
    }

    .tbody {
      display: flex;
      flex: 99999 1 auto;
      flex-direction: column;
    }

    .table-column,
    .table-column-head {
      flex: 1 0 0;
      overflow: initial;
      max-height: none;
      display: flex;
      align-items: center;

      &.fixed {
        position: sticky;
        z-index: 1;

        &.left {
          left: 0;
        }

        &.right {
          right: 0;
        }
      }

      &.is-sortable {
        & > div {
          height: 100%;
        }
      }

      &[colspan] {
        display: block;
        height: auto;
      }
    }

    .accordion {
      &.table-accordion {
        .accordion-head {
          .toggle-icon {
            display: none;
          }
        }
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`;

export const TableRowStyle = styled.tr`
  /* Parent styles
  ------------------------------- */
  width: 100%;

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`;

export const TableColumnStyle = styled.td`
  /* Parent styles
  ------------------------------- */
  width: auto;
  /* min-height: 60px;
  max-height: 165px; */
  background-color: white;
  padding: 0 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 60px;
  color: ${VARIABLES.COLOR.PRIMARY};

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.text-left {
    text-align: left;
  }

  &.text-right {
    text-align: right;
  }

  &.text-center {
    text-align: center;
  }
  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
  }

  @media (max-width: 1280.98px) {
  }

  @media (max-width: 1024.98px) {
  }
`;

export const TableColumnHeadStyle = styled.th`
  /* Parent styles
  ------------------------------- */
  width: auto;
  font-weight: bold;
  color: ${VARIABLES.COLOR.PRIMARY};
  height: 60px;
  background-color: ${VARIABLES.COLOR.WHITE};
  padding: 0 14px;
  line-height: 1.2;
  font-size: 17px;
  text-transform: uppercase;

  /* Child element styles
  ------------------------------- */
  .sort-icon {
    width: 11px;
    flex: 11px 0 0;
    margin-left: 6px;
    transition: ${VARIABLES.TRANSITIONS.FAST} all;
    transform: translateY(0) rotate(180deg);
    display: inline-block;
  }

  /* Modifiers
  ------------------------------- */
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.space-accordion-icon {
    width: 5%;
  }

  /* State
  ------------------------------- */
  &.is-sortable {
    cursor: pointer;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.is-desc {
      .sort-icon {
        transform: translateY(-3px);
      }
    }

    &.is-asc {
      .sort-icon {
        transform: translateY(0) rotate(180deg);
      }
    }
  }
  &.shareholder-stucture {
    &.order {
      width: 94px;
      text-align: center;
    }
    &.name {
      width: 400px;
      text-align: left;
    }
    &.quantity {
      width: 250px;
      text-align: center;
    }
    &.percent {
      width: 114px;
      text-align: center;
    }
  }

  &.historical-price {
    &.date {
      width: 308px;
      text-align: left;
      font-family: 'Roboto';   
      ${VARIABLES.TYPOGRAPHYS.TITLE._7}
    }
    &.price {
      width: 200px;
      text-align: center;
      font-family: 'Roboto';   
      ${VARIABLES.TYPOGRAPHYS.TITLE._7}
    }

    @media (max-width: 1366.98px) {
      &.date {
        width: 148px;
      }
      &.price {
        width: 128px;
      }
    }
  }

  &.financial-highlight {
    &.name {
      width: 362px;
      text-align: left;
      @media (max-width: 425.98px) {
        width: 200px;
      }
    }
    &.date {
      width: 276px;
      text-align: center;

      @media (max-width: 768.98px) {
        width: 150px;
      }
    }
  }
  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    height: 50px;
    font-size: 15px;
    padding: 0 12px;

    .sort-icon {
      width: 10px;
      flex: 10px 0 0;
    }
  }

  @media (max-width: 1280.98px) {
    height: 45px;
    font-size: 14px;
    padding: 0 10px;

    .sort-icon {
      width: 8px;
      flex: 8px 0 0;
    }
  }

  @media (max-width: 1024.98px) {
    height: 35px;
    font-size: 12px;
    padding: 0 5px;

    .sort-icon {
      width: 7px;
      flex: 7px 0 0;
    }
  }
  @media (max-width: 425.98px) {
    /* &.shareholder-stucture {
      &.order {
        font-size: 20px;
        height: 50px;
      }
      &.name {
        font-size: 20px;
      }
      &.quantity {
        font-size: 20px;
      }
      &.percent {
        font-size: 20px;
      }
    } */
  }
`;

export const TableBodyStyle = styled.tbody``;

export const TableHeaderStyle = styled.thead`
  &.header-primary {
    color: white;
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
  }
`;

export const TableFooterStyle = styled.tfoot``;
