import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const ButtonStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: auto;
  padding: 0 25px;
  border: 1px solid #EEEEEE;
  color: black;
  cursor: pointer;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  &.small-primary {
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 30px;
  }

  &.medium-primary {
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 40px;
  }

  &.small-primary-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 30px;
  }

  &.medium-primary-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 40px;
  }

  &.small-primary-rounded {
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 30px;
  }

  &.medium-primary-rounded {
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 40px;
  }

  &.small-primary-rounded-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 30px;
  }

  &.medium-primary-rounded-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 40px;
  }

  &.small-secondary {
    background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 30px;
  }

  &.medium-secondary {
    background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 40px;
  }

  &.small-secondary-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 30px;
  }

  &.medium-secondary-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 40px;
  }

  &.small-secondary-rounded {
    background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 30px;
  }

  &.medium-secondary-rounded {
    background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 40px;
  }

  &.small-secondary-rounded-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 30px;
  }

  &.medium-secondary-rounded-outline {
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    min-height: 40px;
  }

  &.small-primary-rounded-outline-transparent {
    background-color: unset;
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 30px;
    gap: 10px;
  }
  
  &.small-primary-outline-transparent {
    background-color: unset;
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    border-radius: 6px;
    border: 1px solid  ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    min-height: 40px;
    min-width: 139px;
    gap: 10px;
  }
  
  &.small-neutral8-rounded-outline {
    color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 100px;
    border: 1px solid  ${VARIABLES.COLOR.NEUTRAL8};
    min-height: 30px;
  }
  
  &.medium-danger-outline {
    color: #DC3545;
    background-color: ${VARIABLES.COLOR.NEUTRAL8};
    border-radius: 6px;
    border: 1px solid #DC3545;
    min-height: 40px;
  }
  &.is-disabled {
    background-color: ${VARIABLES.COLOR.NEUTRAL10};
    color: ${VARIABLES.COLOR.NEUTRAL11};
    opacity: 0.8;
  }

  /* State
  ------------------------------- */

  &.is-disabled {
    &:hover {
      background-color: ${VARIABLES.COLOR.NEUTRAL10};
      color: ${VARIABLES.COLOR.NEUTRAL11};
    }
  }

  &:hover {
    background-color: #AAAAAA;
    color: white;
  }

  &.small-primary,
  &.medium-primary,
  &.small-primary-rounded,
  &.medium-primary-rounded {
    &:hover {
      background-color: #FFFFFF;
      color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    }

  }

  &.small-primary-outline,
  &.medium-primary-outline,
  &.small-primary-rounded-outline,
  &.medium-primary-rounded-outline {
    &:hover {
      background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      color: #FFFFFF;
    }

  }

  &.small-primary-rounded-outline-transparent,
  &.small-primary-outline-transparent {
    &:hover {
      background-color: #FFFFFF;
      color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    }
  }

  &.small-secondary,
  &.medium-secondary,
  &.small-secondary-rounded,
  &.medium-secondary-rounded {
    &:hover {
      background-color: #FFFFFF;
      color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    }
  }

  &.small-secondary-outline,
  &.medium-secondary-outline,
  &.small-secondary-rounded-outline,
  &.medium-secondary-rounded-outline {
    &:hover {
      background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      color: #FFFFFF;
    }
  }

  &.medium-danger-outline {
    &:hover {
      background-color: #DC3545;
      color: #FFFFFF;
    }
  }
  /* Media queries
  ------------------------------- */
`
