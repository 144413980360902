import service from "./../service";

export const PagesShareholderStructure = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get(
      "/pages-shareholder-structure",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const GetShareholder = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/shareholder", payload, true);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
