import InvestorRelationsCalendarComponent from "../components/Pages/InvestorRelationsCalendar";
import Auth from "../containerWarping/Auth";
const InvestorRelationsCalendar = () => {
  return (
    <Auth>
      <InvestorRelationsCalendarComponent />
    </Auth>
  );
};

export default InvestorRelationsCalendar;
