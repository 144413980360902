import React, { useState, useRef, useEffect } from "react";
import { NewAndActivityCorporateDetailStyle } from "./styled";
import { Button } from "../../../Global";
// Slick slide
// https://www.npmjs.com/package/react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "../../../../constants/routes";
import ImgHeadDetail from "../Components/ImgHeadDetail";
import TitleCoporateDetail from "../Components/TitleCoporateDetail";
import BodyCoporateDetail from "../Components/BodyCoporateDetail";
import {
  CorporateNEWSbyID,
  CorporateCSRbyID,
} from "../../../../Service/policy/coporateNEWSandCSR/coporate";
import { getBaseUploadUrl } from "../../../../Service/service";
import { scrollToTop } from "../../../../helpers";
import { langTh } from "../../../../store/lang";

const NewAndActivityCorporateDetailComponent = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const originPath = window.location.origin;
  const shareLink = `${originPath}${ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK}`;

  const [content, setContent] = useState(null);
  const [photo, setPhoto] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const page = params.get("page");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const CardContent = async () => {
      try {
        const ResponseContent = await CorporateNEWSbyID(id);
        if (ResponseContent?.data?.length <= 0) {
          onClickBack();
        }
        setContent(ResponseContent?.data);
        setPhoto(ResponseContent?.data[0]?.attributes?.photo?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const CSRContent = async () => {
      try {
        const ResponseContent = await CorporateCSRbyID(id);
        setContent(ResponseContent?.data);
        setPhoto(ResponseContent?.data[0]?.attributes?.photo?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (page === "csr") {
      CSRContent();
    } else {
      CardContent();
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = (date.getMonth() + 1) > 12 ? 12 : date.getMonth() + 1;
    const addYear = lang === langTh ? 543 : 0;
    const year = date.getFullYear() + addYear;
    return `${day}/${month}/${year}`;
  };

  const goToTopPage = () => {
    window.scrollTo(0, 0);
  };

  const history = useHistory();
  const [current, setCurrent] = useState(0);

  const PrevArrow = ({ onClick }) => (
    <button className="button prev-arrow-c" onClick={onClick}>
      <img
        className="icon-button"
        src="../asset/media/images/icons/icon-chevron-up-black-slick.svg"
        alt=""
      />
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="button next-arrow-c" onClick={onClick}>
      <img
        className="icon-button"
        src="../asset/media/images/icons/icon-chevron-down-black-slick.svg"
        alt=""
      />
    </button>
  );

  const checkSlideToShow = () => {
    if (photo?.length === 1) {
      return 1
    } else if (photo?.length === 2) {
      if (windowWidth >= 470) {
        return 2
      } else {
        return 1
      }
    } else {
      if (windowWidth >= 625) {
        return 3
      } else if (windowWidth < 625 && windowWidth >= 470) {
        return 2
      } else {
        return 1
      }
    }
  }

  const newActiVitySettingsColumn = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: checkSlideToShow(),
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    draggable: false,
    swipe: false,
    arrows: true,
    focusOnSelect: true,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: function (currentSlide) {
      setCurrent(currentSlide);
    },
  };

  const newActiVitySettingsRow = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: checkSlideToShow(),
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: function (currentSlide) {
      setCurrent(currentSlide);
    },
    // responsive: [
    //   {
    //     breakpoint: 625,
    //     settings: { slidesToShow: 2 },
    //   },
    //   {
    //     breakpoint: 470,
    //     settings: { slidesToShow: 1 },
    //   },
    // ],
  };

  const Element = ({ image, index }) => {
    return (
      <div className="content-mid" onClick={() => setCurrent(index)}>
        <div>
          <img className="img" src={image} alt={"pic" + index} />
        </div>
      </div>
    );
  };

  const onClickBack = () => {
    let link = "";
    if (page === "csr") {
      link = ROUTE_PATH.NEWS_ACTIVITY_CSR.LINK;
    } else {
      link = ROUTE_PATH.NEWS_ACTIVITY_COPORATE_NEW.LINK;
    }

    history.push(link);
    scrollToTop();
    // history.push(ROUTE_PATH.NEWS_ACTIVITY_COPORATE_NEW.LINK + "?id=" + id + "&page=" + page);
  };

  return (
    <NewAndActivityCorporateDetailStyle>
      {content && (
        <div className="new-activity-detail">
          <div className="activity-img">
            <ImgHeadDetail
              image={
                BASE_URL_UPLOAD +
                content[0]?.attributes?.preview_image?.data?.attributes?.url
              }
            />
          </div>
          <div className="activity-title">
            <TitleCoporateDetail
              title={content[0]?.attributes?.title}
              date={formatDate(content[0]?.attributes?.datetime)}
              shareLink={`${shareLink}?id=${id}&page=${page}`}
              shareHashtag={""}
            />
          </div>
          <div className="activity-body">
            <BodyCoporateDetail body={content[0]?.attributes?.details} />
          </div>

          <span className="bg-gray-coporate-detail">
            {photo && photo.length > 0 && (
              <div className="slide-img">
                <div className="content">
                  <div className="content-left">
                    <div className="text-1">
                      {translate["news_and_activities.text_1"]}
                    </div>
                    <div className="text-2">
                      {
                        translate[
                        page === "csr"
                          ? "news_and_activities.text_csr"
                          : "news_and_activities.text_2"
                        ]
                      }
                    </div>
                    <div className="text-2">
                      {page === "csr"
                        ? "CSR"
                        : translate["news_and_activities.text_3"]}
                    </div>
                  </div>
                  <div className="content-mid">
                    <div className="slider-wrapper">
                      <div className="slider-d">
                        <Slider {...newActiVitySettingsColumn}>
                          {photo?.map((e, i) => (
                            <Element
                              key={i}
                              index={i}
                              image={BASE_URL_UPLOAD + e?.attributes?.url}
                            />
                          ))}
                        </Slider>
                      </div>
                      <div className="slider-m">
                        <Slider {...newActiVitySettingsRow}>
                          {photo?.map((e, i) => (
                            <Element
                              key={i}
                              index={i}
                              image={BASE_URL_UPLOAD + e?.attributes?.url}
                            />
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="content-right">
                    <img
                      className="img"
                      src={BASE_URL_UPLOAD + photo[current]?.attributes?.url}
                      alt={"pic" + current}
                    />

                    {/* <img src='../asset/media/images/contents/img-activity-slide-1.png' alt='' /> */}
                  </div>
                </div>
              </div>
            )}
          </span>

          <div className="activity-button">
            <Button
              className="medium-secondary-outline"
              onClick={() => {
                onClickBack();
              }}
            >
              {translate["common.back"]}
            </Button>
          </div>
        </div>
      )}

      <div
        className="up"
        onClick={() => {
          goToTopPage();
        }}
      >
        <img
          src="../asset/media/images/icons/icon-chevron-up-white.png"
          alt=""
        />
      </div>
    </NewAndActivityCorporateDetailStyle>
  );
};
export default NewAndActivityCorporateDetailComponent;
