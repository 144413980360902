import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'
// Wrapper
// ============================================================
export const BodyCoporateDetailStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .details {
    height: ${props => props.height}
  }

  .more{
    cursor: pointer;
  }

  .body {
    overflow: hidden;
  }
  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1024.98px) {
   
  }

  @media (max-width: 768.98px) {
   
  }

  @media (max-width: 425.98px) {
    
  }

  @media (max-width: 375.98px) {
    
  }

  @media (max-width: 320.98px) {
   
  }
`
