import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'


// Wrapper
// ============================================================
export const TabsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: fit-content;
  /* Child element styles
  ------------------------------- */
  .tabs-wrapper {
    padding: 24px;
    display: flex;
    height: 44px;
    align-items: center;
    .separate {
      margin: 30px;
      height: 44px;
      width: 1px;
      border-left: 1px solid black;
    }
    .introduction,.opp-day {
      cursor: pointer;
      ${VARIABLES.TYPOGRAPHYS.BODY._1}
      &.isActive {
        text-decoration: underline;
        text-underline-offset: 8px;
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1024.98px) {
 
  }

  @media (max-width: 768.98px) {
 
  }

  @media (max-width: 425.98px) {
 
  }

  @media (max-width: 375.98px) {

  }

  @media (max-width: 320.98px) {

  }
`
