import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import { ROUTE_PATH } from "../../../constants/routes";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { HomePageStyle } from "./styled";
import { Button } from "../../Global";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  scrollToTop,
  displayDateBuddhistEra,
  displayDate,
  displayTime,
  removeDuplicates,
  sortArray,
  displayNumber,
  displayTimeEn,
} from "../../../helpers";
import AnnCard from "./components/AnnCard";
import NewsCard from "./components/NewsCard";
import CardOneReport from "./components/CardOneReport";
import { langTh } from "../../../store/lang";

import {
  Home,
  WeAreBioTec,
  VisionAndMission,
  SaveFile,
} from "./../../../Service/API";
import { getBaseUploadUrl } from "./../../../Service/service";

import { GetChartQuotation } from "./../../../Service/stockPrices";
import { ourBusinessService } from "../../../Service/ourBusinessService";
import {
  CorporateNEWS,
  CorporateCSR,
} from "../../../Service/coporateNEWSandCSR/coporate";
import { OneReportFiles } from "./../../../Service/publications";
import {
  PagesIrPressRelease,
  GetNewsSet,
} from "./../../../Service/newsActivities";

const HomeComponent = ({ onPress }) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const lang = useSelector((state) => state.lang.value);
  const dispatch = useDispatch();
  const count = 3;

  const currentDate = new Date();
  const numberOfMlSeconds = currentDate.getTime();

  const hour = 60 * 60 * 1000;
  const day = 24 * hour;
  const week = 7 * day;
  const week52 = 52 * week;

  const dateOfLastYear = new Date(numberOfMlSeconds - week52);

  const [activeSlide, setActiveSlide] = useState(0);
  const [currentAnnData, setCurrentAnnData] = useState(1);
  const slidRef = useRef(null);
  const sliderRef = useRef(null);
  const sliderCsrRef = useRef(null);
  const sliderAnnRef = useRef(null);

  const [homeData, setHomeData] = useState({});
  const [stockPriceSummary, setStockPriceSummary] = useState({});
  const [stockPrice52WeekData, setStockPrice52WeekData] = useState({});
  const [aboutUs, setAboutUs] = useState([]);
  const [newsPage, setNewsPage] = useState({});
  const [newsData, setNewsData] = useState([]);
  const [ourBusiness, setOurBusiness] = useState([]);
  const [corporateNews, setCorporateNews] = useState([]);
  const [corporateCsr, setCorporateCsr] = useState([]);
  const [oneReports, setOneReports] = useState([{}]);
  const [indexBG, setIndexBG] = useState("0");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const HomeData = async () => {
      try {
        //! Home data
        const ResHome = await Home();
        setHomeData(ResHome?.data);

        //! Stock price
        getStockPriceData();

        //! About us
        getAboutUsData();

        //! Set Biotec
        getSetBiotecData();

        //! Our business
        getOurBusinessData();

        //! One report | 56-1
        getReportData();

        //! News
        getNewsData();

        //! CSR
        getCsrData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    HomeData();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStockPriceData = async () => {
    const payloadStockPrice = {
      period: "5Y",
    };
    const resStockPrice = await GetChartQuotation(payloadStockPrice);
    if (resStockPrice?.result?.quotations) {
      handleSetSummaryData(resStockPrice?.result);
      handleSetDataOf52Week(resStockPrice?.result?.quotations);
    }
  };

  const getAboutUsData = async () => {
    const ResWeAreBiotec = await WeAreBioTec();
    const ResVisionAndMission = await VisionAndMission();

    const content =
      ResWeAreBiotec?.data?.attributes?.content?.slice(0, 330) + "...";
    const visionAndMission =
      ResVisionAndMission?.data?.attributes?.content_vision ||
      ResVisionAndMission?.data?.attributes?.content_mission;
    const visionContent = visionAndMission?.slice(0, 330) + "...";

    let aboutUsData = [];
    aboutUsData.push({
      ...ResWeAreBiotec?.data,
      content,
      link: ROUTE_PATH.WE_ARE_BIOTEC.LINK,
    });
    aboutUsData.push({
      ...ResVisionAndMission?.data,
      content: visionContent,
      link: ROUTE_PATH.VISION_AND_MISION.LINK,
    });

    setAboutUs(aboutUsData);
  };

  const getSetBiotecData = async () => {
    const resNewsPage = await PagesIrPressRelease();
    setNewsPage(resNewsPage?.data);

    const payloadNewsSet = { limit: 100 };
    const resNews = await GetNewsSet(payloadNewsSet);
    resNews?.result?.newsInfoList &&
      handleMapNews(resNews?.result?.newsInfoList);
  };

  const getOurBusinessData = async () => {
    const payloadOurBusiness = {
      "sort[id]": "desc",
    };
    const resOurBusiness = await ourBusinessService().getOurBusiness(
      payloadOurBusiness
    );
    if (resOurBusiness) {
      resOurBusiness?.data?.map((item) => {
        const id = item.id;
        item.link = generatePath(ROUTE_PATH.OUR_BUSINESS.LINK, { id });
        return item;
      });
      setOurBusiness(resOurBusiness?.data);
    }
  };

  const getReportData = async () => {
    const payloadOneReport = {
      "sort[date]": "desc",
      "pagination[page]": 1,
      "pagination[pageSize]": 3,
    };
    const ResOneReports = await OneReportFiles(payloadOneReport);
    setOneReports(ResOneReports?.data);
  };

  const getNewsData = async () => {
    const payloadNews = {
      "sort[datetime]": "desc",
      "pagination[page]": 1,
      "pagination[pageSize]": 3,
    };
    const ResCorporateNews = await CorporateNEWS(payloadNews);
    setCorporateNews(ResCorporateNews?.data);
  };

  const getCsrData = async () => {
    const payloadCsr = {
      "sort[datetime]": "desc",
      "pagination[page]": 1,
      "pagination[pageSize]": 4,
    };
    const ResCorporateCsr = await CorporateCSR(payloadCsr);
    setCorporateCsr(ResCorporateCsr?.data);
  };

  const handleSetSummaryData = (params) => {
    const data = [...params?.quotations];
    let dateList = [];

    data?.map((item) => {
      const date = new Date(item.timestamp * 1000);
      const date_display =
        lang === langTh
          ? `${displayDateBuddhistEra(new Date(item.timestamp * 1000))} ${displayTime(new Date(item.timestamp * 1000), "HH.mm")} น.`
          : `${displayDate(new Date(item.timestamp * 1000), "D MMM YYYY")} ${displayTimeEn(new Date(item.timestamp * 1000))}`

      item.date = date;
      item.date_display = date_display;
      item.change_volume =
        ((item.last_min + item.last_max) / 2) * (item.change_precent / 100);

      dateList.push(displayDate(date));
      return item;
    });

    // Group by date
    const getLastDate = removeDuplicates([...dateList])[0];
    const lastDateList = data?.filter(
      (r) => displayDate(r.date) === getLastDate
    );

    const dateLength = data?.filter(
      (r) => displayDate(r.date) === getLastDate
    )?.length;

    // Set data
    const last_min = sortArray(lastDateList, "last_min")[0]?.last_min;
    const last_max = sortArray(lastDateList, "last_max", "desc")[0]?.last_max;
    const last_open = sortArray(lastDateList, "last_open", "desc")[0]
      ?.last_open;
    const last_close = sortArray(lastDateList, "last_close", "desc")[0]
      ?.last_close;

    const change_volume =
      lastDateList?.reduce((sum, obj) => sum + Number(obj.change_volume), 0) /
      dateLength;
    const change_precent =
      lastDateList?.reduce((sum, obj) => sum + Number(obj.change_precent), 0) /
      dateLength;
    const volume = lastDateList?.reduce(
      (sum, obj) => sum + Number(obj.volume),
      0
    );

    const dateTimeDisplay =
      lang === langTh
        ? `${displayDateBuddhistEra(new Date(params?.modified))} ${displayTime(new Date(params?.modified), "HH.mm")} น.`
        : `${displayDate(new Date(params?.modified), "D MMM YYYY")} ${displayTimeEn(new Date(params?.modified))}`

    setStockPriceSummary({
      date_display: dateTimeDisplay,
      last_min,
      last_max,
      last_open,
      last_close,
      change_volume,
      change_precent,
      volume,
    });
  };

  const handleSetDataOf52Week = (data) => {
    // Set data of day
    let dayList = [];
    data?.forEach((item) => {
      dayList.push(displayDate(new Date(item.timestamp * 1000)));
    });

    // Set data of 52 week
    let dateOf52WeekList = [];
    dayList = removeDuplicates(dayList);
    dayList?.forEach((item, index) => {
      if (new Date(item) >= new Date(dateOfLastYear)) {
        dateOf52WeekList.push({
          date: item,
          last_min: data[index].last_min,
          last_max: data[index].last_max,
        });
      }
    });

    const sortMin = sortArray([...dateOf52WeekList], "last_min");
    const sortMax = sortArray([...dateOf52WeekList], "last_max", "desc");

    const minPrice52week = sortMin[0]?.last_min;
    const maxPrice52week = sortMax[0]?.last_max;
    const avgPrice52week = (minPrice52week + maxPrice52week) / 2;

    setStockPrice52WeekData({
      minPrice52week,
      maxPrice52week,
      avgPrice52week,
    });
  };

  const handleMapNews = (params) => {
    const data = sortArray(params, "timestamp", "desc");
    let limit = 5;
    let newsList = [];

    data?.map((item) => {
      if (limit > 0 && item?.tag === "") {
        newsList.push(item);
        limit--;
      }
      return item;
    });

    setNewsData(newsList);
  };

  const onChangeSlide = (index) => {
    setIndexBG(index);
  };

  const HomeHeroBannerSlideSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: false,
    className: "slider-home-banner",
    appendDots: (dots) => (
      <div className="slick-dots">
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "30px",
          color: "white",
          // border: "1px blue solid"
        }}
      >
        {i + 1}
      </div>
    ),
    afterChange: (index) => onChangeSlide(index),
  };

  // const handleNewsSlideChange = (index) => {
  //   setActiveSlide(index);
  // };

  const handleSlideChange = (index) => {
    // Check which slide is in the middle
    const middleSlideIndex = Math.floor(count / 2);
    if (index === middleSlideIndex) {
    }
  };

  const previousSlide = () => {
    slidRef.current.slickPrev();
  };

  const nextSlide = () => {
    slidRef.current.slickNext();
  };

  const prevAnnSlide = () => {
    sliderAnnRef.current.slickPrev();
    setTimeout(() => {
      if (currentAnnData > 1) {
        setCurrentAnnData(currentAnnData - 1);
      }
    }, 500);
  };

  const nextAnnSlide = () => {
    sliderAnnRef.current.slickNext();
    setTimeout(() => {
      if (currentAnnData < AnnData.length) {
        setCurrentAnnData(currentAnnData + 1);
      }
    }, 500);
  };

  const arrowRightStyles = {
    color: "red",
    width: "30px",
    height: "30px",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    transform: "translateY(-100%) translateX(300%)",
  };

  const arrowLeftStyles = {
    color: "red",
    width: "30px",
    height: "30px",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    transform: "translateY(100%) translateX(-300%)",
  };

  const ArrowLeft = ({ onClick }) => (
    <div
      id="btn-prev-corporate-csr"
      className="prev"
      onClick={onClick}
    // style={{ ...arrowLeftStyles, left: '410px', top: '350px', zIndex:'2'}}
    >
      <img
        src="../asset/media/images/icons/arrow-prev-short.svg"
        alt="arrow-prev-short"
      />
    </div>
  );

  const ArrowRight = ({ onClick }) => (
    <div
      id="btn-next-corporate-csr"
      className="next"
      onClick={onClick}
    // style={{ ...arrowRightStyles, right: '440px', top: '10px' }}
    >
      <img
        src="../asset/media/images/icons/arrow-next-short.svg"
        alt="arrow-next-short"
      />
    </div>
  );

  const checkSlidesToShowsOneReports = () => {
    if (oneReports?.length === 1) {
      return { num: 1, styleName: "one" };
    } else if (oneReports?.length === 2) {
      return { num: 2, styleName: "two" };
    } else if (oneReports?.length >= 3 && windowWidth > 800) {
      return { num: 3, styleName: "" };
    } else if (oneReports?.length >= 3 && windowWidth <= 800) {
      return { num: 1, styleName: "one" };
    } else {
      return { num: 3, styleName: "" };
    }
  };

  const DownloadDocSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: checkSlidesToShowsOneReports().num,
    className: "center",
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const checkSlidesToShowNews = () => {
    if (corporateCsr?.length === 1) {
      return { num: 1, styleName: "one" };
    } else if (corporateCsr?.length === 2) {
      return { num: 2, styleName: "two" };
    } else {
      return { num: 3, styleName: "" };
    }
  };

  const NewsSettings = {
    dots: false,
    arrows: true,
    centerMode: true,
    draggable: false,
    speed: 500,
    slidesToShow: checkSlidesToShowNews().num,
    slidesToScroll: 1,
    centerPadding: "10px",
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 640,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const slidesToShowCondition = () => {
    if (windowWidth > 1300) {
      return 3;
    } else if (windowWidth <= 1300 && windowWidth > 767) {
      return 2;
    } else if (windowWidth <= 767) {
      return 1;
    }
  };

  const AnnSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    swipe: false,
    slidesToShow: slidesToShowCondition(),
    slidesToScroll: 1,
    initialSlide: 0,
    // responsive: [
    //   { breakpoint: 1300,
    //     settings: { slidesToShow: 2 }
    //   },
    //   { breakpoint: 800,
    //     settings: { slidesToShow: 1 }
    //   },
    // ],
    // afterChange: (index) => onChangeSlide(index)
  };

  const AnnData = [
    {
      date: "29 ธันวาคม 2532",
      icon: "../asset/media/images/icons/icon-ann-people.svg",
      name: "แปรสภาพเป็นบริษัทมหาชน บริษัท จุฑานาวี จำกัด (มหาชน) และเข้าเป็นบริษัทจดทะเบียนในตลาดหลักทรัพย์แห่งประเทศไทย (SET) ด้วยทุนจดทะเบียนเริ่มต้น จำนวน 40 ล้านบาท",
    },
    {
      date: "6 กุมภาพันธ์ 2557",
      icon: "../asset/media/images/icons/icon-ann-graph.svg",
      name: "เพิ่มทุนจดทะเบียนของบริษัท เป็นจำนวน 350.70 ล้านบาท ",
    },
    {
      date: "2 กุมภาพันธ์ 2564",
      icon: "../asset/media/images/icons/icon-ann-money.svg",
      name: "ทุนจดทะเบียนของบริษัท ณ วันที่ 2 กุมภาพันธ์ 2564 จำนวน 1,274,281,233.00 บาท",
    },
    {
      date: "2 กุมภาพันธ์ 2564",
      icon: "../asset/media/images/icons/icon-ann-money.svg",
      name: "ทุนจดทะเบียนของบริษัท ณ วันที่ 2 กุมภาพันธ์ 2564 จำนวน 1,274,281,233.00 บาท",
    },
    {
      date: "6 กุมภาพันธ์ 2557",
      icon: "../asset/media/images/icons/icon-ann-graph.svg",
      name: "เพิ่มทุนจดทะเบียนของบริษัท เป็นจำนวน 350.70 ล้านบาท ",
    },
  ];

  const HomeOurBusinessSlideSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slider-home-our-business",
  };

  return (
    <HomePageStyle
      bgImgNews={
        homeData?.attributes?.SET_news_bg_image?.data?.attributes?.url &&
        BASE_URL_UPLOAD +
        homeData?.attributes?.SET_news_bg_image?.data?.attributes?.url
      }
      bgImgReportFiles={
        homeData?.attributes?.public_download_bg_image?.data?.attributes?.url &&
        BASE_URL_UPLOAD +
        homeData?.attributes?.public_download_bg_image?.data?.attributes?.url
      }
    >
      <div className="home-page">
        <div className={`block-section home-banner bg-0${indexBG}`}>
          {indexBG === 1 ? (
            <img
              className="bg-banner-img-leaf"
              src="../asset/media/images/contents/leaf-bg.png"
              alt="..."
            />
          ) : (
            <img
              className="bg-banner-img"
              // src="../asset/media/images/contents/bio-wording.png"
              src={`../asset/media/images/contents/${windowWidth < 1100 ? "text-bi" : "text-bio"
                }.png`}
              alt="..."
            />
          )}
          <div className="block-home-banner-content content-wrapper">
            <Slider {...HomeHeroBannerSlideSettings}>
              {aboutUs &&
                aboutUs?.map((item, i) => {
                  return (
                    <div className="home-banner-content-title" key={i + 1}>
                      <div className="title">
                        <span>{item?.attributes?.menu_text}</span>
                      </div>
                      <div className="description">
                        {
                          <ReactMarkdown
                            children={item?.content}
                            rehypePlugins={[rehypeRaw]}
                          />
                        }
                      </div>
                      <div className="button-read-more white">
                        <Link to={item?.link} onClick={scrollToTop}>
                          <div className="read-more-text">
                            {translate["common.read_more"]}
                          </div>
                          <img
                            className="icon"
                            src="../asset/media/images/icons/icon-arrow-right-short.svg"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>

          <div className="block-set-biotec">
            <div className="block-bg">
              <div className="set-biotec-item">
                <div className="title">SET : BIOTEC</div>
                <div className="update-information">
                  {stockPriceSummary?.date_display}
                </div>
              </div>
              <div className="set-biotec-item">
                <div className="title">
                  {translate["stock_price.latest_price"]}
                </div>
                <div className="update-information">
                  {stockPriceSummary?.last_close?.toFixed(2)} THB
                </div>
              </div>
              <div className="set-biotec-item">
                <div className="title">
                  {translate["home_investor.change"]} (%)
                </div>
                <div className="update-information">
                  {stockPriceSummary?.change_precent >= 0 ? "+" : ""}
                  {stockPriceSummary?.change_volume === 0
                    ? "0.00 "
                    : `${stockPriceSummary?.change_volume?.toFixed(2)} `}
                  ({stockPriceSummary?.change_precent?.toFixed(2)}%)
                </div>
              </div>
              <div className="set-biotec-item">
                <div className="title">
                  {translate["home_investor.trading_volume_shares"]}
                </div>
                <div className="update-information">
                  {displayNumber(Number(stockPriceSummary?.volume)?.toFixed(2))}
                </div>
              </div>
              <div className="set-biotec-item">
                <div className="title">
                  {translate["home_investor.intraday_price_range"]}
                </div>
                <div className="update-information">
                  {stockPriceSummary?.last_min?.toFixed(2)} -{" "}
                  {stockPriceSummary?.last_max?.toFixed(2)}
                </div>
              </div>
              <div className="set-biotec-item">
                <div className="title">
                  {translate["home_investor.52_week_price_range"]}
                </div>
                <div className="update-information">
                  {stockPrice52WeekData?.minPrice52week?.toFixed(2)} -{" "}
                  {stockPrice52WeekData?.maxPrice52week?.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block-section home-our-business">
          <div className="our-business content-wrapper">
            <div className="our-business-title">
              {homeData?.attributes?.our_business_text}
            </div>
            <div className="blok-card-our-business">
              {ourBusiness &&
                ourBusiness?.map((item, i) => {
                  return (
                    <ScrollAnimation
                      animateIn={`slideIn${i % 2 === 0 ? 'Left' : 'Right'}`}
                      animateOnce={true}
                      className="frame-ann"
                    >
                      <div className="card-our-business"
                        key={item?.id || i + 1}>
                        <div className={`card-body ${i % 2 === 0 ? 'left' : 'right'}`}>
                          <div className="card-content">
                            <p className="content-title">
                              <img
                                className="icon"
                                src="../asset/media/images/contents/line-our-business-palm.png"
                                alt=""
                              ></img>
                              <div className="title">
                                {item?.attributes?.name}
                              </div>
                            </p>
                            <p className="content-text">
                              {item?.attributes?.subtitles}
                            </p>
                            <div className="button-read-more blue">
                              <Link to={item?.link} onClick={scrollToTop}>
                                <div className="read-more-text">
                                  {translate["common.read_more"]}
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className={`card-img-right ${i % 2 === 0 ? 'left' : 'right'}`}>
                            {item?.attributes?.image?.data?.attributes
                              ?.url && (
                                <>
                                  <img
                                    className="img-card"
                                    src={
                                      BASE_URL_UPLOAD +
                                      item?.attributes?.image?.data?.attributes
                                        ?.url
                                    }
                                    alt={
                                      item?.attributes?.image?.data?.attributes
                                        ?.alternativeText
                                    }
                                  />
                                  <img
                                    className={`bg-img-card ${i % 2 === 0 ? 'left' : 'right'}`}
                                    src={`../asset/media/images/contents/bg-home-our-business-${i % 2 === 0 ? 'left' : 'right'}.png`}
                                    alt="bg-card"
                                  />
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="block-section annoucements-bg" id="annoucements">
          <div className="content-wrapper">
            <div className="stock-market-news">
              <ScrollAnimation
                animateIn="slideInLeft"
                animateOnce={true}
                className="frame-ann"
              >
                <div className="ann-header">
                  <div className="title">
                    {lang === langTh ? (
                      <ReactMarkdown
                        children={homeData?.attributes?.SET_news_text?.replace(
                          " ",
                          "<br/>"
                        )}
                        rehypePlugins={[rehypeRaw]}
                      ></ReactMarkdown>
                    ) : (
                      <ReactMarkdown
                        children={homeData?.attributes?.SET_news_text}
                        rehypePlugins={[rehypeRaw]}
                      ></ReactMarkdown>
                    )}
                    <span className="rectangle"></span>
                  </div>
                  <div className="ann-des">
                    <div className="des-text">
                      <ReactMarkdown
                        children={newsPage?.attributes?.content_description}
                        rehypePlugins={[rehypeRaw]}
                      ></ReactMarkdown>
                    </div>
                  </div>
                  <div className="count-number home-com first">
                    <span className="count-current">
                      {currentAnnData.toString().padStart(2, "0")}
                    </span>
                    <span className="count-total">
                      /{AnnData.length.toString().padStart(2, "0")}
                    </span>
                  </div>
                  <div className="button-ann-group home-com first">
                    <Button className="button-ann-prev" onClick={prevAnnSlide}>
                      <img
                        className="icon-button"
                        width={30}
                        src="../asset/media/images/icons/icon-arrow-left-light-blue.svg"
                        alt=""
                      />
                    </Button>
                    <Button className="button-ann-next" onClick={nextAnnSlide}>
                      <img
                        className="icon-button"
                        width={16}
                        src="../asset/media/images/icons/ann-arrow-right.png"
                        alt=""
                      />
                    </Button>
                  </div>
                </div>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="slideInUp"
                animateOnce={true}
                className="slide-ann-frame"
              >
                <Slider
                  {...AnnSettings}
                  ref={sliderAnnRef}
                  afterChange={handleSlideChange}
                >
                  {newsData &&
                    newsData?.map((row, index) => {
                      const symbol = "BIOTEC";
                      const link = `https://www.set.or.th/th/market/news-and-alert/newsdetails?id=${row?.id}&symbol=${symbol}`;

                      return (
                        <Link
                          key={row?.id}
                          to={{ pathname: link }}
                          target="_blank"
                        >
                          <div className="slider-item" key={index}>
                            <AnnCard
                              date={
                                lang === langTh
                                  ? displayDateBuddhistEra(
                                    new Date(row?.timestamp * 1000),
                                    true
                                  )
                                  : displayDate(
                                    new Date(row?.timestamp * 1000),
                                    "D MMMM YYYY"
                                  )
                              }
                              name={row?.headline}
                              icon={
                                row?.icon ||
                                AnnData[index]?.icon ||
                                AnnData[0]?.icon
                              }
                              current={currentAnnData - 1 === index}
                            ></AnnCard>
                          </div>
                        </Link>
                      );
                    })}
                </Slider>
              </ScrollAnimation>

              <div className="count-number home-com second">
                <span className="count-current">
                  {currentAnnData.toString().padStart(2, "0")}
                </span>
                <span className="count-total">
                  /{AnnData.length.toString().padStart(2, "0")}
                </span>
              </div>
              <div className="button-ann-group home-com second">
                <Button className="button-ann-prev" onClick={prevAnnSlide}>
                  <img
                    className="icon-button"
                    width={16}
                    src="../asset/media/images/icons/ann-arrow-right-darkblue.png"
                    alt=""
                  />
                </Button>
                <Button className="button-ann-next" onClick={nextAnnSlide}>
                  <img
                    className="icon-button"
                    width={16}
                    src="../asset/media/images/icons/ann-arrow-right.png"
                    alt=""
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="block-section background-sec-doc-lastest-news">
          <div className="content-wrapper">
            <div className="download-doc-container">
              <div className="header-download-doc">
                <div className="download-title">
                  {homeData?.attributes?.public_download_text}

                  <Link
                    className="read-more-wrapper"
                    to={ROUTE_PATH.ONE_REPORT.LINK}
                    onClick={scrollToTop}
                  >
                    <Button className="btn link read-more">
                      {translate["common.more"]}
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="slide-download-doc-container">
                <div>
                  <div>
                    <div className="slide-bg-download-doc"></div>
                  </div>
                  <div
                    className={`slide-position ${checkSlidesToShowsOneReports().styleName
                      }`}
                  >
                    <Slider
                      {...DownloadDocSettings}
                      ref={sliderRef}
                      afterChange={handleSlideChange}
                    >
                      {oneReports &&
                        oneReports?.map((row, index) => {
                          return (
                            <div key={index}>
                              <CardOneReport
                                name={row.attributes?.file_name}
                                date={
                                  lang === langTh
                                    ? displayDateBuddhistEra(
                                      new Date(row.attributes?.date)
                                    )
                                    : displayDate(
                                      row.attributes?.date,
                                      "DD/MM/YYYY"
                                    )
                                }
                                isCenter={index === Math.floor(count / 2)}
                                onClick={() => {
                                  SaveFile(
                                    BASE_URL_UPLOAD +
                                    row.attributes?.file?.data?.attributes
                                      ?.url,
                                    row.attributes?.file?.data?.attributes?.name
                                  );
                                }}
                                isLangTh={lang === langTh}
                              />
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></div>
                </div>
              </div>
            </div>

            {corporateNews && corporateNews?.length > 0 && (
              <div className="container-lastest-sec">
                <div className="content-description-lastest-news">
                  <ScrollAnimation
                    animateIn="slideInLeft"
                    animateOnce={true}
                    className="description-lastest-sec-container"
                  >
                    <div className="description-lastest-sec">
                      <div className="header-topic">
                        {homeData?.attributes?.corporate_news_text}
                      </div>

                      <div className="lastest-sec-content">
                        <ReactMarkdown
                          children={
                            homeData?.attributes?.corporate_news_description
                          }
                          rehypePlugins={[rehypeRaw]}
                        ></ReactMarkdown>
                      </div>

                      <div className="more-button-latest-sec">
                        <Link
                          to={ROUTE_PATH.NEWS_ACTIVITY_COPORATE_NEW.LINK}
                          onClick={scrollToTop}
                        >
                          <Button className="btn link read-more">
                            {translate["common.more"]}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="pic-and-description">
                  {corporateNews?.map((item, index) => {
                    const file = item?.attributes?.preview_image?.data;
                    const link = `/news-activity/corporate-detail?id=${item.id}&page=news`;
                    return (
                      <Link to={link} key={index}>
                        <ScrollAnimation
                          animateIn="slideInDown"
                          animateOnce={true}
                          className="pic-lastest-sec-container"
                        >
                          <div className="content-img">
                            <img
                              src={BASE_URL_UPLOAD + file?.attributes?.url}
                              alt={file?.attributes?.alternativeText}
                            />
                          </div>
                          <div className="content-inside">
                            <div className="content-date">
                              <p>
                                {lang === langTh
                                  ? displayDateBuddhistEra(
                                    item?.attributes?.datetime
                                  )
                                  : displayDate(
                                    item?.attributes?.datetime,
                                    "D MMM YYYY"
                                  )}
                              </p>
                            </div>
                            <div className="content-descriptopn">
                              <p>{item?.attributes?.title}</p>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        {corporateCsr && corporateCsr?.length > 0 && (
          <div className="block-section csr-activity content-wrapper">
            <div className="csr-container">
              <div className="csr-news">
                <p>{homeData?.attributes?.CSR_news_text}</p>
              </div>
              <Link
                to={ROUTE_PATH.NEWS_ACTIVITY_CSR.LINK}
                onClick={scrollToTop}
              >
                <Button className="btn link read-more">
                  {translate["common.more"]}
                </Button>
              </Link>
            </div>
            <div
              className={`csr-slide-container ${checkSlidesToShowNews().styleName
                }`}
            >
              <Slider
                {...NewsSettings}
                ref={sliderCsrRef}
                afterChange={handleSlideChange}
              >
                {corporateCsr?.map((row, index) => {
                  const link = `/news-activity/corporate-detail?id=${row.id}&page=csr`;
                  return (
                    <Link to={link} key={index}>
                      <div className="block-news-card" key={index}>
                        <NewsCard
                          img={
                            BASE_URL_UPLOAD +
                            row?.attributes?.preview_image?.data?.attributes
                              ?.url
                          }
                          time={displayTime(row?.attributes?.datetime)}
                          date={
                            lang === langTh
                              ? displayDateBuddhistEra(
                                new Date(row?.attributes?.datetime)
                              )
                              : displayDate(
                                row?.attributes?.datetime,
                                "MMM DD YYYY"
                              )
                          }
                          description={row?.attributes?.title}
                          isActive={index === activeSlide}
                        />
                      </div>
                    </Link>
                  );
                })}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </HomePageStyle>
  );
};

export default HomeComponent;
