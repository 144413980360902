import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const ShareholderStructureStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .canvasjs-chart-credit {
    display: none;
  }

  .shareholderstructure {
    .grapshareholder {
      display: flex;
      width: auto;
      height: 567px;
      padding: 60px 119px;
      justify-content: center;
      align-items: center;
      gap: 60px;

      .graph {
        display: contents;
        /* width: 70%; */

        div {
          width: 50% !important;
          max-width: 500px !important;

          .canvasjs-chart-tooltip {
            width: auto !important;
            border-radius: 5px !important;
            div {
              width: auto !important;
              border-radius: 5px !important;
            }
          }
        }
        .canvasjs-chart-canvas {
          /* position: relative !important; */
          /* width: 757px; */
          /* width: 50% !important; */
        }
        a {
          /* display: none; */
        }
      }

      .legend {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 26px;
        width: 300px;

        .legend-text {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;

          .circle {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            &.darkblue {
              background-color: #003f5c;
            }
            &.blue {
              background-color: #444e86;
            }
            &.purple {
              background-color: #955196;
            }
            &.pink {
              background-color: #dd5182;
            }
            &.salmon {
              background-color: #ff6e54;
            }
            &.orange {
              background-color: #ffa600;
            }
          }
        }
      }
      .overviewshareholder {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 36px;

        .overview {
          display: flex;
          width: 372px;
          flex-direction: column;
          align-items: center;
          gap: 29px;

          .text-head {
            ${VARIABLES.TYPOGRAPHYS.BODY._1};
          }

          .text-body {
            display: flex;
            width: 371px;
            justify-content: space-between;
            align-items: center;
            ${VARIABLES.TYPOGRAPHYS.BODY._6};

            .text-right {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 21px;
            }

            .text-left {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 21px;
            }
          }
        }
      }
    }
    .top-list {
      display: flex;
      width: 100%;
      padding: 60px 65px;
      align-items: flex-start;
      justify-content: center;

      .list-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: 100%;

        .text-head-table {
          ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        }

        /* .table {
          width: 100%;
        } */
        .table {
          margin: 0 auto;
          max-height: 400px;
          max-width: 100%;
          overflow: auto;
          .header-primary {
            position: sticky;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  /* Media queries
  ------------------------------- */
  @media (max-width: 1299.98px) {
    .shareholderstructure {
      .grapshareholder {
        padding: 60px 60px;
        .legend {
          gap: 16px;
        }
      }
    }
  }


  @media (max-width: 1100.98px) {
    .shareholderstructure {
      .grapshareholder {
        display: flex;
        flex-wrap: wrap;
        height: auto !important;
        padding: 39px 0px !important;

        /* .graph {
        } */

        .legend {
          width: auto;
        }
        .overviewshareholder {
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: unset;

          .overview {
            padding: 0 10px;
          }
        }
      }

      .top-list {
        padding: 0px 16px 30px;
        .table {
          overflow-y: auto;
        }
      }
    }
  }

  @media (max-width: 768.98px) {
    .shareholderstructure {
      .top-list {
        .list-table {
          .table {
            overflow-y: auto;
          }
        }
      }
    }
  }

  @media (max-width: 430.98px) {
    .shareholderstructure {
      .grapshareholder {
        .graph {
          div {
            max-height: 200px !important; 
          }
        }
        
        .overviewshareholder {
          .overview {
            .text-head {
              font-size: 24px;
            }
            .text-body {
              font-size: 16px;
            }
          }
        }
      }

      .top-list {
        .list-table {
          .text-head-table {
            font-size: 20px;
          }
          .table {
            overflow-y: auto;
          }
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .shareholderstructure {
      .grapshareholder {
        .overviewshareholder {
          .overview {
            .text-body {
              width: 325px;
            }
          }
        }
      }
      .top-list {
        .list-table {
          .table {
            overflow-y: auto;
          }
        }
      }
    }
  }

  @media (max-width: 320.98px) {
    .shareholderstructure {
      .grapshareholder {
        .overviewshareholder {
          .overview {
            .text-body {
              width: 270px;
            }
          }
        }
      }
      .top-list {
        .list-table {
          .table {
            overflow-y: auto;
          }
        }
      }
    }
  }
`;
