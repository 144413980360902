import EmailContactComponent from "../components/Pages/EmailContact";
import Auth from "../containerWarping/Auth";
const EmailContact = () => {
  return (
    <Auth>
      <EmailContactComponent />
    </Auth>
  );
};

export default EmailContact;
