import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const DavidendPolicyStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .page-content-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    min-height: 645px;
  }

  .davidend-policy-text-head {
    display: flex;
    padding: 60px 127px;
    align-items: flex-start;
    gap: 10px;

    .head {
      /* text-align: center; */
      ${VARIABLES.TYPOGRAPHYS.BODY._4}

      .change-font {
        font-family: 'Kanit', sans-serif;
        font-weight: 500;
      }
    }
  }
  .davidend-policy {
    padding: 60px;

    .davidend-policy-title {
      margin-bottom: 43px;
      display: flex;
      justify-content: space-between;

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
      }

      .select-wrapper {
        min-width: 155px;
        margin-left: 15px;
      }
    }
  }
  
 
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 768.98px) {
    .davidend-policy-text-head {
    padding: 60px 40px;
  }
    .davidend-policy {
      padding: 30px;
    }
  }

  @media (max-width: 450.98px) {
    .davidend-policy {
      .davidend-policy-title {
        display: block;
        .title {
          margin-bottom: 16px;
        }

        .select-wrapper {
          text-align: center;
        }
      }
    }
  }
`
