import service from "./../service";

export const PagesCompanyPresentation = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-company-presentation', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const OppDayVdo = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/opp-day-vdos', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
export const OppDayVdoById = async (id) => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get(`/opp-day-vdos/${id}`, payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CompanyPresentVDO = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/company-present-vdos', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CompanyPresentVDObyID = async (id) => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get(`/company-present-vdos/${id}`, payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CompanyPresentVDObyIDbyYear = async (year) => {
  try {
    const payload = {
      params: {
        "filters[date][$gte]": `${year}-01-01`,
        "filters[date][$lte]": `${year}-12-31`,
        "sort[date]": "desc",
        "sort[id]": "desc",
        populate: "*",
      }
    }
    const response = await service().get(`/company-present-vdos`, payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CompanyPresentVDObyIDbyDate = async () => {
  try {
    const payload = {
      params: {
        "fields[0]": "date",
        "sort[date]": "desc",
        "sort[id]": "desc"
      }
    }
    const response = await service().get(`/company-present-vdos`, payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
