import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../constants/routes";
import { ShareholderMeetingDocumentsStyle } from "./styled";
import { PageHeader, CardDownloadFile } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  PagesShareholderMeetingDocument,
  ShareholderMeetingDocuments,
} from "./../../../Service/shareholders";
import {
  displayDate,
  displayYear,
  displayYearBuddhistEra,
  removeDuplicates,
} from "./../../../helpers";
import { langTh } from "../../../store/lang";

const ShareholderMeetingDocumentsComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [meta, setMeta] = useState({});
  const [content, setContent] = useState([]);
  const [date, setDate] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [paginations, setPaginations] = useState([]);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const MeetingDocument = async () => {
      const resPage = await PagesShareholderMeetingDocument();
      setData(resPage?.data);

      const payloadDate = {
        "fields[0]": "date",
        "sort[date]": "desc",
      };
      const resDate = await ShareholderMeetingDocuments(payloadDate);
      setDate(resDate?.data);

      setIsMounted(true);
    };

    MeetingDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const MeetingDocument = async () => {
      const payload = {
        "filters[date][$gte]": `${selectedYear}-01-01`,
        "filters[date][$lte]": `${selectedYear}-12-31`,
        "sort[date]": "desc",
        "sort[id]": "desc",
        "pagination[page]": selectedPage,
        "pagination[pageSize]": 10,
      };
      const resContent = await ShareholderMeetingDocuments(payload);
      setContent(resContent?.data);
      setMeta(resContent?.meta);
    };

    (selectedPage || selectedYear) && MeetingDocument();
  }, [selectedYear, selectedPage]);

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({
        value: e,
        label: lang === langTh ? displayYearBuddhistEra(e) : e
      })
    );

    setOptionYears(yearsData);
    // setSelectedYear(years[0]);
    yearsData[0] && onChangeYear(yearsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (meta) {
      const maxPage = meta?.pagination?.pageCount || 1;
      let pages = [];
      for(let i = 1; i <= maxPage; i++) {
        pages.push({
          value: i,
          label: i
        });
      }
      setPaginations(pages);
    }
  }, [meta]);

  const onChangeYear = (e) => {
    setSelectedPage(1);
    setSelectedYear(e?.value);
  };

  const onChangePagination = (e) => {
    setSelectedPage(e?.value);
  }

  return (
    <ShareholderMeetingDocumentsStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.information_for_shareholders"] },
          {
            url: ROUTE_PATH.SHAREHOLDER_MEETING_DOCUMENT.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="shareholder-meeting-documents content-wrapper">
            <div className="shareholder-meeting-documents-title">
              <div className="title">
                {data?.attributes?.menu_text}{" "}
                {lang === langTh ? displayYearBuddhistEra(selectedYear) : selectedYear}
              </div>
              <div className="select-wrapper">
                <span>{translate["common.year"]}</span>
                {isMounted && (
                  <SelectBox
                    placeholder={optionYears[0]?.label}
                    defaultValue={optionYears[0]}
                    options={optionYears}
                    onChange={(e) => onChangeYear(e)}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>
            {content &&
              content?.map((d, i) => {
                const file = d?.attributes?.file?.data;
                return (
                  <CardDownloadFile
                    key={i}
                    id={d.id}
                    title={d?.attributes?.file_name}
                    date={`${displayDate(d?.attributes?.date, "DD/MM")}/${lang === langTh ? displayYearBuddhistEra(new Date(d?.attributes?.date)) : displayYear(d?.attributes?.date)}`}
                    url={file?.attributes?.url}
                    fileName={file?.attributes?.name}
                    btnName={translate["common.download_document"]}
                  />
                );
              })}

            <div className="shareholder-meeting-documents-footer">
              <div className="title"></div>
              <div className="select-wrapper-page">
                <span> {translate["common.page"]} </span>
                {isMounted && (
                  <SelectBox
                    defaultValue={paginations[0]}
                    value={paginations?.find(r => r.value === selectedPage)}
                    options={paginations}
                    onChange={(e) => onChangePagination(e)}
                    isSearchable={false}
                  />
                )}
                <span> {translate["common.page_of"]} </span>
                {meta?.pagination?.pageCount}
              </div>
            </div>

          </div>
        </div>
      </div>
    </ShareholderMeetingDocumentsStyle>
  );
};

export default ShareholderMeetingDocumentsComponent;
