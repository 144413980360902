import { CompanyProfilePageStyle } from "./styled";
import { PageHeader } from "../../Global";
import { PagesCompanyProfile, CompanyHistory } from "../../../Service/about";
import { getBaseUploadUrl } from "../../../Service/service";
import ClampLines from "react-clamp-lines";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollAnimation from "react-animate-on-scroll";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  displayDateBuddhistEra,
  displayYearBuddhistEra,
  displayDate,
  displayYear,
} from "././../../../helpers";
import { langTh } from "../../../store/lang";

const CompanyProfileComponent = () => {
  const dispatch = useDispatch();
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const lang = useSelector(state => state.lang.value);
  const translate = useSelector(state => state.lang.translate);

  const [data, setData] = useState({});
  const [content, setContent] = useState([]);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const CompanyProfile = async () => {
      const ResPage = await PagesCompanyProfile();
      setData(ResPage?.data);

      const Response = await CompanyHistory();
      setContent(Response?.data);
    };

    CompanyProfile();
  }, []);

  const checkDuplicateYear = (index) => {
    const sortedData = content?.slice().sort((a, b) => {
      const dateA = new Date(a?.attributes?.date);
      const dateB = new Date(b?.attributes?.date);
      return dateA - dateB;
    });
    if (index > 0) {
      if (
        new Date(sortedData[index]?.attributes?.date).getFullYear() ===
        new Date(sortedData[index - 1]?.attributes?.date).getFullYear()
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  var arr = [];
  var toggle = 0;
  for (let index = 0; index < content?.length; index++) {
    const sortedData = content?.slice().sort((a, b) => {
      const dateA = new Date(a?.attributes?.date);
      const dateB = new Date(b?.attributes?.date);
      return dateA - dateB;
    });
    if (index > 0) {
      if (
        new Date(sortedData[index]?.attributes?.date).getFullYear() ===
        new Date(sortedData[index - 1]?.attributes?.date).getFullYear()
      ) {
        arr?.push(toggle);
      } else {
        toggle = toggle + 1;
        arr?.push(toggle);
      }
    } else {
      arr.push(toggle);
    }
  }

  const renderEvents = () => {
    return content
      ?.slice()
      .sort((a, b) => {
        const dateA = new Date(a?.attributes?.date);
        const dateB = new Date(b?.attributes?.date);
        return dateA - dateB;
      })
      .map((event, index) => (
        <ScrollAnimation
          key={event?.id}
          animateIn="slideInUp"
          animateOnce={true}
        >
          <div
            className={`history-timeline-block ${
              arr[index] % 2 === 0 ? "even" : "odd"
            }`}
          >
            <div className="icon-and-year">
              <div className="icon-and-year-inner-wrapper">
                {
                  event?.attributes?.history_picture?.data?.attributes?.url && (
                    <img
                      className="icon"
                      alt="icon"
                      src={
                        BASE_URL_UPLOAD +
                        event?.attributes?.history_picture?.data?.attributes?.url
                      }
                    />
                  )
                }

                {checkDuplicateYear(index) && (
                  <div className="year">
                    {
                      lang === langTh ? (
                        `พ.ศ. ${displayYearBuddhistEra(event?.attributes?.date)}`
                      ) : (
                        `${displayYear(event?.attributes?.date)}`
                      )
                    }
                  </div>
                )}
              </div>
            </div>

            <div className="text-content">
              <div className="text-content-inner-wrapper">
                {
                  event?.attributes?.date_display && (
                    <div className="date-time">
                      {
                        lang === langTh ? (
                          displayDateBuddhistEra(new Date(event?.attributes?.date), true)
                        ) : (
                          displayDate(event?.attributes?.date, "D MMMM YYYY")
                        )
                      }
                    </div>
                  )
                }
                <div className="detail">
                  <ClampLines
                    text={event?.attributes?.content}
                    lines={4}
                    ellipsis="..."
                    moreText={translate["common.read_more"]}
                    lessText={translate["common.read_less"]}
                    className="detail"
                    innerElement="div"
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ));
  };

  return (
    <CompanyProfilePageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/about", text: translate["menu.about_us"] },
          { url: "/about/company-profile", text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
        id="company_profile_page_header"
      />

      <div className="page-content fluid">
        <div className="company-profile">
          <div className="header-content">
            <div className="title" id="company_profile_title">
              {data?.attributes?.title_text}
            </div>

            <div
              className="company-description"
              id="company_profile_description"
            >
              <ReactMarkdown
                children={data?.attributes?.company_story_description?.replaceAll("/n", "<br/>")}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            </div>
          </div>

          <div
            className="history-timeline"
            id="company_profile_history_timline"
          >
            {renderEvents()}
          </div>
        </div>
      </div>
    </CompanyProfilePageStyle>
  );
};

export default CompanyProfileComponent;
