import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../constants/routes";
import { OneReportStyle } from "./styled";
import { PageHeader, CardDataSheet, Input } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import { getBaseUploadUrl } from "../../../Service/service";
import {
  PagesOneReport,
  OneReportFiles,
} from "./../../../Service/publications";
import { removeDuplicates, displayYearBuddhistEra, displayYear } from "./../../../helpers";
import moment from "moment";
import { langTh } from "../../../store/lang";

const OneReportComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [content, setContent] = useState([]);
  const [date, setDate] = useState([]);

  const defaultOptionYears = [
    { value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date()) },
  ];
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState(defaultOptionYears);
  const [searchValue, setSearchValue] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const OneReport = async () => {
      try {
        const resPage = await PagesOneReport();
        setData(resPage?.data);

        const payloadDate = {
          "fields[0]": "date",
          "sort[date]": "desc",
        };
        const resDate = await OneReportFiles(payloadDate);
        setDate(resDate?.data);

        setIsMounted(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    OneReport();
  }, []);

  useEffect(() => {
    const updateContentByYear = async () => {
      const payload = {
        "filters[date][$gte]": `${selectedYear}-01-01`,
        "filters[date][$lte]": `${selectedYear}-12-31`,
        "sort[date]": "desc",
        "sort[id]": "desc",
        populate: "*",
      };
      const responseContent = await OneReportFiles(payload);
      setContent(responseContent?.data);
    };

    selectedYear && updateContentByYear();
  }, [selectedYear]);

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    const addYear = lang === langTh ? 543 : 0;
    years?.map((e) =>
      yearsData.push({ value: e, label: (e + addYear).toString() })
    );

    setOptionYears(yearsData);
    years[0] && setSelectedYear(years[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onChangeYear = (e) => {
    setSelectedYear(e?.value);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  let img = "../asset/media/images/contents/card-img-56-1-one-report.png";

  return (
    <OneReportStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.published_document"] },
          {
            url: ROUTE_PATH.ONE_REPORT.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="annual-report content-wrapper">
            <div className="annual-report-search">
              <div className="search">
                <Input
                  className="input-search"
                  inputSearch
                  type="text"
                  placeholder={translate["common.search_for_documents"]}
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="select-wrapper">
                <span>{translate["common.year"]}</span>
                {isMounted && (
                  <SelectBox
                    placeholder={optionYears[0]?.label}
                    defaultValue={optionYears[0]}
                    options={optionYears}
                    onChange={(e) => onChangeYear(e)}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>

            <div className="block-annual-report">
              {content &&
                content
                  .filter((item) =>
                    item.attributes.file_name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  )
                  .map((e, i) => {
                    return (
                      <CardDataSheet
                        key={i}
                        id={e?.id}
                        cardImage={img}
                        cardTitle={e?.attributes?.file_name}
                        file_name={e?.attributes?.file?.data?.attributes?.name}
                        file_url={e?.attributes?.file?.data?.attributes?.url}
                        themeColor="green"
                        btnName={translate["common.download_document"]}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </OneReportStyle>
  );
};

export default OneReportComponent;
