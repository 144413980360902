
import ShareholderStructureComponent from "../components/Pages/ShareholderStructure";
import Auth from "../containerWarping/Auth";
const ShareholderStructure = () => {
  return (
    <Auth>
      <ShareholderStructureComponent />
    </Auth>
  );
};

export default ShareholderStructure;