import InvestorRelationsContactComponent from "../components/Pages/InvestorRelationsContact";
import Auth from "../containerWarping/Auth";
const InvestorRelationsContact = () => {
  return (
    <Auth>
      <InvestorRelationsContactComponent />
    </Auth>
  );
};

export default InvestorRelationsContact;
