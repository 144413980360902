import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const CompanySnapshotPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .company-snapshot {
    padding: 60px;

    .block-card-big {
      position: relative;
      padding: 100px 0;
      margin-bottom: 60px;

      .bg-img {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }

      .card-big-company-snapshot {
        position: relative;
        width: 60%;
        background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        padding: 35px;
        border-radius: 8px 8px 150px 8px;
        margin: 10px;
        margin-left: 0;
        z-index: 1;

        .card-head,
        .card-body {
          margin-bottom: 25px;
        }

        .card-head {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }

        .card-body {
          display: flex;

          .icon {
            width: 32px;
            height: 32px;
            margin: auto 15px auto 0;
          }

          .name {
            font-size: 40px;
            font-weight: 700;
            color: #ffffff;
          }
        }

        .card-footer {
          .file-download {
            cursor: pointer;
            display: flex;
            min-width: 170px;
            /* max-width: 180px; */
            width: fit-content;
            margin-right: 40px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            padding: 6px 11px;
            border-radius: 6px;
            border: 1px solid ${VARIABLES.COLOR.SECONDARY_DEFAULT};
            background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};

            .icon {
              margin-right: 8px;
            }

            &:hover {
              .icon {
                transition: 1s;
                transform: translate(0, 5px);
              }
            }
          }
        }
      }
    }

    .block-card-content {
      .select-wrapper {
        text-align: end;
        margin: 15px 0;
      }

      .block-card {
        display: grid;
        justify-items: center;
        gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1180.98px) {
    .company-snapshot {
      .block-card-content {
        .block-card {
          .card-company-snapshot-wrapper {
            padding: 20px 25px;

            .card-company-snapshot {
              .card-head {
                margin-bottom: 15px;
              }

              .card-body {
                margin-bottom: 15px;

                .icon {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024.98px) {
    .company-snapshot {
      padding: 40px;

      .block-card-big {
        padding: 30px 0;

        .card-big-company-snapshot {
          padding: 25px 35px;

          .card-head,
          .card-body {
            margin-bottom: 18px;
          }

          .card-head {
            font-size: 20px;
          }

          .card-body {
            .icon {
              width: 26px;
              height: 26px;
            }

            .name {
              font-size: 24px;
            }
          }
        }
      }

      .block-card-content {
        .block-card {
          gap: 20px;
        }
      }
    }
  }

  @media (max-width: 820.98px) and (min-width: 769.98px) {
    .company-snapshot {
      .block-card-content {
        .block-card {
          grid-template-columns: 1fr 1fr;

          .card-company-snapshot-wrapper {
            padding: 20px 24px;

            .card-company-snapshot {
              .card-head {
                font-size: 16px;
                margin-bottom: 15px;
              }

              .card-body {
                margin-bottom: 15px;

                .icon {
                  width: 20px;
                  height: 20px;
                  margin: auto 10px auto 0;
                }

                .name {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768.98px) {
    .company-snapshot {
      padding: 30px;

      .block-card-big {
        padding: 60px 0 0 0;

        .bg-img {
          width: 60%;
          min-height: 300px;
          height: 60%;
        }

        .card-big-company-snapshot {
          width: 70%;

          .card-head {
            font-size: 24px;
            margin-bottom: 10px;
          }

          .card-body {
            .name {
              font-size: 28px;
            }
          }
        }
      }

      .block-card-content {
        .block-card {
          display: grid;
          gap: 10px;
          grid-template-columns: 1fr 1fr;
          justify-items: center;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .company-snapshot {
      .block-card-big {
        .bg-img {
          width: 80%;
        } 

        .card-big-company-snapshot {
          width: 90%;
          padding: 20px 25px;
          border-radius: 8px 8px 100px 8px;
        }
      }

      .block-card-content {
        .block-card {
          max-width: 350px;
          margin: auto;
          gap: 25px;
          grid-template-columns: 1fr;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .company-snapshot {
      .block-card-big {
        .card-big-company-snapshot {
          .card-footer {
            .file-download {
              font-size: 14px;
              min-width: 160px;
    
              .icon { 
                &.icon-download {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
