import service from "./service";

export const PagesCorruptionReported = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-corruption-reported', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorruptionReportedPolicyFiles = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/corruption-reported-policy-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorruptionReported = async (payload) => {
  try {
    const response = await service().post('/corruption-reporteds', { data: payload })
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};