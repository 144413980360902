import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const SocialResponsibilityReportStyle = styled.div`
  /* Parent styles
  ------------------------------- */

    background-image: url('../../asset/media/images/contents/bg-layout-left-bottom.png');
    background-size: cover;
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  .social-responsibility-report-content {
    .card-wrapper {
      padding: 70px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
      gap: 70px;
      justify-items: center;

      .card {
        position: relative;
        width: 390px;
        height: 257px;
        border-radius: 6px;

        .card-image {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 6px;
        }

        .card-header {
          position: absolute;
          top: 0;
          right: 0;
          width: fit-content;
          padding: 8px 16px;
          border-radius: 0px 6px 0px 24px; 
          background-color: white;
          img {
            width: 72px;
            height: 20px;
          }
        }

        .card-footer{
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 6px 16px;
          border-radius: 0px 24px 0px 6px;
          background-color: white;
          max-width:250px;

          .card-name {
            position: relative;

            .name {
              ${VARIABLES.TYPOGRAPHYS.BODY._7};
              margin-bottom: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;

              @media (max-width: 991.98px) {
                font-size: 20px;
              }
            }

            .sub-name {
              display: none;
            }

            &:hover {
              .sub-name {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(	94, 172, 27, 0.8);
                color: white;
                width: 150%;
                padding: 15px;
                border-radius: 10px;
                transform: translate(0, 25px);
                z-index: 990;
              }
            }
          }

          .card-download {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 8px;
            gap: 8px;
            cursor: pointer;
            width: fit-content;
            height: 27px;

            img {
              width: 18px;
              height: 18px;
            }

            span {
              margin-right: 8px;
              ${VARIABLES.TYPOGRAPHYS.BODY._11};
              color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
            }

            border: 1px solid transparent;
            transition: 00ms;
            
            &:hover {
              background-color: ${VARIABLES.COLOR.SECONDARY_LIGHT};
              border: 1px solid ${VARIABLES.COLOR.SECONDARY_DEFAULT};
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
  

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    .social-responsibility-report-content {
      .card-wrapper {
        padding: 50px;
        grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
        gap: 50px;

        .card {
          width: 390px;
          height: 257px;
        }
      }
    }
  }
  
  @media (max-width: 1024.98px) {
    .social-responsibility-report-content {
      
    }
  }

  @media (max-width: 768.98px) {
    .social-responsibility-report-content {

      .card-wrapper {
        padding: 40px;
        grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
        gap: 40px;

        .card {
          width: 380px;
          height: 247px;
        }
      }
     
    }
  }

  @media (max-width: 425.98px) {
    .social-responsibility-report-content {
      .card-wrapper {
        padding: 30px;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 30px;

        .card {
          width: 350px;
          height: 217px;
        }
      }
    }
  }

  @media (max-width: 390.98px) {
    .social-responsibility-report-content {
      .card-wrapper {
        padding: 30px;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        gap: 30px;

        .card {
          width: 320px;
          height: 187px;
        }
      }
    }
  }

  @media (max-width: 360.98px) {
    .social-responsibility-report-content {
      .card-wrapper {
        padding: 20px;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;

        .card {
          width: 280px;
          height: 147px;
        }
      }
    }
  }

  @media (max-width: 280.98px) {
    .social-responsibility-report-content {
      .card-wrapper {
        padding: 12px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;

        .card {
          width: 250px;
          height: 147px;
        }
      }
    }
  }
`
