import React from "react";
import { CardVDOStyle } from "./styled";
import { formatURL } from "../../function/formatURL";
import { Button } from "../../../../Global";
import { SaveFile } from "../../../../../Service/API";
import { getBaseUploadUrl } from "../../../../../Service/service";
import { useSelector } from "react-redux";

function CardVDO({
  url,
  fileUrl,
  fileName,
  id,
  title,
  type,
  subTitle,
  setIsSelectDetail,
  business,
}) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const translate = useSelector((state) => state.lang.translate);

  return (
    <CardVDOStyle>
      <div className="card-vdo-wrapper">
        <div
          className="vdo-wrapper"
          onClick={() =>
            setIsSelectDetail({ isOpen: true, type: type, id: id, url: url })
          }
        >
          <div className="vdo">
            <iframe
              src={formatURL(url)}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              title="vdo"
            ></iframe>
          </div>

          <div className="film"></div>

          {/* <div className="vdo-title-wrapper">
            <div className="vdo-title">Company Presentation Video</div>
            <div className="vdo-date">November 2022</div>
          </div> */}

          {type === "introduction" && (
            <div className="logo">
              <img
                src="../asset/media/images/contents/biotec-logo-font-blue.png"
                alt="logo"
              />
            </div>
          )}
        </div>

        <div className="title">
          <span className='name'>{title}</span>
          <span className="sub-name">{title}</span>
        </div>
        {type === "opp-day" && <div className="business">
          <span className='name'>{business}</span>
          <span className="sub-name">{business}</span>
        </div>}

        <hr />

        {type === "introduction" && <div className="sub-title">
          <span className='name'>{subTitle}</span>
          <span className="sub-name">{subTitle}</span>
        </div>}
        {type === "opp-day" && (
          <Button
            className="medium-secondary btn-save"
            onClick={() => {
              SaveFile(BASE_URL_UPLOAD + fileUrl, fileName);
            }}
          >
            <img
              className="icon icon-download"
              src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
              alt="icon-arrow-down-circle-white"
            />
            {translate["common.download_document"]}
          </Button>
        )}
      </div>
    </CardVDOStyle>
  );
}

export default CardVDO;
