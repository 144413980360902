import React from 'react'
import { ServiceCardStyle } from './styled'

function ServiceCard({ image, title, alt }) {
  return (
    <ServiceCardStyle>
      <div className='container-wrapper'>
        <div className='card'>
          <img src={image} alt={alt || "card"} />
        </div>
        <div className="title">
          {title}
        </div>
      </div>
    </ServiceCardStyle>
  )
}

export default ServiceCard