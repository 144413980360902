import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const OurBusinessComponentStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .background-page {
    background-image: url('../../asset/media/images/contents/background-sustainability1.png');
    background-size: 100%;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -100vh;
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1024.98px) {
   
  }

  @media (max-width: 768.98px) {
   
  }

  @media (max-width: 425.98px) {
 
  }

  @media (max-width: 375.98px) {
 
  }

  @media (max-width: 320.98px) {
   
  }
`
