import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducer";
import langReducer from "./lang";

export default configureStore({
  reducer: {
    lang: langReducer,
    counter: counterReducer,
  },
});
