import classNames from "classnames";
import { ProfileListStyle } from "./styled";
import { Link } from "react-router-dom";
import { Button } from "../../Global";
import { useSelector } from "react-redux";

export default function ProfileList({
  className,
  id,
  firstName,
  lastName,
  profilePicture,
  jobTitle,
  onSelect,
  resumeDetails,
}) {
  const translate = useSelector((state) => state.lang.translate);
  const handleTopPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <ProfileListStyle className={classNames("profile-list-wrapper", className)}>
      <div className="first-last-name">
        <p className="first-name">{firstName}</p>
        <p className="last-name">{lastName}</p>
      </div>
      <div className="profile-picture">
        <img src={profilePicture} alt={`profile-img-id-${id}`} />
      </div>
      <div className="position">
        <p className="job-title">{jobTitle}</p>
      </div>
      <Button
        className="link read-more"
        onClick={() => {
          onSelect({
            isOpen: true,
            id: id,
            firstName: firstName,
            lastName: lastName,
            profilePicture: profilePicture,
            jobTitle: jobTitle,
            resumeDetails: resumeDetails,
          });
          handleTopPage();
        }}
      >
        {translate["common.read_more"]}
      </Button>
    </ProfileListStyle>
  );
}
