import React from "react";
import { Button } from "../../../Global";
import { SaveFile } from "../../../../Service/API";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { getBaseUploadUrl } from "../../../../Service/service";
import { useSelector } from "react-redux";

const CardElement = ({ title, description, urlFile, fileName }) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <div className="content-title">
      <div className="title">{title}</div>
      <div className="description">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
      </div>
      {
        urlFile && (
          <Button
            className="medium-secondary btn-download"
            onClick={() => {
              SaveFile(BASE_URL_UPLOAD + urlFile, fileName);
            }}
          >
            <img
              className="icon-download"
              src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
              alt="icon"
            />
            <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>
              {translate["common.download_document"]}
            </span>
          </Button>
        )
      }
    </div>
  );
};

export default CardElement;
