import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const SocialDimensionManagementStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .background-page {
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    background-size: 100%;
    background-position: center;
    margin-top: -100vh;
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  .social-dimension-management-content {
    .title {
      padding: 60px 142px;
      ${VARIABLES.TYPOGRAPHYS.BODY._3}

      p {
        word-break: break-all;
      }
    }

    .content-section {
      padding: 60px 142px;
      display: flex;
      gap: 48px;

      .shape-image {
        width: fit-content;
        border-radius: 8px 150px;
        width: 460px;
        height: 336px;
      }
      
      .dialog {
        text-align: left;
        
        .dialog-title {
          /* Title/Medium 32 */
          ${VARIABLES.TYPOGRAPHYS.TITLE._1}
          margin-bottom: 16px;
        }

        .dialog-content {
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1440.98px) { 
    .social-dimension-management-content {
      .content-section {
        padding: 40px 80px;
      }
    }
  }

  
  @media (max-width: 1024.98px) {
    .social-dimension-management-content {
      .title {
        padding: 30px 34px;
      }
      .content-section {
        padding: 30px 34px;
        .shape-image {
          width: 460px;
          height: 336px;
        } 
      }
    }
  }

  @media (max-width: 991.98px) {
    .social-dimension-management-content {
      background-color: white;
      .title {
        padding: 30px 34px;
      }
      .content:nth-child(even) {
        background-color: ${VARIABLES.COLOR.NEUTRAL8};
      }
      .content:nth-child(odd) {
        background-color: white;
      }
      .content-section {
        align-items: center;
        padding: 30px 34px;
        flex-direction: column;

        
        .shape-image {
          width: 460px;
          height: 336px;
        } 
      }
    }
  }

  @media (max-width: 500.98px) {
    .social-dimension-management-content {
      .content-section {
        .shape-image {
          width: 356px;
          height: 256px;
        } 
      }
    }
  }

  @media (max-width: 375.98px) {
    .social-dimension-management-content {
      .content-section {
        .shape-image {
          width: 300px;
          height: 200px;
        } 
      }
    }
  }

  @media (max-width: 320.98px) {
    .social-dimension-management-content {
      .title {
        padding: 30px 34px;
      }
      
      .content-section {
        padding: 30px 34px;
        flex-direction: column;

        .shape-image {
          border-radius: 50px 0px;
          width: 250px;
          height: 200px;
        } 
      }
    }
  }
`
