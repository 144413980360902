import React from 'react'
import { TabsStyle } from './styled'
import { useSelector } from 'react-redux';

function Tabs({isActiveIntroduction,isActiveOppDay,setTabs}) {
  const translate = useSelector((state) => state.lang.translate);

  return (
    <TabsStyle>
      <div className='tabs-wrapper'>
        <div 
        className={`introduction ${isActiveIntroduction && 'isActive'}`}
          onClick={()=>{setTabs(false)}}
        >
          {translate["company_introduction_vdo.company_introduction"]}
        </div>
        <div className='separate'></div>
        <div 
        className={`opp-day ${isActiveOppDay && 'isActive'}`}
        onClick={()=>{setTabs(true)}}
        >
          Opp Day
        </div>
      </div>
    </TabsStyle>
  )
}

export default Tabs