import { StockPricePageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import { PageHeader } from "../../Global";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBaseUploadUrl } from "../../../Service/service";
import CanvasJSReact from '@canvasjs/react-stockcharts';
import { PagesPriceChart, GetChartQuotation, GetBidOffer } from "./../../../Service/stockPrices";
import { displayDateBuddhistEra, displayDate, removeDuplicates, displayNumber, sortArray, displayTime, displayTimeEn } from "./../../../helpers";
import { langTh } from "../../../store/lang";

const StockPriceComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;
  const dispatch = useDispatch();

  const currentDate = new Date();
  const numberOfMlSeconds = currentDate.getTime();

  const hour = 60 * 60 * 1000;
  const day = 24 * hour;
  const week = 7 * day;
  const week52 = 52 * week;

  const dateOfLastYear = new Date(numberOfMlSeconds - week52);

  const minimumDate = dateOfLastYear;
  const maximumDate = currentDate;

  const [data, setData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [summary52WeekData, setSummary52WeekData] = useState({});
  const [bidOfferData, setBidOfferData] = useState({});

  const [dataPoints1, setDataPoints1] = useState([]);
  const [dataPoints2, setDataPoints2] = useState([]);
  const [dataPoints3, setDataPoints3] = useState([]);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const StockPrice = async () => {
      try {
        const resPage = await PagesPriceChart();
        setData(resPage?.data);

        const payload = {
          period: '5Y'
        }
        const resContent = await GetChartQuotation(payload);
        resContent?.result?.quotations && handleSetGraphData(resContent?.result);

        const resBid = await GetBidOffer();
        resBid && setBidOfferData(resBid?.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    StockPrice();
  }, []);

  const handleSetGraphData = (params) => {
    const data = [...params?.quotations];
    const dps1 = [];
    const dps2 = [];
    const dps3 = [];

    for (let i = 0; i < data?.length; i++) {
      const setDate = data[i].timestamp * 1000;

      // if (setDate >= +minDate && setDate <= +maxDate) {
      dps1.push({
        x: new Date(setDate),
        y: Number(data[i].last_close)
      });
      dps2.push({
        x: new Date(setDate),
        y: Number(+data[i].volume),
      });
      // }

      dps3.push({
        x: new Date(setDate),
        y: Number(data[i].last_close),
      });
    }

    setDataPoints1(dps1);
    setDataPoints2(dps2);
    setDataPoints3(dps3);

    handleSetSummaryData(data, params);
    handleSetDataOf52Week(data);
  }

  const handleSetSummaryData = (data, params) => {
    let dateList = [];

    data?.map((item) => {
      const date = new Date(item.timestamp * 1000);
      const date_display = 
        lang === langTh
          ? `${displayDateBuddhistEra(new Date(item.timestamp * 1000))} ${displayTime(new Date(item.timestamp * 1000), "HH.mm")} น.`
          : `${displayDate(new Date(item.timestamp * 1000), "D MMM YYYY")} ${displayTimeEn(new Date(item.timestamp * 1000))}`

      item.date = date;
      item.date_display = date_display;
      item.change_volume = ((item.last_min + item.last_max) / 2) * (item.change_precent / 100);

      dateList.push(displayDate(date));
      return item;
    });

    // Group by date
    const getLastDate = removeDuplicates([...dateList])[0];
    const lastDateList = data?.filter(r => displayDate(r.date) === getLastDate);

    const dateLength = data?.filter(r => displayDate(r.date) === getLastDate)?.length;

    // Set data
    const last_min = sortArray([...lastDateList], 'last_min')[0]?.last_min;
    const last_max = sortArray([...lastDateList], 'last_max', 'desc')[0]?.last_max;
    const last_open = sortArray([...lastDateList], 'last_open', 'desc')[0]?.last_open;
    const last_close = sortArray([...lastDateList], 'last_close', 'desc')[0]?.last_close;

    const change_volume = lastDateList?.reduce(
      (sum, obj) => sum + Number(obj.change_volume)
      , 0
    ) / dateLength;
    const change_precent = lastDateList?.reduce(
      (sum, obj) => sum + Number(obj.change_precent)
      , 0
    ) / dateLength;
    const volume = lastDateList?.reduce(
      (sum, obj) => sum + Number(obj.volume)
      , 0
    );

    const dateTimeDisplay = 
      lang === langTh
        ? `${displayDateBuddhistEra(new Date(params?.modified))} ${displayTime(new Date(params?.modified), "HH.mm")} น.`
        : `${displayDate(new Date(params?.modified), "D MMM YYYY")} ${displayTimeEn(new Date(params?.modified))}`

    setSummaryData({
      date_display: dateTimeDisplay,
      last_min,
      last_max,
      last_open,
      last_close,
      change_volume,
      change_precent,
      volume,
    })
  }

  const handleSetDataOf52Week = (data) => {
    // Set data of day
    let dayList = [];
    data?.forEach((item) => {
      dayList.push(displayDate(new Date(item.timestamp * 1000)));
    })

    // Set data of 52 week
    let dateOf52WeekList = [];
    dayList = removeDuplicates(dayList);
    dayList?.forEach((item, index) => {
      if (new Date(item) >= new Date(dateOfLastYear)) {
        dateOf52WeekList.push({
          date: item,
          last_min: data[index].last_min,
          last_max: data[index].last_max,
        });
      }
    });

    const sortMin = sortArray([...dateOf52WeekList], 'last_min');
    const sortMax = sortArray([...dateOf52WeekList], 'last_max', 'desc');

    const minPrice52week = sortMin[0]?.last_min;
    const maxPrice52week = sortMax[0]?.last_max;

    setSummary52WeekData({
      minPrice52week,
      maxPrice52week,
    });
  }
  const options = {
    theme: "light2",
    charts: [
      {
        // zoomEnabled: true,
        height: 200,
        axisX: {
          lineThickness: 5,
          tickLength: 0,
          labelFormatter: () => "",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
            labelFormatter: () => "",
          },
        },
        axisY2: {
          prefix: "THB ",
          tickLength: 0,
        },
        toolTip: {
          shared: true,
        },
        data: [
          {
            name: "Price (in THB)",
            yValueFormatString: "#,##0.## THB",
            type: "line",
            color: "#1B3B6A",
            dataPoints: dataPoints1,
            axisYType: "secondary",
          },
        ],
      },
      {
        height: 150,
        axisX: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
        },
        axisY2: {
          prefix: "THB ",
          tickLength: 0,
        },
        toolTip: {
          shared: true,
        },
        data: [
          {
            name: "Volume",
            yValueFormatString: "#,###.##",
            type: "column",
            color: "#1B3B6A",
            dataPoints: dataPoints2,
            axisYType: "secondary",
          },
        ],
      },
    ],
    navigator: {
      data: [
        {
          dataPoints: dataPoints3,
        },
      ],
      slider: {
        minimum: minimumDate,
        maximum: maximumDate,
      },
    },
    rangeChanging: (e) => {
      if (e?.minimum && e?.maximum) {
        // setMinimumDate(new Date(e?.minimum));
        // setMaximumDate(new Date(e?.maximum));
        // handleSetGraphData(stockData);
      }
    },
  };

  const containerProps = {
    width: "100%",
    height: "450px",
    margin: "auto"
  };

  return (
    <StockPricePageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.stock_price_information"] },
          { url: ROUTE_PATH.STOCK_PRICE.LINK, text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="stock-price content-wrapper">
          <div className="stock-price-title">
            <p className="title-left">{data?.attributes?.menu_text}</p>
            <p className="title-right">{data?.attributes?.graph_note}</p>
          </div>

          <div className="stock-price-charts">
            <CanvasJSStockChart
              containerProps={containerProps}
              options={options}
            />
          </div>

          <div className="stock-price-description">
            <div className="description-left">
              <p className="left-first">SET : BIOTEC</p>
              <p className="left-second">{summaryData?.last_close?.toFixed(2)} THB</p>
              <p className="left-third">
                {summaryData?.change_precent >= 0 ? "+" : ""}
                {
                  summaryData?.change_volume === 0
                    ? "0.00 "
                    : `${summaryData?.change_volume?.toFixed(2)} `
                }
                ({summaryData?.change_precent?.toFixed(2)}%)
              </p>
              <p className="left-fourth">
                {summaryData?.date_display}
              </p>
            </div>
            <div className="description-right">
              <div className="description-right-top">
                <div className="top-left">
                  <div className="top-first">
                    <p>{translate["stock_price.trading_volume_shares"]}</p>
                    <p>{displayNumber(Number(summaryData?.volume)?.toFixed(2))}</p>
                  </div>
                  <div className="top-second">
                    <p>{translate["stock_price.offer_price_share_quantity"]}</p>
                    <p>{bidOfferData?.offer?.buy?.price} / {displayNumber(Number(bidOfferData?.offer?.buy?.volume))}</p>
                  </div>
                </div>
                <div className="top-right">
                  <div className="top-first">
                    <p>{translate["stock_price.offering_price_quantity_of_shares"]}</p>
                    <p>{bidOfferData?.offer?.sell?.price} / {displayNumber(Number(bidOfferData?.offer?.sell?.volume))}</p>
                  </div>
                  <div className="top-second">
                    <p>{translate["stock_price.closing_price"]}</p>
                    <p>{summaryData?.last_close?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <div className="description-right-bottom">
                <div className="right-bottom-first">
                  <p>{translate["stock_price.intraday_price_range"]}</p>
                  <p>{summaryData?.last_min?.toFixed(2)} - {summaryData?.last_max?.toFixed(2)}</p>
                </div>
                <div className="right-bottom-second">
                  <p>{translate["stock_price.52_week_price_range"]}</p>
                  <p>{`${summary52WeekData?.minPrice52week?.toFixed(2)} - ${summary52WeekData?.maxPrice52week?.toFixed(2)}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StockPricePageStyle>
  );
};

export default StockPriceComponent;