import React, { useEffect, useState } from "react";
import { OurBusinessComponentStyle } from "./styled";
import { PageHeader } from "../../Global";
import { getBaseUploadUrl } from "../../../Service/service";
import { ourBusinessService } from "../../../Service/ourBusinessService";
import { useParams } from 'react-router-dom';
import ThemeOurBusiness from "./components/ThemeOurBusiness";

// Slick slide
// https://www.npmjs.com/package/react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";

const OurBusinessComponent = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [Data, setData] = useState({});
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const OurBusinessData = async () => {
      const res = await ourBusinessService().getOurBusiness();
      const ourBusiness = res?.data;

      let ourData = {};
      ourBusiness?.forEach((item) => {
        if (lang === item?.attributes?.locale && item?.id === +id) {
          ourData = item;
        } else if (lang === item?.attributes?.locale && item?.attributes?.localizations?.data[0]?.id === +id) {
          ourData = item;
        }
      });

      const setId = ourData?.id || id;
      const payload = {
        // "populate": "deep,3",
        "populate[service_id][populate]": "*",
        "populate[image][populate]": "*",
        "populate[product_id][populate]": "*",
        "populate[menu_image][populate]": "*",
        "populate[localizations][populate]": "*"
      }
      const Response = await ourBusinessService().getOurBusinessById(setId, payload);
      setData(Response?.data);
    };
    OurBusinessData();
  }, [id])

  return (
    <OurBusinessComponentStyle>
      <PageHeader
        pageHeaderImage={Data?.attributes?.menu_image?.data?.attributes?.url && BASE_URL_UPLOAD + Data?.attributes?.menu_image?.data?.attributes?.url}
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/our_business", text: translate["menu.our_business"] },
          {
            url: "/our-business/our-business-palm",
            text: Data?.attributes?.menu_text,
          },
        ]}
        title={Data?.attributes?.menu_text}
      />

      <div className="background-page"></div>
      <div className="content-wrapper">
        <ThemeOurBusiness
          data={Data}
          isService={Data?.attributes?.service_id?.data?.length > 0}
          isProduct={Data?.attributes?.product_id?.data?.length > 0}
        />
      </div>
    </OurBusinessComponentStyle>
  );
};
export default OurBusinessComponent;
