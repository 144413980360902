import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'
// Wrapper
// ============================================================
export const ImgHeadDetailStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .img-head {
    max-width: 1145px;
    max-height: 553px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  
  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1024.98px) {
   
  }

  @media (max-width: 768.98px) {
   
  }

  @media (max-width: 425.98px) {
    
  }

  @media (max-width: 375.98px) {
    
  }

  @media (max-width: 320.98px) {
   
  }
`
