import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const CardDownloadFileStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .block-file-download {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    align-items: center;
    margin:0 40px 40px 40px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 20px -10px ${VARIABLES.COLOR.NEUTRAL12};

    &:before {
      content: '';
      position: absolute;
      top: 23px;
      left: -3px;
      background-size: cover;
      background-repeat: no-repeat;
      width: 6px;
      height: 54%;
      background-image: url('../asset/media/images/icons/icon-line-blue-green.svg');
    }

    .block-left {
      margin: 0 40px;

      .name {
        margin-bottom: 10px;
        ${VARIABLES.TYPOGRAPHYS.BODY._3}

        .data-set-announcements {
          color: ${VARIABLES.COLOR.PRIMARY_DEFAULT};
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
        }
      }

      .date {
        color: ${VARIABLES.COLOR.NEUTRAL13};
        ${VARIABLES.TYPOGRAPHYS.BODY._6}

        .title-set-announcements {
          ${VARIABLES.TYPOGRAPHYS.BODY._3}
        }
      }
    }

    .block-right {
      .file-download {
        cursor: pointer;
        display: flex;
        min-width: 170px;
        /* max-width: 180px; */
        width: fit-content;
        margin-right: 40px;
        color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        ${VARIABLES.TYPOGRAPHYS.BODY._6}
        padding: 6px 11px;
        border-radius: 6px;
        border: 1px solid transparent;
        background: transparent;

        .icon {
          margin-right: 8px;
        }

        &:hover {
          border-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          background: ${VARIABLES.COLOR.SECONDARY_LIGHT};
        }
      }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 800.98px) {
    .block-file-download {
      display: block;
      margin:0 30px 30px 30px;

      &:before {
        top: 30px;
      }

      .block-right {
        padding-top: 10px;

        .file-download {
          margin-left: 40px;
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .block-file-download {
      margin:0 20px 20px 20px;
    }
  }
`
