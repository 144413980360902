import styled from "styled-components";
import VARIABLES from "../../../../../../themes/variables";
// Wrapper
// ============================================================
export const OneCardStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  margin-bottom: 50px;

  .csr-news-story {
    overflow: hidden;
    display: flex;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    .csr-news-story-img {
      position: relative;
      width: 100%;
      max-width: 50%;
      max-height: 450px;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .csr-news-story-content {
      position: relative;
      width: 100%;
      max-width: 50%;
      padding: 20px 27px 60px;

      .date {
        margin: 15px 0 30px;
        text-align: end;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }

      .block-content {
        .content-title {
          margin-bottom: 15px;
          color: #000000;
          font-size: 28px;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0.56px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .content-body {
          color: ${VARIABLES.COLOR.NEUTRAL18};
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0.32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .more {
          position: absolute;
          bottom: 0;
          left: 0;
          padding-left: 27px;
          padding-bottom: 20px;
          width: 100%;
          height: auto;
          ${VARIABLES.TYPOGRAPHYS.BODY._7};
        }
      }
    }
  }
  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */
  .more {
    cursor: pointer;
    display: flex;
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    ${VARIABLES.TYPOGRAPHYS.BODY._7};
    width: fit-content;
    padding: 10px 10px 10px 0;
    background-color: transparent;
    border-color: transparent;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
  }

  @media (max-width: 768.98px) {
    .csr-news-story {
      .csr-news-story-content {
        .block-content {
          .content-title {
            font-size: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .csr-news-story {
      flex-direction: column;

      .csr-news-story-img,
      .csr-news-story-content {
        max-width: 100%;
      }
    }
  }

  /* @media (max-width: 425.98px) {
  } */

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
