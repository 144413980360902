import WeAreBiotecComponent from "../components/Pages/WeAreBiotec";
import Auth from "../containerWarping/Auth";
const WeAreBiotec = () => {
  return (
    <Auth>
      <WeAreBiotecComponent />
    </Auth>
  );
};

export default WeAreBiotec;
