import HomeInvestorComponent from "../components/Pages/HomeInvestor";
import Auth from "../containerWarping/Auth";
const HomeInvestor = () => {
  return (
    <Auth>
      <HomeInvestorComponent />
    </Auth>
  );
};

export default HomeInvestor;
