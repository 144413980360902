//import { Link } from "react-router-dom";
import {
  Button
} from '../../Global'

const TestComponent = () => (
  <div>
    <Button />
  </div>
);

export default TestComponent;