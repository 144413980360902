import InvestorKitComponent from "../components/Pages/InvestorKits";
import Auth from "../containerWarping/Auth";
const Investorkits = () => {
  return (
    <Auth>
      <InvestorKitComponent/>
    </Auth>
  );
};

export default Investorkits;