import React from "react";
import { SaveFile } from "../../../../Service/API";
import { getBaseUploadUrl } from "../../../../Service/service";
import { Button } from "../../../Global";
import { useSelector } from "react-redux";

const CardCharter = ({ title, fileIcon, file, fileName, index }) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <div className="card">
      <div className="card-header">
        <img
          src="../asset/media/images/icons/icon-logo-BioTec-white.png"
          alt="header-card"
        />
      </div>
      <div className="card-body">
        <div className="card-body-content">
          <div>
            <img src={BASE_URL_UPLOAD + fileIcon} alt="icon-note" />
          </div>
          <div className='card-name'>
            <span className='name'>{title}</span>
            <span className='sub-name'>{title}</span>
          </div>
        </div>
        <Button
          className="medium-secondary btn-card-download"
          onClick={() => {
            SaveFile(BASE_URL_UPLOAD + file, fileName);
          }}
        >
          <img
            className="icon-download"
            src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
            alt="icon"
          />
          <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>
            {translate["common.download_document"]}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default CardCharter;
