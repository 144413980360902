

import OurBusinessComponent from "../components/Pages/OurBusiness";
import Auth from "../containerWarping/Auth";
const OurBusiness = () => {
  return (
    <Auth>
      <OurBusinessComponent />
    </Auth>
  );
};

export default OurBusiness;