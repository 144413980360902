import classNames from 'classnames'
import {
  BreadcrumbStyle,
  BreadcrumbItemStyle
} from './styled'

import { Link } from 'react-router-dom';

export default function Breadcrumb({
  children,
  id,
  className,
}) {
  return (
    <BreadcrumbStyle
      className={classNames(
        'breadcrumb',
        className
      )}
      id={id}
    >
      {children}
    </BreadcrumbStyle>
  )
}

export function BreadcrumbItem({
  children,
  id,
  className,
  linkTo,
}) {
  return (
    <BreadcrumbItemStyle
      className={classNames(
        'breadcrumb-item',
        className
      )}
      id={id}
    >
      {
        linkTo !== undefined ?
          (<Link to={linkTo}>{children}</Link>)
          : children
      }

    </BreadcrumbItemStyle>
  )
}

Breadcrumb.defaultProps = {
  linkTo: undefined
}

