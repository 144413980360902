import SustainableDevelopmentPolicyComponent from "../components/Pages/SustainableDevelopmentPolicy";
import Auth from "../containerWarping/Auth";
const SustainableDevelopmentPolicy = () => {
  return (
    <Auth>
      <SustainableDevelopmentPolicyComponent />
    </Auth>
  );
};

export default SustainableDevelopmentPolicy
;
