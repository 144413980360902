import classNames from 'classnames'
import { ButtonStyle } from './styled'

function renderTheme(theme) {
  switch (theme) {
    case 'primary': return 'primary'
    case 'secondary': return 'secondary'
    default: return null
  }
}

export default function Button({
  children,
  title,
  id,
  onClick,
  type,
  onMouseEnter,
  onMouseLeave,
  disable,
  disabled,
  isDisable,
  isDisabled,
  className,
  theme,
}){
  return (
    <ButtonStyle
      className={classNames(
        'button',
        {'is-disabled': disable || disabled || isDisable || isDisabled},
        renderTheme(theme),
        className
      )}
      id={id}
      title={title}
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disable || disabled || isDisable || isDisabled}
    >
      {children}
    </ButtonStyle>
  )
}

// Button.defaultProps = {
//   theme: 'primary'
// }

