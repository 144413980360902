import ChannelsReceivingComplaintsComponent from "../components/Pages/ChannelsReceivingComplaints";
import Auth from "../containerWarping/Auth";
const ChannelsReceivingComplaints = () => {
  return (
    <Auth>
      <ChannelsReceivingComplaintsComponent />
    </Auth>
  );
};

export default ChannelsReceivingComplaints;
