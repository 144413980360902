import PrivacyCenterComponent from "../components/Pages/Privacy/PrivacyCenter";
import Auth from "../containerWarping/Auth";
const PrivacyCenter = () => {
  return (
    <Auth>
      <PrivacyCenterComponent />
    </Auth>
  );
};

export default PrivacyCenter;