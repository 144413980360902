import classNames from 'classnames';
import { CardPolicyComplaintProtectionStyle } from './styled'
import { SaveFile } from "../../../../../Service/API";
import { getBaseUploadUrl } from "../../../../../Service/service";
import { useSelector } from 'react-redux';

function CardPolicyComplaintProtection({
  className,
  iconHead,
  cardName,
  file,
}) {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <CardPolicyComplaintProtectionStyle
      className={classNames(
        'card-policy-complaint-protection-wrapper',
        className
      )}
    >
      <div className='card-head'>
        <img src={iconHead} alt='icon-head' />
      </div>
      <div className='card-body'>
        <div className='card-name'>
          <span className='name'>{cardName}</span>
          <span className='sub-name sub-name-card-business'>{cardName}</span>
        </div>
      </div>
      <div className='card-footer'>
        <div className='file-download' 
          onClick={()=>{
            SaveFile(BASE_URL_UPLOAD + file?.url, file?.fileName || file?.name)
          }}
        >
          <img className='icon icon-download' src='../asset/media/images/icons/icon-arrow-down-circle-white.svg' alt='icon-arrow-down-circle-white'/>
          {translate["common.download_document"]}
        </div>
      </div>
    </CardPolicyComplaintProtectionStyle>
  )
}

export default CardPolicyComplaintProtection;