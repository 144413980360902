import styled from "styled-components";
import VARIABLES from "../../../../themes/variables";

// Wrapper
// ============================================================
export const WorkWithUsPageInterestingPositionStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .body-job-description {
    padding-left: 50px;
    padding-top: 30px;
    margin-bottom: 30px;

    ul {
      // list-style-type: none;
    }
  }

  .line-break {
    white-space: pre-wrap;

    ul {
      margin: 0 0 -5px 0;
      padding: 0;
      white-space-collapse: collapse;

      li {
        white-space-collapse: preserve;
      }
    }

    ol {
      margin: -10px 0 0 0;
      padding: 0;
      white-space-collapse: collapse;
    }

    p {
      margin: -10px 0 0 0;
      padding: 0;
    }
  }

  .work-with-us {
    .work-with-us-search {
      display: flex;
      padding: 60px 105px;
      justify-content: space-around;
      align-items: center;
      /* gap: 195px; */
      flex-wrap: wrap;

      @media (max-width: 1050.98px) {
        padding: 60px 20px;
      }

      .result {
        display: flex;
        align-items: center;
        gap: 46px;

        .text-result {
          color: #000;
          font-family: Anuphan;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: 0.4px;
        }
      }

      .search {
        display: flex;
        align-items: center;
        gap: 43px;

        .box-search {
          display: flex;
          /* width: 356px; */
          flex-direction: column;
          align-items: flex-start;

          .field-wrapper {
            width: 100%;
          }
        }
      }

      .contact {
        display: flex;
        align-items: center;
        gap: 79px;

        .box-contact {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 7px;

          .text-contact {
            color: #000;
            font-family: Anuphan;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.32px;
          }

          .link-contact {
            display: flex;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 24px;

            .icon-share-link {
              cursor: pointer;
            }
          }
        }
      }

      @media (max-width: 430.98px) {
        
        .line-img {
          display: none;
        }
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        .result {
          width: 100%;
          padding-bottom: 24px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50%;
            border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          }
        }

        .search {
          width: 100%;
          padding-bottom: 24px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50%;
            border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          }
        }

        .contact {
          .box-contact {
            align-items: flex-start;
          }
        }
      }

      @media (max-width: 780.98px) {
        padding: 20px;
        .box-search {
          width: 100%;
        }
      }
    }

    .underline-search {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-readmore {
      display: flex;
      align-items: flex-start;
    }

    #more {
      display: none;
    }

    #more-dropdown {
      display: none;
    }

    .work-with-us-jobs {
      display: flex;
      padding: 60px 66px;
      flex-direction: column;
      align-items: center;
      /* gap: 49px; */
      .accordion-wrapper:first-child {
        border-top: 1px solid black;
      }
      .accordion-wrapper {
        padding-top: 16px;
        width: calc(100% - 12px);
        border-bottom: 1px solid black;
        .accordion {
          .accordion-head {
            padding-left: 12px;
            padding-right: 12px;
            justify-content: space-between;
            align-items: center;
            .toggle-icon {
              background: url("../asset/media/images/icons/icon-chevron-down-black.png");
              background-repeat: no-repeat, repeat;
              background-position: center;
              background-size: contain;
              border-radius: 0;
              border: none;
            }
            .toggle-icon::after {
              content: "";
            }
          }
          .accordion-body {
            .job-detail {
              .button-job {
                display: flex;
                align-items: flex-start;
                width: 200px;
              }
            }
          }
        }

        .jobs-read-more {
          padding-top: 49px;
          gap: 49px;
        }

        .box-job {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          /* gap: 49px; */

          .accordion-head {
            justify-content: center;
          }

          .accordion-body {
            justify-content: center;
            align-items: center;
          }

          .is-open .accordion-body {
            /* max-height: 1500px; */
          }

          .is-open .accordion-head .toggle-icon {
            background: url("../asset/media/images/icons/icon-chevron-up.png");
            background-repeat: no-repeat, repeat;
            background-position: center;
            border-radius: 0;
            border: none;
          }

          .toggle-icon {
            background: url("../asset/media/images/icons/icon-chevron-down-black.png");
            background-repeat: no-repeat, repeat;
            background-position: center;
            background-size: contain;
            border-radius: 0;
            border: none;
            transform: translateX(-100px) translateY(-35px);
          }

          .job {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 49px;
          }
          .is-open .accordion-head .toggle-icon::after {
            content: "";
          }

          .toggle-icon::after {
            content: "";
            /* background: url('../asset/media/images/icons/icon-chevron-down-black.png'); */
          }

          .job-detail {
            display: flex;
            /* width: 1308px; */
            /* height: 1315px; */
            /* width: auto; */
            /* height: auto; */
            padding: 0px 64px;
            flex-direction: column;
            align-items: flex-start;
            gap: 28px;
            background: var(--bs-gray-100, #f8f9fa);

            ul {
              margin: unset;
              padding: 0 0 0 25px;
            }
            .button-job {
              display: flex;
              align-items: flex-start;
              width: 200px;
            }

            .job-description {
              display: flex;
              padding: 20px 0px 31px 14px;
              flex-direction: column;
              align-items: flex-start;
              gap: 33px;
            }
            .qualification {
              display: flex;
              padding: 20px 0px 14px 14px;
              flex-direction: column;
              align-items: flex-start;
              gap: 33px;
            }
            .title {
              color: var(--theme-colors-dark, #212529);
              text-align: center;
              font-family: Anuphan;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 48px;
              letter-spacing: 0.64px;
            }

            .body-job-description {
              color: var(--bs-gray-black, #000);
              font-family: Sukhumvit Set;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 34px; /* 170% */
              letter-spacing: 0.4px;

              .job-qualification {
                /* width: 1090px; */
              }

              .additional-info {
                width: 776px;
                /* height: 259px; */
              }
            }
          }

          .pagedown {
            display: flex;
            /* width: 1028px; */
            justify-content: space-between;
            align-items: center;

            .chevron {
              background-color: ${VARIABLES.COLOR.NEUTRAL8};
              color: ${VARIABLES.COLOR.NEUTRAL8};
              border: none;
              cursor: pointer;
            }
          }

          .text-job {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .text-heading {
              color: #000;
              font-family: Anuphan;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 34px;
              letter-spacing: 0.4px;
            }

            .text-body {
              color: var(--bs-gray-800, #343a40);
              font-family: Anuphan;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: 0.32px;
            }
          }
        }
      }

      @media (max-width: 430.98px) {
        padding: 0px;
        .accordion-wrapper {
          width: calc(100% - 80px);
          .accordion {
            .accordion-head {
              padding-left: 2px;
              padding-right: 2px;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
      @media (max-width: 430.98px) {
        .accordion-wrapper {
          .accordion {
            .accordion-body {
              .job-detail {
                .button-job {
                  width: 100%;
                  a {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
