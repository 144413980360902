import styled from 'styled-components'
import VARIABLES from '../../../../../../themes/variables'


// Wrapper
// ============================================================
export const ServiceCardStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: fit-content;

  img {
    width: 100%;
  }

  margin: 0 auto;
  .container-wrapper {
    margin: 0 auto;
    border: 1px solid transparent;
    width: 194px;
    height: 194px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .card {
      width: 110px;
      height: 110px;
      background-color: white;
      padding: 19px;
      border-radius: 40px 6px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    }
    .title {
      text-align: center;
      white-space: pre-wrap;
      ${VARIABLES.TYPOGRAPHYS.BODY._3}
    }
  }

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1024.98px) {
   
  }

  @media (max-width: 768.98px) {
   
  }

  @media (max-width: 425.98px) {
 
  }

  @media (max-width: 375.98px) {
    .container-wrapper {
      width: 164px;
      height: 164px;
      display: flex;
      .card {
        width: 100px;
        height: 100px;
        border-radius: 30px 6px;
      }
    }
  }

  @media (max-width: 320.98px) {
    .container-wrapper {
      width: 154px;
      height: 154px;
      display: flex;
      .card {
        width: 70px;
        height: 70px;
        border-radius: 30px 6px;
      }
    }
  }
`
