import StockPriceComponent from "../components/Pages/StockPrice";
import Auth from "../containerWarping/Auth";
const StockPrice = () => {
  return (
    <Auth>
      <StockPriceComponent />
    </Auth>
  );
};

export default StockPrice;
