import TestComponent from "../components/Pages/Test";
import Auth from "../containerWarping/Auth";
const Test = () => {
  return (
    <Auth>
      <TestComponent />
    </Auth>
  );
};

export default Test;
