import React, { useEffect, useState } from "react";
import { VisionAndMissionPageStyle } from "./styled";
import { PageHeader } from "../../Global";
import { VisionAndMission } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";

const VisionAndMissionComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const VisionAndMissionData = async () => {
      const Response = await VisionAndMission();
      setData(Response?.data);
    };

    VisionAndMissionData();
  }, []);

  return (
    <VisionAndMissionPageStyle
      contentMission={
        {
          isImage: data?.attributes?.image_mission?.data?.attributes?.url ? true : false
        }
      }
      contentVision={
        {
          isImage: data?.attributes?.image_vision?.data?.attributes?.url ? true : false
        }
      }
    >
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url && BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/about", text: translate["menu.about_us"] },
          { url: "/about/vision-and-mission", text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
        id="vision_and_mission_page_header"
      />

      <div className="page-content fluid">
        <div className="vision-and-mission">
          <div className="vision content">
            {
              data?.attributes?.image_vision?.data?.attributes?.url && (
                <div className="content-img-wrapper">
                  <img
                    className="content-img"
                    alt="vision-img"
                    src={
                      data?.attributes?.image_vision?.data?.attributes?.url &&
                      BASE_URL_UPLOAD + data?.attributes?.image_vision?.data?.attributes?.url
                    }
                  />
                </div>
              )
            }

            <div className="content-text vision">
              <ScrollAnimation
                animateIn="slideInLeft"
                animateOnce={true}
                className="text-bg"
              >
                <div className="content-text-bg"></div>
              </ScrollAnimation>

              <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
                <div className="content-text-inside-wrapper">
                  <div className="header">{data?.attributes?.topic_vision}</div>
                  <div className="description">
                    <ReactMarkdown
                      children={data?.attributes?.content_vision}
                      rehypePlugins={[rehypeRaw]}
                    ></ReactMarkdown>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="mission content">
            {
              data?.attributes?.image_mission?.data?.attributes?.url && (
                <div className="content-img-wrapper">
                  <img
                    className="content-img"
                    alt="vision-img"
                    src={
                      data?.attributes?.image_mission?.data?.attributes?.url &&
                      BASE_URL_UPLOAD + data?.attributes?.image_mission?.data?.attributes?.url
                    }
                  />
                </div>
              )
            }

            <div className="content-text mission">
              <ScrollAnimation
                animateIn="slideInRight"
                animateOnce={true}
                className="text-bg"
              >
                <div className="content-text-bg"></div>
              </ScrollAnimation>

              <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
                <div className="content-text-inside-wrapper">
                  <div className="header">
                    {data?.attributes?.topic_mission}
                  </div>
                  <div className="description">
                    <ReactMarkdown
                      children={data?.attributes?.content_mission}
                      rehypePlugins={[rehypeRaw]}
                    ></ReactMarkdown>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </VisionAndMissionPageStyle>
  );
};

export default VisionAndMissionComponent;
