import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const ShareholderMeetingDocumentsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    min-height: 645px;
  }
  
  .shareholder-meeting-documents {
    padding: 60px;

    .shareholder-meeting-documents-title,
    .shareholder-meeting-documents-footer {
      margin-bottom: 43px;
      display: flex;
      justify-content: space-between;

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
      }

      .select-wrapper {
        min-width: 155px;
        margin-left: 15px;
      }
    }
  }
  
 
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 700.98px) {
    .shareholder-meeting-documents {
      padding: 30px;
    }
  }

  @media (max-width: 450.98px) {
    .shareholder-meeting-documents {
      .shareholder-meeting-documents-title {
        display: block;
        .title {
          margin-bottom: 16px;
        }

        .select-wrapper {
          text-align: center;
        }
      }

      .shareholder-meeting-documents-footer {
        display: block;
        .title {
          margin-bottom: 16px;
        }

        .select-wrapper-page {
          text-align: center;
        }
      }
    }
  }
`
