import SocialResponsibilityReportComponent from "../components/Pages/SocialResponsibilityReport";
import Auth from "../containerWarping/Auth";
const SocialResponsibilityReport = () => {
  return (
    <Auth>
      <SocialResponsibilityReportComponent />
    </Auth>
  );
};

export default SocialResponsibilityReport
;
