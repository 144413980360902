import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const InvestorRelationsContactPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .investor-relations-contact {
    position: relative;

    .contact-location {
      padding: 48px 78px;
      display: flex;
      justify-content: center;

      .contact {
        position: relative;
        padding: 20px 25px;
        margin: 45px 0;
        background: url("../asset/media/images/contents/bg-ship.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px 0px 0px 12px;
        max-width: 637px;
        min-width: 400px;

        &::before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(27, 59, 106, 0.75);
          border-radius: 12px 0px 0px 12px;
        }

        .contact-title,
        .contact-content {
          position: relative;
          display: flex;
          color: #ffffff;
          z-index: 1;
        }

        .contact-title {
          margin: 36px 0;
          ${VARIABLES.TYPOGRAPHYS.BODY._1};
        }

        .contact-content {
          align-items: center;
          ${VARIABLES.TYPOGRAPHYS.BODY._9};

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 15px;
          }

          .icon,
          .content-text {
            margin-bottom: 36px;
          }

          &.google-maps {
            .icon {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            .icon,
            .content-text {
              margin-bottom: 36px;
            }

            .map {
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
        }
      }

      .location {
        position: relative;
        max-width: 654px;
        min-width: 400px;
        width: 100%;
        min-height: 300px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.15);
        }
      }
    }

    .contact-form {
      padding: 61px 118px;
      background-color: ${VARIABLES.COLOR.NEUTRAL7};

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
      }

      .sub-title {
        margin-top: 15px;
        ${VARIABLES.TYPOGRAPHYS.BODY._4};
        color: ${VARIABLES.COLOR.NEUTRAL10};
      }

      .title,
      .sub-title {
        text-align: center;
      }

      .form {
        /* margin-top: 30px; */
        margin: 30px auto auto auto;
        max-width: 1000px;
        /* padding: 0 57px; */

        .form-name-lastname,
        .form-ipone-email {
          display: flex;
        }

        .form-name,
        .form-lastname,
        .form-email,
        .form-ipone,
        .form-toppic,
        .form-message {
          width: 100%;
          margin-bottom: 15px;

          .field-wrapper {
            .input-wrapper,
            .textarea-wrapper {
              margin: 6px 0;

              .input,
              .textarea {
                border-color: ${VARIABLES.COLOR.NEUTRAL11};
                border-radius: 6px;
              }
            }
          }
        }

        .form-name,
        .form-ipone {
          margin-right: 15px;
        }
      }

      .btn-submit-message {
        display: flex;
        justify-content: center;

        .submit-message {
          max-width: 150px;
          min-width: 116px;
          ${VARIABLES.TYPOGRAPHYS.BODY._7};

          &:hover {
            background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 820.98px) {
    .investor-relations-contact {
      .contact-location {
        padding: 25px;
        display: block;

        .contact {
          margin: 15px 0;
          max-width: unset;
          min-width: unset;
          border-radius: 6px;

          &::before {
            border-radius: 6px;
          }

          .contact-title {
            margin: 24px 0;
          }

          .contact-content {
            .icon {
              width: 18px;
              height: 18px;
            }

            .icon,
            .content-text {
              margin-bottom: 24px;
            }

            &.google-maps {
              justify-content: space-between;

              .icon {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }

              .icon,
              .content-text {
                margin-bottom: 36px;
              }

              .map {
                justify-content: start;
              }
            }
          }
        }

        .location {
          max-width: unset;
          min-width: unset;
        }
      }

      .contact-form {
        padding: 30px 40px;

        .form {
          padding: 0;

          .form-name-lastname,
          .form-ipone-email {
            display: block;
          }
        }
      }
    }
  }

  @media (max-width: 400.98px) {
    .investor-relations-contact {
      .contact-location {
        .contact {
          .contact-content {
            &.google-maps {
              display: block;

              .icon,
              .content-text {
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }
  }
`;
