import service from "./service";

export * from "./fileService";
export * from "./service";
export * from "./coporateNEWSandCSR";
export * from "./financialAnnualReport";
export * from "./financials";
export * from "./home";
export * from "./newsActivities";
export * from "./policy";
export * from "./presentationCompanyAndOppDay";
export * from "./publications";
export * from "./shareholders";
export * from "./stockPrices";

export * from "./announcementService";
export * from "./corporateGovernanceService";
export * from "./ourBusinessService";
export * from "./workWithUsService";

export const FetchHeader = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-header-content", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const FetchFooter = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-footer-content", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const GetNavbarMenu = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/navbar-menu", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const Home = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-home", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const WeAreBioTec = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-we-are-biotec", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const VisionAndMission = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-vision-mission", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const OurBusiness = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/our-businesses", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ChairmanMessages = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/chairman-messages", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PageChairmanMessages = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-chairman-message", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesAssociatedCompany = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-associated-company", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const AssociatedCompany = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get("/associated-companies", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesBusinessStructure = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-business-structure", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesManagementStructure = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get(
      "/pages-management-structure",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const SustainabilityPolicy = async (section, year, data = {}) => {
  try {
    let pathFilter = `/sustainability-policy-documents?filters[policy_date][$gte]=${year}-01-01&filters[policy_date][$lte]=${year}-12-31`;
    let path =
      section === "page-title" ? "/pages-sustainability-policy" : pathFilter;
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(path, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const SustainabilityPolicyOfDate = async () => {
  try {
    const payload = {
      params: {
        "fields[0]": "policy_date",
      },
    };
    const response = await service().get(
      "/sustainability-policy-documents",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const SustainabilityTarget = async (section, data = {}) => {
  try {
    let path =
      section === "page-title"
        ? "/pages-sustainability-target"
        : "/sustainability-target-lists";

    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(path, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesCsrReport = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-csr-report", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CsrReportFiles = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get("/csr-report-files", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ManagementSocial = async (section, data = {}) => {
  try {
    let path =
      section === "page-title"
        ? "/pages-management-social"
        : "/management-social-lists";
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(path, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesManagementEnvironmental = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get(
      "/pages-management-environmental",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const ManagementEnvironmental = async (section, data = {}) => {
  try {
    let path =
      section === "page-title"
        ? "/pages-management-environmental"
        : "/management-environmental-lists";
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(path, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesIrContact = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-ir-contact", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const BusinessValueChain = async (section, data = {}) => {
  try {
    let path =
      section === "page-title"
        ? "/pages-business-value-chain"
        : "/business-value-chain-lists";
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(path, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const Charter = async (section, year) => {
  try {
    let pathFilter = `/charter-files?populate=*&filters[date][$gt]=${
      year - 1
    }-12-31&filters[date][$lt]=${year + 1}-01-01&sort[createdAt]=desc`;
    let path =
      section === "page-title" ? "/pages-charter?populate=*" : pathFilter;
    const response = await service().get(path);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const BusinessEthicsFiles = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get("/business-ethics-files", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const GetDateOfCharter = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        "fields[0]": "date",
      },
    };
    const response = await service().get("/charter-files", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const corporateDocuments = async (section, data = {}) => {
  try {
    let path =
      section === "page-title"
        ? "/pages-corparate-document"
        : "/corparate-documents";
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(path, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const IrContact = async (payload) => {
  try {
    const response = await service().post("/ir-contacts", { data: payload });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const directorPersonnels = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
        "sort[0]": "id",
      },
    };
    const response = await service().get("/director-personnels", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesCorporateGovernance = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get(
      "/pages-corporate-governance",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const pagesDirector = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-director", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CorporateGovernanceLists = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    };
    const response = await service().get(
      "/corporate-governance-lists",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const auditPersonnels = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
        "sort[0]": "id",
      },
    };
    const response = await service().get("/audit-personnels", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const pagesAudit = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-audit", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesContactUs = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-contact-us", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const CtUs = async (payload) => {
  try {
    const response = await service().post("/contacts-us", { data: payload });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesSubscription = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/pages-subscription", payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const SubscriptionEmail = async (payload) => {
  try {
    const response = await service().post("/subscription-emails", {
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
