import styled from 'styled-components'
import VARIABLES from '../../../../../../themes/variables'


// Wrapper
// ============================================================
export const OneImageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: fit-content;

  img {
    width: 100%;
  }

  .container-wrapper {
    width: 460px;
    height: 348px;
    display: flex;
    position: relative;
    
    .image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 432px;
      height: 327px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 120px 12px;
        object-fit: cover;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
      } 
    } 
    .shadow-border {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 120px 12px;
      width: 432px;
      height: 327px;
      border: 4px solid ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    }
  }

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  /* @media (max-width: 1200.98px) {
    .container-wrapper {
      width: 360px;
      height: 248px;
      .image {
        width: 332px;
        height: 227px;
        img {
          border-radius: 60px 12px;
        }
      }
      .shadow-border {
        width: 332px;
        height: 227px;
        border-radius: 60px 12px;
      }
    }
  } */
  
  @media (max-width: 1024.98px) {
   
  }

  @media (max-width: 768.98px) {
   
  }

  /* @media (max-width: 425.98px) {
    .container-wrapper {
      width: 360px;
      height: 248px;
      .image {
        width: 332px;
        height: 227px;
        img {
          border-radius: 60px 12px;
        }
      }
      .shadow-border {
        width: 332px;
        height: 227px;
        border-radius: 60px 12px;
      }
    }
  } */

  /* @media (max-width: 375.98px) {
    .container-wrapper {
      width: 340px;
      height: 228px;
      .image {
        width: 312px;
        height: 207px;
      }
      .shadow-border {
        width: 312px;
        height: 207px;
      }
    }
  } */

  @media (max-width: 1200.98px) {
    .container-wrapper {
      width: 300px;
      height: 188px;
      .image {
        width: 272px;
        height: 167px;
        img {
          border-radius: 40px 8px;
        }
      }
      .shadow-border {
        width: 272px;
        height: 167px;
        border-radius: 40px 8px;
      }
    }
  }

  @media (max-width: 360.98px) {
    .container-wrapper {
      width: 200px;
      height: 128px;
      .image {
        width: 190px;
        height: 117px;
        img {
          border-radius: 40px 8px;
        }
      }
      .shadow-border {
        width: 190px;
        height: 117px;
        border-radius: 40px 8px;
      }
    }
  }
`
