import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../constants/routes";
import { DavidendPolicyStyle } from "./styled";
import { PageHeader, CardDownloadFile } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import {
  PagesShareholderDividendPolicy,
  ShareholderDividendPolicyFiles,
} from "./../../../Service/shareholders";
import { getBaseUploadUrl } from "./../../../Service/service";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  displayDate,
  displayYear,
  displayYearBuddhistEra,
  removeDuplicates,
} from "./../../../helpers";
import { langTh } from "../../../store/lang";

const DavidendPolicyComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [content, setContent] = useState([]);
  const [date, setDate] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const DavidendPolicy = async () => {
      try {
        const ResPage = await PagesShareholderDividendPolicy();
        setData(ResPage?.data);

        const payloadDate = {
          "fields[0]": "date",
          "sort[date]": "desc",
        };
        const ResDate = await ShareholderDividendPolicyFiles(payloadDate);
        setDate(ResDate?.data);

        const payload = {
          "sort[date]": "desc",
          "sort[id]": "desc",
        };
        const ResDividend = await ShareholderDividendPolicyFiles(payload);
        setContent(ResDividend?.data);

        setIsMounted(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    DavidendPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const DavidendPolicy = async () => {
      const payload = {
        "filters[date][$gte]": `${selectedYear}-01-01`,
        "filters[date][$lte]": `${selectedYear}-12-31`,
        "sort[date]": "desc",
        "sort[id]": "desc",
      };
      const resContent = await ShareholderDividendPolicyFiles(payload);
      setContent(resContent?.data);
    };

    selectedYear && DavidendPolicy();
  }, [selectedYear]);

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({
        value: e,
        label: lang === langTh ? displayYearBuddhistEra(e) : e,
      })
    );

    setOptionYears(yearsData);
    yearsData[0] && onChangeYear(yearsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onChangeYear = (e) => {
    setSelectedYear(e.value);
  };

  return (
    <DavidendPolicyStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.information_for_shareholders"] },
          {
            url: ROUTE_PATH.DAVIDEND_POLICY.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="page-content-wrapper">
        <div className="content-wrapper">
          <div className="davidend-policy-text-head ">
            <div className="head">
              <ReactMarkdown
                children={data?.attributes?.content_description
                  ?.replaceAll("\n", "<br/>")
                  ?.replaceAll(
                    "ต่ำ",
                    "<span className='change-font'>ต่ำ</span>"
                  )}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            </div>
          </div>
          <div className="davidend-policy">
            <div className="davidend-policy-title">
              <div className="title">
                {translate["davidend_policy.dividend_policy_document"]}
              </div>
              <div className="select-wrapper">
                <span>{translate["common.year"]}</span>
                {isMounted && (
                  <SelectBox
                    placeholder={optionYears[0]?.label}
                    defaultValue={optionYears[0]}
                    options={optionYears}
                    onChange={(e) => onChangeYear(e)}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>
            {content &&
              content?.map((data, i) => {
                const file = data?.attributes?.file?.data;
                return (
                  <CardDownloadFile
                    key={i}
                    id={data.id}
                    title={data?.attributes?.file_name}
                    date={`${displayDate(data?.attributes?.date, "DD/MM")}/${
                      lang === langTh
                        ? displayYearBuddhistEra(
                            new Date(data?.attributes?.date)
                          )
                        : displayYear(data?.attributes?.date)
                    }`}
                    url={file?.attributes?.url}
                    fileName={file?.attributes?.name}
                    btnName={translate["common.download_document"]}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </DavidendPolicyStyle>
  );
};

export default DavidendPolicyComponent;
