import styled from 'styled-components'
//import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const ImageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: 25px;
  height: auto;

  /* Child element styles
  ------------------------------- */
  .image {
    width: 100%;
    height: auto;
  }

  /* Modifiers
  ------------------------------- */
  &.is_circle {
    border-radius: 50%;
    overflow: hidden;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
