import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const FooterStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    display: flex;
    width: auto;
    min-height: 350px;
    padding: 45px 0 32px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-image: url("../asset/media/images/contents/footer.png");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
    position: relative;

  /* Child element styles
  ------------------------------- */
  .footer-inner-wrapper {
    display: flex;
    width: 100%;
    padding: 0px 64px;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;

    .company-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 16px;
    }

    .company-info {
      width: 100%;
      max-width: 560px;
      padding-right: 10px;
      padding-bottom: 15px;

      .company-name {
        color: white;
        ${VARIABLES.TYPOGRAPHYS.TITLE._7}
      }

      .company-address {
        margin-top: 16px;
        color: white;
        ${VARIABLES.TYPOGRAPHYS.BODY._6}
      }
    }

    .contact-number {
      display: flex;
      max-width: 400px;
      width: 100%;
      justify-content: space-between;

      .phone-number,
      .fax-number {
        width: 100%;
        max-width: 185px;
        padding-right: 16px;
        padding-bottom: 16px;
      }

      .label {
        color: white;
        ${VARIABLES.TYPOGRAPHYS.TITLE._7}
      }

      .number {
        color: white;
        ${VARIABLES.TYPOGRAPHYS.BODY._6}
        margin-top: 16px;
      }
    }
  }

  .join-us {
    flex: 1 1 auto;
    max-width: 360px;
    padding-bottom: 16px;

    .title {
      color: white;
      ${VARIABLES.TYPOGRAPHYS.TITLE._7}
    }

    .email-sumbit {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 16px;

      .email-input {
        width: 100%;
        flex: 1 1 auto;
        border-radius: 6px;

        .input {
          color: ${VARIABLES.COLOR.NEUTRAL9};
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
          border-radius: 6px;
        }
      }

      .submit-email-button {
        flex: 0 0 102px;
        margin-left: 16px;
        white-space: nowrap;
        padding: 0;
        border-radius: 6px;
      }
    }
  }

  .copyright-and-policy {
    display: flex;
    border-top: 1px solid white;
    width: 100%;
    padding: 27px 0 0;
    justify-content: space-between;
    flex-wrap: wrap;

    .copy-right {
      color: white;
      ${VARIABLES.TYPOGRAPHYS.BODY._6}
      padding-top: 5px;
      padding-bottom: 16px;
    }

    .policies {
      display: flex;
      color: white;
      ${VARIABLES.TYPOGRAPHYS.BODY._6}
      padding-bottom: 16px;

      .link {
        padding: 5px 16px 5px 0;

        &:hover {
          color: ${VARIABLES.COLOR.SECONDARY_HOVER};
        }
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    opacity: 0.7;
    z-index: 2;
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 767.98px) {
    .footer-inner-wrapper {
      padding: 0px 50px;

      .company-wrapper {
        .company-info {
          max-width: 100%;
        }
      }
      .join-us {
        .email-sumbit {
          display: block;
    
          .submit-email-button {
            margin: 0;
            margin-top: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .footer-inner-wrapper {
      padding: 0px 30px;
    }

  }
`
