import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import { HomeInvestorStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import { Button, Modal } from "../../Global";
import AnnCard from "./components/AnnCard";
import CardLatestReleases from "./components/CardLatestReleases";
import { useDispatch, useSelector } from "react-redux";
import CanvasJSReact from "@canvasjs/react-charts";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { Link } from "react-router-dom";

import { SaveFile, DownloadZipFile } from "./../../../Service/API";
import { getBaseUploadUrl } from "./../../../Service/service";

import { PagesInvestorRelation } from "./../../../Service/home";
import {
  PagesIrPressRelease,
  GetNewsSet,
} from "./../../../Service/newsActivities";
import { GetChartQuotation } from "./../../../Service/stockPrices";
import {
  OneReportFiles,
  CompanySnapshotFiles,
} from "./../../../Service/publications";
import {
  FinancialAnnualReport,
  FinancialSummaryFiles,
  FinancialManagementAnalysisFiles,
} from "./../../../Service/financialAnnualReport";
import {
  GetFinancialData,
  PagesFinancialHighlight,
} from "./../../../Service/financials";
import {
  PagesShareholderIrCalendar,
  ShareholderIrEvents,
} from "./../../../Service/shareholders";

import {
  scrollToTop,
  displayFullDateBuddhistEra,
  displayDateBuddhistEra,
  displayDate,
  displayTime,
  removeDuplicates,
  displayNumber,
  sortArray,
  addDays,
  displayYearBuddhistEra,
  setSessionStorage,
  getSessionStorage,
  displayYear,
  displayTimeEn,
} from "./../../../helpers";
import { langTh } from "../../../store/lang";

const HomeInvestorComponent = () => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const lang = useSelector((state) => state.lang.value);

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const dispatch = useDispatch();
  const count = 3;

  const currentDate = new Date();
  const numberOfMlSeconds = currentDate.getTime();

  const hour = 60 * 60 * 1000;
  const day = 24 * hour;
  const week = 7 * day;
  const week52 = 52 * week;

  const dateOfLastYear = new Date(numberOfMlSeconds - week52);

  const [currentAnnData, setCurrentAnnData] = useState(1);
  const sliderAnnRef = useRef(null);

  const [homeData, setHomeData] = useState({});
  const [newsPage, setNewsPage] = useState({});
  const [newsData, setNewsData] = useState([]);
  const [financialData, setFinancialData] = useState({});
  const [financialDataPoint, setFinancialDataPoint] = useState({});
  const [reportData, setReportData] = useState([]);
  const [shareholderData, setShareholderData] = useState({});
  const [investorKitsData, setInvestorKitsData] = useState({});

  const [openModalCalendar, setOpenModalCalendar] = useState({
    isOpen: false,
  });

  const [stockPriceSummary, setStockPriceSummary] = useState({});
  const [stockPrice52WeekData, setStockPrice52WeekData] = useState({});
  const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const CallAPIGetData = async () => {
      try {
        const resHome = await PagesInvestorRelation();
        setHomeData(resHome?.data);

        //! Stock price
        getStockPrice();

        //! News
        getNews();

        //! Financial Highlight
        getFinancialHighlight();

        //! Report
        getReportData();

        //! Investor Kits
        getInvestorKits();

        //! Shareholders Meeting
        getShareholdersData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    CallAPIGetData();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shareholderData.events && shareholderData.events.length > 0) {
      const getSessionCalendar = getSessionStorage(
        "shareholder-calendar-popup",
        true
      );
      if (!getSessionCalendar) {
        setOpenModalCalendar({ isOpen: true });
      }
    }
  }, [shareholderData]);

  const getStockPrice = async () => {
    const payloadStockPrice = {
      period: "5Y",
    };
    const resStockPrice = await GetChartQuotation(payloadStockPrice);
    resStockPrice?.result?.quotations &&
      handleSetGraphData(resStockPrice?.result);
  };

  const getNews = async () => {
    const resNewsPage = await PagesIrPressRelease();
    setNewsPage(resNewsPage?.data);

    const payloadNews = { limit: 20 };
    const resNews = await GetNewsSet(payloadNews);

    let newsList = [];
    resNews?.result?.newsInfoList?.forEach((item) => {
      if (item?.tag === "" && newsList?.length < 5) {
        newsList.push(item);
      }
    });
    setNewsData(newsList);
  };

  const getFinancialHighlight = async () => {
    const resPage = await PagesFinancialHighlight();
    const resFinancial = await GetFinancialData();
    let data = sortArray(resFinancial?.result?.shareholder, "year", "desc");
    data?.map((item) => {
      item.displayYear =
        lang === langTh
          ? displayYearBuddhistEra(new Date(`${item.year}-01-01`))
          : displayYear(new Date(`${item.year}-01-01`));
      return item;
    });

    data = sortArray(data, "year", "desc");

    // Summary
    const summary = {
      displayYear: data[0].displayYear,
      year: data[0].year,
      totalRevenue: data[0].totalRevenue * 1000,
      totalAsset: data[0].totalAsset * 1000,
      totalLiability: data[0].totalLiability * 1000,
      netProfit: data[0].netProfit * 1000,
    };

    // Chart
    let dataPointsRevenue = [];
    let dataPointsAsset = [];
    let dataPointsNetProfit = [];

    const colors = ["#1B3B6A", "#1246A9", "#C3D8FF"];
    data?.forEach((item, index) => {
      if (index < 3) {
        const labelText = index === 0
          ? `${item.quarter}/${item.displayYear}`
          : item.displayYear

        dataPointsRevenue.push({
          label: labelText,
          y: Number((item.totalRevenue * 0.001).toFixed(2)),
          indexLabel: displayNumber(
            Number((item.totalRevenue * 0.001).toFixed(2))
          ),
          color: colors[index],
        });

        dataPointsAsset.push({
          label: labelText,
          y: Number((item.totalAsset * 0.001).toFixed(2)),
          indexLabel: displayNumber(
            Number((item.totalAsset * 0.001).toFixed(2))
          ),
          color: colors[index],
        });

        dataPointsNetProfit.push({
          label: labelText,
          y: Number((item.netProfit * 0.001).toFixed(2)),
          indexLabel: displayNumber(
            Number((item.netProfit * 0.001).toFixed(2))
          ),
          color: colors[index],
        });
      }
    });

    // min, max total revenue
    const maxRevenue = sortArray([...dataPointsRevenue], "y", "desc")[0].y + 200;
    const minRevenue = sortArray([...dataPointsRevenue], "y", "asc")[0].y + (-100);

    // min, max asset
    const maxAsset = sortArray([...dataPointsAsset], "y", "desc")[0].y + 200;
    const minAsset = sortArray([...dataPointsAsset], "y", "asc")[0].y + (-100);

    // min, max net profit
    const maxNetProfit = sortArray([...dataPointsNetProfit], "y", "desc")[0].y + 200;
    const minNetProfit = sortArray([...dataPointsNetProfit], "y", "asc")[0].y + (-100);

    const chartData = {
      revenue: sortArray(dataPointsRevenue, "label"),
      asset: sortArray(dataPointsAsset, "label"),
      netProfit: sortArray(dataPointsNetProfit, "label"),
      maxRevenue,
      minRevenue: minRevenue > 0 ? 0 : minRevenue,
      maxAsset,
      minAsset: minAsset > 0 ? 0 : minAsset,
      maxNetProfit,
      minNetProfit: minNetProfit > 0 ? 0 : minNetProfit,
    };
    setFinancialDataPoint(chartData);

    // Ratings

    // Set financial data
    const setData = {
      page: resPage?.data,
      summary,
      rating: {},
    };
    setFinancialData(setData);
  };

  const getReportData = async () => {
    let reports = [];
    const payload = {
      "sort[date]": "desc",
    };
    const resOneReport = await OneReportFiles(payload);
    reports.push({
      ...resOneReport?.data[0],
      link: ROUTE_PATH.ONE_REPORT.LINK,
    });

    const resAnnualReport = await FinancialAnnualReport(payload);
    reports.push({
      ...resAnnualReport?.data[0],
      link: ROUTE_PATH.ANNUAL_REPORT.LINK,
    });

    const resSummaryFilesReport = await FinancialSummaryFiles(payload);
    reports.push({
      ...resSummaryFilesReport?.data[0],
      link: ROUTE_PATH.QUARTERLY_FINANCIAL_SUMMARY.LINK,
    });

    setReportData(reports);
  };

  const getInvestorKits = async () => {
    const InvestorDocument = async () => {
      const currentYear = displayDate(currentDate, "YYYY");
      try {
        const payload = {
          "filters[date][$gte]": `${currentYear}-01-01`,
          "filters[date][$lte]": `${currentYear}-12-31`,
        };

        const resOne = await OneReportFiles(payload);
        const resQuarterly = await FinancialSummaryFiles(payload);
        const resDiscussion = await FinancialManagementAnalysisFiles({
          ...payload,
          "sort[0]": "date",
        });
        const resSnapshot = await CompanySnapshotFiles({
          ...payload,
          "sort[date]": "desc"
        });

        // Set data
        const reports = {
          oneReport: resOne?.data,
          quarterly: resQuarterly?.data,
          discussion: resDiscussion?.data,
          snapshot: resSnapshot?.data,
        };

        setInvestorKitsData(reports);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    InvestorDocument();
  };

  const getShareholdersData = async () => {
    const resPage = await PagesShareholderIrCalendar();

    const dateNow = new Date();
    const next14Day = addDays(dateNow, 14);
    const payload = {
      "filters[event_date][$gte]": `${displayDate(dateNow, "YYYY-MM-DD")}`,
      "filters[event_date][$lte]": `${displayDate(next14Day, "YYYY-MM-DD")}`,
      "sort[event_date]": "asc",
      populate: "*",
      "pagination[page]": 1,
      "pagination[pageSize]": 3,
    };
    const resEvents = await ShareholderIrEvents(payload);

    let mapEvents = resEvents?.data;
    mapEvents?.map((item) => {
      item.attributes.display_date = `
        ${lang === langTh
          ? displayFullDateBuddhistEra(item?.attributes?.event_date) +
          " เวลา " +
          displayTime(item?.attributes?.event_date, "HH:mm") +
          " น."
          : displayDate(item?.attributes?.event_date, "D MMM YYYY") +
          " Time " +
          displayTime(item?.attributes?.event_date, "HH:mm") +
          " hrs."
        }
      `;

      return item;
    });

    setShareholderData({
      data: resPage?.data,
      events: mapEvents || [],
    });
  };

  const handleSetGraphData = (params) => {
    const data = [...params?.quotations];
    let dateList = [];

    data?.forEach((item) => {
      if (new Date(item?.timestamp * 1000) >= new Date(dateOfLastYear)) {
        dateList.push(displayDate(new Date(item?.timestamp * 1000)));
      }
    });

    let lineDataPoints = [];
    let barDataPoints = [];
    dateList = removeDuplicates(dateList);
    dateList?.forEach((date) => {
      const findData = data?.find(
        (r) => date === displayDate(new Date(r?.timestamp * 1000))
      );

      const last_close = findData?.last_close || 0;
      lineDataPoints.push({
        x: new Date(date),
        y: last_close,
      });

      const volume = +findData?.volume || 0;
      barDataPoints.push({
        x: new Date(date),
        y: volume,
      });
    });

    setLineChartData(lineDataPoints);
    setBarChartData(barDataPoints);

    handleSetSummaryData(data, params);
    handleSetDataOf52Week(data);
  };

  const handleSetSummaryData = (data, params) => {
    let dateList = [];

    data?.map((item) => {
      const date = new Date(item.timestamp * 1000);
      const date_display =
        lang === langTh
          ? `${displayDateBuddhistEra(new Date(item.timestamp * 1000))} ${displayTime(new Date(item.timestamp * 1000), "HH.mm")} น.`
          : `${displayDate(new Date(item.timestamp * 1000), "D MMM YYYY")} ${displayTimeEn(new Date(item.timestamp * 1000))}`

      item.date = date;
      item.date_display = date_display;
      item.change_volume =
        ((item.last_min + item.last_max) / 2) * (item.change_precent / 100);

      dateList.push(displayDate(date));
      return item;
    });

    // Group by date
    const getLastDate = removeDuplicates([...dateList])[0];
    const lastDateList = data?.filter(
      (r) => displayDate(r.date) === getLastDate
    );

    const dateLength = data?.filter(
      (r) => displayDate(r.date) === getLastDate
    )?.length;

    // Set data
    const last_min = sortArray(lastDateList, "last_min")[0]?.last_min;
    const last_max = sortArray(lastDateList, "last_max", "desc")[0]?.last_max;
    const last_open = sortArray(lastDateList, "last_open", "desc")[0]
      ?.last_open;
    const last_close = sortArray(lastDateList, "last_close", "desc")[0]
      ?.last_close;

    const change_volume =
      lastDateList?.reduce((sum, obj) => sum + Number(obj.change_volume), 0) /
      dateLength;
    const change_precent =
      lastDateList?.reduce((sum, obj) => sum + Number(obj.change_precent), 0) /
      dateLength;
    const volume = lastDateList?.reduce(
      (sum, obj) => sum + Number(obj.volume),
      0
    );

    const dateTimeDisplay =
      lang === langTh
        ? `${displayDateBuddhistEra(new Date(params?.modified))} ${displayTime(new Date(params?.modified), "HH.mm")} น.`
        : `${displayDate(new Date(params?.modified), "D MMM YYYY")} ${displayTimeEn(new Date(params?.modified))}`

    setStockPriceSummary({
      date_display: dateTimeDisplay,
      last_min,
      last_max,
      last_open,
      last_close,
      change_volume,
      change_precent,
      volume,
    });
  };

  const handleSetDataOf52Week = (data) => {
    // Set data of day
    let dayList = [];
    data?.forEach((item) => {
      dayList.push(displayDate(new Date(item.timestamp * 1000)));
    });

    // Set data of 52 week
    let dateOf52WeekList = [];
    dayList = removeDuplicates(dayList);
    dayList?.forEach((item, index) => {
      if (new Date(item) >= new Date(dateOfLastYear)) {
        dateOf52WeekList.push({
          date: item,
          last_min: data[index].last_min,
          last_max: data[index].last_max,
        });
      }
    });

    const sortMin = sortArray([...dateOf52WeekList], "last_min");
    const sortMax = sortArray([...dateOf52WeekList], "last_max", "desc");

    const minPrice52week = sortMin[0]?.last_min;
    const maxPrice52week = sortMax[0]?.last_max;

    setStockPrice52WeekData({
      minPrice52week,
      maxPrice52week,
    });
  };

  const dataM = [
    {
      img: "../asset/media/images/icons/icon-documents-amico.svg",
      cardName: "แบบ 56-1 One Reportปี 2565",
      cardDate: "เผยแพร่เมื่อ 16 .ก.พ 2565",
    },
    {
      img: "../asset/media/images/icons/icon-documents-bro.svg",
      cardName: "บริษัทจดทะเบียนพบผู้ลงทุน ประจำปี 2565",
      cardDate: "เผยแพร่เมื่อ 16 .ก.พ 2565",
    },
    {
      img: "../asset/media/images/icons/icon-documents-pana.svg",
      cardName: "งบประมาณประจำปี 2566",
      cardDate: "เผยแพร่เมื่อ 16 .ก.พ 2565",
    },
  ];

  const AnnData = [
    {
      date: "29 ธันวาคม 2532",
      icon: "../asset/media/images/icons/icon-ann-people.svg",
      name: "แปรสภาพเป็นบริษัทมหาชน บริษัท จุฑานาวี จำกัด (มหาชน) และเข้าเป็นบริษัทจดทะเบียนในตลาดหลักทรัพย์แห่งประเทศไทย (SET) ด้วยทุนจดทะเบียนเริ่มต้น จำนวน 40 ล้านบาท",
    },
    {
      date: "6 กุมภาพันธ์ 2557",
      icon: "../asset/media/images/icons/icon-ann-graph.svg",
      name: "เพิ่มทุนจดทะเบียนของบริษัท เป็นจำนวน 350.70 ล้านบาท ",
    },
    {
      date: "2 กุมภาพันธ์ 2564",
      icon: "../asset/media/images/icons/icon-ann-money.svg",
      name: "ทุนจดทะเบียนของบริษัท ณ วันที่ 2 กุมภาพันธ์ 2564 จำนวน 1,274,281,233.00 บาท",
    },
    {
      date: "2 กุมภาพันธ์ 2564",
      icon: "../asset/media/images/icons/icon-ann-money.svg",
      name: "ทุนจดทะเบียนของบริษัท ณ วันที่ 2 กุมภาพันธ์ 2564 จำนวน 1,274,281,233.00 บาท",
    },
    {
      date: "6 กุมภาพันธ์ 2557",
      icon: "../asset/media/images/icons/icon-ann-graph.svg",
      name: "เพิ่มทุนจดทะเบียนของบริษัท เป็นจำนวน 350.70 ล้านบาท ",
    },
  ];

  const optionsChart = {
    animationEnabled: true,
    theme: "light2",
    axisX: {
      valueFormatString: "DD MMM",
    },
  };

  const optionsLineChart = {
    ...optionsChart,
    data: [
      {
        axisYType: "secondary",
        type: "line",
        color: "#1B3B6A",
        indexLabelFontSize: 16,
        markerType: "none",
        dataPoints: lineChartData,
      },
    ],
  };

  const optionsBarChart = {
    ...optionsChart,
    exportEnabled: false,
    axisY: {
      includeZero: true,
    },
    data: [
      {
        axisYType: "secondary",
        type: "column",
        color: "#1B3B6A",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: barChartData,
      },
    ],
  };

  const optionsExchange = {
    axisX: {
      lineThickness: 0,
      lineColor: "#8492A4",
      tickLength: 0,
      labelFontSize: 11,
      labelFontWeight: "bold",
      labelFontColor: "#8492A4",
    },
    axisY: {
      lineThickness: 0,
      tickLength: 0,
      labelFormatter: () => "",
      gridColor: "#e0e0e0",
      gridThickness: 0,
      stripLines: [
        {
          value: 0,
          showOnTop: true,
          color: "gray",
          thickness: 2
        }
      ]
    },
  };

  const optionsRevenue = {
    ...optionsExchange,
    axisY: {
      ...optionsExchange.axisY,
      maximum: financialDataPoint?.maxRevenue,
      minimum: financialDataPoint?.minRevenue,
      stripLines: [
        {
          ...optionsExchange.axisY.stripLines[0],
          thickness: 2
        }
      ],
    },
    data: [
      {
        indexLabelFontColor: "#000",
        // indexLabelFontColor: "#263238",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: financialDataPoint.revenue,
        backgroundColor: "transparent",
      },
    ],
  };

  const optionsAsset = {
    ...optionsExchange,
    axisY: {
      ...optionsExchange.axisY,
      maximum: financialDataPoint?.maxAsset,
      minimum: financialDataPoint?.minAsset,
      stripLines: [
        {
          ...optionsExchange.axisY.stripLines[0],
          thickness: 2
        }
      ],
    },
    data: [
      {
        indexLabelFontColor: "#000",
        // indexLabelFontColor: "#263238",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: financialDataPoint.asset,
        backgroundColor: "transparent",
      },
    ],
  };

  const optionsNetProfit = {
    ...optionsExchange,
    axisY: {
      ...optionsExchange.axisY,
      maximum: financialDataPoint?.maxNetProfit,
      minimum: financialDataPoint?.minNetProfit,
      stripLines: [
        {
          ...optionsExchange.axisY.stripLines[0],
          thickness: 1
        }
      ],
      // labelFontWeight: 600,
    },
    data: [
      {
        indexLabelFontColor: "#000",
        type: "column",
        indexLabelFontWeight: "bold",
        dataPoints: financialDataPoint.netProfit,
        backgroundColor: "transparent",
      },
    ],
  };

  const slidesToShowCondition = () => {
    if (windowWidth > 1300) {
      return 3;
    } else if (windowWidth <= 1300 && windowWidth > 767) {
      return 2;
    } else if (windowWidth <= 767) {
      return 1;
    }
  };

  const AnnSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow: slidesToShowCondition(),
    slidesToScroll: 1,
    initialSlide: 0,
    // responsive: [
    //   { breakpoint: 1020,
    //     settings: { slidesToShow: 2 }
    //   },
    //   { breakpoint: 800,
    //     settings: { slidesToShow: 1 }
    //   },
    // ]
  };

  const handleSlideChange = (index) => {
    // Check which slide is in the middle
    const middleSlideIndex = Math.floor(count / 2);
    if (index === middleSlideIndex) {
    }
  };

  const prevAnnSlide = () => {
    sliderAnnRef.current.slickPrev();
    setTimeout(() => {
      if (currentAnnData > 1) {
        setCurrentAnnData(currentAnnData - 1);
      }
    }, 500);
  };

  const nextAnnSlide = () => {
    sliderAnnRef.current.slickNext();
    setTimeout(() => {
      if (currentAnnData < newsData.length) {
        setCurrentAnnData(currentAnnData + 1);
      }
    }, 500);
  };

  const downloadInvestorKits = async () => {
    let files = [];

    investorKitsData?.oneReport?.forEach((item, index) => {
      if (index === 0) {
        files.push(
          BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
        );
      }
    });

    investorKitsData?.quarterly?.forEach((item, index) => {
      files.push(
        BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
      );
    });

    investorKitsData?.discussion?.forEach((item, index) => {
      files.push(
        BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
      );
    });

    investorKitsData?.snapshot?.forEach((item, index) => {
      files.push(
        BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
      );
    });

    await DownloadZipFile(
      files,
      `investor-kits-${lang === langTh
        ? displayYearBuddhistEra(currentDate)
        : displayYear(currentDate)
      }`
    );
  };

  const handleBottomPage = () => {
    window.scrollTo({ top: window.innerHeight, left: 0, behavior: "smooth" });
  };

  return (
    <HomeInvestorStyle>
      <div className="block-banner-investor">
        <div className="title-wrapper">
          <p className="title">{homeData?.attributes?.banner_title}</p>
        </div>

        {homeData?.attributes?.investor_media?.data?.attributes?.ext ===
          ".mp4" ? (
          <video className="video-banner" preload="auto"
            muted=""
            autoplay="true"
            loop="true"
            playsinline=""
            alt="video-banner-biotec"
            src={
              homeData?.attributes?.investor_media?.data?.attributes?.url &&
              BASE_URL_UPLOAD +
              homeData?.attributes?.investor_media?.data?.attributes?.url
            }>
          </video>
        ) : (
          <img
            className="img-banner"
            src={
              homeData?.attributes?.investor_media?.data?.attributes?.url &&
              BASE_URL_UPLOAD +
              homeData?.attributes?.investor_media?.data?.attributes?.url
            }
            alt={
              homeData?.attributes?.investor_media?.data?.attributes
                ?.alternativeText
            }
          />
        )}
        <div className="content-wrapper set-biotec">
          {/* Stock price */}
          <div className="block-set-biotec">
            <div className="datas-chart-item">
              <div className="first-item-left">
                <p className="left">SET : BIOTEC</p>
              </div>
              <div className="first-item-middle">
                <p className="middle">
                  {stockPriceSummary?.last_close?.toFixed(2)} <span>THB</span>
                </p>
              </div>
              <div className="first-item-right">
                <p
                  className={
                    stockPriceSummary?.change_precent < 0
                      ? "right-minus"
                      : "right"
                  }
                >
                  {stockPriceSummary?.change_precent >= 0 ? "+" : ""}
                  {stockPriceSummary?.change_volume === 0
                    ? "0.00 "
                    : `${stockPriceSummary?.change_volume?.toFixed(2)} `}
                  ({stockPriceSummary?.change_precent?.toFixed(2)}%)
                </p>
              </div>
            </div>
            <div className="datas-chart-item">
              <div className="second-item-left">
                <p className="top">
                  {translate["home_investor.trading_volume_shares"]}
                </p>
                <p className="button">
                  {displayNumber(
                    Number(stockPriceSummary?.volume)?.toFixed(2)
                  )}
                </p>
              </div>
              <div className="second-item-middle">
                <p className="top">
                  {translate["home_investor.intraday_price_range"]}
                </p>
                <p className="button">
                  {stockPriceSummary?.last_min?.toFixed(2)} -{" "}
                  {stockPriceSummary?.last_max?.toFixed(2)}
                </p>
              </div>
              <div className="second-item-right">
                <p className="top">
                  {translate["home_investor.52_week_price_range"]}
                </p>
                <p className="button">
                  {stockPrice52WeekData?.minPrice52week?.toFixed(2)} -{" "}
                  {stockPrice52WeekData?.maxPrice52week?.toFixed(2)}
                </p>
              </div>
            </div>
            <div className="datas-chart-item last-item">
              <div className="third-item-left">
                <p className="left">
                  {translate["home_investor.updated_when"]}{" "}
                  {stockPriceSummary?.date_display}
                </p>
              </div>
              <div className="fourth-item-right">
                <Link
                  to={ROUTE_PATH.STOCK_PRICE.LINK}
                  onClick={() => scrollToTop()}
                >
                  <Button className="btn link read-more">
                    {translate["common.more"]}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mouse-scroll">
            <Button
              className='btn-mouse-scroll'
              onClick={() => {
                handleBottomPage();
              }}>
              <img className="img-mouse-scroll"
                src='../asset/media/images/contents/mouse-scroll.png' alt="mouse-scroll" />
            </Button>
          </div>
        </div>
      </div>

      <div className="page-content fluid">
        <div className="home-investor">
          {/* Stock price */}
          <div className="block-section sec-first">
            <div className="content-wrapper block-set-biotec">
              <div className="title-set-biotec">
                SET : BIOTEC
              </div>
              <div className="block-last-price">
                <p className="last-price">
                  {stockPriceSummary?.last_close?.toFixed(2)} <span>THB</span>
                </p>
                <p
                  className={
                    stockPriceSummary?.change_precent < 0
                      ? "right-minus"
                      : "right"
                  }
                >
                  {stockPriceSummary?.change_precent >= 0 ? "+" : ""}
                  {stockPriceSummary?.change_volume === 0
                    ? "0.00 "
                    : `${stockPriceSummary?.change_volume?.toFixed(2)} `}
                  ({stockPriceSummary?.change_precent?.toFixed(2)}%)
                </p>
              </div>
              <div className="block-item trading-volume">
                <p className="title-item">
                  {translate["home_investor.trading_volume_shares"]}
                </p>
                <p className="price-item">
                  {displayNumber(
                    Number(stockPriceSummary?.volume)?.toFixed(2)
                  )}
                </p>
              </div>
              <div className="block-item intraday-price-range">
                <p className="title-item">
                  {translate["home_investor.intraday_price_range"]}
                </p>
                <p className="price-item">
                  {stockPriceSummary?.last_min?.toFixed(2)} -{" "}
                  {stockPriceSummary?.last_max?.toFixed(2)}
                </p>
              </div>
              <div className="block-item week-price-range">
                <p className="title-item">
                  {translate["home_investor.52_week_price_range"]}
                </p>
                <p className="price-item">
                  {stockPrice52WeekData?.minPrice52week?.toFixed(2)} -{" "}
                  {stockPrice52WeekData?.maxPrice52week?.toFixed(2)}
                </p>
              </div>
              <div className="block-updated-when">
                <p className="updated-when">
                  {translate["home_investor.updated_when"]}{" "}
                  {stockPriceSummary?.date_display}
                </p>
              </div>
            </div>
          </div>

          {/* News */}
          <div className="block-section sec-second">
            <div className="stock-market-news content-wrapper">
              <ScrollAnimation
                animateIn="slideInLeft"
                animateOnce={true}
                className="frame-ann"
              >
                <div className="ann-header">
                  <div className="title">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={homeData?.attributes?.news_title?.replace(
                        " ",
                        "<br/>"
                      )}
                    ></ReactMarkdown>
                    <div className="rectangle"></div>
                  </div>
                  <div className="ann-des">
                    <ReactMarkdown
                      className="des-text"
                      children={newsPage?.attributes?.content_description}
                      rehypePlugins={[rehypeRaw]}
                    ></ReactMarkdown>
                  </div>
                  <div className="count-number home-ir first">
                    <span className="count-current">
                      {currentAnnData.toString().padStart(2, "0")}
                    </span>
                    <span className="count-total">
                      /{newsData.length.toString().padStart(2, "0")}
                    </span>
                  </div>
                  <div className="button-ann-group home-ir first">
                    <Button className="button-ann-prev" onClick={prevAnnSlide}>
                      <img
                        className="icon-button"
                        width={30}
                        src="../asset/media/images/icons/icon-arrow-left-light-blue.svg"
                        alt=""
                      />
                    </Button>
                    <Button className="button-ann-next" onClick={nextAnnSlide}>
                      <img
                        className="icon-button"
                        width={16}
                        src="../asset/media/images/icons/ann-arrow-right.png"
                        alt=""
                      />
                    </Button>
                  </div>
                </div>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="slideInUp"
                animateOnce={true}
                className="slide-ann-frame"
              >
                <Slider
                  {...AnnSettings}
                  ref={sliderAnnRef}
                  afterChange={handleSlideChange}
                >
                  {newsData &&
                    newsData?.map((row, i) => {
                      const symbol = "BIOTEC";
                      const link = `https://www.set.or.th/th/market/news-and-alert/newsdetails?id=${row?.id}&symbol=${symbol}`;

                      return (
                        <Link
                          key={row?.id}
                          to={{ pathname: link }}
                          target="_blank"
                        >
                          <div className="slider-item" key={i}>
                            <AnnCard
                              date={
                                lang === langTh
                                  ? displayDateBuddhistEra(
                                    new Date(row?.timestamp * 1000),
                                    true
                                  )
                                  : displayDate(
                                    new Date(row?.timestamp * 1000),
                                    "D MMMM YYYY"
                                  )
                              }
                              name={row?.headline}
                              icon={
                                row?.icon ||
                                AnnData[i]?.icon ||
                                AnnData[0]?.icon
                              }
                              current={currentAnnData - 1 === i}
                            ></AnnCard>
                          </div>
                        </Link>
                      );
                    })}
                </Slider>
              </ScrollAnimation>

              <div className="count-number home-ir second">
                <span className="count-current">
                  {currentAnnData.toString().padStart(2, "0")}
                </span>
                <span className="count-total">
                  /{AnnData.length.toString().padStart(2, "0")}
                </span>
              </div>
              <div className="button-ann-group home-ir second">
                <Button className="button-ann-prev" onClick={prevAnnSlide}>
                  <img
                    className="icon-button"
                    width={16}
                    src="../asset/media/images/icons/ann-arrow-right-darkblue.png"
                    alt=""
                  />
                </Button>
                <Button className="button-ann-next" onClick={nextAnnSlide}>
                  <img
                    className="icon-button"
                    width={16}
                    src="../asset/media/images/icons/ann-arrow-right.png"
                    alt=""
                  />
                </Button>
              </div>
            </div>
          </div>

          {/* Financial Highlight */}
          <div className="block-section sec-third">
            <div className="important-financial-information">
              <div className="content-wrapper">
                <div className="title-btn">
                  <p className="title-text">
                    {`
                    ${financialData.page?.attributes?.menu_text} 
                    ${lang === langTh ? "ปี" : ""} 
                    ${financialData.summary?.displayYear}
                  `}
                  </p>
                  <Link
                    to={ROUTE_PATH.FINANCIAL_HIGHLIGHTS.LINK}
                    onClick={() => scrollToTop()}
                  >
                    <Button className="btn link read-more">
                      {translate["common.more"]}
                    </Button>
                  </Link>
                </div>
                <div className="block-information">
                  <div className="information-left">
                    <img
                      className="icon"
                      src="../asset/media/images/icons/icon-total-income.svg"
                      alt="icon-total-income"
                    />
                    <div className="information">
                      <p className="top-text">
                        {translate["home_investor.total_income"]}
                      </p>
                      <p className="bottom-text">
                        {`${displayNumber(
                          (
                            Number(financialData.summary?.totalRevenue) /
                            10 ** 6
                          )?.toFixed(2)
                        )} ${translate["financial_highlights.million_baht"]}`}
                      </p>
                    </div>
                  </div>
                  <div className="information-middle">
                    <img
                      className="icon"
                      src="../asset/media/images/icons/icon-total-liabilities.svg"
                      alt="icon-total-income"
                    />
                    <div className="information">
                      <p className="top-text">
                        {translate["home_investor.total_assets"]}
                      </p>
                      <p className="bottom-text">
                        {`${displayNumber(
                          (
                            Number(financialData.summary?.totalAsset) /
                            10 ** 6
                          )?.toFixed(2)
                        )} ${translate["financial_highlights.million_baht"]}`}
                      </p>
                    </div>
                  </div>
                  <div className="information-right">
                    <img
                      className="icon"
                      src="../asset/media/images/icons/icon-all-assets.svg"
                      alt="icon-total-income"
                    />
                    <div className="information">
                      <p className="top-text">
                        {translate["home_investor.net_profit"]}
                      </p>
                      <p className="bottom-text">
                        {`${displayNumber(
                          (
                            Number(financialData.summary?.netProfit) /
                            10 ** 6
                          )?.toFixed(2)
                        )} ${translate["financial_highlights.million_baht"]}`}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="block-charts">
                  <div className="charts-service-income">
                    <p className="charts-title">
                      {translate["home_investor.service_income"]}
                    </p>
                    <p className="sub-title">
                      ({translate["home_investor.million_baht"]})
                    </p>
                    <div className="charts">
                      <div className="service-income">
                        <CanvasJSChart options={optionsRevenue} />
                      </div>
                    </div>
                  </div>
                  <div className="charts-total-assets">
                    <p className="charts-title">
                      {translate["home_investor.total_assets"]}
                    </p>
                    <p className="sub-title">
                      ({translate["home_investor.million_baht"]})
                    </p>
                    <div className="charts">
                      <div className="total-assets">
                        <CanvasJSChart options={optionsAsset} />
                      </div>
                    </div>
                  </div>
                  <div className="charts-net-profit">
                    <p className="charts-title">
                      {translate["home_investor.net_profit"]}
                    </p>
                    <p className="sub-title">
                      ({translate["home_investor.million_baht"]})
                    </p>
                    <div className="charts">
                      <div className="net-profit">
                        <CanvasJSChart options={optionsNetProfit} />
                      </div>
                    </div>
                  </div>
                </div>

                {
                  (
                    homeData?.attributes?.ratings_fitch1 &&
                    homeData?.attributes?.ratings_fitch2 &&
                    homeData?.attributes?.ratings_tris
                  ) &&
                  <div className="block-stable">
                    <div className="stable-left">
                      <img
                        className="icon"
                        src="../asset/media/images/icons/icon-investor-01.svg"
                        alt="icon-investor"
                      />
                      <div className="stable">
                        <p className="top-text">Fitch Ratings</p>
                        <p className="bottom-text">
                          {homeData?.attributes?.ratings_fitch1}
                        </p>
                      </div>
                    </div>
                    <div className="stable-middle">
                      <img
                        className="icon"
                        src="../asset/media/images/icons/icon-investor-02.svg"
                        alt="icon-investor"
                      />
                      <div className="stable">
                        <p className="top-text">Fitch Ratings</p>
                        <p className="bottom-text">
                          {homeData?.attributes?.ratings_fitch2}
                        </p>
                      </div>
                    </div>
                    <div className="stable-right">
                      <img
                        className="icon"
                        src="../asset/media/images/icons/icon-investor-03.svg"
                        alt="icon-investor"
                      />
                      <div className="stable">
                        <p className="top-text">TRIS Rating</p>
                        <p className="bottom-text">
                          {homeData?.attributes?.ratings_tris}
                        </p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          {/* One report */}
          <span className="bg-sec-fourth-fifth">
            <div className="block-section sec-fourth">
              <div className="blcok-latest-releases content-wrapper">
                <p className="title">
                  {translate["home_investor.latest_published_documents"]}
                </p>
                <ScrollAnimation
                  animateIn="slideInUp"
                  animateOnce={true}
                  className="block-card"
                >
                  {reportData &&
                    reportData?.map((d, i) => {
                      const file = d?.attributes?.file?.data?.attributes;
                      return (
                        <div key={i}>
                          <CardLatestReleases
                            cardIcon={dataM[i].img}
                            cardName={d?.attributes?.file_name}
                            cardDate={
                              lang === langTh
                                ? displayDateBuddhistEra(
                                  new Date(d?.attributes?.date)
                                )
                                : displayDate(d?.attributes?.date, "D MMM YYYY")
                            }
                            link={d?.link}
                            file={file}
                          />
                        </div>
                      );
                    })}
                </ScrollAnimation>
              </div>
            </div>

            <div className="block-section sec-fifth">
              <div className="block-investor-kits">
                <div className="investor-kits content-wrapper">
                  <ScrollAnimation
                    animateIn="slideInLeft"
                    animateOnce={true}
                    className="investor-kits-content"
                  >
                    <p className="title">
                      {homeData?.attributes?.investor_kits_title}
                    </p>
                    <p className="description">
                      {homeData?.attributes?.investor_kits_description}
                    </p>
                    <div className="btn-download-wrapper">
                      <Button
                        className="medium-secondary btn-download"
                        onClick={() => {
                          downloadInvestorKits();
                        }}
                      >
                        <img
                          src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
                          alt="icon"
                        />
                        <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>
                          {translate["common.download_document"]}
                        </span>
                      </Button>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="slideInRight"
                    animateOnce={true}
                    className="investor-kits-img"
                  >
                    <div className="block-image-content">
                      {homeData?.attributes?.investor_kits_image?.data
                        ?.attributes?.url && (
                          <img
                            className="image-content"
                            src={
                              BASE_URL_UPLOAD +
                              homeData?.attributes?.investor_kits_image?.data
                                ?.attributes?.url
                            }
                            alt="investor kits"
                          />
                        )}
                      <div className="bg-white left">
                        <img
                          className="icon-pie-chart"
                          src="../asset/media/images/icons/icon-pie-chart.svg"
                          alt="icon-pie-chart.svg"
                        />
                      </div>
                      <div className="bg-white right">
                        <img
                          className="icon-graph-up-arrow"
                          src="../asset/media/images/icons/icon-graph-up-arrow.svg"
                          alt="icon-graph-up-arrow.svg"
                        />
                      </div>
                      <div className="bg-blue"></div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </span>

          {/* Shareholder */}
          {shareholderData?.events?.length > 0 && (
            <div className="block-section sec-sixth">
              <div className="block-schedule-ir-activities">
                <div className="schedule-ir-activities">
                  <ScrollAnimation
                    animateIn="slideInLeft"
                    animateOnce={true}
                    className="schedule-ir-activities-img"
                  >
                    <div className="block-image-content">
                      <div className="knife-shape"></div>
                      <div className="circle-shape"></div>
                      {homeData?.attributes?.investor_calendar_image?.data
                        ?.attributes?.url ? (
                        <img
                          className="image-content"
                          src={
                            BASE_URL_UPLOAD +
                            homeData?.attributes?.investor_calendar_image?.data
                              ?.attributes?.url
                          }
                          alt="investor calendar"
                        />
                      ) : (
                        <img
                          className="image-content"
                          src="../asset/media/images/contents/investor-calendar-relationships.png"
                          alt="investor calendar"
                        />
                      )}
                      <div className="leaf-shape"></div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="slideInRight"
                    animateOnce={true}
                    className="schedule-ir-activities-content"
                  >
                    <div className="block-schedule">
                      <p className="title">
                        {shareholderData?.data?.attributes?.menu_text}
                      </p>
                      {shareholderData?.events &&
                        shareholderData?.events?.map((e, i) => {
                          return (
                            <div key={i} className="card-activities">
                              <div className="card-left">
                                <p className="card-left-title">
                                  {e?.attributes?.event_name}
                                </p>
                                <p className="card-date">
                                  <img
                                    className="icon-date"
                                    src="../asset/media/images/icons/icon-calenda.svg"
                                    alt="icon-calenda"
                                  />
                                  {lang === langTh
                                    ? displayDateBuddhistEra(
                                      new Date(e?.attributes?.event_date),
                                      true
                                    )
                                    : displayDate(
                                      e?.attributes?.event_date,
                                      "D MMMM YYYY"
                                    )}
                                </p>
                                <p className="card-times">
                                  <img
                                    className="icon-times"
                                    src="../asset/media/images/icons/icon-clock.svg"
                                    alt="icon-clock"
                                  />
                                  {displayTime(
                                    new Date(e?.attributes?.event_date),
                                    "HH:mm"
                                  )}{" "}
                                  น.
                                </p>
                              </div>
                              <div className="card-right">
                                <div className="btn-download-wrapper">
                                  <Button
                                    className="medium-secondary btn-download"
                                    onClick={() => {
                                      if (
                                        e?.attributes?.file?.data?.attributes
                                          ?.url
                                      ) {
                                        SaveFile(
                                          BASE_URL_UPLOAD +
                                          e?.attributes?.file?.data
                                            ?.attributes?.url,
                                          e?.attributes?.file?.data?.attributes
                                            ?.name
                                        );
                                      }
                                    }}
                                  >
                                    <img
                                      src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
                                      alt="icon"
                                    />
                                    <span
                                      style={{
                                        marginLeft: 10,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {translate["common.download_document"]}
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="block-btn-schedule">
                        <Link
                          to={ROUTE_PATH.INVESTOR_RELATIONS_CALENDAR.LINK}
                          onClick={() => scrollToTop()}
                        >
                          <Button className="btn link read-more">
                            {translate["common.see_more"]}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          )}

          {/* Contact */}
          <span className="bg-sec-fourth-seventh">
            <div className="block-section sec-seventh">
              <div className="block-contact-investor-relations home-investor">
                <div className="contact-investor-relations content-wrapper">
                  <ScrollAnimation
                    animateIn="slideInLeft"
                    animateOnce={true}
                    className="contact-investor-relations-content"
                  >
                    <p className="title">
                      {homeData?.attributes?.investor_contact_title}
                    </p>
                    <p className="description">
                      {homeData?.attributes?.investor_contact_description}
                    </p>
                    <div className="btn-download-wrapper">
                      <Button
                        className="medium-secondary btn-download"
                        onClick={() => {
                          const file =
                            homeData?.attributes?.investor_contact_file?.data;
                          SaveFile(
                            BASE_URL_UPLOAD + file?.attributes?.url,
                            file?.attributes?.name
                          );
                        }}
                      >
                        <img
                          src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
                          alt="icon"
                        />
                        <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>
                          {translate["common.download_document"]}
                        </span>
                      </Button>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="slideInRight"
                    animateOnce={true}
                    className="contact-investor-relations-img"
                  >
                    <div className="block-image-content">
                      <div className="circle-shape"></div>
                      {homeData?.attributes?.investor_contact_image?.data
                        ?.attributes?.url && (
                          <img
                            className="image-content"
                            src={
                              BASE_URL_UPLOAD +
                              homeData?.attributes?.investor_contact_image?.data
                                ?.attributes?.url
                            }
                            alt="investor contact"
                          />
                        )}
                      <div className="leaf-shape"></div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>

      {/* Modal */}
      {openModalCalendar.isOpen && (
        <Modal
          modalAnnouncement
          data={shareholderData?.events}
          onClose={(data) => {
            setOpenModalCalendar({ isOpen: !data });
            setSessionStorage("shareholder-calendar-popup", true);
          }}
        />
      )}
    </HomeInvestorStyle>
  );
};

export default HomeInvestorComponent;
