import React from "react";
import { OneCardStyle } from "./styled";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../../constants/routes";
import { useSelector } from "react-redux";

function OneCard({ image, date, title, header, detail, id, page }) {
  const translate = useSelector((state) => state.lang.translate);
  const history = useHistory();

  return (
    <OneCardStyle>
      <div className="csr-news-story">
        <div className="csr-news-story-img">
          <img className="img" src={image} alt="" />
        </div>
        <div className="csr-news-story-content">
          <div className="date">
            <div>{header}</div>
            <div>{date}</div>
          </div>
          <div className="block-content">
            <div className="content-title">{title}</div>
            <div className="content-body">{detail}</div>
            <div
              className="more"
              onClick={() => {
                history.push(
                  ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK +
                  "?id=" +
                  id +
                  "&page=" +
                  page
                );
              }}
            >
              {translate["common.see_more"]}
            </div>
          </div>
        </div>
      </div>
    </OneCardStyle>
  );
}

export default OneCard;
