import CorporateDocumentsComponent from "../components/Pages/CorporateDocuments";
import Auth from "../containerWarping/Auth";
const CorporateDocuments = () => {
  return (
    <Auth>
      <CorporateDocumentsComponent />
    </Auth>
  );
};

export default CorporateDocuments;