import styled from 'styled-components';
import VARIABLES from '../../../themes/variables';

// Wrapper
// ============================================================
export const DatePickerInputStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  display: flex;
  height: 49px;
  width: 190px;

  /* Child element styles
  ------------------------------- */
  .input-wrapper {
    position: relative;
    width: 100%;

    .calendar {
      width: 16px;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .react-datepicker__close-icon {
      width: 30px;
      background-color: white;
      padding: 0px 6px 0px 3px;
      border-radius: 6px;
      height: 93%;
      z-index: 9;
      margin-top: 1px;

      &::after {
        max-width: 16px;
        max-height: 16px;
        font-size: 25px;
        line-height: 13px;
        background-color: transparent;
        border-radius: 0;
        color: ${VARIABLES.COLOR.PRIMARY};
      }

      &:hover {
        &::after {
          color: ${VARIABLES.COLOR.RED_1};
        }
      }
    }

    .react-datepicker {
      font-family: ${VARIABLES.TYPOGRAPHYS.FONT_FAMILIES.PRIMARY};
      display: flex;
      flex-wrap: wrap;

      .react-datepicker__navigation {
        width: 19px;
        height: 19px;
        border: 0;

        &.react-datepicker__navigation--next {
          &::after {
            background-image: url('../../images/icons/icon-chevron-right-circle.png');
          }
        }

        &.react-datepicker__navigation--previous {
          &::after {
            background-image: url('../../images/icons/icon-chevron-left-circle.png');
          }
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .react-datepicker__header {
        background-color: white;
        padding-bottom: 5px;
      }

      .react-datepicker__current-month {
        // display: none;
      }

      .react-datepicker__month-dropdown-container {
        position: relative;
        margin: 1px 5px 0;
        width: 95px;

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 7px;
          height: 4px;
          top: 8px;
          right: 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../../images/icons/icon-chevon-down.png');
        }
      }

      .react-datepicker__year-dropdown-container {
        position: relative;
        margin: 1px 5px 0;
        width: 50px;

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 7px;
          height: 4px;
          top: 8px;
          right: 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../../images/icons/icon-chevron-down.png');
        }
      }

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        border: 0;
        color: ${VARIABLES.COLOR.PRIMARY};
        font-weight: bold;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        width: 100%;
        position: relative;
        z-index: 2;
        background-color: transparent;
        height: 100%;
        font-size: 12px;
      }

      .react-datepicker__day-names {
      }

      .react-datepicker__header__dropdown {
        margin-bottom: 7px;
        display: flex;
        justify-content: center;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        margin: 2px 4px;
        width: 24px;
        line-height: 24px;
        font-size: 14px;
        color: ${VARIABLES.COLOR.PRIMARY};
      }

      .react-datepicker__day {
        &.react-datepicker__day--today {
          color: white;
          background-color: ${VARIABLES.COLOR.SECONDARY};
          border-radius: 0.3rem;
        }

        &.react-datepicker__day--selected,
        &.react-datepicker__day--in-selecting-range,
        &.react-datepicker__day--in-range,
        &.react-datepicker__month-text--selected,
        &.react-datepicker__month-text--in-selecting-range,
        &.react-datepicker__month-text--in-range,
        &.react-datepicker__quarter-text--selected,
        &.react-datepicker__quarter-text--in-selecting-range,
        &.react-datepicker__quarter-text--in-range,
        &.react-datepicker__year-text--selected,
        &.react-datepicker__year-text--in-selecting-range,
        &.react-datepicker__year-text--in-range {
          color: white;
          background-color: ${VARIABLES.COLOR.PRIMARY};
        }

        &.react-datepicker__day--keyboard-selected {
          border-radius: 0.3rem;
          background-color: #f0f0f0;
          border: 0;
        }

        &.react-datepicker__day--outside-month {
          color: ${VARIABLES.COLOR.GRAY_1};
        }
      }

      .react-datepicker__input-time-container {
        color: ${VARIABLES.COLOR.PRIMARY};

        .react-datepicker-time__caption {
          font-weight: bold;
        }

        .react-datepicker-time__input {
          input {
            border: 1px solid ${VARIABLES.COLOR.PRIMARY};
          }
        }
      }

      .react-datepicker__time-container {
        width: 100px;
        .react-datepicker-time__header {
          color: ${VARIABLES.COLOR.PRIMARY};
        }

        .react-datepicker__time {
          color: ${VARIABLES.COLOR.PRIMARY};

          .react-datepicker__time-box {
            width: 99px;
          }
        }
      }

      .react-datepicker__monthPicker {
        display: flex;
        flex-wrap: wrap;

        .react-datepicker__month-wrapper {
          display: flex;
          //width: 100%;

          .react-datepicker__month-text {
            width: 51px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__today-button {
    min-width: 80px;
    max-width: 30%;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid ${VARIABLES.COLOR.GRAY_2};
    margin-bottom: 5px;

    &:hover {
      background-color: ${VARIABLES.COLOR.PRIMARY};
      border-color: ${VARIABLES.COLOR.PRIMARY};
      color: ${VARIABLES.COLOR.WHITE};
    }
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 112px;
  }

  .react-datepicker-wrapper {
    background-color: white;
    width: 100%;
    border-radius: 6px;
    border: 1px solid ${VARIABLES.COLOR.GRAY_1};
    height: 100%;
    box-sizing: border-box;
    display: flex;
    z-index: 2;

    .react-datepicker__input-container {
      height: 100%;

      .date-picker-custom {
        font-family: ${VARIABLES.TYPOGRAPHYS.FONT_FAMILIES.PRIMARY};
        border: 0;
        background: transparent;
        line-height: 48px;
        padding: 0 0 0 15px;
        font-size: 17px;
        padding-right: 37px;
        box-sizing: border-box;
        width: 100%;
        color: ${VARIABLES.COLOR.BLACK};
        cursor: pointer;
        outline-color: ${VARIABLES.COLOR.PRIMARY};
        border-radius: 6px;

        &:disabled {
          background-color: ${VARIABLES.COLOR.GRAY_3} !important;
          color: ${VARIABLES.COLOR.GRAY_2} !important;
        }

        /* &:read-only {
          color: ${VARIABLES.COLOR.GRAY_2} !important;
        } */
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${VARIABLES.COLOR.PRIMARY};
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover,
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: ${VARIABLES.COLOR.PRIMARY_HOVER};
  }

  /* Modifiers
  ------------------------------- */
  &.is-inline-datepicker {
    .input-wrapper {
      .react-datepicker {
        display: inline-block;
      }
    }
  }

  &.is-disabled {
    .input-wrapper {
      .calendar {
        filter: contrast(0);
      }
    }
  }

  &.is-readonly {
    .input-wrapper {
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          .date-picker-custom {
            background-color: ${VARIABLES.COLOR.GRAY_3};
          }
        }
      }

      .react-datepicker__close-icon {
        display: none;
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    height: 45px;
    width: 180px;

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        .date-picker-custom {
          font-size: 15px;
          line-height: 44px;
        }
      }
    }

    .input-wrapper {
      .react-datepicker__close-icon {
        width: 26px;

        &::after {
          font-size: 24px;
        }
      }

      .calendar {
        width: 14px;
      }
    }
  }

  @media (max-width: 1280.98px) {
    height: 40px;
    width: 160px;

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        .date-picker-custom {
          font-size: 14px;
          padding-left: 10px;
          line-height: 39px;
        }
      }
    }

    .input-wrapper {
      .react-datepicker__close-icon {
        width: 24px;

        &::after {
          font-size: 22px;
        }
      }

      .calendar {
        width: 14px;
        /* top: 14px;
        right: 10px; */
      }
    }
  }

  @media (max-width: 1024.98px) {
    height: 34px;
    width: 130px;

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        .date-picker-custom {
          font-size: 12px;
          padding-left: 5px;
          line-height: 33px;
        }
      }
    }

    .input-wrapper {
      .react-datepicker__close-icon {
        width: 22px;

        &::after {
          font-size: 20px;
        }
      }

      .calendar {
        width: 12px;
        /* top: 12px;
        right: 8px; */
      }
    }
  }
`;
