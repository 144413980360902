import React from "react";
import { SaveFile } from "../../../../Service/API";
import { getBaseUploadUrl } from "../../../../Service/service";
import { Button } from "../../../Global";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { useSelector } from "react-redux";

const CardBusiness = ({ title, icon, content, urlFile, fileName }) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <div className="card-business">
      <div>
        <img src={BASE_URL_UPLOAD + icon} alt="icon" />
      </div>
      <div className="card-business-title">
        <div className='card-name'>
          <span className='name'>{title}</span>
          <span className='sub-name sub-name-card-business'>{title}</span>
        </div>
      </div>
      <div className="card-business-content">
        <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
      </div>
      <div className="btn-download-business-wrapper">
        <Button
          className="medium-secondary btn-card-download"
          onClick={() => {
            SaveFile(BASE_URL_UPLOAD + urlFile, fileName);
          }}
        >
          <img
            className="icon-download"
            src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
            alt="icon"
          />

          <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>
            {translate["common.download_document"]}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default CardBusiness;
