import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const PageHeaderStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  height: 320px;
  width: 100%;
  background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
  display: flex;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,${VARIABLES.COLOR.PRIMARY_DARKBLUE} 0%, transparent 100%);
  }

  /* Child element styles
  ------------------------------- */
  .page-header-bg-img {
    width: 100%;
    height: 100%;
    object-fit:  cover;
  }

  .page-header-content {
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 1350px;
    max-height: 320px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    z-index: 2;
    padding: 0 15px;

    .title {
      ${VARIABLES.TYPOGRAPHYS.HEADING._1}
      color: white;
      line-height: 1.8;
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
