import service from "./../service";

export const PagesFinancialHighlight = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get(
      "/pages-financial-high-light",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const GetFinancialData = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      },
    };
    const response = await service().get("/financial-data", payload, true);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
