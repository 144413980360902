import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const CompanyPolicyPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    min-height: 645px;
  }

  .company-policy {
    padding: 40px 110px 60px 110px;

    .company-policy-title {
      ${VARIABLES.TYPOGRAPHYS.BODY._4}
      margin-bottom: 60px;
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
    .company-policy {
      padding: 40px 60px 60px 60px;
    }
  }

  @media (max-width: 820.98px) {
    .company-policy {
      padding: 40px 40px 60px 40px;
    }
  }

  @media (max-width: 550.98px) {
    .company-policy {
      padding: 30px 20px 35px 20px;
    }
  }
`
