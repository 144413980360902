import service from "./../service";

export const PagesFinancialAnnualReport = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-financial-annual-report', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const FinancialAnnualReport = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      },
    }
    const response = await service().get('/financial-annual-report-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const FinancialAnnualReportByYear = async (year) => {
  try {
    const payload = {
      params: {
        'filters[date][$gte]': `${year}-01-01`,
        'filters[date][$lte]': `${year}-12-31`,
        "sort[date]": "asc",
        "sort[id]": "asc",
        populate: '*'
      }
    }
    const response = await service().get('/financial-annual-report-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const FinancialAnnualReportByDate = async () => {
  try {
    const payload = {
      params: {
        'fields[0]': 'date'
      }
    }
    const response = await service().get('/financial-annual-report-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
