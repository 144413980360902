import { Fragment } from 'react';
import { ROUTE_PATH } from './constants/routes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import { Provider } from 'react-redux';
import store from './store/store';

import useEnvConfigLoader from './configs/useEnvConfigLoader';

import Header from './components/Layouts/Header';
import Footer from './components/Layouts/Footer';
import MainLayout from './components/Layouts/MainLayout'

import About from './pages/about';
import AuditCommittee from './pages/audit-committee';
import BoardDirectors from './pages/board-directors';
import WeAreBiotec from './pages/wearebiotec';
import VisionAndMission from './pages/visionandmission';
import CompanyPolicy from './pages/companypolicy';
import CompanyProfile from './pages/companyprofile';
import CorporateDocuments from './pages/corporate-governance-documents';
import MessageFromChairman from './pages/massage-from-chairman';
import SubsidiaryAndAssociatedCompanies from './pages/subsidiaryandassociatedcompanies';
import BusinessStructure from './pages/businessstructure';
import ManagementStructure from './pages/managementstructure';
import EmailContact from './pages/emailcontact';
import InvestorRelationsContact from './pages/investor-relations-contact';
import SupplyChainManagement from './pages/SupplyChainManagement'
import socialDimensionManagement from './pages/socialDimensionManagement'
import EnvironmentalManagement from './pages/environmentalManagement'
import SetAnnouncements from './pages/set-announcements'
import ShareholderMeetingDocuments from './pages/shareholder-meeting-documents'
import SustainableDevelopmentGoals from './pages/sustainable-development-goals'
import SustainableDevelopmentPolicy from './pages/sustainable-development-policy'
import SocialResponsibilityReport from './pages/social-responsibility-report'
import CorporateGovernancePolicy from './pages/corporate-governance-policy'
import Home from './pages/home';
import Test from './pages/test';
import ErrorNotfound from './pages/notfound';
import OtherDocuments from './pages/other-documents';
import ourBusiness from './pages/our-business';
import WorkWithUsPageInterestingPosition from './pages/workwithuspageiterestingposition';
import WorkWithUsPageOnlineRegister from './pages/workwithuspageonelineregister';
import NewAndActivityCorporateNew from './pages/newandactivitycorporatenew';
import NewAndActivityCorporateDetail from './pages/newandactivitycorporatedetail';
import NewAndActivityCSR from './pages/newandactivitycsr';
import QuarterlyFinancialSummary from './pages/quarterly-financial-summary';
import ManagementDiscussionAnalysis from './pages/management-discussion-analysis';
import AnnualReport from './pages/annual-report';
import OneReport from './pages/one-report';
import ChannelsReceivingComplaints from './pages/channels-receiving-complaints';
import HomeInvestor from './pages/home-investor';
import PrivacyCenter from './pages/privacy-center';
import CookiePolicy from './pages/cookie-policy';
import TermsAndConditions from './pages/terms-and-conditions';
import CompanySnapshot from './pages/company-snapshot';
import ContactUs from './pages/contact-us';
import ShareholderStructure from './pages/sharehoder-structure';
import CompanyIntroductionVDO from './pages/company-introduction-vdo';
import ShareholderMeetingVDO from './pages/shareholder-meeting-vdo';
import StockPrice from './pages/stock-price';
import HistoricalPrice from './pages/historical-price';
import FinancialHighlight from './pages/financial-highlights';
import InvestorRelationsCalendar from './pages/investor-relations-calendar';
import Investorkits from './pages/investorkits';
import DavidendPolicy from './pages/davidend-policy';

const App = () => {
  const config = useEnvConfigLoader();
  if (!config) {
    return (<></>);
  }

  return (
    <Fragment>
      <Provider store={store}>

      <GlobalStyle />
        <Router>
          <Header />
          <MainLayout>
            <Switch>
              <Route path={ROUTE_PATH.ABOUT.LINK} exact={true} component={About} />
              <Route path={ROUTE_PATH.TEST.LINK} exact={true} component={Test} />
              <Route path={ROUTE_PATH.HOME.LINK} exact={true} component={Home} />
              <Route path={ROUTE_PATH.WE_ARE_BIOTEC.LINK} exact={true} component={WeAreBiotec} />
              <Route path={ROUTE_PATH.VISION_AND_MISION.LINK} exact={true} component={VisionAndMission} />
              <Route path={ROUTE_PATH.COMPANY_PROFILE.LINK} exact={true} component={CompanyProfile} />
              <Route path={ROUTE_PATH.MESSAGE_FROM_CHAIRMAN.LINK} exact={true} component={MessageFromChairman} />
              <Route path={ROUTE_PATH.SUBSIDIARY_AND_ASSOCIATED_COMPANIES.LINK} exact={true} component={SubsidiaryAndAssociatedCompanies} />
              <Route path={ROUTE_PATH.BUSINESS_STRUCTURE.LINK} exact={true} component={BusinessStructure} />
              <Route path={ROUTE_PATH.MANAGEMENT_STRUCTURE.LINK} exact={true} component={ManagementStructure} />
              <Route path={ROUTE_PATH.EMAIL_CONTACT.LINK} exact={true} component={EmailContact} />
              <Route path={ROUTE_PATH.INVESTOR_RELATIONS_CONTACT.LINK} exact={true} component={InvestorRelationsContact} />
              <Route path={ROUTE_PATH.SUPPLY_CHAIN_MANAGEMENT.LINK} exact={true} component={SupplyChainManagement} />
              <Route path={ROUTE_PATH.SOCIAL_DIMENSION_MANAGEMENT.LINK} exact={true} component={socialDimensionManagement} />
              <Route path={ROUTE_PATH.ENVIRONMENTAL_MANAGEMENT.LINK} exact={true} component={EnvironmentalManagement} />
              <Route path={ROUTE_PATH.SUSTAINABLE_DEVELOPMENT_GOALS.LINK} exact={true} component={SustainableDevelopmentGoals} />
              <Route path={ROUTE_PATH.SUSTAINABLE_DEVELOPMENT_POLICY.LINK} exact={true} component={SustainableDevelopmentPolicy} />
              <Route path={ROUTE_PATH.SOCIAL_RESPONSIBILITY_REPORT.LINK} exact={true} component={SocialResponsibilityReport} />
              <Route path={ROUTE_PATH.COMPANY_POLICY.LINK} exact={true} component={CompanyPolicy} />
              <Route path={ROUTE_PATH.CORPORATE_GOVERNANCE_POLICY.LINK} exact={true} component={CorporateGovernancePolicy} />
              <Route path={ROUTE_PATH.WORK_WITH_US_PAGE_INTERESTING_POSITION.LINK} component={WorkWithUsPageInterestingPosition} />
              <Route path={ROUTE_PATH.WORK_WITH_US_PAGE_ONLINE_REGISTER.LINK} component={WorkWithUsPageOnlineRegister} />
              <Route path={ROUTE_PATH.CORPORATE_DOCUMENTS.LINK} exact={true} component={CorporateDocuments} />
              <Route path={ROUTE_PATH.SHAREHOLDER_MEETING_DOCUMENT.LINK} exact={true} component={ShareholderMeetingDocuments} />
              <Route path={ROUTE_PATH.SHAREHOLDER_MEETING_DOCUMENTAS.LINK} exact={true} component={ShareholderMeetingDocuments} />
              <Route path={ROUTE_PATH.OTHER_DOCUMENTS.LINK} exact={true} component={OtherDocuments} />
              <Route path={ROUTE_PATH.SET_ANNOUNCEMENTS.LINK} exact={true} component={SetAnnouncements} />
              <Route path={ROUTE_PATH.NEWS_ACTIVITY_COPORATE_NEW.LINK} exact={true} component={NewAndActivityCorporateNew} />
              <Route path={ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK} exact={true} component={NewAndActivityCorporateDetail} />
              <Route path={ROUTE_PATH.NEWS_ACTIVITY_CSR.LINK} exact={true} component={NewAndActivityCSR} />
              <Route path={ROUTE_PATH.BOARD_DIRECTORS.LINK} exact={true} component={BoardDirectors} />
              <Route path={ROUTE_PATH.AUDIT_COMMITTEE.LINK} exact={true} component={AuditCommittee} />
              <Route path={ROUTE_PATH.QUARTERLY_FINANCIAL_SUMMARY.LINK} exact={true} component={QuarterlyFinancialSummary} />
              <Route path={ROUTE_PATH.MANAGEMENT_DISCUSSION_ANALYSIS.LINK} exact={true} component={ManagementDiscussionAnalysis} />
              <Route path={ROUTE_PATH.ANNUAL_REPORT.LINK} exact={true} component={AnnualReport} />
              <Route path={ROUTE_PATH.OUR_BUSINESS.LINK} exact={true} component={ourBusiness} />
              <Route path={ROUTE_PATH.ONE_REPORT.LINK} exact={true} component={OneReport} />
              <Route path={ROUTE_PATH.CHANNELS_RECEIVING_COMPLAINTS.LINK} exact={true} component={ChannelsReceivingComplaints} />
              <Route path={ROUTE_PATH.HOME_INVESTOR.LINK} exact={true} component={HomeInvestor} />
              <Route path={ROUTE_PATH.PRIVACY_CENTER.LINK} exact={true} component={PrivacyCenter} />
              <Route path={ROUTE_PATH.COOKIE_POLICY.LINK} exact={true} component={CookiePolicy} />
              <Route path={ROUTE_PATH.TERMS_AND_CONDITIONS.LINK} exact={true} component={TermsAndConditions} />
              <Route path={ROUTE_PATH.COMPANY_SNAPSHOT.LINK} exact={true} component={CompanySnapshot} />
              <Route path={ROUTE_PATH.CONTACT_US.LINK} exact={true} component={ContactUs} />
              <Route path={ROUTE_PATH.SHAREHOLDER_STRUCTURE.LINK} exact={true} component={ShareholderStructure} />
              <Route path={ROUTE_PATH.SHAREHOLDER_MEETING_VDO.LINK} exact={true} component={ShareholderMeetingVDO} />
              <Route path={ROUTE_PATH.COMPANY_INTRODUCTION_VDO.LINK} exact={true} component={CompanyIntroductionVDO} />
              <Route path={ROUTE_PATH.STOCK_PRICE.LINK} exact={true} component={StockPrice} />
              <Route path={ROUTE_PATH.HISTORICAL_PRICE.LINK} exact={true} component={HistoricalPrice} />
              <Route path={ROUTE_PATH.FINANCIAL_HIGHLIGHTS.LINK} exact={true} component={FinancialHighlight} />
              <Route path={ROUTE_PATH.INVESTOR_RELATIONS_CALENDAR.LINK} exact={true} component={InvestorRelationsCalendar} />
              <Route path={ROUTE_PATH.INVESTOR_KITS.LINK} exact={true} component={Investorkits} />
              <Route path={ROUTE_PATH.DAVIDEND_POLICY.LINK} exact={true} component={DavidendPolicy} />
              <Route path={''} component={ErrorNotfound} />
            </Switch>
          </MainLayout>
          <Footer />
        </Router>
      </Provider>
    </Fragment >
  );
};

export default App;
