import classNames from 'classnames';
import { CardOneReportPageStyle } from './styled';

const CardOneReportComponent = ({
  className,
  name, 
  date, 
  onClick,
  isLangTh
}) => {

  return (
    <CardOneReportPageStyle
      className={classNames(
        'card-one-report-wrapper',
        className
      )}
    >
      <div className='block-card-one-report'>
        <div className='card-one-report-content'>
          <img
            className='icon'
            alt='quote-doc-img'
            src='../asset/media/images/icons/quote-doc.svg'
          />
          <div className='name'>{name}</div>
          <div className='date'>{date}</div>
          <div
            className="download-button-container"
            onClick={onClick}
          >
            {
              isLangTh ? (
                <img
                  className="dowmload-button"
                  alt='download-doc-img'
                  src="../asset/media/images/icons/download-doc.svg"
                />
              ) : (
                <img
                  className="dowmload-button"
                  alt='download-doc-img'
                  src="../asset/media/images/icons/download-doc-en.svg"
                />
              )
            }
          </div>
        </div>
      </div>
    </CardOneReportPageStyle>
  );
};

export default CardOneReportComponent;
