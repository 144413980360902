import React from "react";
import classNames from "classnames";
import { CardImgAndTextStyle } from "./styled";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../constants/routes";
import { useSelector } from "react-redux";

function CardImgAndText({
  className,
  image,
  date,
  title,
  header,
  detail,
  id,
  page,
}) {
  const translate = useSelector((state) => state.lang.translate);
  const history = useHistory();

  return (
    <CardImgAndTextStyle
      className={classNames("card-img-text-wrapper", className)}
    >
      <div className="card-img-text">
        <div className="block-img">
          <img className="img-zoom" src={image} alt="" />
        </div>
        <div className="card-img-text-content">
          <div className="date">
            <div>{header}</div>
            <div>{date}</div>
          </div>
          <div className="block-content">
            <div className="content-title">{title}</div>
            <div className="content-body">{detail}</div>
            <div
              className="more"
              onClick={() => {
                history.push(
                  ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK +
                  "?id=" +
                  id +
                  "&page=" +
                  page
                );
              }}
            >
              {translate["common.see_more"]}
            </div>
          </div>
        </div>
      </div>
    </CardImgAndTextStyle>
  );
}

export default CardImgAndText;
