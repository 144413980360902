import service from "./service";

export const ourBusinessService = () => {
  return {
    getOurBusiness: async (data = {}) => {
      try {
        const payload = {
          params: {
            ...data,
            populate: "*",
          }
        }
        const response = await service().get('/our-businesses', payload)
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    },
    getOurBusinessFindOne: async (id) => {
      try {
        const payload = {
          params: {
            populate: "deep,3",
          }
        }
        const response = await service().get(`/our-businesses/${id}`, payload)
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    },

    getOurBusinessById: async (id, data = {}) => {
      try {
        const payload = {
          params: {
            ...data,
            // populate: "deep,3",
          }
        }
        const response = await service().get(`/our-businesses/${id}`, payload)
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    },

    getOurBusinessServiceById: async (id) => {
      try {
        const payload = {
          params: {
            populate: "*",
          }
        }
        const response = await service().get(`/our-business-services/${id}`, payload)
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    },
  }
};



