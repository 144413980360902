import React, { useState, useEffect } from "react";
import { ThemeOurBusinessStyle } from "./styled";
import { getBaseUploadUrl } from "../../../../../Service/service";
import OneImage from "../elements/OneImage";
import TwoImage from "../elements/TwoImage";
import ThreeImage from "../elements/ThreeImage";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ServiceCard from "../elements/ServiceCard";
import { Button } from "../../../../Global";

function ThemeOurBusiness({ data, isService, isProduct }) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const lineImg = "../asset/media/images/contents/line-our-business-palm.png";
  const [dimensions, setDimensions] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setNameBusiness = (businessName) => {
    return businessName?.replace("กลุ่ม", "");
  };

  const checkElementImage = (dataImage) => {
    if (dataImage?.length === 1) {
      return (
        <OneImage
          image={
            dataImage[0].attributes.url &&
            BASE_URL_UPLOAD + dataImage[0].attributes.url
          }
        />
      );
    } else if (dataImage?.length === 2) {
      return <TwoImage image={dataImage} />;
    } else if (dataImage?.length === 3) {
      return <ThreeImage image={dataImage} />;
    } else {
      return null;
    }
  };

  const checkImageProduct = (dataProductImage, dataProduct) => {
    return dataProductImage?.length > 0 ? (
      <div className="image-product">{checkElementImage(dataProductImage)}</div>
    ) : (
      <div className="name-product">
        <img src={lineImg} alt="line" />
        {dataProduct.attributes.product_name}
      </div>
    );
  };

  const ElementProduct = ({ dataProduct, index }) => {
    return (
      <div className="product-wrapper">
        {(dimensions <= 768 ? true : index % 2 === 0) &&
          checkImageProduct(
            dataProduct?.attributes?.product_image?.data,
            dataProduct
          )}
        <div className="product-detail-wrapper">
          {dataProduct?.attributes?.product_image?.data?.length > 0 && (
            <div className="name-product-detail">
              <img src={lineImg} alt="line" />
              {dataProduct?.attributes?.product_name}
            </div>
          )}
          <div className="product-detail">
            <ReactMarkdown
              children={dataProduct?.attributes?.product_deiails.replaceAll(
                "\n",
                "<br/>"
              )}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
        </div>
        {(dimensions <= 768 ? false : index % 2 !== 0) &&
          checkImageProduct(
            dataProduct?.attributes?.product_image?.data,
            dataProduct
          )}
      </div>
    );
  };

  return (
    <ThemeOurBusinessStyle>
      {data !== null && (
        <div className="our-business-wrapper">
          <div className="our-business-title">
            {!!data?.attributes?.image?.data ? (
              <div className="title-have-image">
                <div className="title">
                  <img src={lineImg} alt="symbol" />
                  {data?.attributes?.name}
                </div>
                <div className="title-detail-wrapper">
                  <div className="title-detail">
                    <ReactMarkdown
                      children={data?.attributes?.details.replaceAll(
                        "\n",
                        "<br/>"
                      )}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </div>
                  <div className="title-image">
                    <OneImage
                      image={
                        data?.attributes?.image?.data?.attributes?.url &&
                        BASE_URL_UPLOAD +
                          data?.attributes?.image?.data?.attributes?.url
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="title-no-image">
                <div className="title">
                  <img src={lineImg} alt="symbol" />
                  {data?.attributes?.name}
                </div>
                <div className="title-detail">
                  <ReactMarkdown
                    children={data?.attributes?.details.replaceAll(
                      "\n",
                      "<br/>"
                    )}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              </div>
            )}
          </div>

          {isProduct && (
            <div className="product-business-content">
              {data?.attributes?.product_id?.data?.map((e, i) => (
                <ElementProduct key={i} dataProduct={e} index={i} />
              ))}
            </div>
          )}

          {isService && (
            <div className="service-business-content">
              <div className="service-business-content-title">
                โดยมีการประกอบ{setNameBusiness(data?.attributes?.name)}ดังนี้
              </div>
              <div className="service-business-list">
                {data?.attributes?.service_id?.data?.map((e, i) => {
                  const serviceIcon = e?.attributes?.service_icon?.data;
                  return (
                    <ServiceCard
                      key={i}
                      image={
                        serviceIcon?.attributes?.url &&
                        BASE_URL_UPLOAD + serviceIcon?.attributes?.url
                      }
                      title={e?.attributes?.service_name}
                      alt={serviceIcon?.attributes?.alternativeText}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <div className="our-business-outline">
            <div className="content-outline">
              <ReactMarkdown
                children={data?.attributes?.outline_text}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            {data?.attributes?.viewmore_link && (
              <Button
                className="small-primary-rounded-outline-transparent btn-visit"
                onClick={() => {
                  data?.attributes?.viewmore_link &&
                    window.open(data?.attributes?.viewmore_link);
                }}
              >
                Visit Website
              </Button>
            )}
          </div>
        </div>
      )}
    </ThemeOurBusinessStyle>
  );
}

export default ThemeOurBusiness;
