import React, { useEffect, useState } from "react";
import { WorkWithUsPageInterestingPositionStyle } from "./styled";
import { Button, PageHeader, Accordion, Input } from "../../../Global";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rehypeRaw from "rehype-raw";
import { scrollToTop, copyLink, displayDate, displayYearBuddhistEra } from "./../../../../helpers";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  LineShareButton,
} from "react-share";
import { JobRecruitments, PagesWorkWithUs } from "../../../../Service/API";
import { getBaseUploadUrl } from "../../../../Service/service";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import OnlineRegister from "../OnlineRegister";
import { ROUTE_PATH } from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { langTh } from "../../../../store/lang";

const WorkWithUsPageInterestingPositionComponent = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);

  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const originPath = window.location.origin;
  const shareLink = `${originPath}${ROUTE_PATH.WORK_WITH_US_PAGE_INTERESTING_POSITION.LINK}`;

  const [dataPage, setDataPage] = useState(null);
  const [dataContent, setDataContent] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsopened] = useState(false);
  const [checkposition, setCheckposition] = useState({
    postion_name: null,
    postion_id: null,
  });

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const InterestingPosition = async () => {
      const ResponsePages = await PagesWorkWithUs();
      const ResponseContent = await JobRecruitments({
        "sort[date]": "desc",
        "sort[id]": "desc"
      });
      setDataPage(ResponsePages?.data);
      setDataContent(ResponseContent?.data);
    };

    InterestingPosition();
  }, []);

  const handleSearch = async (event) => {
    const text = event.target.value;
    setSearchText(text);
    setIsLoading(true); // Set loading state to true
    // Simulate an asynchronous search (replace this with your actual search logic)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false); // Set loading state to false
  };

  const applyWork = (e) => {
    setIsopened(e);
  };

  return (
    <WorkWithUsPageInterestingPositionStyle>
      <PageHeader
        pageHeaderImage={
          dataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD +
          dataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/work-with-us", text: translate["menu.work_with_us"] },
          {
            url: "/work-with-us/interesting-position",
            text: dataPage?.attributes?.menu_text,
          },
        ]}
        title={dataPage?.attributes?.menu_text}
      />
      {!isOpened && (
        <div className="work-with-us content-wrapper">
          <div className="work-with-us-search">
            <div className="result">
              <div className="line-img">
                <img
                  src="../asset/media/images/contents/line-work-with-us.png"
                  alt=""
                />
              </div>
              <div className="text-result" id="num-result">
                {dataContent?.length} {translate["interesting_position.result"]}
              </div>
            </div>
            <div className="search">
              <div className="line-img">
                <img
                  src="../asset/media/images/contents/line-work-with-us.png"
                  alt=""
                />
              </div>
              <div className="box-search">
                <Input
                  inputSearch
                  type="input"
                  placeholder={
                    translate["interesting_position.find_a_location"]
                  }
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="contact">
              <div className="line-img">
                <img
                  src="../asset/media/images/contents/line-work-with-us.png"
                  alt=""
                />
              </div>
              <div className="box-contact">
                <div className="text-contact">
                  {translate["interesting_position.share_job_vacancies"]} :
                </div>
                <div className="link-contact">
                  <FacebookShareButton
                    url={shareLink}
                    title={"BIOTEC - Work With Us"}
                    quote={"BIOTEC - Work With Us"}
                    hashtag={"#BIOTEC"}
                  >
                    <img
                      src="../asset/media/images/contents/icon-facebook.png"
                      alt="facebook share"
                    />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={shareLink}
                    hashtag={"#BIOTEC"}
                    via="BIOTEC - Work With Us"
                    title="BIOTEC - Work With Us"
                  >
                    <img
                      src="../asset/media/images/contents/icon-tweeter.png"
                      alt=""
                    />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={shareLink}
                    title="work with us"
                    source="BIOTEC - Work With Us"
                    summary="Work With Us"
                  >
                    <img
                      src="../asset/media/images/contents/icon-in.png"
                      alt=""
                    />
                  </LinkedinShareButton>

                  <LineShareButton
                    url={shareLink}
                    title="BIOTEC - Work With Us"
                  >
                    <img
                      src="../asset/media/images/contents/icon-line.png"
                      alt=""
                    />
                  </LineShareButton>

                  <span
                    className="icon-share-link"
                    onClick={() => copyLink(shareLink)}
                  >
                    <img
                      src="../asset/media/images/contents/icon-link.png"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="work-with-us-jobs">
            {isLoading ? (
              <p>{translate["common.loading"]}</p>
            ) : dataContent ? (
              dataContent
                .filter((d) =>
                  d?.attributes?.job_position
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                .map((d, i) => {
                  const location = d?.attributes?.base_localtion ? `(${d?.attributes?.base_localtion})` : '';
                  return (
                    <div className="accordion-wrapper" key={i}>
                      <Accordion
                        key={d?.id}
                        title={`${d?.attributes?.job_position} ${location}`}
                        date={lang === langTh ? `${displayYearBuddhistEra(new Date(d?.attributes?.date))}-${displayDate(d?.attributes?.date, "MM-DD")}` : d?.attributes?.date}
                        imgLogo="../asset/media/images/contents/work-with-us-contact.png"
                      >
                        <div className="job-detail">
                          <div className="job-description">
                            <div className="title">{translate["interesting_position.job_description"]}</div>
                            <div className="body-job-description">
                              <ReactMarkdown
                                className="line-break"
                                rehypePlugins={[rehypeRaw]}
                                children={d?.attributes?.job_details}
                              />
                            </div>
                          </div>
                          <div className="button-job">
                            <Link
                              to={`${ROUTE_PATH.WORK_WITH_US_PAGE_ONLINE_REGISTER.LINK}?position=${d?.id}`}
                              onClick={() => scrollToTop()}
                            >
                              <Button className="medium-secondary-outline">
                                {translate["interesting_position.apply_now"]}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Accordion>
                    </div>
                  );
                })
            ) : null}
          </div>
        </div>
      )}
      {isOpened && (
        <OnlineRegister
          positions={checkposition.postion_name}
          position_id={checkposition.postion_id}
          content={dataContent}
          applyWork={applyWork}
        />
      )}
    </WorkWithUsPageInterestingPositionStyle>
  );
};
export default WorkWithUsPageInterestingPositionComponent;
