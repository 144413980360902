import classNames from 'classnames';
import { CardCompanySnapshotPageStyle } from './styled';
import { SaveFile } from "./../../../../../Service/API";
import { getBaseUploadUrl } from "./../../../../../Service/service";
import { useSelector } from 'react-redux';

const CardCompanySnapshotComponent = ({
  className,
  cardHeadName,
  cardBodyName,
  file
}) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <CardCompanySnapshotPageStyle
      className={classNames(
        'card-company-snapshot-wrapper',
        className
      )}
    >
      <div className='card-company-snapshot'>
        <div className='card-head'>
          <p className='year'>{cardHeadName}</p>
        </div>
        <div className='card-body'>
          <img className='icon' src='../asset/media/images/icons/icon-bank-white.svg' alt='icon-bank-white' />
          <p className='name'>{cardBodyName}</p>
        </div>
        <div className='card-footer'>
          <div className='file-download' 
            onClick={()=>{
              SaveFile(BASE_URL_UPLOAD + file?.url, file?.fileName || file?.name)
            }}
          >
            <img className='icon icon-download' src='../asset/media/images/icons/icon-arrow-down-circle-white.svg' alt='icon-arrow-down-circle-white'/>
            {translate["common.download_document"]}
          </div>
        </div>
      </div>
    </CardCompanySnapshotPageStyle>
  );
};

export default CardCompanySnapshotComponent;
