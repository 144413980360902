import { BodyCoporateDetailStyle } from "./styled";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../constants/routes";
import ClampLines from "react-clamp-lines";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function BodyCoporateDetail({ body, id }) {
  const translate = useSelector((state) => state.lang.translate);
  const [isReadmore, setIsReadmore] = useState(false);

  const [readmore, setReadmore] = useState(() => (
    <div className="readmore">
      <div>{translate["common.read_more"]}</div>
      <div className="img-readmore">
        <img
          src="../asset/media/images/contents/icon-arrow-readmore.png"
          alt=""
        />
      </div>
    </div>
  ));

  return (
    <BodyCoporateDetailStyle height={isReadmore ? "100%" : "200px"}>
      <div className="body">
        {/* <ClampLines
          text={body}
          lines={4}
          ellipsis="..."
          moreText={readmore}
          lessText=" "
          className="detail"
          innerElement="div"
        /> */}

        <div className="details">
          <ReactMarkdown children={body} rehypePlugins={[rehypeRaw]} />
        </div>
      </div>
      {!isReadmore &&
        <div className="readmore more" onClick={() => setIsReadmore(true)}>
          <div>{translate["common.read_more"]}</div>
          <div className="img-readmore">
            <img
              src="../asset/media/images/contents/icon-arrow-readmore.png"
              alt=""
            />
          </div>
        </div>
      }
    </BodyCoporateDetailStyle>
  );
}

export default BodyCoporateDetail;
