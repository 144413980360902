import styled from 'styled-components'
import VARIABLES from '../../../../themes/variables'

// Wrapper
// ============================================================
export const QuarterlyFinancialSummaryStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .quarterly-financial-summary {
    padding: 50px;

    div > .block-card-data-sheet {
      /* margin: auto; */
    }

    .quarterly-financial-summary-title {
      margin-bottom: 43px;
      display: flex;
      justify-content: space-between;

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
      }

      .select-wrapper {
        min-width: 155px;
        margin-left: 15px;
      }
    }


    .title-year {
      margin: 24px 0;
      ${VARIABLES.TYPOGRAPHYS.TITLE._4};
    }

    .block-quarterly-financial-summary {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      gap: 40px;
      padding-bottom: 24px;
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 991.98px) {
    .quarterly-financial-summary {
      .block-quarterly-financial-summary {
        grid-template-columns: unset;
      }
    }
  }

  @media (max-width: 450.98px) {
    .quarterly-financial-summary {
      .quarterly-financial-summary-title {
        display: block;
        .title {
          margin-bottom: 16px;
          text-align: center;
        }

        .select-wrapper {
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }


  /* @media (max-width: 767.98px) {
    
  } */

  /* @media (max-width: 600.98px) {
    
  } */

  /* @media (max-width: 450.98px) {
    
  } */
`
