import React, { useState } from 'react';
import classNames from 'classnames';
import { ModalStyle } from './styled';
import {
  Button,
} from '../../Global';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import { useSelector } from "react-redux";

export default function Modal({
  id,
  className,
  srcImage,
  alt,
  data,
  children,
  modalBanner,
  modalAnnouncement,
  modalWarningMessage,
  onClick,
  onClose,
}) {
  const translate = useSelector((state) => state.lang.translate);

  const [isModalOpen, setModalOpen] = useState(false);
  const ModalClose = () => {
    setModalOpen(true);
    onClose(true);
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <ModalStyle
      className={classNames(
        'modal-wrapper',
        { 'is-open-modal': !isModalOpen },
        { 'is-close-modal': isModalOpen },
        className
      )}
    >
      <div className='bg-onclick' onClick={ModalClose}></div>
      {modalBanner && (
        <Button className='btn-close banner' onClick={ModalClose}>
          <img className='icon icon-close' src='../../asset/media/images/icons/icon-close-white.svg' alt='icon-close-white' />
        </Button>
      )}

      {modalBanner && (
        <div className='modal-banner' onClick={() => { onClick(data); ModalClose() }}>
          <div className='modal-banner-content'>
            <div className='modal-content'>
              <img className='modal-banner' src={srcImage} alt={alt} />
            </div>
          </div>
        </div>
      )}

      {modalAnnouncement && (
        <div className='modal-announcement'>
          <div className='modal-announcement-content bg-modal-content' onClick={() => (setModalOpen(false))}>
            <Button className='btn-close' onClick={ModalClose}>
              <img className='icon icon-close' src='../../asset/media/images/icons/icon-close-blue.svg' alt='icon-close-blue' />
            </Button>
            <div className='modal-body'>
              <div className='modal-logo'>
                <img className='icon icon-logo' src='../../asset/media/images/contents/biotec-logo-font-blue.png' alt='logo-BioTec' />
              </div>
              <Slider {...settings}>
                {data?.map((d) => {
                  return (
                    <div key={d.id}>
                      <div className='modal-content'>
                        <div className='content title'>
                          {d?.attributes?.event_name || d?.title}
                        </div>
                        <div className='content date'>
                          {d?.attributes?.display_date || d?.attributes?.event_date || d?.date}
                        </div>
                        <div className='content contact'>
                          <ReactMarkdown
                            children={d?.attributes?.display_detail || d?.attributes?.event_detail?.replaceAll('\n', '<br/>') || d?.contact}
                            rehypePlugins={[rehypeRaw]}
                          >
                          </ReactMarkdown>
                        </div>
                        <Link
                          className='link more-details'
                          to={d?.attributes?.link ? d?.attributes?.link :'/information-shareholders/investor-relations-calendar'}
                        >
                          {translate["home_investor.more_details"]}
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      )}

      {modalWarningMessage && (
        <div className='modal-warning-message'>
          <div className='modal-warning-message-content bg-modal-content'>
            <div className='modal-body'>
              <div className='modal-logo'>
                <img className='icon icon-logo' src={srcImage} alt={alt} />
              </div>
              <div className='modal-warning-message-text white-space-pre'>
                {children}
              </div>
              <div className='btn-close'>
                <Button onClick={() => { onClose({ isOpen: false }) }} className='medium-primary close'>ปิด</Button>
              </div>
            </div>
          </div>
        </div>
      )}

    </ModalStyle >
  );
}

