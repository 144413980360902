import { createEvent } from "ics";
import { saveAs } from "file-saver";

export const exportIcsFile = (data, filename = "event-schedule") => {
  createEvent(data || mockIcsData, (error, value) => {
    const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${filename}.ics`);
  });
}

const mockIcsData = {
  start: [2023, 10, 30, 16, 30],
  duration: { hours: 2, minutes: 30 },
  title: "Test Gen ICS",
  description: "Annual 10-kilometer run in Boulder, Colorado",
  location: "Folsom Field, University of Colorado (finish line)",
  url: "http://www.bolderboulder.com/",
  geo: { lat: 40.0095, lon: 105.2669 },
  categories: ["10k races", "Memorial Day Weekend", "Boulder CO"],
  status: "CONFIRMED",
  busyStatus: "BUSY",
  organizer: { name: "Admin", email: "Race@BolderBOULDER.com" },
  attendees: [
    {
      name: "Adam Gibbons",
      email: "adam@example.com",
      rsvp: true,
      partstat: "ACCEPTED",
      role: "REQ-PARTICIPANT"
    },
    {
      name: "Brittany Seaton",
      email: "brittany@example2.org",
      dir: "https://linkedin.com/in/brittanyseaton",
      role: "OPT-PARTICIPANT"
    }
  ]
};