import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'
// import VARIABLES from '../../../themes/variables'

export const CheckboxStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;

  .fake-input {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
  }

  .check-icon {
    width: 16px;
    display: none;
  }

  .fake-checkbox {
    cursor: pointer;
    height: 18px;
    width: 18px;
    flex: 0 0 18px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -khtml-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #AAAAAA;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    word-break: break-all;
    padding: 1px;
    border-radius: 4px;
  }

  .fake-label {
    color: black;
  }

  .real-input {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    &:checked ~ .fake-input {
      .check-icon {
        display: block;
      }
    }
  }

  &.is-disabled {
    pointer-events: none;

    .fake-checkbox {
      background-color: #CCCCCC;
    }

    &.is-checked .fake-checkbox {
      background-color: #BBBBBB;
    }
  }

  /* States
  ------------------------------- */
  /* &.is-checked {
    .fake-checkbox {
      background-color: #222222;
      border-color: #222222;
    }
  } */

  &.is-checked-sub {
    .fake-checkbox {
      background-color: #222222;
      border-color: #222222;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.semibold-label {
    .fake-label {
      font-weight: normal;
    }
  }

  &.is-radio-type {
    .fake-input {
      .fake-checkbox {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -khtml-border-radius: 50%;
        border-radius: 50%;
        position: relative;
        margin-top: 4px;
        margin-right: 15px;

        .check-icon {
          display: none;
        }

        &::after {
          content: '';
          display: none;
          position: absolute;
          width: 70%;
          height: 70%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -khtml-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    &.is-checked {
      .fake-checkbox {
        background-color: #FFFFFF;

        &::after {
          display: block;
        }
      }
    }
  }

  &.inline {
    width: auto;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  /* States with modifiers
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
