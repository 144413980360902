import React from 'react';
import classNames from 'classnames';
import { DatePickerInputStyle } from './styled';
import { Image } from '../../Global';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DatePickerInput({
  label,
  className,
  disabled,
  id,
  showTimeInput,
  readOnly,
  ...props
}) {
  return (
    <DatePickerInputStyle
      className={classNames(
        'date-picker-wrapper',
        { 'is-disabled': disabled },
        { 'is-readonly': readOnly },
        { 'is-inline-datepicker': showTimeInput },
        className
      )}
    >
      {label && (
        <label className='label' htmlFor={id}>
          {label}
        </label>
      )}
      <div className='input-wrapper'>
        <DatePicker
          minDate={props.min}
          maxDate={props.max}
          placeholderText={props.placeholder}
          popperPlacement='bottom'
          {...props}
          className='date-picker-custom'
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          // dateFormat="MMMM d, yyyy "
        />
        <Image
          src='../asset/media/images/icons/icon-calenda.svg'
          alt='icon-calendar'
          className='calendar'
        />
      </div>
    </DatePickerInputStyle>
  );
}
