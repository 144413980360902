import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'

// Wrapper
// ============================================================
export const AnnCardPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: 100%;
  height: 100%;
  max-width: 296px;
  min-height: 400px;
  border-radius: 16px;
  background-color: ${VARIABLES.COLOR.NEUTRAL16};
  margin: 24px auto;
  
  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }
  
  .card-ann {
    position: relative;
    padding: 50px 24px 24px 24px;
    transform: translate(0, -25px);
     
    .ann-icon {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 24px;
    }

    .card-ann-container {
      .date-ann {
        font-size: 24px;
        font-weight: 700;
        color: ${VARIABLES.COLOR.PRIMARY_DEFAULT};
      }

      .date-ann,
      .des-ann {
        margin-top: 24px;
      }
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

`
