import service from "./../service";

export const PagesCookiesPolicy = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-cookies-policy-tb', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PgesPrivacyCenter = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-privacy-center', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const PagesTermsCondition = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-terms-condition', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
