import React, { useState, useEffect } from "react";
import { HistoricalPriceStyle } from "./styled";
import {
  Button,
  DatePickerInput,
  PageHeader,
  TD,
  TH,
  TR,
  Table,
  TableBody,
  TableHeader,
} from "../../Global";
import { useDispatch, useSelector } from "react-redux";
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  PagesHistoricalPrice,
  GetChartQuotation,
} from "./../../../Service/stockPrices";
import { ROUTE_PATH } from "../../../constants/routes";
import {
  displayDate,
  displayDateBuddhistEra,
  removeDuplicates,
  displayNumber,
} from "../../../helpers";
import { langTh } from "../../../store/lang";

const HistoricalPriceComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [loadData, setLoadData] = useState([]);
  const [content, setContent] = useState([]);
  const [searchDate, setSearchDate] = useState({
    from: null,
    to: null
  })
  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const HistoricalPrice = async () => {
      try {
        const resPage = await PagesHistoricalPrice();
        setData(resPage?.data);

        const payload = {
          period: "5Y",
        };
        const resContent = await GetChartQuotation(payload);
        resContent?.result?.quotations &&
          handleSetData(resContent?.result?.quotations, true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    HistoricalPrice();
  }, []);

  useEffect(() => {
    handleSetData(loadData);
    if (loadData && loadData.length > 0) {
      const getMinDate = new Date(loadData[loadData.length-1]?.timestamp * 1000);
      setMinDate(getMinDate);
    }
  }, [loadData])

  const handleSetData = (params, isLoad = false) => {
    let dateList = [];

    const data = params?.map((item) => {
      const date = new Date(item.timestamp * 1000);
      const date_display = displayDate(
        new Date(item.timestamp * 1000),
        "YYYY-MM-DD"
      );

      item.date = date;
      item.date_display = date_display;

      dateList.push(date_display);
      return item;
    });

    isLoad && setLoadData(data);

    // setContent(data);
    dateList && handleSetGroupData(dateList);
  };

  //! Group by year and average value
  const handleSetGroupData = (dates) => {
    const dateList = removeDuplicates(dates);

    let setDateList = [];
    dateList.map((e) => {
      let objDate = {
        date: new Date(e),
        last_min: 0,
        last_max: 0,
        last_open: 0,
        last_close: 0,
        volume: 0,
      };

      const dateLength =
        loadData.filter((r) => r.date_display === e)?.length || 1;

      objDate.last_min =
        loadData.reduce(
          (sum, obj) =>
            e === obj.date_display ? sum + Number(obj.last_min) : sum + 0,
          0
        ) / dateLength;

      objDate.last_max =
        loadData.reduce(
          (sum, obj) =>
            e === obj.date_display ? sum + Number(obj.last_max) : sum + 0,
          0
        ) / dateLength;

      objDate.last_open =
        loadData.reduce(
          (sum, obj) =>
            e === obj.date_display ? sum + Number(obj.last_open) : sum + 0,
          0
        ) / dateLength;

      objDate.last_close =
        loadData.reduce(
          (sum, obj) =>
            e === obj.date_display ? sum + Number(obj.last_close) : sum + 0,
          0
        ) / dateLength;

      objDate.volume = loadData.reduce(
        (sum, obj) =>
          e === obj.date_display ? sum + Number(obj.volume) : sum + 0,
        0
      );

      setDateList.push(objDate);
    });

    setContent(setDateList);
  };

  const onSearch = () => {
    const from = new Date(displayDate(searchDate.from, "YYYY-MM-DD"));
    const to = new Date(displayDate(searchDate.to, "YYYY-MM-DD"));

    const data = [...loadData];
    const findData = data?.filter(
      (item) => {
        if (searchDate.from && searchDate.to) {
          return item.date >= from && item.date <= to;
        } else {
          if (searchDate.from) {
            return item.date >= from;
          } else if (searchDate.to) {
            return item.date <= to;
          } else {
            return item;
          }
        }
      }
    );

    handleSetData(findData);
  };

  return (
    <HistoricalPriceStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.stock_price_information"] },
          { url: ROUTE_PATH.HISTORICAL_PRICE.LINK, text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="historicalprice content-wrapper">
        <div className="history-date">
          <div className="title">{data?.attributes?.menu_text}</div>
          <div className="sub-title">
            <div className="sub-title-content">
              <div className="text-sub-title">
                {translate["historical_price.select_period_from"]}
              </div>
              <div className="datepicker-wrapper">
                <div className="datepicker">
                  <DatePickerInput
                    min={minDate}
                    max={new Date()}
                    placeholderText="Select date"
                    selected={searchDate.from && searchDate.from}
                    value={searchDate.from}
                    onChange={(e) => setSearchDate({ ...searchDate, from: e })}
                  />
                </div>
                <div className="text-sub-title">{translate["historical_price.to"]}</div>
                <div className="datepicker">
                  <DatePickerInput
                    min={searchDate.from}
                    max={new Date()}
                    placeholderText="Select date"
                    selected={searchDate.to && searchDate.to}
                    value={searchDate.to}
                    onChange={(e) => setSearchDate({ ...searchDate, to: e })}
                  />
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <Button
                className="medium-primary-outline"
                onClick={() => onSearch()}
              >
                {translate["historical_price.show_results"]}
              </Button>
            </div>
          </div>
        </div>
        <div className="table">
          <Table className="split-row-color split-color-primary">
            <TableHeader className="header-primary">
              <TR>
                <TH className="historical-price date">{translate["historical_price.date"]}</TH>
                <TH className="historical-price price">{translate["historical_price.opening_price"]}</TH>
                <TH className="historical-price price">{translate["historical_price.maximum"]}</TH>
                <TH className="historical-price price">{translate["historical_price.lowest"]}</TH>
                <TH className="historical-price price">{translate["historical_price.closing_price"]}</TH>
                <TH className="historical-price price">{translate["historical_price.amount"]}</TH>
              </TR>
            </TableHeader>
            <TableBody>
              {content &&
                content?.map((e, i) => {
                  return (
                    <TR key={i}>
                      <TD className="text-left">
                        {lang === langTh ? displayDateBuddhistEra(e?.date) : displayDate(e?.date, "D MMM YYYY")}
                      </TD>
                      <TD className="text-center">
                        {e?.last_open?.toFixed(2)}
                      </TD>
                      <TD className="text-center">{e?.last_max?.toFixed(2)}</TD>
                      <TD className="text-center">{e?.last_min?.toFixed(2)}</TD>
                      <TD className="text-center">
                        {e?.last_close?.toFixed(2)}
                      </TD>
                      <TD className="text-center">
                        {displayNumber(e?.volume)}
                      </TD>
                    </TR>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    </HistoricalPriceStyle>
  );
};

export default HistoricalPriceComponent;
