import QuarterlyFinancialSummaryComponent from "../components/Pages/FinancialDataSheet/QuarterlyFinancialSummary";
import Auth from "../containerWarping/Auth";
const QuarterlyFinancialSummary = () => {
  return (
    <Auth>
      <QuarterlyFinancialSummaryComponent />
    </Auth>
  );
};

export default QuarterlyFinancialSummary;
