import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const CorporateDocumentsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .background-page {
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    background-size: 100%;
    background-position: center;
    margin-top: -100vh;
    position: sticky;
    top: 0;
    width: 100%;
    height: 110vh;
    z-index: -1;
  }

  .page-content {
    background-color: unset;
  }

  .corporate-governance-document-content {
    .card-wrapper {
      padding: 60px 66px;
      display: grid;
      /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
      gap: 24px;
      justify-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @media (max-width: 1400.98px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (max-width: 1090.98px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 750.98px) {
        grid-template-columns: 1fr;
      }

      .card {
        position: relative;
        background-color: white;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
        width: 300px;
        border-radius: 12px;
        padding: 35px 25px;

        .card-header {
          background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0px 12px 0px 24px;
          padding: 3px 5px 3px 20px;
          width: 100%;
          max-width: 115px;
          max-height: 36px;
                
          .logo {
            width: 100%;
            height: 100%;
          }
        }

        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .card-body-content {
            div:first-child {
              margin-bottom: 16px;
            }
            
            div:last-child {
              color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              ${VARIABLES.TYPOGRAPHYS.BODY._1};
              margin-bottom: 40px;
            }
          }
        }
      }

      @media (max-width: 560.98px) {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        .card {
          width: 280px;
        }
      }

      @media (max-width: 340.98px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        .card {
          width: 250px;
        }
      }
    }

    .btn-card-download {
      width: 155px;
      ${VARIABLES.TYPOGRAPHYS.BODY._11}

      .icon-download {
        width: 18px;
        height: 18px;
      }

      &:hover {
        color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        background: ${VARIABLES.COLOR.SECONDARY_LIGHT};

        .icon-download {
          border-radius: 12px;
          border-color:transparent;
          background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        }
      }
    }
  }
    
  /* Modifiers
  ------------------------------- */

  .card-name {
    position: relative;

    .name {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media (max-width: 991.98px) {
        font-size: 20px;
      }
    }

    .sub-name {
      display: none;
    }

    &:hover {
      .sub-name {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(	94, 172, 27, 0.8);
        color: white;
        width: 150%;
        padding: 15px;
        border-radius: 10px;
        transform: translate(0, 55px);
        z-index: 990;
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 425.98px) {
    .corporate-governance-document-content {
      .card-wrapper {
        padding: 30px 10px;
      }
    }
  }
`
