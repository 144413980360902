import { createSlice } from "@reduxjs/toolkit";
import { setLocalStorage, getLocalStorage } from "../helpers";
import translate from "../translate.json";

export const langTh = "th";
export const langEn = "en";

export const checkLang = (lang) => [langTh, langEn].includes(lang);

const storageLang = getLocalStorage("lang");
const defaultLang = checkLang(storageLang) ? storageLang : langTh;

const setText = (trans, field, obj) => {
  if (typeof obj === "object") {
    for (const key in obj) {
      const value = obj[key];
      if (typeof value === "object") {
        setText(trans, `${field}.${key}`, value);
      } else if (key in trans) {
        trans[key][field] = value;
      }
    }
  }
};

const translation = (() => {
  const trans = {
    [langTh]: {},
    [langEn]: {}
  };

  for (const key in translate) {
    setText(trans, key, translate[key]);
  }

  return trans;
})();

export const langSlice = createSlice({
  name: "lang",
  initialState: {
    value: defaultLang,
    translate: translation[defaultLang],
    translation
  },
  reducers: {
    setLang: (state, action) => {
      const lang = action.payload;
      if (checkLang(lang)) {
        state.value = lang;
        setLocalStorage("lang", lang);
        window.location.reload();
      }
    },
  },
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
