import React from "react";
import { ImgHeadDetailStyle } from "./styled";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../constants/routes";

function ImgHeadDetail({ image , id}) {
  return (
    <ImgHeadDetailStyle>
      <img className="img-head"
            src={image}
            alt=""
          />
    </ImgHeadDetailStyle>
  );
}

export default ImgHeadDetail;
