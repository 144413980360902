import React, { useState, useEffect } from "react";
import { InvestorKitStyle } from "./styled";
import { Button, Checkbox, PageHeader } from "../../Global";
import { ROUTE_PATH } from "./../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import SelectBox from "../../Global/SelectBox";
import { getBaseUploadUrl } from "./../../../Service/service";
import { DownloadZipFile, GetNavbarMenu } from "./../../../Service/API";
import {
  PagesInvestorKits,
  OneReportFiles,
  CompanySnapshotFiles,
} from "./../../../Service/publications";
import {
  FinancialSummaryFiles,
  FinancialManagementAnalysisFiles,
} from "./../../../Service/financialAnnualReport";
import {
  displayDate,
  displayYearBuddhistEra,
  removeDuplicates,
  sortArray,
  displayFileSizeMB,
  displayYear,
} from "./../../../helpers";
import { langTh } from "../../../store/lang";

const InvestorKitComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [menu, setMenu] = useState({});
  const [data, setData] = useState({});
  const [downloadFileSize, setDownloadFileSize] = useState(0);

  const [loadData, setLoadData] = useState(null);
  const [reportList, setReportList] = useState([]);

  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState(null);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const InvestorKits = async () => {
      try {
        const resPage = await PagesInvestorKits();
        setData(resPage?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    InvestorKits();

    // Get report data
    const InvestorDocument = async () => {
      try {
        const resOne = await OneReportFiles();
        const resQuarterly = await FinancialSummaryFiles();
        const resDiscussion = await FinancialManagementAnalysisFiles({
          "sort[0]": "date",
        });
        const resSnapshot = await CompanySnapshotFiles();

        // Set data
        const reports = {
          oneReport: resOne?.data,
          quarterly: resQuarterly?.data,
          discussion: resDiscussion?.data,
          snapshot: resSnapshot?.data,
        };
        setLoadData({ ...reports });

        handleMapDate(reports);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    InvestorDocument();

    // Get menu
    getNavbarMenu();
  }, []);

  useEffect(() => {
    const InvestorDocument = async () => {
      try {
        const payload = {
          "filters[date][$gte]": `${selectedYear}-01-01`,
          "filters[date][$lte]": `${selectedYear}-12-31`,
        };

        const resOne = await OneReportFiles(payload);
        const resQuarterly = await FinancialSummaryFiles(payload);
        const resDiscussion = await FinancialManagementAnalysisFiles({
          ...payload,
          "sort[0]": "date",
        });
        const resSnapshot = await CompanySnapshotFiles(payload);

        // Set data
        const reports = {
          oneReport: resOne?.data,
          quarterly: resQuarterly?.data,
          discussion: resDiscussion?.data,
          snapshot: resSnapshot?.data,
        };

        handleMapDate(reports);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    selectedYear && InvestorDocument();
  }, [selectedYear]);

  const getNavbarMenu = async () => {
    const Response = await GetNavbarMenu();
    let data = {};
    Response?.data.forEach((item) => {
      for (const [key, value] of Object.entries(item)) {
        if (key === "page_id") {
          const setKey = value.replaceAll("-", "_");
          data[setKey] = item.text;
        }
      }
    });
    setMenu(data);
  }

  const handleMapDate = (reports) => {
    let dateList = [];

    reports.oneReport?.map((item) => {
      dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      item.isSelected = false;
      return item;
    });
    reports.quarterly?.map((item) => {
      dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      item.isSelected = false;
      return item;
    });
    reports.discussion?.map((item) => {
      dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      item.isSelected = false;
      return item;
    });
    reports.snapshot?.map((item) => {
      dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      item.isSelected = false;
      return item;
    });

    dateList = removeDuplicates(dateList);
    dateList = sortArray(dateList, "", "desc");

    let years = [];
    dateList?.map((e) =>
      years.push({
        value: e,
        label: lang === langTh ? displayYearBuddhistEra(new Date(`${e}-01-01`)) : displayYear(new Date(`${e}-01-01`)),
      })
    );

    handleMapDataList(dateList, reports);
    handleSetOptionYears(dateList, reports);
  };

  const handleMapDataList = (years, reports) => {
    let list = [];
    if (!selectedYear) {
      years?.forEach((item) => {
        const data = {
          isSelected: false,
          year: item,
          oneReport: reports?.oneReport?.filter(
            (r) => displayDate(r.attributes.date, "YYYY") === item
          ),
          quarterly: reports?.quarterly?.filter(
            (r) => displayDate(r.attributes.date, "YYYY") === item
          ),
          discussion: reports?.discussion?.filter(
            (r) => displayDate(r.attributes.date, "YYYY") === item
          ),
          snapshot: reports?.snapshot?.filter(
            (r) => displayDate(r.attributes.date, "YYYY") === item
          ),
        };
        list.push(data);
      });
    } else {
      const data = {
        isSelected: false,
        year: selectedYear,
        oneReport: reports?.oneReport,
        quarterly: reports?.quarterly,
        discussion: reports?.discussion,
        snapshot: reports?.snapshot,
      };
      list.push(data);
    }

    setReportList(list);
  };

  const handleSetOptionYears = (dateList, reports) => {
    let years = [];

    if (loadData) {
      dateList = [];

      loadData.oneReport?.forEach((item) => {
        dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      });
      loadData.quarterly?.forEach((item) => {
        dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      });
      loadData.discussion?.forEach((item) => {
        dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      });
      loadData.snapshot?.forEach((item) => {
        dateList.push(displayDate(item?.attributes?.date, "YYYY"));
      });

      dateList = removeDuplicates(dateList);
      dateList = sortArray(dateList, "", "desc");
      dateList?.map((e) =>
        years.push({
          value: e,
          label: lang === langTh ? displayYearBuddhistEra(new Date(`${e}-01-01`)) : displayYear(new Date(`${e}-01-01`)),
        })
      );
    } else {
      dateList?.map((e) =>
        years.push({
          value: e,
          label: lang === langTh ? displayYearBuddhistEra(new Date(`${e}-01-01`)) : displayYear(new Date(`${e}-01-01`)),
        })
      );
    }

    setOptionYears(years);
  };

  const onChangeYear = (e) => {
    setSelectedYear(e?.value);
  };

  const onSelectedAll = (checked, data, index) => {
    data.isSelected = checked;

    data.oneReport?.map((r, i) => {
      if (i === 0) {
        r.isSelected = checked;
      }
      return r;
    });

    data.quarterly?.map((r) => {
      r.isSelected = checked;
      return r;
    });

    data.discussion?.map((r) => {
      r.isSelected = checked;
      return r;
    });

    data.snapshot?.map((r) => {
      r.isSelected = checked;
      return r;
    });

    const reports = reportList;
    reports[index] = data;

    setReportList(reports);
    handleSetFileSize();
  };

  const handleSetFileSize = () => {
    let fileSize = 0;

    reportList?.forEach((report) => {
      report.oneReport?.forEach((item) => {
        if (item.isSelected) {
          fileSize += item?.attributes?.file?.data?.attributes?.size;
        }
      });

      report.quarterly?.forEach((item) => {
        if (item.isSelected) {
          fileSize += item?.attributes?.file?.data?.attributes?.size;
        }
      });

      report.discussion?.forEach((item) => {
        if (item.isSelected) {
          fileSize += item?.attributes?.file?.data?.attributes?.size;
        }
      });

      report.snapshot?.forEach((item) => {
        if (item.isSelected) {
          fileSize += item?.attributes?.file?.data?.attributes?.size;
        }
      });
    });

    setDownloadFileSize(fileSize);
  };

  const onChecked = (data, checked) => {
    data.isSelected = checked;
    handleSetFileSize();
  };

  const onClickDownload = async () => {
    let files = [];

    reportList?.forEach((report) => {
      report?.oneReport?.forEach((item) => {
        if (item.isSelected) {
          files.push(
            BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
          );
        }
      });

      report?.quarterly?.forEach((item) => {
        if (item.isSelected) {
          files.push(
            BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
          );
        }
      });

      report?.discussion?.forEach((item) => {
        if (item.isSelected) {
          files.push(
            BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
          );
        }
      });

      report?.snapshot?.forEach((item) => {
        if (item.isSelected) {
          files.push(
            BASE_URL_UPLOAD + item?.attributes?.file?.data?.attributes?.url
          );
        }
      });
    });

    if (files.length > 0) {
      const resDownload = await DownloadZipFile(files, "investor-kits");
      if (resDownload) {
        onClickReset();
      }
    }
  };

  const onClickReset = () => {
    let reports = [...reportList];
    reports?.map((report) => {
      report.oneReport?.map((item) => {
        item.isSelected = false;
        return item;
      });

      report.quarterly?.map((item) => {
        item.isSelected = false;
        return item;
      });

      report.discussion?.map((item) => {
        item.isSelected = false;
        return item;
      });

      report.snapshot?.map((item) => {
        item.isSelected = false;
        return item;
      });

      report.isSelected = false;
      return report;
    });

    setReportList(reports);
    setDownloadFileSize(0);
  };

  return (
    <InvestorKitStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.INVESTOR_KITS.LINK, text: translate["menu.home"] },
          { url: "/", text: translate["menu.home_investor_relation"] },
          { url: "/", text: translate["menu.published_document"] },
          {
            url: ROUTE_PATH.INVESTOR_KITS.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="investor-kits content-wrapper">
        <div className="text-investor-kits">
          {data?.attributes?.content_description}
        </div>
        <div className="select-years">
          <SelectBox options={optionYears} onChange={(e) => onChangeYear(e)} isSearchable={false} />
        </div>
        {/* Contents */}
        <div className="block-content">
          <div className="content">
            {reportList &&
              reportList.map((item, i) => {
                let oneReport = item.oneReport;
                let quarterly = item.quarterly;
                let discussion = item.discussion;
                let snapshot = item.snapshot;

                return (
                  <div className="content-investor-kit" key={i}>
                    <div className="title">
                      {lang === langTh ? displayYearBuddhistEra(new Date(`${item.year}-01-01`)) : displayYear(new Date(`${item.year}-01-01`))}
                    </div>
                    <div className="body">
                      {/* One report */}
                      <div className="body-year-report">
                        <div className="text-body">
                          {menu?.pages_one_report}
                        </div>
                        <div>
                          <Checkbox
                            type="checkbox"
                            id={`year-report-box1-left${i}`}
                            name={`year-report-box1-left${i}`}
                            disable={oneReport?.length <= 0}
                            value={oneReport[0]?.isSelected}
                            checked={oneReport[0]?.isSelected}
                            onChange={(event) => {
                              onChecked(oneReport[0], event.target.checked);
                            }}
                          />
                        </div>
                      </div>

                      {/* Quarterly */}
                      <div className="body-checkbox">
                        <div className="text-body">
                          {menu?.pages_financial_summary}
                        </div>
                        <div className="space-check-box">
                          <div className="space-text">
                            <div>
                              <Checkbox
                                type="checkbox"
                                id={`Q1-box1-left${i}`}
                                name={`Q1-box1-left${i}`}
                                disable={
                                  !quarterly?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )
                                }
                                checked={
                                  quarterly?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )?.isSelected
                                }
                                value={
                                  quarterly?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )?.isSelected
                                }
                                onChange={(event) => {
                                  onChecked(
                                    quarterly?.find(
                                      (r) => r?.attributes?.quarters === "Q1"
                                    ),
                                    event.target.checked
                                  );
                                }}
                              />
                            </div>
                            <div className="text-body">Q1</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q2-box1-left${i}`}
                              name={`Q2-box1-left${i}`}
                              disable={
                                !quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )
                              }
                              checked={
                                quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )?.isSelected
                              }
                              value={
                                quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  quarterly?.find(
                                    (r) => r?.attributes?.quarters === "Q2"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q2</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q3-box1-left${i}`}
                              name={`Q3-box1-left${i}`}
                              disable={
                                !quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )
                              }
                              checked={
                                quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )?.isSelected
                              }
                              value={
                                quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  quarterly?.find(
                                    (r) => r?.attributes?.quarters === "Q3"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q3</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q4-box1-left${i}`}
                              name={`Q4-box1-left${i}`}
                              disable={
                                !quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )
                              }
                              checked={
                                quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )?.isSelected
                              }
                              value={
                                quarterly?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  quarterly?.find(
                                    (r) => r?.attributes?.quarters === "Q4"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q4</div>
                          </div>
                        </div>
                      </div>

                      {/* Discussion */}
                      <div className="body-checkbox">
                        <div className="text-body">
                          {menu?.pages_financial_management_analyze}
                        </div>
                        <div className="space-check-box">
                          <div className="space-text">
                            <div>
                              <Checkbox
                                type="checkbox"
                                id={`Q1-box2-left${i}`}
                                name={`Q1-box2-left${i}`}
                                disable={
                                  !discussion?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )
                                }
                                checked={
                                  discussion?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )?.isSelected
                                }
                                value={
                                  discussion?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )?.isSelected
                                }
                                onChange={(event) => {
                                  onChecked(
                                    discussion?.find(
                                      (r) => r?.attributes?.quarters === "Q1"
                                    ),
                                    event.target.checked
                                  );
                                }}
                              />
                            </div>
                            <div className="text-body">Q1</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q2-box2-left${i}`}
                              name={`Q2-box2-left${i}`}
                              disable={
                                !discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )
                              }
                              checked={
                                discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )?.isSelected
                              }
                              value={
                                discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  discussion?.find(
                                    (r) => r?.attributes?.quarters === "Q2"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q2</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q3-box2-left${i}`}
                              name={`Q3-box2-left${i}`}
                              disable={
                                !discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )
                              }
                              checked={
                                discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )?.isSelected
                              }
                              value={
                                discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  discussion?.find(
                                    (r) => r?.attributes?.quarters === "Q3"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q3</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q4-box2-left${i}`}
                              name={`Q4-box2-left${i}`}
                              disable={
                                !discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )
                              }
                              checked={
                                discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )?.isSelected
                              }
                              value={
                                discussion?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  discussion?.find(
                                    (r) => r?.attributes?.quarters === "Q4"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q4</div>
                          </div>
                        </div>
                      </div>

                      {/* Snapshot */}
                      <div className="body-checkbox">
                        <div className="text-body">
                          {menu?.pages_company_snapshot}
                        </div>
                        <div className="space-check-box">
                          <div className="space-text">
                            <div>
                              <Checkbox
                                type="checkbox"
                                id={`Q1-box3-left${i}`}
                                name={`Q1-box3-left${i}`}
                                disable={
                                  !snapshot?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )
                                }
                                checked={
                                  snapshot?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )?.isSelected
                                }
                                value={
                                  snapshot?.find(
                                    (r) => r?.attributes?.quarters === "Q1"
                                  )?.isSelected
                                }
                                onChange={(event) => {
                                  onChecked(
                                    snapshot?.find(
                                      (r) => r?.attributes?.quarters === "Q1"
                                    ),
                                    event.target.checked
                                  );
                                }}
                              />
                            </div>
                            <div className="text-body">Q1</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q2-box3-left${i}`}
                              name={`Q2-box3-left${i}`}
                              disable={
                                !snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )
                              }
                              checked={
                                snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )?.isSelected
                              }
                              value={
                                snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q2"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  snapshot?.find(
                                    (r) => r?.attributes?.quarters === "Q2"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q2</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q3-box3-left${i}`}
                              name={`Q3-box3-left${i}`}
                              disable={
                                !snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )
                              }
                              checked={
                                snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )?.isSelected
                              }
                              value={
                                snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q3"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  snapshot?.find(
                                    (r) => r?.attributes?.quarters === "Q3"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q3</div>
                          </div>
                          <div className="space-text">
                            <Checkbox
                              type="checkbox"
                              id={`Q4-box3-left${i}`}
                              name={`Q4-box3-left${i}`}
                              disable={
                                !snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )
                              }
                              checked={
                                snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )?.isSelected
                              }
                              value={
                                snapshot?.find(
                                  (r) => r?.attributes?.quarters === "Q4"
                                )?.isSelected
                              }
                              onChange={(event) => {
                                onChecked(
                                  snapshot?.find(
                                    (r) => r?.attributes?.quarters === "Q4"
                                  ),
                                  event.target.checked
                                );
                              }}
                            />
                            <div className="text-body">Q4</div>
                          </div>
                        </div>
                      </div>

                      <div className="body-all-download">
                        <div className="text-body">{translate["investor_kits.download_all"]}</div>
                        <div>
                          <Checkbox
                            type="checkbox"
                            id={`all-download-left-${i}`}
                            name={`all-download-left-${i}`}
                            checked={item.isSelected}
                            value={item.isSelected}
                            onChange={(e) =>
                              onSelectedAll(e.target.checked, item, i)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Summary file size */}
          <div className="download">
            <div className="text-file">
              {translate["investor_kits.total_selected_file_size"]} {displayFileSizeMB(downloadFileSize)?.toFixed(2)} MB
            </div>
            <div className="btn-button">
              <div className="btn">
                <Button
                  className="medium-secondary-outline"
                  onClick={onClickDownload}
                >
                  <img
                    src="../asset/media/images/icons/icon-download-green.svg"
                    alt=""
                  />
                  {translate["investor_kits.download"]}
                </Button>
              </div>
              <div className="btn">
                <Button
                  className="medium-danger-outline"
                  onClick={onClickReset}
                >
                  <img
                    src="../asset/media/images/icons/icon-clear-danger.svg"
                    alt=""
                  />
                  {translate["investor_kits.clear"]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InvestorKitStyle>
  );
};

export default InvestorKitComponent;
