import styled from "styled-components";
import VARIABLES from "../../../../../../themes/variables";

// Wrapper
// ============================================================
export const TwoImageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: fit-content;

  img {
    width: 100%;
  }

  .container-wrapper {
    width: 611px;
    height: 590px;
    display: flex;
    position: relative;

    .section-left {
      position: absolute;
      bottom: 0;
      left: 0;
      .image-first {
        position: relative;
        padding-bottom: 100%;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
        width: 300px;
        height: 533px;
        margin-right: 48px;
      }
    }
    .section-right {
      position: absolute;
      top: 0;
      right: 0;
      .image-second {
        width: 263px;
        height: 334px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          box-shadow: 30px 30px 0px 0px #385278;
        }
      }
    }
  }

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1400.98px) {
    .container-wrapper {
      width: 480px;
      height: 480px;

      .section-left {
        .image-first {
          width: 230px;
          height: 360px;
          margin-right: 48px;
        }
      }
      .section-right {
        .image-second {
          width: 223px;
          height: 294px;
        }
      }
    }
  }

  @media (max-width: 1255.98px) {
    .container-wrapper {
      width: 360px;
      height: 340px;

      .section-left {
        .image-first {
          width: 170px;
          height: 300px;
          margin-right: 28px;
        }
      }
      .section-right {
        .image-second {
          width: 163px;
          height: 234px;
        }
      }
    }
  }

  @media (max-width: 768.98px) {
  }

  @media (max-width: 440.98px) {
    .container-wrapper {
      width: 330px;
      height: 330px;

      .section-left {
        .image-first {
          width: 160px;
          height: 290px;
          margin-right: 18px;
        }
      }
      .section-right {
        .image-second {
          width: 153px;
          height: 224px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
            box-shadow: 30px 30px 0px 0px #385278;
          }
        }
      }
    }
  }

  @media (max-width: 425.98px) {
    .container-wrapper {
      width: 320px;
      height: 530px;
      .section-left {
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        .image-first {
          width: 190px;
          height: 270px;
          margin-right: unset;
        }
      }
      .section-right {
        top: unset;
        bottom: 0;
        left: 53%;
        transform: translate(-53%, 0);
        .image-second {
          width: 143px;
          height: 234px;
        }
      }
    }
  }

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
