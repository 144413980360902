import CompanyIntroductionVDOComponent from "../components/Pages/CompanyIntroductionVDO";
import Auth from "../containerWarping/Auth";
const CompanyIntroductionVDO = () => {
  return (
    <Auth>
      <CompanyIntroductionVDOComponent />
    </Auth>
  );
};

export default CompanyIntroductionVDO;