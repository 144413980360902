import styled from "styled-components";
import VARIABLES from "../../../../themes/variables";

// Wrapper
// ============================================================
export const NewAndActivityCorporateDetailStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  position: relative;
  overflow: hidden;

  /* Child element styles
  ------------------------------- */
  .bg-gray-coporate-detail {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 200vw;
      height: 100%;
      transform: translate(-50%, 0);
      background-color: ${VARIABLES.COLOR.NEUTRAL7};
    }
  }

  .new-activity-detail {
    width: 100%;
    max-width: 1145px;
    margin: auto;
    padding: 0 30px;

    .activity-img {
      overflow: hidden;
      width: 100%;
      padding-top: 30px;
      border-radius: 12px;
    }

    .activity-title {
      margin: 30px 0;
    }

    .activity-body {
      margin-bottom:  25px;
      
      .body {
        margin-bottom: 30px;
        color: ${VARIABLES.COLOR.NEUTRAL20};
        /* text-align: justify; */
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0.4px;

        .clamp-lines__button {
          color: ${VARIABLES.COLOR.NEUTRAL20};
          display: contents;
        }

        .img-readmore {
          width: 28px;
          height: 40px;
        }

        .readmore {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .readmore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-readmore {
          color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          text-align: justify;
          font-family: Anuphan;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.32px;
        }

        .img-readmore {
          width: 28px;
          height: 40px;
        }
      }
    }

    .slide-img {
      align-items: center;
      width: 100%;
      padding: 30px 0;

      .content {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        max-height: 600px;

        .content-left {
          display: flex;
          flex-direction: column;

          .text-1 {
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            font-family: Anuphan;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0.64px;
          }

          .text-2 {
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            font-family: Anuphan;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 79px;
            letter-spacing: 0.96px;
          }
        }

        .content-mid {
          display: flex;
          justify-content: center;
          width: 100%;
          max-width: 150px;
          text-align: center;

          .slider-wrapper {
            .slider-d {
              padding: 15px 0;
              max-width: 150px;

              .slick-slider {
                .slick-list {
                  padding: 0 !important;

                  .slick-track {
                    .slick-slide {
                      & > div {
                        padding: 15px 0;
                      }
                    }
                  }
                }
              }
            }

            .slider-m {
              display: none;
            }
          }

          .button {
            height: 50px;
            width: 50px;
            align-self: center;
            border: none;
            background: none;
            cursor: pointer;
          }

          .img {
            width: 136px;
            height: 136px;
            border-radius: 20px;
            object-fit: cover;
          }
        }

        .content-right {
          margin-top: 30px;
          max-height: 670px;

          .img {
            width: 100%;
            height: 100%;
            max-width: 667px;
            max-height: 542px;
            object-fit: cover;
            border-radius: 20px;
            box-shadow: 2px 6px 15px 0px #d9d9d9;
          }
        }
      }
    }

    .activity-button {
      max-width: 130px;
      margin: 30px auto 45px;
    }
  }

  .up {
    position: fixed;
    top: 50%;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 990;
  }

  /* Media queries
  ------------------------------- */

  @media (max-width: 1100.98px) {
    .prev-arrow-c {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-40px, 65px) rotate(270deg);
      border-radius: 50%;
    }

    .next-arrow-c {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50px, 65px) rotate(270deg);
      border-radius: 50%;
    }

    .new-activity-detail {
      .activity-img {
        padding-top: 80px;
      }

      .slide-img {
        .content {
          flex-direction: column;
          max-height: unset;

          .content-left {
            flex-direction: unset;
            align-items: center;
            margin: auto;

            .text-1,
            .text-2,
            .text-3 {
              font-size: 40px;
              font-weight: 600;
            }
          }

          .content-mid {
            display: block;
            margin: 15px 0;
            max-width: 100%;

            .slider-wrapper {
              display: flex;
              justify-content: center;

              .slider-d {
                display: none;
              }

              .slider-m {
                display: block;
                width: 100%;

                .slick-slider {
                  .slick-list {
                    padding: 0 !important;

                    .slick-track {
                      .slick-slide {
                        & > div {
                          .content-mid {
                            & > div {
                              img {
                                margin: auto;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .content-right {
            align-self: center;
          }
        }
      }
    }
  }

  @media (max-width: 1024.98px) {
    .new-activity-detail {
      .slide-img {
        .content {
          .content-mid {
            .slider-wrapper {
              .slider-m {
                max-width: 80%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 840.98px) {
    .new-activity-detail {
      .slide-img {
        .content {
          .content-left {
            .text-1,
            .text-2,
            .text-3 {
              font-size: 32px;
            }
          }

          .content-mid {
            .slider-wrapper {
              .slider-m {
                max-width: 85%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768.98px) {
    .new-activity-detail {
      .slide-img {
        .content {
          .content-mid {
            .slider-wrapper {
              .slider-m {
                .slick-slider {
                  .slick-list {
                    .slick-track {
                      .slick-slide {
                        padding: 0 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 500.98px) {
    .new-activity-detail {
      .slide-img {
        .content {
          .content-left {
            .text-1,
            .text-2,
            .text-3 {
              font-size: 28px;
            }
          }

          .content-mid {
            .slider-wrapper {
              .slider-m {
                .slick-slider {
                  .slick-list {
                    .slick-track {
                      .slick-slide {
                        & > div {
                          .content-mid {
                            & > div {
                              display: flex;
                              justify-content: center;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 414.98px) {
    .new-activity-detail {
      .slide-img {
        .content {
          .content-left {
            .text-1,
            .text-2,
            .text-3 {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
`;
