import { useState, useEffect } from "react";
import { LOCAL_STORAGE } from "./constant";
import { setLocalStorage, getLocalStorage } from "./../helpers";

export let CONFIGS = {};

export default function useEnvConfigLoader() {
  const path = "/asset/configs/config.json";
  const [config, setConfig] = useState(null)

  useEffect(() => {
    const getConfig = async () => {
      try {
        const response = await fetch(path);
        const data = await response.json();
        CONFIGS = data;

        setLocalStorage(LOCAL_STORAGE.CONFIG, data);
        setConfig(data);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    }

    getConfig();
  }, [])
  
  return config;
}

export const getEnvConfig = () => {
  return CONFIGS || getLocalStorage(LOCAL_STORAGE.CONFIG);
}