import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'

// Wrapper
// ============================================================
export const NewsCardPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  max-width: 655px;

  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .csr-card-container {
    padding: 24px 30px;
    background-color: #FFFFFF;
    
    .img-news {
      position: relative;
      border-radius: 15px;
      
      img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: cover;
        border-radius: 15px;
      }
    }

    .card-details {
      margin-top: 15px !important;
      align-items: center;
      
      .date-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 125px;
        padding: 0 10px; 

        .card-date {
          font-size: 20px;
          font-weight: 500;
        }

        .card-time {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 300;

          .icon-time {
            margin-right: 5px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .card-content-inside {
      padding: 15px 10px;
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 991.98px) {
    .csr-card-container {
      padding: 24px 15px;
    }
  }

  @media (max-width: 820.98px) {
    .csr-card-container {
      .card-details {
        .date-time {
          display: block;

          .card-date {
            font-size: 16px;
          }

          .card-time {
            margin-top: 5px;
            font-size: 12px;

            .icon-time {
              width: 12px;
              height: 12px;
            }
          }
        }

        .card-content-inside {
          padding: 8px 10px;
          font-size: 12px;
        }
      }
    }
  }
`
