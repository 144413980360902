import React from "react";
import { ThreeCardStyle } from "./styled";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../../constants/routes";
import { useSelector } from "react-redux";

function ThreeCard({ image, date, title, header, detail, id, page }) {
  const translate = useSelector((state) => state.lang.translate);
  const history = useHistory();

  return (
    <ThreeCardStyle>
      <div className="block-card-csr-news">
        <div className="card-csr-news-img">
          <img className="img" src={image} alt="" />
        </div>
        <div className="card-csr-news-content">
          <div className="date">
            <div>{header}</div>
            <div>{date}</div>
          </div>
          <div className="block-content">
            <div className="content-title">{title}</div>
            <div className="content-body">{detail}</div>
            <div
              className="more"
              onClick={() => {
                history.push(
                  ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK +
                  "?id=" +
                  id +
                  "&page=" +
                  page
                );
              }}
            >
              {translate["common.see_more"]}
            </div>
          </div>
        </div>
      </div>
    </ThreeCardStyle>
  );
}

export default ThreeCard;
