import styled from "styled-components";
import VARIABLES from "../../../../../../themes/variables";

// Wrapper
// ============================================================
export const ThreeImageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: fit-content;

  img {
    width: 100%;
  }

  .container-wrapper {
    width: 484px;
    height: 383px;
    display: flex;

    .section-left {
      .image-main {
        position: relative;
        padding-bottom: 100%;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 8px 130px;
          object-fit: cover;
        }
        width: 276px;
        height: 383px;
        margin-right: 20px;
      }
    }
    .section-right {
      .symbol-wrapper {
        display: flex;
        margin-bottom: 15px;
        .circle {
          background-color: ${VARIABLES.COLOR.SECONDARY_HOVER};
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin-right: 7px;
        }
        .leaf {
          background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          width: 111px;
          height: 72px;
          border-radius: 60px 6px;
        }
      }
      .image-second {
        width: 188px;
        height: 142px;
        margin-bottom: 14px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 70px 70px 8px;
          object-fit: cover;
        }
      }
      .image-end {
        width: 188px;
        height: 142px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 70px 70px 8px;
          object-fit: cover;
        }
      }
    }
  }

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1200.98px) {
    .container-wrapper {
      width: 404px;
      height: 303px;

      .section-left {
        .image-main {
          img {
            border-radius: 8px 80px;
          }
          width: 196px;
          height: 303px;
          margin-right: 20px;
        }
      }
      .section-right {
        .symbol-wrapper {
          margin-bottom: 15px;
          .circle {
            width: 40px;
            height: 40px;
            margin-right: 7px;
          }
          .leaf {
            width: 80px;
            height: 40px;
            border-radius: 60px 6px;
          }
        }
        .image-second {
          width: 188px;
          height: 116px;
          margin-bottom: 14px;
        }
        .image-end {
          width: 188px;
          height: 116px;
        }
      }
    }
  }

  @media (max-width: 1024.98px) {
  }

  @media (max-width: 768.98px) {
  }

  @media (max-width: 548.98px) {
    .container-wrapper {
      width: 404px;
      height: 303px;

      .section-left {
        .image-main {
          img {
            border-radius: 8px 80px;
          }
          width: 196px;
          height: 303px;
          margin-right: 20px;
        }
      }
      .section-right {
        .symbol-wrapper {
          margin-bottom: 15px;
          .circle {
            width: 40px;
            height: 40px;
            margin-right: 7px;
          }
          .leaf {
            width: 80px;
            height: 40px;
            border-radius: 60px 6px;
          }
        }
        .image-second {
          width: 188px;
          height: 116px;
          margin-bottom: 14px;
        }
        .image-end {
          width: 188px;
          height: 116px;
        }
      }
    }
  }

  @media (max-width: 467.98px) {
    .container-wrapper {
      width: 304px;
      height: 203px;

      .section-left {
        .image-main {
          img {
            border-radius: 8px 60px;
          }
          width: 140px;
          height: 203px;
          margin-right: 10px;
        }
      }
      .section-right {
        .symbol-wrapper {
          margin-bottom: 10px;
          .circle {
            width: 30px;
            height: 30px;
            margin-right: 7px;
          }
          .leaf {
            width: 70px;
            height: 30px;
            border-radius: 30px 6px;
          }
        }
        .image-second {
          width: 153px;
          height: 76px;
          margin-bottom: 10px;
        }
        .image-end {
          width: 153px;
          height: 76px;
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .container-wrapper {
      width: 284px;
      height: 183px;

      .section-left {
        .image-main {
          img {
            border-radius: 8px 60px;
          }
          width: 130px;
          height: 183px;
          margin-right: 10px;
        }
      }
      .section-right {
        .image-second {
          width: 142px;
          height: 66px;
        }
        .image-end {
          width: 142px;
          height: 66px;
        }
      }
    }
  }

  @media (max-width: 333.98px) {
    .container-wrapper {
      width: 254px;
      height: 163px;

      .section-left {
        .image-main {
          img {
            border-radius: 8px 60px;
          }
          width: 120px;
          height: 163px;
          margin-right: 10px;
        }
      }
      .section-right {
        .image-second {
          width: 122px;
          height: 56px;
        }
        .image-end {
          width: 122px;
          height: 56px;
        }
      }
    }
  }
`;
