import React, { useState, useEffect } from "react";
import { BusinessStructurePageStyle } from "./styled";
import { PageHeader } from "../../Global";
import {
  PagesBusinessStructure,
  AssociatedCompany,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import { useDispatch, useSelector } from "react-redux";
import { openNewTab } from "../../../helpers";

const BusinessStructureComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [zoomBusinessChart, setzoomBusinessChart] = React.useState(false);
  const [data, setData] = useState(null);
  const [table, setTable] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const BusinessStructure = async () => {
      const Response = await PagesBusinessStructure();
      const ResponseTable = await AssociatedCompany({
        "sort[company_id]": "asc",
      });
      setData(Response?.data);
      setTable(ResponseTable?.data);
    };

    BusinessStructure();
  }, []);

  // Map company list
  const CompanyList = table?.map((company) => (
    <tr key={company?.id}>
      <td className="company-symbol">{company?.attributes?.company_symbol}</td>
      <td className="company_name">{company?.attributes?.company_name}</td>
    </tr>
  ));

  return (
    <BusinessStructurePageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: '/', text: translate["menu.home"] },
          { url: '/about', text: translate["menu.about_us"] },
          {
            url: "/about/business-structure",
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="business-structure">
          <div className="business-structure-chart">
            {data?.attributes?.structure_image?.data?.attributes?.url && (
              <img
                src={
                  data?.attributes?.structure_image?.data?.attributes?.url &&
                  BASE_URL_UPLOAD +
                    data?.attributes?.structure_image?.data?.attributes?.url
                }
                alt="business structure chart"
                className="img"
                // onClick={() => setzoomBusinessChart(true)}
                onClick={() =>
                  data?.attributes?.structure_image?.data?.attributes?.url &&
                  openNewTab(BASE_URL_UPLOAD + data?.attributes?.structure_image?.data?.attributes?.url)
                }
              />
            )}
          </div>

          <div className="business-lists">
            <div className="business-lists-header">{data?.attributes?.table_name}</div>

            <div className="business-lists-table-wrapper">
              <table className="business-lists-table">
                <thead>
                  <tr>
                    <th className="company-header" id="" colSpan={2}>
                      {data?.attributes?.group_title}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="business-lists-table-wrapper">
              <table className="business-lists-table">
                <tbody>
                  {CompanyList}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Lightbox
        carousel={{ finite: true }}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
        controller={{
          closeOnBackdropClick: true,
        }}
        open={zoomBusinessChart}
        close={() => setzoomBusinessChart(false)}
        plugins={[Zoom]}
        slides={[
          {
            src: BASE_URL_UPLOAD + data?.attributes?.structure_image?.data?.attributes?.url,
            alt: "business chart zoom",
          },
        ]}
      />
    </BusinessStructurePageStyle>
  );
};

export default BusinessStructureComponent;
