import React from "react";
import { CardTextStyle } from "./styled";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../constants/routes";
import { useSelector } from "react-redux";

function CardText({ date, title, header, detail, id, theme, page }) {
  const translate = useSelector((state) => state.lang.translate);
  const history = useHistory();

  return (
    <CardTextStyle>
      <div className={`card-text-content ${theme}`}>
        <div className="date">
          <div>{date}</div>
        </div>
        <div className="block-content">
          <div className="content-title">{title}</div>
          <div className="content-body">{detail}</div>
          <div
            className="more"
            onClick={() => {
              history.push(
                ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK +
                "?id=" +
                id +
                "&page=" +
                page
              );
            }}
          >
            {translate["common.see_more"]}
          </div>
        </div>
      </div>
    </CardTextStyle>
  );
}

export default CardText;
