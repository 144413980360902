import CorporateGovernancePolicyComponent from "../components/Pages/CorporateGovernancePolicy";
import Auth from "../containerWarping/Auth";
const CorporateGovernancePolicy = () => {
  return (
    <Auth>
      <CorporateGovernancePolicyComponent />
    </Auth>
  );
};

export default CorporateGovernancePolicy;