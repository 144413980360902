import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmailContactPageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import { Checkbox, Input, Modal, PageHeader } from "../../Global";
import { Link } from "react-router-dom";
import {
  PagesSubscription,
  SubscriptionEmail,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import { scrollToTop } from "./../../../helpers";

const EmailContactComponent = () => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [accept, setAccept] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
  });

  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
  })

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const Subscription = async () => {
      const Response = await PagesSubscription();
      setData(Response?.data);
    };

    Subscription();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!accept) {
      alert(translate["email_contact.message_error.invalid_terms"])
      return;
    }

    try {
      const payload = {
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
      };

      const response = await SubscriptionEmail(payload);
      if (response) {
        setOpenModal({isOpen:true});
        handleResetForm();
      }
    } catch (error) {
      console.error("Error submitting form", error);
      alert(translate["email_contact.message_error.submitting_form"]);
    }
  };

  const handleResetForm = () => {
    setFormData({
      name: "",
      surname: "",
      email: "",
    });
    setAccept(false);
  }

  let dataMockA = [
    {
      id: 1,
      title: "ขอเชิญประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
      date: "วันจันทร์ที่ 24 เมษายน 2566 เวลา 14.00 น.",
      contact: "โดยประชุมผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
      img: "../../asset/media/images/icons/icon-warning-message-confirmed-2.svg",
      alt: "icon-warning-message-confirmed",
      test: translate["email_contact.modal_form_success"],
    },
  ];

  return (
    <EmailContactPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.ask_for_information"] },
          { url: ROUTE_PATH.EMAIL_CONTACT.LINK, text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="email-contact content-wrapper">
          <div className="title-content">
            {data?.attributes?.contact_description}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="email-contact-form">
              <span className="form-name-lastname">
                <div className="form-name">
                  <Input
                    required
                    label={translate["email_contact.first_name"]}
                    type="text"
                    placeholder={translate["email_contact.first_name"]}
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-lastname">
                  <Input
                    required
                    label={translate["email_contact.last_name"]}
                    type="text"
                    placeholder={translate["email_contact.last_name"]}
                    value={formData.surname}
                    onChange={(e) =>
                      setFormData({ ...formData, surname: e.target.value })
                    }
                  />
                </div>
              </span>
              <div className="form-email">
                <Input
                  required
                  label={translate["email_contact.email"]}
                  type="email"
                  placeholder={translate["email_contact.email"]}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
              <div className="form-condition-policy">
                <Checkbox
                  className="checkbox-policy"
                  id="policy"
                  value={accept}
                  checked={accept}
                  onChange={(e) => setAccept( e.target.checked )}
                />
                <div className="condition-policy">
                  <p className="policy">
                    {translate["email_contact.i_have_read_conditions"]}
                  </p>
                  <Link
                    className="link condition"
                    to={ROUTE_PATH.PRIVACY_CENTER.LINK}
                    onClick={scrollToTop}
                   >
                    {translate["email_contact.personal_Policy"]}
                  </Link>
                </div>
              </div>
              <div className="btn-subscribe">
                <button
                  className="btn-inputform subscribe medium-secondary-outline"
                  type="submit"
                >
                  {translate["email_contact.subscribe_to_news"]}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>      
      {/* Modal */}
      { openModal.isOpen && (
        <Modal modalWarningMessage 
          children={dataMockA[0].test} 
          srcImage={dataMockA[0].img} 
          alt={dataMockA[0].alt}  
          onClose={(e) => {
            setOpenModal(e);
          }}
        />
      )}
    </EmailContactPageStyle>
  );
};

export default EmailContactComponent;