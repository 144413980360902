import styled from "styled-components";
import VARIABLES from "../../../../themes/variables";

// Wrapper
// ============================================================
export const NewAndActivityCorporateNewStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .activity-page {
    width: 100%;
    margin: auto;
    padding: 0 60px;
    max-width: 1440px;

    .new-activity-corporate {
      width: 100%;

      .year {
        margin: 60px 0;
        width: 100%;
        text-align: end;
      }

      .corporate {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
        justify-content: center;

        &.corporate-first {
          .two-card-centent {
            display: flex;

            & > div {
              &:first-child {
                padding-right: 15px;
              }

              &:last-child {
                padding-left: 15px;
              }
            }
          }
        }

        &.corporate-second {
          max-height: 400px;

          .card-img-text-wrapper {
            margin-right: 30px;
          }
        }

        &.corporate-third {
          max-height: 400px;
          
          &.mobile {
            display: none;
          }
          
          .card-img-text-wrapper {
            margin-left: 30px;
          }
        }
      }

      .corporate-news-three-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        & > div {
          &:first-child,
          &:last-child {
            padding-right: 15px;
          }
        }
      }

      .block-item-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 25px;
        margin-bottom: 30px;
      }
    }
  }

  .up {
    position: fixed;
    top: 50%;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 990;
  }
  /* Media queries
  ------------------------------- */

  /* @media (max-width: 1439.98px) {
    
  } */

  @media (max-width: 991.98px) {
    .activity-page {
      .new-activity-corporate {
        .year {
          margin: 30px 0;
        }

        .corporate {
          &.corporate-first {
            .two-card-centent {
              flex-direction: column;

              & > div {
                margin-bottom: 30px;

                &:first-child {
                  padding-right: 0;
                }

                &:last-child {
                  padding-left: 0;
                }
              }
            }
          }

          &.corporate-second {
            div > .block-img {
              display: none;
            }

            .card-img-text-wrapper {
              .card-img-text {
                flex-direction: column;

                .block-img {
                  max-width: 100%;
                }

                .card-img-text-content {
                  max-width: 100%;
                }
              }
            }
          }

          &.corporate-third {
            div > .block-img {
              display: none;
            }

            .card-img-text-wrapper {
              .card-img-text {
                flex-direction: column;

                .block-img {
                  max-width: 100%;
                }

                .card-img-text-content {
                  max-width: 100%;
                }
              }
            }
          }
        }

        .block-item-content {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

  @media (max-width: 768.98px) {
    .activity-page {
      padding: 0 20px;

      .new-activity-corporate {
        .corporate-news-three-content {
          flex-direction: column;
          justify-content: center;

          & > div {
            .block-card-csr-news {
              max-width: 100%;
            }

            &:first-child,
            &:last-child {
              padding-right: 0;
              margin-bottom: 30px;
            }
          }
        }

        .corporate {
          &.corporate-second {
            .card-img-text-wrapper {
              .card-img-text {
                .card-img-text-content {
                  padding: 20px 17px 60px;
                }
              }
            }
          }

          &.corporate-third {
            .card-img-text-wrapper {
              .card-img-text {
                .card-img-text-content {
                  padding: 20px 17px 60px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 730.98px) {
    .activity-page {
      .new-activity-corporate {
        .corporate {
          &.corporate-second {
            max-height: unset;
            flex-direction: column;

            div > .block-img {
              display: unset;
            }

            .card-img-text-wrapper {
              margin-right: 0;
              margin-bottom: 30px;
              
              .card-img-text {
                .card-img-text-content {
                  padding: 20px 17px 60px;
                }
              }
            }

            .card-img-wrapper {
              min-width: 100%;
              max-width: 100%;
            }
          }

          &.corporate-third {
            max-height: unset;
            flex-direction: column;

            &.desktop {
              display: none;
            }

            &.mobile {
              display: unset;
              
              .card-img-wrapper {
                margin-bottom: 30px;
              }
            }

            div > .block-img {
              display: unset;
            }

            .card-img-text-wrapper {
              margin-left: 0;
              margin-bottom: 30px;
            }

            .card-img-wrapper {
              min-width: 100%;
              max-width: 100%;
            }
          }
        }

        .block-item-content {
          grid-template-columns: 1fr;

          & > div {
            .card-text-content {
              max-width: 100%;
              height: auto;
              padding: 20px 17px 60px;

              .block-content {
                .content-title {
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
  }

  /* @media (max-width: 425.98px) {
  } */

  /* @media (max-width: 375.98px) {
    
  } */

  /* @media (max-width: 320.98px) {
    
  } */
`;
