import { css } from 'styled-components';
import VARIABLES from '.';

const TYPOGRAPHYS = {
  FONT_FAMILIES: {
    PRIMARY: `Anuphan`,
    // SECONDARY: `"Roboto"`,
  },

  HEADING: {
    _1: ({ weight = 700, size = 48 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 8) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 12) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 16) + 'px'};
        }
      `
    ,
    _2: ({ weight = 600, size = 36 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 9) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 12) + 'px'};
        }
      `
    ,
    _3: ({ weight = 700, size = 65 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 12) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 18) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 24) + 'px'};
        }
      `
    ,
    _4: ({ weight = 600, size = 50 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 5) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 10) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 15) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 20) + 'px'};
        }
      `
    ,
    _5: ({ weight = 700, size = 40 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 8) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 12) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 16) + 'px'};
        }
      `
    ,
    _6: ({ weight = 700, size = 28 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 8) + 'px'};
        }
      `
    ,
  },

  TITLE: {
    _1: ({ weight = 500, size = 34 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 9) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 12) + 'px'};
        }
      `
    ,
    _2: ({ weight = 400, size = 30 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 9) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 12) + 'px'};
        }
      `
    ,
    _3: ({ weight = 500, size = 26 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 7) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 10) + 'px'};
        }
      `
    ,
    _4: ({ weight = 700, size = 24 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 8) + 'px'};
        }
      `
    ,
    _5: ({ weight = 600, size = 38 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 10) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 14) + 'px'};
        }
      `
    ,

    _7: ({ weight = 700, size = 16 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _10: ({ weight = 600, size = 28 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 8) + 'px'};
        }
      `
    ,
    _11: ({ weight = 700, size = 18 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
  },

  BODY: {
    _1: ({ weight = 500, size = 24 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 8) + 'px'};
        }
      `
    ,
    _2: ({ weight = 400, size = 24 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 8) + 'px'};
        }
      `
    ,
    _3: ({ weight = 500, size = 20 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 6) + 'px'};
        }
      `
    ,
    _4: ({ weight = 400, size = 20 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 6) + 'px'};
        }
      `
    ,
    _5: ({ weight = 400, size = 18 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _6: ({ weight = 400, size = 16 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _7: ({ weight = 500, size = 16 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _8: ({ weight = 600, size = 24 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 4) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 6) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 8) + 'px'};
        }
      `
    ,
    _9: ({ weight = 500, size = 18 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _10: ({ weight = 400, size = 14 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 2) + 'px'};
        }
      `
    ,
    _11: ({ weight = 400, size = 12 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 1) + 'px'};
        }
      `
    ,
    _12: ({ weight = 700, size = 12 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 1) + 'px'};
        }
      `
    ,
    _13: ({ weight = 500, size = 12 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 1) + 'px'};
        }
      `
    ,
  },

  CAPTION: {
    _1: ({ weight = 500, size = 18 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _2: ({ weight = 300, size = 18 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _3: ({ weight = 300, size = 16 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 2) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
          font-size: ${(size - 3) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 4) + 'px'};
        }
      `
    ,
    _4: ({ weight = 300, size = 14 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
          font-size: ${(size - 1) + 'px'};
        }

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 2) + 'px'};
        }
      `
    ,
    _5: ({ weight = 300, size = 10 }) =>
      css`
        font-weight: ${weight};
        font-size: ${size + 'px'};

        @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
          font-size: ${(size - 1) + 'px'};
        }
      `
    ,
  },
}

export default TYPOGRAPHYS
