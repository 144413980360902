import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const EmailContactPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .email-contact {
    padding: 60px 100px;

    .title-content {
      text-align: center;
      margin-bottom: 40px;
      ${VARIABLES.TYPOGRAPHYS.BODY._4};
    }

    .email-contact-form {
      margin: 0 auto;
      max-width: 1000px;
      
      .form-name-lastname {
        display: flex;

        .form-name, 
        .form-lastname {
          width: 100%;
        }

        .form-lastname {
          margin-left: 20px;
        }
      }

      .form-name,
      .form-lastname,
      .form-condition-policy,
      .form-email {
        margin-bottom: 30px;
        ${VARIABLES.TYPOGRAPHYS.BODY._6};

        .field-wrapper {
          .input-wrapper {
            margin: 6px 0;

            .input {
              border-color: ${VARIABLES.COLOR.NEUTRAL11};
              border-radius: 6px;
            }
          }
        }
      }

      .form-condition-policy {
        display: flex;
        margin-top: 16px;

        .checkbox-policy {
          width: 18px;
          margin: 0 12px 0 0;
          
          .fake-input {
            .fake-checkbox {
              margin: 0;
              border-radius: 6px;
              border-color: ${VARIABLES.COLOR.NEUTRAL1};
            }
          }
        }

        .condition-policy {
          display: flex;
          width: 100%;

          .policy {
            margin: 0;
          }

          a {
            margin-left: 3px;
            text-decoration:underline;
            color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          }
        }
      }

      .btn-subscribe {
        display: flex;
        justify-content: center;
        
        .subscribe {
          max-width : 160px;
          min-width: 118px;
          ${VARIABLES.TYPOGRAPHYS.BODY._7};
          
          &:hover {
            background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          }
        }
      }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 690.98px) {
    .email-contact {
      padding: 30px 40px;

      .email-contact-form {
        .form-name-lastname {
          display: block;

          .form-name {
            margin-right: 0;
          }

          .form-lastname {
            margin-left: 0;
          }
        }

        .form-condition-policy {
          align-items: flex-start;

          .condition-policy {
            display: block;

            a {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`
