import styled from "styled-components";
import VARIABLES from "../../../../../themes/variables";

// Wrapper
// ============================================================
export const ViewVdoDetailStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .view-vdo-detail-wrapper {
    padding: 60px 146px;
    .vdo {
      width: 100%;
      height: 600px;
      border-radius: 6px;
      iframe {
        border-radius: 6px;
        display: block;
      }
    }
    .title {
      margin-top: 48px;
      ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })}
    }
    .sub-title-wrapper {
      margin-top: 18px;
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        ${VARIABLES.TYPOGRAPHYS.BODY._10}
      }
      
      .social-btn-save {
        .social-group {
          display: flex;
          justify-content: center;
          gap: 24px;
          margin-bottom: 10px;

          .icon-share-link {
            cursor: pointer;
          }
        }
      }
    }
    .detail {
      /* text-align: justify; */
      ${VARIABLES.TYPOGRAPHYS.BODY._4}
      margin-bottom: 48px;
    }
    .btn-back {
      width: 100px;
      white-space: nowrap;
      margin: 0 auto;
    }
  }

  /* Modifiers
  ------------------------------- */
  .btn-save {
    img {
      margin-right: 10px;
    }

    &:hover {
      img {
        border-radius: 12px;
        background-color: #5EAC1B;
      }
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
    .view-vdo-detail-wrapper {
      padding: 30px 100px;
      .vdo {
        height: 500px;
      }
      .title {
        margin-top: 38px;
      }
      .sub-title-wrapper {
        margin-top: 10px;
        margin-bottom: 38px;

        .social-btn-save {
          .social-group {
            gap: 14px;
          }
        }
      }
      .detail {
        margin-bottom: 38px;
      }
    }
  }

  @media (max-width: 768.98px) {
    .view-vdo-detail-wrapper {
      padding: 10px 80px;
      .vdo {
        height: 400px;
      }
    }
  }

  @media (max-width: 425.98px) {
    .view-vdo-detail-wrapper {
      padding: 10px 30px;
      .vdo {
        height: 300px;
      }
      .sub-title-wrapper {
        margin-top: 10px;
        margin-bottom: 28px;

        .social-btn-save {
          .social-group {
            gap: 8px;
          }
        }
      }
      .detail {
        margin-bottom: 28px;
      }
    }
  }

  @media (max-width: 375.98px) {
    .view-vdo-detail-wrapper {
      padding: 10px 30px;
      .vdo {
        height: 200px;
      }
      .sub-title-wrapper {
        margin-top: 10px;
        margin-bottom: 18px;
      }
      .detail {
        margin-bottom: 18px;
      }
    }
  }

  @media (max-width: 320.98px) {
    .view-vdo-detail-wrapper {
    }
  }
`;
