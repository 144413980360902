import classNames from 'classnames'
import React from "react";
import { CardImgStyle } from "./styled";
import { ROUTE_PATH } from "../../../../../constants/routes";
import { useHistory } from "react-router-dom";
import { getBaseUploadUrl } from "./../../../../../Service/service";

function CardImg({ image, id, date, title, page, className }) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const history = useHistory();

  return (
    <CardImgStyle
      className={classNames(
        'card-img-wrapper',
        className
      )}
    >
      <div
        className="card-img"
        onClick={() => {
          history.push(
            ROUTE_PATH.NEWS_ACTIVITY_COPORATE_DETAIL.LINK +
            "?id=" +
            id +
            "&page=" +
            page
          );
        }}
      >
        <img
          src={image}
          alt=""
          className="img-content"
        />
        <div className="date">{date}</div>
        <div className="title">{title}</div>
      </div>
    </CardImgStyle>
  );
}

export default CardImg;
