import { MessageFromChairmanPageStyle } from "./styled";
import { PageHeader } from "../../Global";
import { ChairmanMessages, PageChairmanMessages } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import ScrollAnimation from "react-animate-on-scroll";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { ROUTE_PATH } from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";

const MessageFromChairmanComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState({});
  const [content, setContent] = useState([]);
  const [readMore, setReadMore] = useState([false]);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const MessageFromChairman = async () => {
      const Res = await PageChairmanMessages();
      setData(Res?.data);

      const Response = await ChairmanMessages();
      setContent(Response?.data);
    };

    MessageFromChairman();
  }, []);

  function truncateText(text, maxWords) {
    const words = text.split(" ");
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(" ");
    return truncatedText;
  }

  const PicFrame = ({ image }) => {
    return (
      <div className="img-wrapper">
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="pic-frame">
            <div className="symbol-wrapper">
              <div className="circle"></div>
              <div className="shape-back"></div>
            </div>
            <img
              className="img"
              alt="chairman pic"
              src={image}
              id="chairman_pic"
            />
          </div>
        </ScrollAnimation>
      </div>
    );
  };

  return (
    <MessageFromChairmanPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/about", text: translate["menu.about_us"] },
          {
            url: ROUTE_PATH.MESSAGE_FROM_CHAIRMAN.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
        id="message_from_chairman_page_header"
      />

      <div className="page-content fluid">
        {content?.map((e, i) => (
          <div key={i} id={i} className="message-from-chairman">
            <div className="quote-and-img">
              <div className="quote-and-chairman-name">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  delay={1000}
                >
                  {e?.attributes?.message && (
                    <div className="quote" id="chairman_quote">
                      {e?.attributes?.message}
                    </div>
                  )}

                  {e?.attributes?.name && (
                    <div className="chairman-name" id="chairman_name">
                      <b>{e?.attributes?.name}</b>
                    </div>
                  )}

                  {e?.attributes?.position && (
                    <div className="chairman-position" id="chairman_position">
                      {e?.attributes?.position}
                    </div>
                  )}

                </ScrollAnimation>
              </div>

              {e?.attributes?.photo?.data?.attributes?.url && (
                <PicFrame
                  image={
                    BASE_URL_UPLOAD +
                    e?.attributes?.photo?.data?.attributes?.url
                  }
                />
              )}
            </div>

            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <div className="content" id="message_from_chairman_content">
                <span style={{ display: "inline-flex" }}>
                  {e?.content_image?.data?.attributes?.url && (
                    <img
                      src={
                        BASE_URL_UPLOAD +
                        e?.content_image?.data?.attributes?.url
                      }
                      alt="content"
                    />
                  )}
                  <div className="content-wrapper">
                    {readMore[i] ? (
                      <>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={e?.attributes?.content}
                        />
                        <span
                          className="read-more"
                          onClick={() => {
                            const newArray = [...readMore];
                            newArray[i] = false;
                            setReadMore(newArray);
                          }}
                        >
                          {translate["common.read_less"]}
                        </span>
                      </>
                    ) : (
                      <>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            truncateText(e?.attributes?.content, 12) + "..."
                          }
                        />
                        <span
                          className="read-more"
                          onClick={() => {
                            const newArray = [...readMore];
                            newArray[i] = true;
                            setReadMore(newArray);
                          }}
                        >
                          {translate["common.read_more"]}
                        </span>
                      </>
                    )}
                  </div>
                </span>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
    </MessageFromChairmanPageStyle>
  );
};

export default MessageFromChairmanComponent;
