import React from 'react'
import { ThreeImageStyle } from './styled'
import { getBaseUploadUrl } from '../../../../../../Service/service'

function ThreeImage({ image }) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <ThreeImageStyle>
      <div className='container-wrapper'>
        <div className='section-left'>
          <div className="image-main">
            <img src={BASE_URL_UPLOAD + image[0].attributes.url} alt="main" />
          </div>
        </div>
        <div className='section-right'>
          <div className='symbol-wrapper'>
            <div className='circle'></div>
            <div className='leaf'></div>
          </div>
          <div className='image-second'>
            <img src={BASE_URL_UPLOAD + image[1].attributes.url} alt="second" />
          </div>
          <div className="image-end">
            <img src={BASE_URL_UPLOAD + image[2].attributes.url} alt="end" />
          </div>
        </div>
      </div>
    </ThreeImageStyle>
  )
}

export default ThreeImage