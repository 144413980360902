import classNames from 'classnames'
import { MenuItemStyle } from './styled'
import { Button } from '../../../Global'
import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../../../helpers';

const getActiveParentMenu = (pathname) => {
  if (pathname.includes('/about')) {
    return 'about';
  }
  if (pathname.includes('/our-business')) {
    return 'our-business';
  }
  if (pathname.includes('/sustainability')) {
    return 'sustainability';
  }
  if (pathname.includes('/corporate-governance')) {
    return 'corporate-governance';
  }
  if (pathname.includes('/news-activity')) {
    return 'news-activity';
  }
  if (pathname.includes('/work-with-us')) {
    return 'work-with-us';
  }
  if (pathname.includes('/financial-information')) {
    return 'financial-information';
  }
  if (pathname.includes('/stock-price-information')) {
    return 'stock-price-information';
  }
  if (pathname.includes('/information-shareholders')) {
    return 'information-shareholders';
  }
  if (pathname.includes('/news-activities')) {
    return 'news-activities';
  }
  if (pathname.includes('/publication')) {
    return 'publication';
  }
  if (pathname.includes('/investor-relations')) {
    return 'investor-relations';
  }
  if (pathname.includes('/contact-us')) {
    return 'contact-us';
  }
  // Add other parent menu conditions here if needed
  return null; // No active parent menu
};

export default function MenuItem({
  label,
  url,
  imgSrc,
  subItems,
  toggleMenu,
  isOpen,
  onClose,
  activeMatch,
  onClick,
  outSideUrl,
  isHide,
  isHome
}) {
  const location = useLocation();
  const activeParentMenu = getActiveParentMenu(location.pathname);
  // const [isHasSubmenu, setIsHasSubmenu] = useState(false);
  const [isHasSuperSubmenu, setIsHasSuperSubmenu] = useState(false);
  const [isIndexUrl, setClassIndexURL] = useState(false)

  useEffect(() => {
    subItems?.map((subItem, index) => {
      if (subItem?.subItems?.length > 0) {
        setIsHasSuperSubmenu(true)
      }
      return subItems;
    });

    if (url === '/') {
      setClassIndexURL(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItems]);

  const handleClick = () => {
    toggleMenu(label);
  };

  return (
    <MenuItemStyle className='nav-menu'>
      {
        subItems?.length > 0 ?
          <>
            <Button
              className={classNames(
                'button-toggle-outline',
                { 'is-active': activeParentMenu === activeMatch },
              )}
              onClick={handleClick}
            >
              <div style={{ display: isHide ? 'none' : 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  {label}
                </div>
                <img className='icon-chevron-down' src='../asset/media/images/icons/icon-chevron-down-white.svg' alt='icon-chevron-down' />
              </div>
            </Button>

            {isOpen && (
              <div className='main-menu-wrapper'>
                <div className='content-wrapper'>
                {imgSrc ?
                  <div className='image-wrapper'>
                    <div className='image-inner-wrapper'>
                      <img
                        src={imgSrc}
                        alt='menu img'
                      />
                    </div>
                  </div>
                  : null
                }

                <div className='submenu-wrapper'>
                  {
                    isHasSuperSubmenu ?
                      // case there are max Lv3 on children menu
                      (
                        <div className='submenu-inner-wrapper'>
                          <div className='submenu-col'>
                            {
                              subItems?.filter(r => r.label)?.map((subItem, index) => (
                                subItem.subItems.length === 0 && !subItem.isRight ?
                                  <div
                                    key={index}
                                    className='submenu'
                                    onClick={() => {onClick(); handleClick();}}
                                  >
                                    {
                                      subItem?.outSideUrl ? (
                                        <Link to={{ pathname: subItem.outSideUrl }} target="_blank">
                                          {subItem.label}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={subItem.url}
                                          className='menu-anchor'
                                          onClick={() => { scrollToTop(); onClose(false); }}
                                        >
                                          {subItem.label}
                                        </Link>
                                      )
                                    }
                                  </div>
                                  : subItem.isLeft ?
                                    <Fragment key={index}>
                                      <div className='submenu fake-anchor'>
                                        {subItem.label}
                                      </div>

                                      {
                                        subItem?.subItems?.filter(r => r.label)?.map((supersubItem, index) => (
                                          <div
                                            key={index}
                                            className='submenu super'
                                            onClick={() => {onClick(); handleClick();}}
                                          >
                                            {
                                              supersubItem?.outSideUrl ? (
                                                <Link to={{ pathname: supersubItem.outSideUrl }} target="_blank">
                                                  {supersubItem.label}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={supersubItem.url}
                                                  className='menu-anchor'
                                                  onClick={() => { scrollToTop(); onClose(false); }}
                                                >
                                                  {supersubItem.label}
                                                </Link>
                                              )
                                            }
                                          </div>
                                        ))
                                      }
                                    </Fragment>
                                  : null
                              ))
                            }
                          </div>

                          <div className='submenu-col super-submenu'>
                            {
                              subItems?.filter(r => r.label)?.map((subItem, index) => (
                                subItem.subItems.length !== 0 && !subItem.isLeft ?
                                  <Fragment key={index}>
                                    <div className='submenu fake-anchor'>
                                      {subItem.label}
                                    </div>

                                    {
                                      subItem?.subItems?.filter(r => r.label)?.map((supersubItem, index) => (
                                        <div
                                          key={index}
                                          className='submenu super'
                                          onClick={() => {onClick(); handleClick();}}
                                        >
                                          {
                                            supersubItem?.outSideUrl ? (
                                              <Link to={{ pathname: supersubItem.outSideUrl }} target="_blank">
                                                {supersubItem.label}
                                              </Link>
                                            ) : (
                                              <Link
                                                to={supersubItem.url}
                                                className='menu-anchor'
                                                onClick={() => { scrollToTop(); onClose(false); }}
                                              >
                                                {supersubItem.label}
                                              </Link>
                                            )
                                          }
                                        </div>
                                      ))
                                    }
                                  </Fragment>
                                  : subItem.isRight ? 
                                    <div
                                      key={index}
                                      className='submenu'
                                      onClick={() => {onClick(); handleClick();}}
                                    >
                                      {
                                        subItem?.outSideUrl ? (
                                          <Link to={{ pathname: subItem.outSideUrl }} target="_blank">
                                            {subItem.label}
                                          </Link>
                                        ) : (
                                          <Link
                                            to={subItem.url}
                                            className='menu-anchor'
                                            onClick={() => { scrollToTop(); onClose(false); }}
                                          >
                                            {subItem.label}
                                          </Link>
                                        )
                                      }
                                    </div>
                                  : null
                              ))
                            }
                          </div>
                        </div>
                      )

                      // case only max lv2 on children menu
                      : (
                        <div className='submenu-inner-wrapper'>
                          <div className='submenu-row'>
                            {
                              subItems?.filter(r => r.label)?.map((subItem, index) => (
                                <div
                                  className='submenu'
                                  key={index}
                                  onClick={() => {onClick(); handleClick();}}
                                >
                                  {
                                    subItem?.outSideUrl ? (
                                      <Link to={{ pathname: subItem.outSideUrl }} target="_blank">
                                        {subItem.label}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={subItem.url}
                                        className='menu-anchor'
                                        onClick={() => { scrollToTop(); onClose(false); }}
                                      >
                                        {subItem.label}
                                      </Link>
                                    )
                                  }
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                  }
                </div>
                {/* <span className='bg-transparent' onClick={handleClick}></span> */}
              </div>
              </div>
            )
            }
          </>
          :
          <div
            className={classNames(
              'button button-toggle-outline',
              { 'is-hide-desktop': url === '/' ? true : false },
              { 'is-active': activeParentMenu === activeMatch },
            )}
            style={{ display: isHide ? 'none' : 'flex'}}
            onClick={() => {onClick(); handleClick();}}
          >
            {
              outSideUrl ? (
                <Link to={{ pathname: outSideUrl }} target="_blank">
                  {label}
                </Link>
              ) : 
              (
                <Link
                  to={url}
                  className='menu-anchor'
                  onClick={() => { scrollToTop(); onClose(false); }}
                >
                  {label}
                </Link>
              )
            }
          </div>
      }
    </MenuItemStyle >
  );
};

