import styled from 'styled-components';
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const TabBoxStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .react-tabs {
    .react-tabs__tab-list {
      border: 0;
      display: flex;

      .react-tabs__tab {
        flex: 1 1 auto;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        color: ${VARIABLES.COLOR.GRAY_2};
        max-width: 50%;

        &:focus {
          box-shadow: none;

          &::after {
            display: none;
          }
        }
      }

      .react-tabs__tab--selected {
        border: 0;
        border-radius: 0;
        border-bottom: 4px solid ${VARIABLES.COLOR.PRIMARY};
        color: ${VARIABLES.COLOR.PRIMARY};
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    /* .react-tabs {
      .react-tabs__tab-list {
        .react-tabs__tab {
          font-size: 20px;
          padding: 6px 10px;
        }

        .react-tabs__tab--selected {
          border-bottom: 2px solid ${VARIABLES.COLOR.PRIMARY};
        }
      }
    } */
  }

  @media (max-width: 1280.98px) {
    /* .react-tabs {
      .react-tabs__tab-list {
        .react-tabs__tab {
          font-size: 18px;
          padding: 5px 9px;
        }
      }
    } */
  }

  @media (max-width: 1024.98px) {
    /* .react-tabs {
      .react-tabs__tab-list {
        .react-tabs__tab {
          font-size: 16px;
          padding: 4px 7px;
        }

        .react-tabs__tab--selected {
          border-bottom: 1px solid ${VARIABLES.COLOR.PRIMARY};
        }
      }
    } */
  }
`;

/*export default TabBoxStyle;*/
