import WorkWithUsPageInterestingPositionComponent from "../components/Pages/WorkWithUsPage/InterestingPosition";
import Auth from "../containerWarping/Auth";
const WorkWithUsPageInterestingPosition = () => {
  return (
    <Auth>
      <WorkWithUsPageInterestingPositionComponent />
    </Auth>
  );
};

export default WorkWithUsPageInterestingPosition;
