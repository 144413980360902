import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompanySnapshotPageStyle } from "./styled";
import { ROUTE_PATH } from "../../../constants/routes";
import { PageHeader } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import CardCompanySnapshot from "./components/CardCompanySnapshot";
import { SaveFile } from "./../../../Service/API";
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  PagesCompanySnapshot,
  CompanySnapshotFiles,
} from "./../../../Service/publications/companySnapshotService";
import {
  removeDuplicates,
  displayYearBuddhistEra,
  displayQuarter,
  displayYear,
} from "./../../../helpers";
import moment from "moment";
import { langTh } from "../../../store/lang";

const CompanySnapshotComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const defaultOptionYears = [
    { value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date())},
  ];
  const [data, setData] = useState({});
  const [content, setContent] = useState([]);
  const [date, setDate] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState(defaultOptionYears);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const CompanySnapshot = async () => {
      const resPage = await PagesCompanySnapshot();
      setData(resPage?.data);

      const payloadDate = {
        "fields[0]": "date",
        "sort[date]": "desc",
      };
      const resDate = await CompanySnapshotFiles(payloadDate);
      setDate(resDate?.data);

      setIsMounted(true);
    };

    CompanySnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const CompanySnapshot = async () => {
      const payload = {
        "filters[date][$gte]": `${selectedYear}-01-01`,
        "filters[date][$lte]": `${selectedYear}-12-31`,
        // "sort[date]": "desc",
        // "sort[id]": "desc",
        "sort[quarters]": "desc"
      };
      const resContent = await CompanySnapshotFiles(payload);
      setContent(resContent?.data);
    };

    selectedYear && CompanySnapshot();
  }, [selectedYear]);

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({ value: e, label: lang === langTh ? displayYearBuddhistEra(e) : e })
    );

    setOptionYears(yearsData);
    yearsData[0] && onChangeYear(yearsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onChangeYear = (e) => {
    setSelectedYear(e?.value);
  };

  return (
    <CompanySnapshotPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "/", text: translate["menu.home_investor_relation"] },
          { url: "/", text: translate["menu.published_document"] },
          {
            url: ROUTE_PATH.COMPANY_SNAPSHOT.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="company-snapshot content-wrapper">
          <div className="block-card-big">
            <img
              className="bg-img"
              src={
                data?.attributes?.overview_image?.data?.attributes?.url
                  ? BASE_URL_UPLOAD + data?.attributes?.overview_image?.data?.attributes?.url
                  : "../asset/media/images/contents/bg-ship-company-snapshot.png"
                }
              alt="icon-arrow-down-circle-white"
            />
            <div className="card-big-company-snapshot">
              <div className="card-head">
                <p className="year">
                  {translate["company_snapshot.annual"]}{" "}
                  {lang === langTh ? displayYearBuddhistEra(content[0]?.attributes?.date) : displayYear(content[0]?.attributes?.date)}
                </p>
              </div>
              <div className="card-body">
                <img
                  className="icon"
                  src="../asset/media/images/icons/icon-bank-white.svg"
                  alt="icon-bank-white"
                />
                <p className="name">
                  {(content[0]?.attributes?.quarters ||
                    `Q${displayQuarter(content[0]?.attributes?.date)}`) +
                    `/${lang === langTh ? displayYearBuddhistEra(content[0]?.attributes?.date) : displayYear(content[0]?.attributes?.date)}`}
                </p>
              </div>
              <div className="card-footer">
                <div
                  className="file-download"
                  onClick={() => {
                    SaveFile(
                      BASE_URL_UPLOAD +
                        content[0]?.attributes?.file?.data?.attributes?.url,
                      content[0]?.attributes?.file?.data?.attributes?.url
                    );
                  }}
                >
                  <img
                    className="icon icon-download"
                    src="../asset/media/images/icons/icon-arrow-down-circle-white.svg"
                    alt="icon-arrow-down-circle-white"
                  />
                  {translate["common.download_document"]}
                </div>
              </div>
            </div>
          </div>

          <div className="block-card-content">
            <div className="select-wrapper">
              <span>{translate["common.year"]}</span>
              {isMounted && (
                <SelectBox
                  placeholder={optionYears[0]?.label}
                  defaultValue={optionYears[0]}
                  options={optionYears}
                  onChange={(e) => onChangeYear(e)}
                  isSearchable={false}
                />
              )}
            </div>
            <div className="block-card">
              {content &&
                content?.map((e, i) => {
                  let data = "";
                  if (i > 0) {
                    data = (
                      <CardCompanySnapshot
                        key={i}
                        cardHeadName={
                          `${translate["company_snapshot.annual"]} ` +
                          `${lang === langTh ? displayYearBuddhistEra(e?.attributes?.date) : displayYear(e?.attributes?.date)}`
                        }
                        cardBodyName={
                          (e?.attributes?.quarters ||
                            `Q${displayQuarter(e?.attributes?.date)}`) +
                          `/${lang === langTh ? displayYearBuddhistEra(e?.attributes?.date) : displayYear(e?.attributes?.date)}`
                        }
                        file={e?.attributes?.file?.data?.attributes}
                      />
                    );
                  }

                  return data;
                })}
            </div>
          </div>
        </div>
      </div>
    </CompanySnapshotPageStyle>
  );
};

export default CompanySnapshotComponent;
