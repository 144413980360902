import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'
// Wrapper
// ============================================================
export const CardImgStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  min-width: 435px;
  max-width: 435px;

  .card-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:hover {
      .img-content{
        transform: scale(1.1);
      }
    }
    
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba(2, 47, 134, 0.50) 0%, rgba(2, 47, 134, 0.00) 100%);
      z-index: 1;
    }

    .img-content{
      position: relative;
      border-radius: 6px;
      transition: transform .2s;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .date {
      position: absolute;
      top: 22px;
      right: 22px;
      color: #FFFFFF;
      ${VARIABLES.TYPOGRAPHYS.CAPTION._4};
      z-index: 1;
    }

    .title {
      position: absolute;
      bottom: 22px;
      left: 22px;
      color: #FFFFFF;
      ${VARIABLES.TYPOGRAPHYS.HEADING._6};
      z-index: 1;
    }
  }
  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  
  @media (max-width: 1200.98px) {
    min-width: 350px;
    max-width: 350px;
  }

  @media (max-width: 1024.98px) {
   
  }

  @media (max-width: 768.98px) {
   
  }

  @media (max-width: 425.98px) {
    
  }

  @media (max-width: 375.98px) {
    
  }

  @media (max-width: 320.98px) {
   
  }
`
