import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_PATH } from '../../../constants/routes';
import { OtherDocumentsStyle } from './styled';
import { PageHeader, CardDownloadFile } from '../../Global';
import { getBaseUploadUrl } from "../../../Service/service";
import { PagesOtherDocuments, OtherDocumentFiles } from "./../../../Service/publications";
import { displayDate, displayYear, displayYearBuddhistEra } from "../../../helpers";
import { langTh } from "../../../store/lang";

const OtherDocumentsComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [content, setContent] = useState([]);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const OtherDocument = async () => {
      try {
        const resPage = await PagesOtherDocuments();
        const resContent = await OtherDocumentFiles({
          "sort[date]": "desc",
          "sort[id]": "desc"
        });
        setData(resPage?.data);
        setContent(resContent?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    OtherDocument();
  }, []);

  return (
    <OtherDocumentsStyle>

      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: '/home-investor', text: translate["menu.home"] },
          { url: '#', text: translate["menu.home_investor_relation"] },
          { url: '#', text: translate["menu.published_document"] },
          { url: ROUTE_PATH.OTHER_DOCUMENTS.LINK, text: data?.attributes?.menu_text }
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="page-content-wrapper">
        <div className='page-content fluid'>
          <div className='other-documents content-wrapper'>
            {content && content?.map((d, i) => {
              const file = d?.attributes?.file?.data;
              return (
                <CardDownloadFile
                  key={i}
                  id={d.id}
                  title={d?.attributes?.file_name}
                  date={`${displayDate(d?.attributes?.date, "DD/MM")}/${lang === langTh ? displayYearBuddhistEra(new Date(d?.attributes?.date)) : displayYear(d?.attributes?.date)}`}
                  url={file?.attributes?.url}
                  fileName={file?.attributes?.name}
                  btnName={translate["common.download_document"]}
                />
              )
            })}
          </div>
        </div>
      </div>
    </OtherDocumentsStyle>
  );
};

export default OtherDocumentsComponent;