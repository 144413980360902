import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const BoardDirectorsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    min-height: 645px;
  }
  
  .board-directors {
    padding: 60px;
    max-width: 1920px;
    margin: auto;

    .block-board-directors {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 50px;
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1300px) {
    .board-directors {
      .block-board-directors {
      grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media (max-width: 1100px) {
    margin-top: 60px;
  }

  @media (max-width: 930.98px) {
    .board-directors {
      padding: 30px 25px;

      .block-board-directors {
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      }
    }
  }
  
  @media (max-width: 800.98px) {
    .board-directors {
      padding: 30px 25px;

      .block-board-directors {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
  }

  /* @media (max-width: 767.98px) {
    
  } */

  @media (max-width: 600.98px) {
    .board-directors {
      .block-board-directors {
        justify-items: center;
      }
    }
  }

  /* @media (max-width: 450.98px) {
    
  } */
`
