import classNames from 'classnames'
import { PageHeaderStyle } from './styled'
import
Breadcrumb, {
  BreadcrumbItem
} from '../Breadcrumb'


export default function PageHeader({
  children,
  id,
  className,
  title,
  pageHeaderImage,
  pageHeaderImageAlt,
  breadcrumb
}) {
  const BreadcrumblistItems = breadcrumb.map((breadcrumb, index) =>
    <BreadcrumbItem
      key={index}
      linkTo={breadcrumb.url}
      id={'breadcrumb-item' + id + index}
    >
      {breadcrumb.text}
    </BreadcrumbItem>
  );

  return (
    <PageHeaderStyle
      className={classNames(
        'page-header',
        className
      )}
      id={id}
    >
      <img
        className='page-header-bg-img'
        alt={pageHeaderImageAlt}
        src={pageHeaderImage}
      />

      <div className='page-header-content'>
        {
          breadcrumb ?
            <Breadcrumb id={'breadcrumb' + id}>
              {BreadcrumblistItems}
            </Breadcrumb>
            : undefined
        }

        <div className='title'>
          {title}
        </div>
      </div>
    </PageHeaderStyle>
  )
}

PageHeader.defaultProps = {
  pageHeaderImageAlt: 'page header image',
  breadcrumb: [],
  title: 'page title'
}

