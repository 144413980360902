import React from 'react'
import { TwoImageStyle } from './styled'
import { getBaseUploadUrl } from '../../../../../../Service/service'

function TwoImage({ image }) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <TwoImageStyle>
      <div className='container-wrapper'>
        <div className='section-left'>
          <div className="image-first">
            <img src={BASE_URL_UPLOAD + image[0].attributes.url} alt="main" />
          </div>
        </div>
        <div className='section-right'>
          <div className='image-second'>
            <img src={BASE_URL_UPLOAD + image[1].attributes.url} alt="second" />
          </div>
        </div>
      </div>
    </TwoImageStyle>
  )
}

export default TwoImage