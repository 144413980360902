import ShareholderMeetingDocumentsComponent from "../components/Pages/ShareholderMeetingDocuments";
import Auth from "../containerWarping/Auth";
const ShareholderMeetingDocuments = () => {
  return (
    <Auth>
      <ShareholderMeetingDocumentsComponent />
    </Auth>
  );
};

export default ShareholderMeetingDocuments;