export const ROUTE_PATH = {
  HOME: {
    TEXT: "Home",
    LINK: "/",
  },
  ABOUT: {
    TEXT: "About",
    LINK: "/about",
  },
  AUDIT_COMMITTEE: {
    TEXT: "Audit Committee",
    LINK: "/corporate-governance/audit-committee",
  },
  BOARD_DIRECTORS: {
    TEXT: "Board Directors",
    LINK: "/corporate-governance/board-directors",
  },
  WE_ARE_BIOTEC: {
    TEXT: "We are biotec",
    LINK: "/about/we-are-biotec",
  },
  VISION_AND_MISION: {
    TEXT: "Vision & Mission",
    LINK: "/about/vision-and-mission",
  },
  COMPANY_PROFILE: {
    TEXT: "Company Profile",
    LINK: "/about/company-profile",
  },
  MESSAGE_FROM_CHAIRMAN: {
    TEXT: "Message From the Chairman",
    LINK: "/about/message-from-the-chairman",
  },
  SUBSIDIARY_AND_ASSOCIATED_COMPANIES: {
    TEXT: "Subsidiary and Associated Companies",
    LINK: "/about/subsidiary-and-associated-companies",
  },
  BUSINESS_STRUCTURE: {
    TEXT: "Business Structure",
    LINK: "/about/business-structure",
  },
  MANAGEMENT_STRUCTURE: {
    TEXT: "Management Structure",
    LINK: "/about/management-structure",
  },
  EMAIL_CONTACT: {
    TEXT: "Email Contact",
    LINK: "/investor-relations/email-contact",
  },
  INVESTOR_RELATIONS_CONTACT: {
    TEXT: "Investor Relations Contact",
    LINK: "/investor-relations/investor-relations-contact",
  },
  SET_ANNOUNCEMENTS: {
    TEXT: "SET Announcements",
    LINK: "/news-activities/set-announcements",
  },
  SHAREHOLDER_MEETING_DOCUMENT: {
    TEXT: "Shareholder Meeting Documents",
    LINK: "/information-shareholders/shareholder-meeting-documents",
  },
  SHAREHOLDER_MEETING_DOCUMENTAS: {
    TEXT: "Shareholder Meeting Documents",
    LINK: "/information-shareholders/shareholder-meeting-documentas",
  },
  SUPPLY_CHAIN_MANAGEMENT: {
    TEXT: "Supply Chain Management",
    LINK: "/sustainability/supply-chain-management",
  },
  SOCIAL_DIMENSION_MANAGEMENT: {
    TEXT: "Social Dimension Management",
    LINK: "/sustainability/social-dimension-management",
  },
  ENVIRONMENTAL_MANAGEMENT: {
    TEXT: "Environmental Management",
    LINK: "/sustainability/environmental-management",
  },
  SUSTAINABLE_DEVELOPMENT_GOALS: {
    TEXT: "Sustainable Development Goals",
    LINK: "/sustainability/sustainable-development-goals",
  },
  SUSTAINABLE_DEVELOPMENT_POLICY: {
    TEXT: "Sustainable Development Policy",
    LINK: "/sustainability/sustainable-development-policy",
  },
  SOCIAL_RESPONSIBILITY_REPORT: {
    TEXT: "Social Responsibility Report",
    LINK: "/sustainability/social-responsibility-report",
  },
  COMPANY_POLICY: {
    TEXT: "Company Policy",
    LINK: "/corporate-governance/company-policy",
  },
  CORPORATE_DOCUMENTS: {
    TEXT: "Corporate Documents",
    LINK: "/corporate-governance/corporate-documents",
  },
  CORPORATE_GOVERNANCE_POLICY: {
    TEXT: "Policy",
    LINK: "/corporate-governance-policy/:id",
  },
  WORK_WITH_US_PAGE_INTERESTING_POSITION: {
    TEXT: "Interesting Position",
    LINK: "/work-with-us/interesting-position",
  },
  WORK_WITH_US_PAGE_ONLINE_REGISTER: {
    TEXT: "Oneline Register",
    LINK: "/work-with-us/online-register",
  },
  OTHER_DOCUMENTS: {
    TEXT: "Other Documents",
    LINK: "/publications/other-documents",
  },
  NEWS_ACTIVITY_COPORATE_NEW: {
    TEXT: "Corporate New",
    LINK: "/news-activity/corporate-new",
  },
  NEWS_ACTIVITY_COPORATE_DETAIL: {
    TEXT: "Corporate Detail",
    LINK: "/news-activity/corporate-detail",
  },
  NEWS_ACTIVITY_CSR: {
    TEXT: "CSR Activity",
    LINK: "/news-activity/csr",
  },
  OUR_BUSINESS: {
    TEXT: "Our Business",
    LINK: "/our-business/:id",
  },
  QUARTERLY_FINANCIAL_SUMMARY: {
    TEXT: "Quarterly Financial Summary",
    LINK: "/financial-information/quarterly-financial-summary",
  },
  MANAGEMENT_DISCUSSION_ANALYSIS: {
    TEXT: "Management Discussion Analysis",
    LINK: "/financial-information/management-discussion-analysis",
  },
  ANNUAL_REPORT: {
    TEXT: "Annual Report",
    LINK: "/financial-information/annual-report",
  },
  ONE_REPORT: {
    TEXT: "56-1 One Report",
    LINK: "/publications/56-1-one-report",
  },
  CHANNELS_RECEIVING_COMPLAINTS: {
    TEXT: "Channels Receiving Complaints",
    LINK: "/corporate-governance/channels-receiving-complaints",
  },
  HOME_INVESTOR: {
    TEXT: "Home Investor",
    LINK: "/home-investor",
  },
  PRIVACY_CENTER: {
    TEXT: "Privacy Center",
    LINK: "/privacy-center",
  },
  COOKIE_POLICY: {
    TEXT: "Cookie Policy",
    LINK: "/cookie-policy",
  },
  TERMS_AND_CONDITIONS: {
    TEXT: "Terms & Conditions",
    LINK: "/terms-and-conditions",
  },
  COMPANY_INTRODUCTION_VDO: {
    TEXT: "Company Introduction",
    LINK: "/publications/company-introduction-vdo",
  },
  SHAREHOLDER_MEETING_VDO: {
    TEXT: "Shareholder Meeting VDO",
    LINK: "/publications/shareholder-meeting-vdo",
  },
  COMPANY_SNAPSHOT: {
    TEXT: "Company Snapshot",
    LINK: "/publications/company-snapshot",
  },
  CONTACT_US: {
    TEXT: "Contact",
    LINK: "/contact-us",
  },
  SHAREHOLDER_STRUCTURE: {
    TEXT: "Shareholder",
    LINK: "/information-shareholders/shareholder-structure",
  },
  STOCK_PRICE: {
    TEXT: "Stock Price",
    LINK: "/stock-price-information/stock-price",
  },
  INVESTOR_RELATIONS_CALENDAR: {
    TEXT: "Investor Relations Calendar",
    LINK: "/information-shareholders/investor-relations-calendar",
  },
  HISTORICAL_PRICE: {
    TEXT: "Historical Price",
    LINK: "/stock-price-information/historical-price",
  },
  FINANCIAL_HIGHLIGHTS: {
    TEXT: "Financial Highlights",
    LINK: "/financial-information/financial-highlights",
  },
  INVESTOR_KITS: {
    TEXT: "Investor Kits",
    LINK: "/publications/investor-kits",
  },
  DAVIDEND_POLICY: {
    TEXT: "davidend-policy",
    LINK: "/information-shareholders/davidend-policy",
  },
  TEST: {
    TEXT: "Test",
    LINK: "/test",
  },
};
