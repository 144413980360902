import CryptoJS from "crypto-js";

export const encrypt = (value) => {
  if (value) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(value),
      process.env.ENCRYPT_KEY || "ENCRYPT_KEY"
    ).toString();
  }
  return "";
};

export const decrypt = (value) => {
  if (value) {
    const bytes = CryptoJS.AES.decrypt(
      value,
      process.env.ENCRYPT_KEY || "ENCRYPT_KEY"
    );
    try {
      const result = bytes.toString(CryptoJS.enc.Utf8);
      if (result) {
        return JSON.parse(result);
      }
      return "";
    } catch (error) {
      return "";
    }
  }
  return "";
};
