import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const OtherDocumentsStyle = styled.div`
  /* Parent styles
  ------------------------------- */
    
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../asset/media/images/contents/background-sustainability1.png');
    min-height: 645px;
  }
  
  .other-documents {
    padding: 60px;
  }
  
 
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 700.98px) {
    .other-documents {
      padding: 30px;
    }
  }
  
  @media (max-width: 600.98px) {
    .other-documents {
      padding: 30px 5px;
    }
  }
`
