import React, { useState, useEffect } from "react";
import { ShareholderStructureStyle } from "./styled";
import {
  PageHeader,
  TD,
  TH,
  TR,
  Table,
  TableBody,
  TableHeader,
} from "../../Global";
// import CanvasJSReact from 'canvasjs';
import CanvasJSReact from "@canvasjs/react-charts";
import { useDispatch, useSelector } from "react-redux";
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  GetShareholder,
  PagesShareholderStructure,
} from "./../../../Service/shareholders";
import { ROUTE_PATH } from "../../../constants/routes";
import {
  displayNumber,
  // dynamicColors
} from "../../../helpers";

const ShareholderStructureComponent = () => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState(null);
  const [overview, setOverview] = useState({
    percentFreeFloat: 0,
    numberOfHolder: 0,
    percentScriptless: 0,
    totalShareholder: 0,
  });
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    try {
      const ShareholderStructure = async () => {
        const resPage = await PagesShareholderStructure();
        setData(resPage?.data);
      };

      ShareholderStructure();
      Shareholder();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [responsive, setResponsive] = useState(false);

  useEffect(() => {
    function checkResponsive() {
      if (window.innerWidth < 768) {
        setResponsive(true);
      } else {
        setResponsive(false);
      }
    }

    checkResponsive();

    window.addEventListener("resize", checkResponsive);

    return () => {
      window.removeEventListener("resize", checkResponsive);
    };
  }, []);

  const Shareholder = async () => {
    try {
      const resPage = await GetShareholder();
      const dataPoints = [];
      const datas = resPage.result.shareholder.majorShareholders.map(
        (defaultData, index) => {

          const dataPoint = setDataPoint(defaultData, index);
          // dataPoints.push(dataPoint);
          if (dataPoints.length > 4) {
            if (dataPoints[5]) {
              dataPoints[5].y += dataPoint.y;
            } else {
              dataPoints[5] = {
                ...dataPoint,
                name: "อื่นๆ",
              };
            }
          } else {
            dataPoints.push(dataPoint);
          }
          return defaultData;
        }
      );
      setChartData(dataPoints);
      setOverview({
        percentFreeFloat: displayNumber(
          Number(
            resPage.result.shareholder.freeFloat.percentFreeFloat
          )?.toFixed(2)
        ),
        numberOfHolder: displayNumber(
          Number(resPage.result.shareholder.freeFloat.numberOfHolder)
        ),
        percentScriptless: displayNumber(
          Number(resPage.result.shareholder.percentScriptless)?.toFixed(2)
        ),
        totalShareholder: displayNumber(
          Number(resPage.result.shareholder.totalShareholder)
        ),
      });
      setList(datas);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const colors = ["#003F5C", "#444E86", "#955196", "#DD5182", "#FF6E54", "#FFA600"];

  const setDataPoint = (defaultData, index) => {
    return {
      name: defaultData.name,
      y: defaultData.percentOfShare,
      // color: dynamicColors(),
      color: colors[index]
    };
  };

  const options = {
    animationEnabled: true,
    // responsive: responsive,
    // maintainAspectRatio: false,
    legend: {
      horizontalAlign: "right", // left, center ,right
      verticalAlign: "center",
    },
    subtitles: [
      {
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
      },
    ],
    data: [
      {
        type: "doughnut",
        showInLegend: false,
        // innerRadius: 110,
        innerRadius: "60%",
        className: "datachart",
        // indexLabel: "{name}: {y}",
        yValueFormatString: "#,###.#0'%'",
        indexLabelFontSize: 16, // ขนาดตัวอักษรเพิ่มขึ้นเมื่อนำเมาส์ไปชี้
        indexLabelFontWeight: "bold", // น้ำหนักตัวอักษรเพิ่มขึ้นเมื่อนำเมาส์ไปชี้
        dataPoints: chartData || [],
      },
    ],
  };

  const holderListChart = (chartData || []).map((d, i) => (
    <div key={i} className="legend-text">
      <div
        className="circle"
        style={{
          minWidth: "19px",
          minHeight: "19px",
          backgroundColor: d.color,
        }}
      ></div>
      <div className="text">{d.name}</div>
    </div>
  ));

  const rowList = list.map((d, i) => (
    <TR key={i}>
      <TD className="text-center">{i + 1}</TD>
      <TD className="text-left">{d.name}</TD>
      <TD className="text-center">{displayNumber(Number(d.numberOfShare))}</TD>
      <TD className="text-center">{d.percentOfShare}</TD>
    </TR>
  ));

  return (
    <ShareholderStructureStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "/", text: translate["menu.home_investor_relation"] },
          { url: "/", text: translate["menu.information_for_shareholders"] },
          {
            url: ROUTE_PATH.SHAREHOLDER_STRUCTURE.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="shareholderstructure content-wrapper">
        <div className="grapshareholder">
          <div className="graph">
            {chartData ? <CanvasJSChart options={options} /> : null}
          </div>
          <div className="legend">{holderListChart}</div>
          <div className="overviewshareholder">
            <div className="overview">
              <div className="text-head">{translate["shareholder_structure.overview_of_shareholder_information"]}</div>
              <div className="text-body">
                <div className="text-right">
                  <div>{translate["shareholder_structure.total_number_of_shareholders"]}</div>
                  <div>% {translate["shareholder_structure.shareholding_without_share_certificate"]}</div>
                </div>
                <div className="text-left">
                  <div>{overview.totalShareholder}</div>
                  <div>{overview.percentScriptless}</div>
                </div>
              </div>
            </div>
            <div className="overview">
              <div className="text-head">{translate["shareholder_structure.minority_shareholders"]}</div>
              <div className="text-body">
                <div className="text-right">
                  <div>{translate["shareholder_structure.number_of_minor_shareholders"]}(Free float)</div>
                  <div>% {translate["shareholder_structure.shareholding_by_minor_shareholders"]}(% Free float)</div>
                </div>
                <div className="text-left">
                  <div>{overview.numberOfHolder}</div>
                  <div>{overview.percentFreeFloat}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-list">
          <div className="list-table">
            <div className="text-head-table">
              {translate["shareholder_structure.list_of_top_10_shareholders"]}
            </div>

            <div className="table">
              <Table className="split-row-color split-color-primary">
                <TableHeader className="header-primary">
                  <TR>
                    <TH className="shareholder-stucture order">{translate["shareholder_structure.number"]}</TH>
                    <TH className="shareholder-stucture name">
                      {translate["shareholder_structure.major_shareholders"]}
                    </TH>
                    <TH className="shareholder-stucture quantity">
                      {translate["shareholder_structure.number_of_shares"]}
                    </TH>
                    <TH className="shareholder-stucture percent">% {translate["shareholder_structure.share"]}</TH>
                  </TR>
                </TableHeader>
                <TableBody>{rowList}</TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </ShareholderStructureStyle>
  );
};

export default ShareholderStructureComponent;
