import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const CompanyIntroductionVDOStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .go-top-page {
    position: fixed;
    top: 50%;
    right: 0;
    background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px 0px 0px 6px;
    z-index: 990;
    cursor: pointer;
  }
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  .company-introduction-vdo-content {
    .tab {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      margin-bottom: 54px;
    }

    .company-introduction-vdo-search {
      margin-bottom: 43px;
      display: flex;
      justify-content: space-between;

      .search {
        max-width: 359px;
        width: 100%;
        margin-left: 40px;
        ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        
        .field-wrapper {
          .input-wrapper {
            .input {
              border-radius: 6px;
              ${VARIABLES.TYPOGRAPHYS.BODY._6}
            }
          }
        }
      }

      .select-wrapper {
        min-width: 155px;
        margin-left: 15px;
      }
    }
    
    .vdo-list {
      margin: 65px 90px;
      /* display: flex; */
      /* flex-wrap: wrap; */
      /* row-gap: 33px; */
      /* justify-content: space-between; */
      display: grid;
      /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
      row-gap: 20px;
      column-gap: 20px;
      
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 1120.98px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 800.98px) {
        grid-template-columns: 1fr;
      }

      /* &.two-items {
        justify-content: center;
        gap: 20%;
      } */
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
    .company-introduction-vdo-content {
    }
  }

  @media (max-width: 768.98px) {
    .company-introduction-vdo-content {
      .search-bar {
        padding-left: 36px;
        padding-right: 36px;
      }
      .vdo-list {
        margin: 0 0 20px 0;
        /* display: flex;
        row-gap: 33px;
        justify-content: center; */
      }
    }
  }

  @media (max-width: 425.98px) {
    .company-introduction-vdo-content {
    }
  }

  @media (max-width: 375.98px) {
    .company-introduction-vdo-content {
      .tab {
        margin-top: 30px;
        margin-bottom: 34px;
      }
      .search-bar {
        padding-left: 10px;
        padding-right: 10px;
        .input-search {
          width: 80%;
        }
        span {
          display: none;
        }
      }
      .vdo-list {
        /* margin: unset; */
      }
    }
  }

  @media (max-width: 320.98px) {
    .company-introduction-vdo-content {
    }
  }
`;
