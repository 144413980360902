import React from "react";
import { Button } from "../../../Global";
import { SaveFile } from "../../../../Service/API";
import { getBaseUploadUrl } from "../../../../Service/service";
import { useSelector } from "react-redux";

const CardElement = ({title, img, urlFile, fileName }) => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  return (
    <div className="card">
      <img className="card-image" src={img} alt="card" />
      <div className="card-header">
        <img
          src="../asset/media/images/icons/icon-logo-BioTec.png"
          alt="header-card"
        />
      </div>
      <div className="card-footer">
        <span className='card-name'>
          <span className='name'>{title}</span>
          <span className='sub-name sub-name-card-business'>{title}</span>
        </span>
        <div
          className="card-download"
          onClick={() => {
            SaveFile(BASE_URL_UPLOAD + urlFile, fileName);
          }}
        >
          <img
            src="../asset/media/images/icons/icon-download-green.svg"
            alt="download"
          />
          <span>{translate["common.download_document"]}</span>
        </div>
      </div>
    </div>
  );
};

export default CardElement;
