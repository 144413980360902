const ZINDEXS = {
  BASE: 0,
  LV_1: 1,
  LV_2: 11,
  LV_3: 21,
  LV_4: 51,
  LV_5: 101,
  LV_6: 501,
  LV_7: 1001,
  LV_8: 5001,
  LV_9: 10001,
  LV_10: 99999,
  NEGATIVE_1: -1,
  NEGATIVE_2: -9,
  NEGATIVE_3: -99,
  NEGATIVE_4: -999,
  NEGATIVE_5: -9999,
  NEGATIVE_6: -99999,
}

export default ZINDEXS
