import classNames from 'classnames';
import React, { useState, useRef, useEffect, Fragment } from 'react';

import {
  AccordionStyle,
  AccordionHeadStyle,
  AccordionBodyStyle,
  // AccordionContentStyle,
  // AccordionListStyle,
} from './styled';

function renderThemeClass(theme) {
  switch (theme) {
    case 'primary':
      return 'theme-primary';
    case 'secondary':
      return 'theme-secondary';
    case 'tertiary':
      return 'theme-tertiary';
    case 'quaternary':
      return 'theme-quaternary';
    default:
      break;
  }
}

export default function Accordion({ className, children, isOpen, theme, title, custom, callBackCalHeight, onClick, imgLogo, date }) {
  const accordionRef = useRef(null);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    isAccordionOpen || isOpen
      ? accordionRef.current.querySelector('.accordion-body') && setBodyHeight(accordionRef.current.querySelector('.accordion-body').scrollHeight)
      : setBodyHeight(0);
  }, [callBackCalHeight, isAccordionOpen, isOpen]);

  return (
    <AccordionStyle
      ref={accordionRef}
      className={classNames('accordion', { 'is-open': isAccordionOpen || isOpen }, renderThemeClass(theme), className)}
      bodyHeight={bodyHeight}
      theme={theme}
    >
      {custom ? (
        children
      ) : (
        <Fragment>
          <AccordionHead
            onClick={() => {
              if (onClick) {
                onClick(!isOpen);
              } else {
                setAccordionOpen(!isAccordionOpen);
              }
            }}
          >
            {imgLogo && (
              <div className='box-job'>
                {/* <img className='img-logo' src={imgLogo} alt=''/>
                <div className='abc'>
                  {title} {isAccordionOpen} <br/>
                  {date}
                </div> */}
                <div className='job'>
                  <div>
                    <img src='../asset/media/images/contents/work-with-us-contact.png' alt='' />
                  </div>
                  <div className='pagedown'>
                    <div className='text-job'>
                      <div className='text-heading'>
                        {title} {isAccordionOpen}
                      </div>
                      <div className='text-body'>
                        {date}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )}
          </AccordionHead>

          <AccordionBody>{children}</AccordionBody>
        </Fragment>
      )}
    </AccordionStyle>
  );
}

export function AccordionHead({ className, onClick, children, isHideToggleIcon, onlyIconClickable }) {
  return (
    <AccordionHeadStyle
      className={classNames('accordion-head', { 'is-only-icon-clickable': onlyIconClickable }, className)}
      onClick={!isHideToggleIcon && onlyIconClickable ? undefined : onClick}
    >
      <div className="heading-text">{children}</div>

      {!isHideToggleIcon && onlyIconClickable ? (
        <div onClick={onClick}>
          <div className="toggle-icon"></div>
        </div>
      ) : (
        <div className="toggle-icon"></div>
      )}
    </AccordionHeadStyle>
  );
}

export function AccordionBody({ className, children }) {
  return <AccordionBodyStyle className={classNames('accordion-body', className)}>{children}</AccordionBodyStyle>;
}

Accordion.defaultProps = {
  custom: false,
};
