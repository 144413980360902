import classNames from 'classnames';
import { NewsCardPageStyle } from './styled';

const NewsCardComponent = ({
  className,
  date,
  time, 
  img, 
  description, 
  index, 
  isActive
}) => {

  return (
    <NewsCardPageStyle
      className={classNames(
        'csr-card-wrapper',
        className
      )}
    >
      <div className='csr-card-container'>
        <div className='img-news'>
          <img src={img} alt='image-card'/>
        </div>
        <div className='card-details'>
          <div className='date-time'>
            <p className='card-date'>
              {date}
            </p>
            <p className='card-time'>
              <img className='icon-time' src='../../../../asset/media/images/icons/icon-clock.svg' alt='icon-time'/>
              {time}
            </p>
          </div>
          <div className='card-content-inside'>
            {description}
          </div>
        </div>
      </div>
    </NewsCardPageStyle>
  );
};

export default NewsCardComponent;
