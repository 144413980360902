import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const InvestorKitStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .investor-kits {
    .text-investor-kits {
      display: flex;
      padding: 40px 0px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      width: 100%;
    }
    .select-years {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: baseline;
      padding: 0px 66px;
      justify-content: flex-end;
    }
    .block-content {
      /* display: flex; */
      padding: 20px 66px 60px 66px;
      /* flex-direction: column;
      align-items: flex-start; */
      /* gap: 50px; */

      .content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
        /* display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start; */

        @media (max-width: 424.98px) {
          grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        }

        .content-investor-kit {
          padding: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            color: white;
            ${VARIABLES.TYPOGRAPHYS.HEADING._6};
            width: 100%;
          }
          .body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;

            .text-body {
              color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
              ${VARIABLES.TYPOGRAPHYS.BODY._8}
            }

            .body-year-report {
              display: flex;
              /* width: 378px; */
              width: 100%;
              padding-bottom: 16px;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid var(--bs-blue-800, #052c65);
            }

            .body-checkbox {
              display: flex;
              /* width: 378px; */
              width: 100%;
              padding-bottom: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              border-bottom: 1px solid var(--bs-blue-800, #052c65);

              .space-check-box {
                display: flex;
                /* width: 378px; */
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;

                .space-text {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 8px;
                }
              }
            }

            .body-all-download {
              display: flex;
              /* width: 378px; */
              width: 100%;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }

      .download {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .text-file {
          color: black;
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
        }
        .btn-button {
          display: flex;
          gap: 20px;

          .button {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  }
  /* Media queries
  ------------------------------- */

  @media (max-width: 1669.98px) {
    .block-content {
      .content {
        /* justify-content: space-evenly !important;
        flex-wrap: wrap !important; */
      }
    }
  }
  @media (max-width: 1024.98px) {
    .content {
      /* flex-wrap: wrap;
      gap: 20px;
      flex-direction: column;
      align-items: center !important; */

      .content-investor-kit {
        width: 100% !important;
      }
      .body {
        width: 100% !important;

        .body-year-report {
          width: 100% !important;
        }
        .body-checkbox {
          width: 100% !important;

          .space-check-box {
            width: 100% !important;
          }
        }
        .body-all-download {
          width: 100% !important;
        }
      }
    }
  }

  @media (max-width: 768.98px) {
    .block-content {
      .content {
        /* display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center !important;
        gap: 40px; */
      }
    }
  }

  @media (max-width: 690.98px) {
    .investor-kits {
      .block-content {
        .download {
          flex-direction: column;
          margin-top: unset;
          .text-file {
            margin-top: 20px;
            margin-bottom: 24px;
          }

          /* .btn-button {
                flex-direction: column;
            } */
        }
      }
    }
  }

  @media (max-width: 510.98px) {
    .investor-kits {
      .block-content {
        /* padding: 20px 20px 40px; */
        padding: unset;
        padding-bottom: 24px;
      }
    }
  }

  @media (max-width: 425.98px) {
    .investor-kits {
      .select-years {
        padding: 0px 24px;
      }
    }
  }

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
