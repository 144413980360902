import styled from "styled-components";
import VARIABLES from "../../../../../themes/variables";
// Wrapper
// ============================================================
export const CardTextStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .card-text-content {
    position: relative;
    width: 100%;
    height: 397px;
    padding: 20px 24px 60px;
    max-width: 423px;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    .date {
      text-align: end;
      margin-bottom: 30px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.28px;
      color: ${VARIABLES.COLOR.NEUTRAL8};
    }

    .block-content {
      .content-title {
        margin-bottom: 15px;
        color: #ffffff;
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0.56px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .content-body {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .more {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: 27px;
        padding-bottom: 20px;
        width: 100%;
        height: auto;
        ${VARIABLES.TYPOGRAPHYS.BODY._7};
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  .more {
    cursor: pointer;
    display: flex;
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    ${VARIABLES.TYPOGRAPHYS.BODY._7};
    width: fit-content;
    padding: 10px 10px 10px 0;
    background-color: transparent;
    border-color: transparent;
  }

  .card-text-darkblue {
    background: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

    .date {
      color: #ffffff;
    }

    .block-content {
      .content-title,
      .content-body,
      .more {
        color: #ffffff;

        &:hover {
          .arrow-right {
            filter: brightness(10);
          }
        }
      }
    }
  }

  .card-text-white {
    background: ${VARIABLES.COLOR.NEUTRAL8};
    .date {
      color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    }

    .block-content {
      .content-title,
      .content-body,
      .more {
        color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
      }
    }
  }

  .more {
    cursor: pointer;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
  }

  @media (max-width: 768.98px) {
  }

  @media (max-width: 600.98px) {
    /* .card-text-content {
      height: 397px;
      padding: 20px 24px 60px;
      max-width: 403px;

    } */
  }

  @media (max-width: 425.98px) {
  }

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
