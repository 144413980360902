import axios from "axios";
import { CONFIGS, getEnvConfig } from "./../configs/useEnvConfigLoader";
import store from "../store/store";

export const getBaseUrl = () => {
  return CONFIGS?.API_URL || getEnvConfig()?.API_URL;
};

export const getBaseUploadUrl = () => {
  return CONFIGS?.API_UPLOAD_URL || getEnvConfig()?.API_UPLOAD_URL;
};

export const getBaseSetUrl = () => {
  return CONFIGS?.API_SET_URL || getEnvConfig()?.API_SET_URL;
};

export default function service() {
  const baseUrl = getBaseUrl();
  const baseSetUrl = getBaseSetUrl();

  return {
    get: async (path, payload = { params: {} }, isApiSet = false) => {
      path = isApiSet ? `${baseSetUrl}${path}` : `${baseUrl}${path}`;

      //lang
      payload.params.locale = store.getState().lang.value;

      return axios
        .get(path, payload)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return null;
        });
    },

    post: async (path, data, isApiSet = false) => {
      let header = new Headers();
      header.append("Content-Type", "application/json");

      path = isApiSet ? `${baseSetUrl}${path}` : `${baseUrl}${path}`;
      return axios
        .post(path, data, header)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return null;
        });
    },

    put: async (path, data, isApiSet = false) => {
      path = isApiSet ? `${baseSetUrl}${path}` : `${baseUrl}${path}`;
      return axios
        .put(path, data)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return null;
        });
    },

    delete: async (path, data, isApiSet = false) => {
      path = isApiSet ? `${baseSetUrl}${path}` : `${baseUrl}${path}`;
      return axios
        .delete(path, data)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return null;
        });
    },

    uploadFile: async (file) => {
      let header = {
        headers: {
          "Content-Type": "multipart/content-type",
        },
      };
      const path = `${baseUrl}/upload`;
      return axios
        .post(path, file, header)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return null;
        });
    },
  };
}
