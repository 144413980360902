import styled, { css } from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const AccordionStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */
  &.theme-primary {
    & > .accordion-head {
      background-color: ${VARIABLES.COLOR.PRIMARY};
      color: ${VARIABLES.COLOR.WHITE};

      .toggle-icon {
        background-color: ${VARIABLES.COLOR.WHITE};
        border-color: ${VARIABLES.COLOR.WHITE};

        &::after {
          color: ${VARIABLES.COLOR.PRIMARY};
        }
      }
    }
  }

  &.theme-secondary {
    & > .accordion-head {
      background-color: ${VARIABLES.COLOR.SECONDARY};
      color: ${VARIABLES.COLOR.WHITE};

      .toggle-icon {
        background-color: ${VARIABLES.COLOR.WHITE};
        border-color: ${VARIABLES.COLOR.WHITE};

        &::after {
          color: ${VARIABLES.COLOR.SECONDARY};
        }
      }
    }
  }

  /* State
  ------------------------------- */
  &.is-open {
    .accordion-head {
      .toggle-icon {
        &::after {
          content: "-";
          margin-bottom: 3px;
        }
      }
    }

    .accordion-body {
      ${(props) => {
        return css`
          max-height: ${(props.bodyHeight + 10) + "px"};
        `;
      }}
      margin-bottom: 24px;
    }
  }

  /* Media queries
  ------------------------------- */
`;

export const AccordionHeadStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  cursor: pointer;

  /* Child element styles
  ------------------------------- */
  .toggle-icon {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #222222;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "+";
      display: block;
      font-size: 20px;
      color: #222222;
      line-height: 16px;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-only-icon-clickable {
    cursor: initial;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`;

export const AccordionBodyStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  display: flex;
  width: 100%;
  padding: 0 15px;
  max-height: 0;
  overflow: hidden;
  flex-direction: column;
  transition: all 0.5s ease-in-out;

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`;
