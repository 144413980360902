import SetAnnouncementsComponent from "../components/Pages/SetAnnouncements";
import Auth from "../containerWarping/Auth";
const SetAnnouncements = () => {
  return (
    <Auth>
      <SetAnnouncementsComponent />
    </Auth>
  );
};

export default SetAnnouncements;