import ShareholderMeetingVDOComponent from "../components/Pages/ShareholderMeetingVDO";
import Auth from "../containerWarping/Auth";
const ShareholderMeetingVDO = () => {
  return (
    <Auth>
      <ShareholderMeetingVDOComponent />
    </Auth>
  );
};

export default ShareholderMeetingVDO;