import service from "./../service";

export const PagesFinancialManagementAnalyze = async () => {
  try {
    const payload = {
      params: {
        populate: "*",
      }
    }
    const response = await service().get('/pages-financial-management-analyze', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const FinancialManagementAnalysisFiles = async (data = {}) => {
  try {
    const payload = {
      params: {
        ...data,
        populate: "*",
      }
    }
    const response = await service().get('/financial-management-analysis-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const FinancialManagementAnalysisFileByYear = async (year) => {
  try {
    const payload = {
      params: {
        'filters[date][$gte]': `${year}-01-01`,
        'filters[date][$lte]': `${year}-12-31`,
        populate: "*",
      }
    }
    const response = await service().get('/financial-management-analysis-files', payload)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};