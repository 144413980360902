import ClassNames from 'classnames'
import { InputTextareaStyle } from './styled'

export default function InputTextarea({
  className,
  label,
  type,
  id,
  name,
  placeholder,
  isHorizontal,
  isDisable,
  disabled,
  isDisabled,
  value,
  defaultValue,
  readOnly,
  isReadOnly,
  onChange,
  style,
  onBlur,
  textareaMsg,
  required,
}) {
  return (
    <InputTextareaStyle
      className={ClassNames(
        'field-wrapper',
        {
          'is-horizontal': isHorizontal ,
          'is-disabled': isDisable || disabled || isDisabled ,
          'is-read-only': readOnly || isReadOnly ,
          'required': required,
        },
        className
      )}
    >
      {label && (
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className='textarea-wrapper'>
        <textarea
          className="textarea"
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={isDisable || disabled || isDisabled}
          readOnly={readOnly || isReadOnly}
          value={value}
          defaultValue={defaultValue}
          style={!style ? {} : { borderColor: 'red' }}
          onBlur={onBlur}
        />
        {textareaMsg && (
        <div className='textarea-msg' htmlFor={id}>
          {label}
        </div>
      )}
      </div>
    </InputTextareaStyle>
  );
}