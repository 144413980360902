import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const HeaderStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-width: -webkit-fill-available;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background-color: white;
  height: auto;

  /* Child element styles
  ------------------------------- */
  .wh-18 {
    width: 18px;
    height: 18px;
  }

  .icon-logo-header {
    width: 160px;
    height: auto;
  }

  .select__control {
    border: 0;
  }

  .select-control {
    &.language {
      min-width: 110px;

      .select__single-value {
        width: 100%;
        color: #000000;
      }

      .select__indicator {
        color: #000000;
      }
    }
  }

  .header-wrapper {
    /* max-width: 1350px; */
    width: 100%;
    margin: 0 auto;
    position: relative;

    a {
      display: flex;
      align-items: center;

      span {
        padding: 0 10px 0 5px;
      }
    }
  }

  .header-topbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 4px 64px;
    z-index: 2;
    position: relative;
    background-color: #ffffff;

    &.header-topbar-investor {
      padding: 4px 0 4px 64px;
      background: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};

      .content-wrapper {
        .header-title {
          color: #ffffff;
  
          .header-topbar-text {
            .header-lang {
              .select-control {
                &.language {
                  .select__single-value {
                    color: #ffffff;
                  }
  
                  .select__indicator {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .header-title {
        color: black;
        /* color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE}; */
        text-align: center;
        font-size: 14px;
        font-family: Anuphan;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.28px;
      }

      .header-topbar-text {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .header-lang {
          display: flex;
          align-items: center;
        }

        .btn-back-to-standard {
          cursor: pointer;
          height: 50px;
          margin-right: -15px;
          padding: 15px 20px;
          color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER};
          background-color: #ffffff;
          ${VARIABLES.TYPOGRAPHYS.BODY._1({ size: 14 })};
        }
      }
    }
  }

  .header-menu {
    display: flex;
    padding: 15px 64px;
    align-items: center;
    flex-shrink: 0;
    z-index: 2;
    position: relative;

    .header-items {
      margin-left: auto;
    }

    .header-items-inner-wrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 12px;

      .nav-menu {
        .button {
          color: black;

          &:last-child {
            padding: 8px 0 8px 5px;
          }
        }
      }
    }

    .burger-menu {
      margin-left: auto;
      display: none;
      padding: 0;
      margin: 0;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 0;
    transition: all 0.3s;
    opacity: ${(props) => props.bgHeaderOpacity};
  }

  /* Modifiers
  ------------------------------- */
  &.is-homepage {
    background-color: transparent;

    .select__indicator {
      color: ${(props) => props.fontColorHeader} !important;
    }
    .select__single-value {
      color: ${(props) => props.fontColorHeader} !important;
    }

    .header-topbar {
      .header-title {
        color: ${(props) => props.fontColorHeader};
      }
    }

    .header-menu {
      .header-items-inner-wrapper {
        .nav-menu {
          .button {
            color: ${(props) => props.fontColorHeader};
          }
        }
      }
    }

    .header-wrapper {
      .header-topbar {
        background-color: ${(props) =>
    props.fontColorHeader == "white" ? "transparent" : "white"};
      }
    }
  }

  &.is-homepage-investor {
    background-color: transparent;

    .select__indicator {
      color: #ffffff !important;
    }

    .select__single-value {
      color: #ffffff !important;
    }

    .header-menu {
      .header-items-inner-wrapper {
        .nav-menu {
          .button {
            color: ${(props) => props.fontColorHeader};
          }
        }
      }
    }

    .header-wrapper {
      .header-topbar {
        background-color: ${(props) =>
    props.fontColorHeader == "white"
      ? "transparent"
      : VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER};

        .header-title {
          color: #ffffff;
        }
      }
    }
  }

  .header-lang-mobile {
    display: flex;
    justify-content: start;
    padding: 15px 15px 15px 0;
    margin: 10px 0;
    max-width: 130px;
    height: 40px;
    border-color: transparent;

    .icon-lang {
      width: 35px;
      height: 24px;
      margin-right: 10px;
    }

    .text-lang {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1100px) {
    .icon-logo-header {
      width: 140px;
      height: auto;
    }

    .header-topbar {
      display: none;
    }

    .header-menu {
      padding-left: 12px;
      padding-right: 12px;

      .header-items-inner-wrapper {
        flex-wrap: wrap;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        padding: 0 12px;
      }

      .header-items {
        display: none;
        z-index: -999;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;

        &.is-mobile-open {
          display: flex;
          z-index: 99;
          height: 100vh;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url("../asset/media/images/contents/mobile-menu-bg.png");
          background-repeat: no-repeat;
          background-size: cover;
          z-index: -2;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
          opacity: 0.88;
          z-index: -1;
        }
      }

      .burger-menu {
        display: flex;
        background-color: transparent;
        border: none;
        /* padding: 5px 15px; */
        margin-left: auto;
      }

      .header-items-inner-wrapper {
        position: relative;
      }
    }
  }

  @media (max-width: 1100px) {
    background-color: white !important;
    .header-menu {
      .header-items-inner-wrapper {
        overflow-x: hidden;
        display: unset;
        align-items: unset;
        gap: unset;

        .nav-menu {
          &.button-toggle-outline {
            color: #ffffff !important;
          }
        }
      }
    }
  }
`;
