import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { CompanyIntroductionVDOStyle } from "./styled";
import { Input, PageHeader } from "../../Global";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getBaseUploadUrl } from "../../../Service/service";
import CardVDO from "./components/CardVDO";
import SelectBox from "../../Global/SelectBox";
import Tabs from "./components/Tabs";
import ViewVdoDetail from "./components/ViewVdoDetail";
import {
  PagesCompanyPresentation,
  OppDayVdo,
  CompanyPresentVDO,
  CompanyPresentVDObyIDbyDate,
  CompanyPresentVDObyIDbyYear,
} from "../../../Service/presentationCompanyAndOppDay/presenttation";
import { scrollToTop, displayYearBuddhistEra, removeDuplicates } from "../../../helpers";
import { langTh } from "../../../store/lang";

const CompanyIntroductionVDOComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const originPath = window.location.origin;
  const shareLink = `${originPath}${ROUTE_PATH.COMPANY_INTRODUCTION_VDO.LINK}`

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const type = params.get("type");

  const dispatch = useDispatch();
  const [dataPage, setDataPage] = useState(null);
  const [content, setContent] = useState([]);
  const [contentOppDay, setContentOppDay] = useState([]);
  const [date, setDate] = useState(null);
  const [tabs, setTabs] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [isSelectDetail, setIsSelectDetail] = useState({
    isOpen: false,
    type: null,
    id: null,
    url: null,
    description: null,
  });
  const [selectedData, setSelectedData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [optionYears, setOptionYears] = useState(null);

  useEffect(() => {
    dispatch({ type: "INVESTOR" });
    const CompanyIntroductionVDOdata = async () => {
      try {
        const Res = await PagesCompanyPresentation();
        setDataPage(Res?.data);

        const ResPage = await CompanyPresentVDO();
        const ResDate = await CompanyPresentVDObyIDbyDate();
        setContent(ResPage?.data);
        setDate(ResDate?.data);
        setIsMounted(true);

        handleCheckUrlParams();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    CompanyIntroductionVDOdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckUrlParams = () => {
    if (id) {
      setIsSelectDetail({
        ...isSelectDetail,
        isOpen: true,
        id,
        type: type || "introduction"
      })

      setSelectedData({
        ...selectedData,
        type: type || "introduction"
      })
    }
  };

  useEffect(() => {
    if (tabs) {
      const CompanyIntroductionVDOdata = async () => {
        try {
          let payloadData = {};
          if (selectedYear) {
            payloadData = {
              "filters[date][$gte]": `${selectedYear}-01-01`,
              "filters[date][$lte]": `${selectedYear}-12-31`,
              "fields[0]": "date",
              "sort[date]": "desc",
              "sort[id]": "desc",
            }
          }
          const ResPage = await OppDayVdo(payloadData);
          setContentOppDay(ResPage?.data);

          const payload = {
            "fields[0]": "date",
            "sort[date]": "desc",
            "sort[id]": "desc"
          };
          const ResDate = await OppDayVdo(payload);
          setDate(ResDate?.data);
          setIsMounted(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      CompanyIntroductionVDOdata();
    } else {
      const CompanyIntroductionVDOdata = async () => {
        try {
          let payloadData = {};
          if (selectedYear) {
            payloadData = {
              "filters[date][$gte]": `${selectedYear}-01-01`,
              "filters[date][$lte]": `${selectedYear}-12-31`,
              "sort[date]": "desc",
              "sort[id]": "desc"
            }
          }
          const ResPage = await CompanyPresentVDO(payloadData);
          const ResDate = await CompanyPresentVDObyIDbyDate();
          setContent(ResPage?.data);
          setDate(ResDate?.data);
          setIsMounted(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      CompanyIntroductionVDOdata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const updateContentByYear = async () => {
      if (selectedYear) {
        if (tabs) {
          const payload = {
            "filters[date][$gte]": `${selectedYear}-01-01`,
            "filters[date][$lte]": `${selectedYear}-12-31`,
            "sort[date]": "desc",
            "sort[id]": "desc",
            populate: "*",
          };
          const responseContent = await OppDayVdo(payload);
          setContentOppDay(responseContent?.data);
        } else {
          const responseContent = await CompanyPresentVDObyIDbyYear(selectedYear);
          setContent(responseContent?.data);
        }
      }
    };

    selectedYear && updateContentByYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const onChangeYear = (e) => {
    setSelectedYear(e.value);
  };

  useEffect(() => {
    let years = [];
    let yearsData = [];

    date?.map((e) => years.push(new Date(e?.attributes?.date).getFullYear()));

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({ value: e, label: lang === langTh ? displayYearBuddhistEra(e) : e })
    );

    setOptionYears(yearsData);
    yearsData[0] && onChangeYear(yearsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleTopPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <CompanyIntroductionVDOStyle>
      {!isSelectDetail.isOpen && (
        <>
          <PageHeader
            pageHeaderImage={
              dataPage?.attributes?.menu_image?.data?.attributes?.url &&
              BASE_URL_UPLOAD +
                dataPage?.attributes?.menu_image?.data?.attributes?.url
            }
            breadcrumb={[
              { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
              { url: "#", text: translate["menu.home_investor_relation"] },
              { url: "#", text: translate["menu.published_document"] },
              { url: "#", text: translate["menu.activities_and_presentation_documents"] },
              {
                url: ROUTE_PATH.SHAREHOLDER_MEETING_VDO.LINK,
                text: dataPage?.attributes?.menu_text,
              },
            ]}
            title={dataPage?.attributes?.menu_text}
          />

          <div className="page-content-wrapper content-wrapper">
            <div className="page-content fluid">
              <div className="company-introduction-vdo-content">
                <div className="tab">
                  <Tabs
                    isActiveIntroduction={!tabs}
                    isActiveOppDay={tabs}
                    setTabs={(e) => {
                      setTabs(e);
                    }}
                  />
                </div>

                <div className="company-introduction-vdo-search">
                  <div className="search">
                    <Input
                      className="input-search"
                      inputSearch
                      type="text"
                      placeholder={translate["common.search_for_documents"]}
                      value={searchValue}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="select-wrapper">
                    <span>
                      {translate["common.year"]}{" "}
                      {isMounted && (
                        <SelectBox
                          placeholder={optionYears[0]?.label}
                          defaultValue={optionYears[0]}
                          options={optionYears}
                          onChange={(e) => onChangeYear(e)}
                          isSearchable={false}
                        />
                      )}
                    </span>
                  </div>
                </div>

                <div
                  className={`vdo-list ${content?.length === 2 && "two-items"}`}
                >
                  {!tabs ? (
                    <>
                      {content &&
                        content
                          ?.filter((item) =>
                            item?.attributes?.topic
                              ?.toLowerCase()
                              ?.includes(searchValue?.toLowerCase())
                          )
                          ?.map((item, index) => (
                            <CardVDO
                              setIsSelectDetail={(e) => {
                                setIsSelectDetail(e);
                                setSelectedData({
                                  ...item,
                                  type: "introduction",
                                });
                                scrollToTop();
                              }}
                              key={index}
                              id={item?.id}
                              url={item?.attributes?.vdo_url}
                              title={item?.attributes?.topic}
                              subTitle={item?.attributes?.short_description}
                              type="introduction"
                            />
                          ))}
                    </>
                  ) : (
                    <>
                      {contentOppDay &&
                        contentOppDay
                          ?.filter((item) =>
                            (
                              item?.attributes?.topic ||
                              item?.attributes?.company_name
                            )
                              ?.toLowerCase()
                              ?.includes(searchValue?.toLowerCase())
                          )
                          ?.map((item, index) => (
                            <CardVDO
                              setIsSelectDetail={(e) => {
                                setIsSelectDetail(e);
                                setSelectedData({ ...item, type: "opp-day" });
                                scrollToTop();
                              }}
                              key={index}
                              id={item?.id}
                              url={item?.attributes?.vdo_url}
                              title={
                                item?.attributes?.topic ||
                                item?.attributes?.company_name
                              }
                              business={item?.attributes?.category}
                              subTitle={
                                item?.attributes?.short_description
                              }
                              fileUrl={
                                item?.attributes?.document_file?.data
                                  ?.attributes?.url
                              }
                              fileName={
                                item?.attributes?.document_file?.data
                                  ?.attributes?.name
                              }
                              type="opp-day"
                            />
                          ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isSelectDetail.isOpen && (
        <ViewVdoDetail
          fileData={selectedData?.attributes?.document_file?.data}
          data={isSelectDetail}
          shareLink={`${shareLink}`}
          onClose={(e) => {
            setIsSelectDetail({ isOpen: e.isOpen });
            setTabs(e.type === "opp-day" ? true : false);
            scrollToTop();
          }}
          type={selectedData?.type}
        />
      )}
      {!isSelectDetail.isOpen && (
        <div
          className="go-top-page"
          onClick={() => {
            handleTopPage();
          }}
        >
          <img
            src="../asset/media/images/icons/icon-chevron-up-white.svg"
            alt="go-top-page"
          />
        </div>
      )}
    </CompanyIntroductionVDOStyle>
  );
};

export default CompanyIntroductionVDOComponent;
