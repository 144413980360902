import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { CorporateGovernancePolicyStyle } from "./styled";
import { PageHeader } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import {
  Charter,
  BusinessEthicsFiles,
  GetDateOfCharter,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import CardCharter from "./components/CardCharter";
import CardBusiness from "./components/CardBusiness";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { langTh } from "../../../store/lang";
import { removeDuplicates, displayYearBuddhistEra } from "./../../../helpers";

const CorporateGovernancePolicyComponent = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const params = useParams();
  const { id } = params;

  const [DataPage, setDataPage] = useState(null);
  const [Data, setData] = useState(null);
  const [DataBusiness, setDataBusiness] = useState(null);
  const [dateOfCharter, setDateOfCharter] = useState(null);
  const [optionYears, setOptionYears] = useState();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const CharterData = async () => {
      const ResponsePage = await Charter("page-title", "");
      const ResponseContent = await Charter("", new Date().getFullYear());
      const ResponseDate = await GetDateOfCharter({
        "sort[date]": "desc",
      });
      const ResponseBusiness = await BusinessEthicsFiles({
        "sort[createdAt]": "desc"
      });

      setDataPage(ResponsePage.data);
      setData(ResponseContent.data);
      setDateOfCharter(ResponseDate.data);
      setDataBusiness(ResponseBusiness.data);
      setIsMounted(true);
    };

    CharterData();
  }, []);

  const onChangeYear = async (e) => {
    const ResponseContent = await Charter("", e.value);
    setData(ResponseContent.data);
  };

  useEffect(() => {
    let years = [];
    let yearsData = [];

    dateOfCharter?.map((e) =>
      years.push(new Date(e?.attributes?.date).getFullYear())
    );

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({
        value: e,
        label: lang === langTh ? displayYearBuddhistEra(e) : e,
      })
    );

    setOptionYears(yearsData);
    yearsData[0] && onChangeYear(yearsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfCharter]);

  return (
    <CorporateGovernancePolicyStyle>
      <PageHeader
        pageHeaderImage={
          DataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD +
            DataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/", text: translate["menu.corporate_governances"] },
          {
            url: ROUTE_PATH.CORPORATE_GOVERNANCE_POLICY.LINK,
            text:
              id === "1"
                ? DataPage?.attributes.menu_text
                : DataPage?.attributes?.business_ethics_title,
          },
        ]}
        title={
          id === "1"
            ? DataPage?.attributes.menu_text
            : DataPage?.attributes?.business_ethics_title
        }
      />

      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="corporate-governance-policy-content">
            <div className="charter-wrapper content-wrapper">
              <div className="charter-title">
                <div className="title">{DataPage?.attributes.menu_text}</div>
                <div className="select-wrapper">
                  <span>{translate["common.year"]}</span>
                  {isMounted && (
                    <SelectBox
                      placeholder={optionYears[0]?.label}
                      defaultValue={optionYears[0]}
                      options={optionYears}
                      onChange={(e) => {
                        onChangeYear(e);
                      }}
                      isSearchable={false}
                    />
                  )}
                </div>
              </div>

              <div className="card-wrapper">
                {Data?.map((e, i) => (
                  <CardCharter
                    key={i}
                    title={e.attributes.file_title}
                    fileIcon={e.attributes.file_icon.data.attributes.url}
                    file={e.attributes.file.data.attributes.url}
                    fileName={e.attributes.file.data.attributes.name}
                    index={i}
                  />
                ))}
              </div>
            </div>
            <div className="business-ethics-wrapper">
              <div className="card-business-container">
                <div className="title">
                  {DataPage?.attributes?.business_ethics_title}
                </div>
                <div className="card-business-wrapper">
                  {DataBusiness?.map((e, i) => (
                    <CardBusiness
                      key={`business` + i}
                      index={i}
                      title={e.attributes.file_title}
                      icon={e.attributes.file_icon.data.attributes.url}
                      content={e.attributes.file_description}
                      urlFile={e.attributes.file.data.attributes.url}
                      fileName={e.attributes.file.data.attributes.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CorporateGovernancePolicyStyle>
  );
};

export default CorporateGovernancePolicyComponent;
