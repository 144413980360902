import React, { useState, useEffect } from "react";

import { ManagementStructurePageStyle } from "./styled";
import { PageHeader } from "../../Global";
import { PagesManagementStructure } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import { ROUTE_PATH } from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { openNewTab } from "../../../helpers";

const ManagementStructureComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [zoomManagementChart, setzoomManagementChart] = React.useState(false);
  const [Data, setData] = useState(null);
  const [zoomData, setZoomData] = useState([]);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const ManagementStructure = async () => {
      const Response = await PagesManagementStructure();
      setData(Response);

      let mapZoomData = [];
      Response?.data?.attributes?.structure_image?.data?.forEach(item => {
        mapZoomData.push({
          src: BASE_URL_UPLOAD + item?.attributes?.url,
          alt: item?.attributes?.alternativeText
        });
      });
      setZoomData(mapZoomData);
    };

    ManagementStructure();
  }, []);

  return (
    <ManagementStructurePageStyle>
      <PageHeader
        pageHeaderImage={
          Data?.data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + Data?.data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/about", text: translate["menu.about_us"] },
          { url: ROUTE_PATH.MANAGEMENT_STRUCTURE.LINK, text: Data?.data?.attributes?.menu_text },
        ]}
        title={Data?.data?.attributes?.menu_text}
      />

      <div className="page-content fluid">
        <div className="management-structure">
          <div className="management-structure-chart">
            {
              Data?.data?.attributes?.structure_image?.data[0].attributes?.url && (
                <img
                  src={
                    Data?.data?.attributes?.structure_image?.data[0].attributes?.url &&
                    BASE_URL_UPLOAD + Data?.data?.attributes?.structure_image?.data[0].attributes?.url
                  }
                  alt="business structure chart"
                  className="img"
                  // onClick={() => setzoomManagementChart(true)}
                  onClick={() =>
                    Data?.data?.attributes?.structure_image?.data[0].attributes?.url &&
                    openNewTab(BASE_URL_UPLOAD + Data?.data?.attributes?.structure_image?.data[0].attributes?.url)
                  }
                />
              )
            }
          </div>
        </div>
      </div>

      <Lightbox
        carousel={{ finite: true }}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
        controller={{
          closeOnBackdropClick: true,
        }}
        open={zoomManagementChart}
        close={() => setzoomManagementChart(false)}
        plugins={[Zoom]}
        slides={zoomData}
      />
    </ManagementStructurePageStyle>
  );
};

export default ManagementStructureComponent;
