import { createGlobalStyle } from "styled-components/macro"
import VARIABLES from './themes/variables'

export default createGlobalStyle`
  html {
    margin: 0;
    font-family: ${VARIABLES.TYPOGRAPHYS.FONT_FAMILIES.PRIMARY};
    line-height: 1.4;

    /* remove error backend iframe dev. */
    iframe {
      display: none;
    }
  }

  .main-layout {
    ${VARIABLES.ELEMENTSSIZE.MARGIN_HEIGHT}

    @media (max-width: 1100px){
      margin-top: 0;
    }
  }

  body {
    margin: 0;
  }

  .white-space-pre{
    white-space: pre;
  }

  .content-wrapper {
    max-width: 1920px !important;
    margin: 0 auto !important;
  }

  .page-content {
    max-width: 1440px;
    margin: 0 auto;
    background-color: white;

    &.fluid {
      max-width: none;
    }
  }

  * {
    box-sizing: border-box;
  }

  .block-upload-input {
    .field-wrapper {
      &.file-input {
        .field-label {
          margin-bottom: 10px;
        }

        .input-wrapper {
          .input {
            padding: 0;
            margin: 10px 0;
            border-color: ${VARIABLES.COLOR.NEUTRAL11};
            border-radius: 6px;
          }

          input[type=file]::file-selector-button {
            background-color: #FFFFFF;
            color: #000;
            border: 0px;
            border-right: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
            padding: 10px 15px;
            margin-right: 20px;
            background: ${VARIABLES.COLOR.NEUTRAL14};
          }

          input[type=file]::file-selector-button:hover {
            background-color: ${VARIABLES.COLOR.NEUTRAL12};
            border: 0px;
            border-right: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
          }
        }
      }
    }
  }

  .btn-inputform {
    font-family: "Anuphan";
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 6px;
    color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    border: 1px solid ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    background-color: #FFFFFF;

    &:hover {
      color: #FFFFFF;
      background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    }
  }

  /* default  react-clamp-lines styles*/
  .clamp-lines__button {
    color: ${VARIABLES.COLOR.PRIMARY_MEDIUM};
    background: none;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: ${VARIABLES.COLOR.PRIMARY_HOVER};
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .main-layout {
    min-height: 100%;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${VARIABLES.COLOR.PRIMARY_MEDIUM};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${VARIABLES.COLOR.PRIMARY_DEFAULT};
  }

  @media (max-width: 1440.98px) {
    .page-content {
      max-width: 1350px;
    }
  }
`