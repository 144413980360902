import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../../constants/routes";
import { PrivacyCenterStyle } from "./styled";
import { PageHeader } from "../../../Global";
import { ManagementEnvironmental } from "../../../../Service/API";
import { getBaseUploadUrl } from "../../../../Service/service";
import { PgesPrivacyCenter } from "../../../../Service/policy/policy";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useSelector, useDispatch } from "react-redux";

const PrivacyCenterComponent = () => {
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const isHome = useSelector((state) => state.counter.isHome);

  useEffect(() => {
    dispatch(isHome ? { type: "STANDARD" } : { type: "INVESTOR" });
    const PrivacyCenter = async () => {
      const Response = await PgesPrivacyCenter();
      setData(Response?.data);
    };

    PrivacyCenter();
  }, []);

  return (
    <PrivacyCenterStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          {
            url: isHome ? "/" : ROUTE_PATH.HOME_INVESTOR.LINK,
            text: translate["menu.home"],
          },
          {
            url: ROUTE_PATH.PRIVACY_CENTER.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="privacy-centerStyle-content content-wrapper">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {data?.attributes?.content_description}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </PrivacyCenterStyle>
  );
};

export default PrivacyCenterComponent;
