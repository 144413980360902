import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const FinancialHighlightStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .canvasjs-chart-credit {
    display: none;
  }

  .financialhighlight {
    .box-content {
      display: flex;
      /* height: 497px; */
      padding: 60px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;

      .block-charts {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        width: 100%;
        margin: 52px 0;

        .charts-service-income,
        .charts-total-assets,
        .charts-net-profit {
          text-align: center;
          width: 100%;
          max-width: 330px;
          padding: 22px 30px;
          margin: 0 auto;
          background-color: #ffffff;
          border-radius: 15px;
          box-shadow: 0px 10px 75px -10px #d9d9d9;

          .charts {
            .service-income,
            .total-assets,
            .net-profit {
              & > div {
                height: 350px !important;
              }
            }
          }

          .charts-title {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 500;
            color: #000000;
          }

          .sub-title {
            font-size: 14px;
            font-weight: 500;
            color: ${VARIABLES.COLOR.NEUTRAL3};
          }
        }
      }
    }
    .top-list {
      display: flex;
      width: 100%;
      padding: 60px 65px;
      align-items: flex-start;
      justify-content: center;

      .list-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: 100%;

        .text-head-table {
          ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        }
        .block-table {
          width: 100%;
          .table-wrapper {
            margin: 0 auto;
            max-width: 1320px;
            overflow: auto;
          }
        }
      }
    }
  }
  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
    .financialhighlight {
      .box-content {
        .block-charts {
          gap: 30px;
          grid-template-columns: 1fr 1fr;

          .charts-service-income,
          .charts-total-assets,
          .charts-net-profit {
            max-width: 400px;
          }
        }
      }
    }
  }

  @media (max-width: 850.98px) {
    .financialhighlight {
      .box-content {
        .block-charts {
          grid-template-columns: 1fr;

          .charts-service-income,
          .charts-total-assets,
          .charts-net-profit {
            max-width: 500px;

            .charts {
              .service-income,
              .total-assets {
                & > div {
                  height: 550px !important;
                }
              }

              .net-profit {
                & > div {
                  height: 750px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 600.98px) {
    .financialhighlight {
      .box-content {
        .block-charts {
          padding: 0 30px;
        }
      }
    }
  }

  @media (max-width: 430.98px) {
    .financialhighlight {
      .top-list {
        padding: 12px;
      }

      .box-content {
        .block-charts {
          grid-template-columns: 1fr;

          .charts-service-income,
          .charts-total-assets,
          .charts-net-profit {
            max-width: 500px;

            .charts {
              .service-income,
              .total-assets,
              .net-profit {
                & > div {
                  height: 400px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-height: 430.98px) {
    .financialhighlight {
      .box-content {
        .block-charts {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  @media (max-width: 320.98px) {
  }
`;
