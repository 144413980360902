import { css } from 'styled-components';
import VARIABLES from '.';

let navbarHeight = 120;

const ELEMENTSSIZE = {
  NAVBAR_HEIGHT: ({ height = navbarHeight }) =>
    css`
      height: ${height + 'px'};

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
        height: ${(height - 1) + 'px'};
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
        height: ${(height - 2) + 'px'};
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
        height: ${(height - 4) + 'px'};
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
        height: ${(height - 6) + 'px'};
      }
    `
  ,
  MARGIN_HEIGHT: ({ marginTop = navbarHeight }) =>
    css`
      margin-top: ${marginTop + 'px'};

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._4}) {
        margin-top: ${(marginTop - 1) + 'px'};
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._3}) {
        margin-top: ${(marginTop - 2) + 'px'};
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
        margin-top: ${(marginTop - 4) + 'px'};
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._1}) {
        margin-top: ${(marginTop - 6) + 'px'};
      }
    `
};

export default ELEMENTSSIZE
