import React, { useEffect, useState } from "react";

// import classNames from "classnames";
import { WeAreBiotecPageStyle } from "./styled";
import { PageHeader } from "../../Global";
import { WeAreBioTec } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";

// Slick slide
// https://www.npmjs.com/package/react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

// Animate on Scroll
// https://github.com/dbramwell/react-animate-on-scroll
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";

var WeAreBiotecSlideSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  lazyLoad: "ondemand",
  cssEase: "ease-out",
  pauseOnHover: false,
};

const WeAreBiotecComponent = () => {
  const dispatch = useDispatch();

  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const [Images, setImages] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });
    const WeAreBiotecData = async () => {
      const Response = await WeAreBioTec();
      setData(Response?.data);
      setImages(Response?.data?.attributes?.image?.data);
    };

    WeAreBiotecData();
  }, []);

  const ImageSlide = ({ img, classImg }) => {
    return <img className={classImg} src={img} alt="img"></img>;
  };

  return (
    <WeAreBiotecPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/about", text: translate["menu.about_us"] },
          { url: "/about/we-are-biotec", text: data?.attributes?.menu_text },
        ]}
        title={data?.attributes?.menu_text}
      />

      <div className="bg"></div>
      <div className="page-content fluid">
        <div className="we-are-biotec">

          <div className="we-are-biotec-inside-wrapper">
            <div className="title-wrapper">
              <div className="title">{data?.attributes?.menu_text}</div>
              <div className="sub-title">{data?.attributes?.company_name}</div>
            </div>

            <div className="we-are-biotec-img-wrapper">
              {/* img='../asset/media/images/contents/we-are-biotec-content-img-1.png' */}
              <Slider {...WeAreBiotecSlideSettings}>
                {Images ? (
                  Images?.map((e, i) => {
                    return (
                      <div key={i} id={i} className="img-wrapper">
                        <div>
                          {e?.attributes?.url ? (
                            <ImageSlide
                              classImg="img-content-wrb"
                              img={BASE_URL_UPLOAD + e?.attributes?.url}
                            ></ImageSlide>
                          ) : (
                            <ImageSlide
                              classImg="img-content-wrb"
                              img={
                                "../asset/media/images/contents/we-are-biotec-content-img-1.png"
                              }
                            ></ImageSlide>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <ImageSlide
                    classImg="img-content-wrb"
                    img={
                      "../asset/media/images/contents/we-are-biotec-content-img-1.png"
                    }
                  ></ImageSlide>
                )}
              </Slider>
              <div className="description-wrapper">
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                  <div className="description">
                    {/* from editor ?? */}
                    {/* <h2 className="title">Biotec</h2> */}

                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {data?.attributes?.content}
                    </ReactMarkdown>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WeAreBiotecPageStyle>
  );
};

export default WeAreBiotecComponent;
