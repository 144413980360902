import React, { useEffect, useState } from "react";

// import classNames from "classnames";
import { SubsidiaryAndAssociatedCompaniesPageStyle } from "./styled";
import CompanyItemComponent from "./CompanyItem";
import { PageHeader } from "../../Global";
import {
  PagesAssociatedCompany,
  AssociatedCompany,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import { useDispatch, useSelector } from "react-redux";
import { openNewTab } from "./../../../helpers";

const SubsidiaryAndAssociatedCompaniesComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [data, setData] = useState(null);
  const [dataImage, setDataImage] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const SubsidiaryAndAssociatedCompanies = async () => {
      const Response = await PagesAssociatedCompany();
      const ResponeAssosiatedImg = await AssociatedCompany({
        "sort[company_id]": "asc",
      });
      setData(Response?.data);
      setDataImage(ResponeAssosiatedImg?.data);
    };

    SubsidiaryAndAssociatedCompanies();
  }, []);

  const onClickLink = (data) => {
    data?.company_link && openNewTab(data?.company_link);
  }

  return (
    <SubsidiaryAndAssociatedCompaniesPageStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "", text: translate["menu.about_us"] },
          {
            url: "/about/subsidiary-and-associated-companies",
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
        id="sasc_"
      />

      <div className="page-content fluid">
        <div className="subsidiary-and-associated-companies">
          <div className="desricption" id="sasc_desricption">
            {data?.attributes?.details}
          </div>

          <div className="company-list">
            {dataImage?.map((even) => (
              <CompanyItemComponent
                key={even?.id}
                src={
                  BASE_URL_UPLOAD +
                  even?.attributes?.company_logo?.data?.attributes?.url
                }
                id={even?.id}
                companyName={even?.attributes?.company_name}
                companySymbol={even?.attributes?.company_symbol}
                onClick={() => onClickLink(even?.attributes)}
              />
            ))}
          </div>
        </div>
      </div>
    </SubsidiaryAndAssociatedCompaniesPageStyle>
  );
};

export default SubsidiaryAndAssociatedCompaniesComponent;
