import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const WeAreBiotecPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  .page-header {
    z-index: 1;
  }

  /* Child element styles
  ------------------------------- */
  .bg {
    background-image: url("../../asset/media/images/contents/we-are-biotec-content-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    margin-top: -100vh;
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    /* z-index: -1; */
  }
  .we-are-biotec {
    position: relative;
    /* min-height: 1240px; */
    @media (max-width: 767.98px) {
      min-height: 1090px;
    }


    .we-are-biotec-inside-wrapper {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 1205px;
      margin: 0 auto;
      padding: 52px 15px;
      @media (max-width: 1180.98px) {
        padding: 52px 40px;
      }
    }

    .title-wrapper {
      width: 100%;
      margin-top: 70px;
      flex: 1 1 auto;

      .title {
        ${VARIABLES.TYPOGRAPHYS.HEADING._1}
        color: ${VARIABLES.COLOR.PRIMARY_DEFAULT};
        line-height: 1.8;
      }

      .sub-title {
        ${VARIABLES.TYPOGRAPHYS.BODY._3}
        color: ${VARIABLES.COLOR.ORANGE1};
      }
    }

    .we-are-biotec-img-wrapper {
      width: 100%;
      flex: 1 1 auto;
      max-width: 559px;
      overflow: hidden;
      border-radius: 20px 300px 20px 20px;

      @media (max-width: 1180.98px) {
        border-radius: 20px 200px 20px 20px;
      }

      .img-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 745px;
        z-index: 1;
      }

      .description-wrapper {
        position: absolute;
        padding-bottom: 100px;
        top: 50%;
        left: 15px;
        width: calc(100% - 30px);
        max-width: 755px;
        padding: 30px 65px 80px 50px;
        background-color: #FFFFFF;
        border-radius: 20px 20px 150px 20px;
        box-shadow: 10px 20px 11px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;

        @media (max-width: 1180.98px) {
          left: 40px !important;
          padding: 30px;
        }

        @media (max-width: 1024.98px) {
          max-width: 555px;
          left: 40px !important;
          padding: 30px;
        }
        
        @media (max-width: 600.98px) {
          max-width: 555px;
          padding: 30px 65px 80px 50px;
          left: 15px !important;
        }
      }
      
      .description {
        color: ${VARIABLES.COLOR.PRIMARY_DEFAULT};
        background-color: transparent;
        /* text-align: justify; */
        padding: 0 37px 0 0;
        height: fit-content;
        overflow-y: auto;
        max-height: 600px;
        ${VARIABLES.TYPOGRAPHYS.BODY._5}

        .title {
          ${VARIABLES.TYPOGRAPHYS.HEADING._6}
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  .img-content-wrb {
    width: 100%;
    height: 100%;
    min-height: 745px;
    max-height: 800px;
    object-fit: cover;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: ${VARIABLES.BREAKPOINTS.MAX._2}) {
    .we-are-biotec {
      /* min-height: 1380px; */
      
      .bg {
        max-width: 100%;
      }

      .we-are-biotec-inside-wrapper {
        padding: 5vw 15px;
        flex-wrap: wrap;
      }

      .title-wrapper {
        padding: 0 10%;
        margin-top: 0;
        margin-bottom: 5vw;

        .sub-title {
          ${VARIABLES.TYPOGRAPHYS.BODY._3}
          color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        }
      }

      .bg {
        right: 20%;
      }

      .we-are-biotec-img-wrapper {
        margin-left: auto;
        width: 85%;
        flex: 0 0 85%;
        border-radius: 20px 150px 20px 20px;
        max-width: 450px;

        .description-wrapper {
          top: 70%;
          padding: 35px 50px;
        }

        .description {
          width: 90%;
          min-height: 280px;
        }
      }
    }
  }

  @media (max-width: 430px) {
    .we-are-biotec {
      .we-are-biotec-inside-wrapper {
        .we-are-biotec-img-wrapper {
          .description-wrapper {
            padding: 35px 0 50px 25px;
            border-radius: 20px 20px 110px 20px;

            .description {
              padding: 34px 15px;
              max-height: 500px;
            }
          }
        }
      }
    }
  }
`
