import SustainableDevelopmentGoalsComponent from "../components/Pages/SustainableDevelopmentGoals";
import Auth from "../containerWarping/Auth";
const SustainableDevelopmentGoals = () => {
  return (
    <Auth>
      <SustainableDevelopmentGoalsComponent />
    </Auth>
  );
};

export default SustainableDevelopmentGoals
;
