import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_PATH } from "../../../constants/routes";
import { SetAnnouncementsStyle } from "./styled";
import { PageHeader, CardDownloadFile, Input } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import { Link } from "react-router-dom";
import { getBaseUploadUrl } from "./../../../Service/service";
import {
  PagesIrPressRelease,
  GetNewsSet,
} from "./../../../Service/newsActivities";
import {
  displayDate,
  displayDateBuddhistEra,
  displayYear,
  displayYearBuddhistEra,
  removeDuplicates,
  sortArray,
} from "./../../../helpers";
import moment from "moment";
import { langTh } from "../../../store/lang";

const SetAnnouncementsComponent = () => {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [meta, setMeta] = useState({});
  const [content, setContent] = useState([]);
  const [contentPage, setContentPage] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const defaultOptionYears = [
    { value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date()) },
  ];
  const [selectedYear, setSelectedYear] = useState(null);
  const [optionYears, setOptionYears] = useState(defaultOptionYears);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [paginations, setPaginations] = useState([]);

  const symbol = "BIOTEC";

  useEffect(() => {
    dispatch({ type: "INVESTOR" });

    const IrPressRelease = async () => {
      try {
        const resPage = await PagesIrPressRelease();
        setData(resPage?.data);

        const payload = {
          limit: 1000,
          // 'fields[0]': 'timestamp',
          // 'sort[0]': 'timestamp:desc',
        };
        const resContent = await GetNewsSet(payload);
        if (resContent?.result?.newsInfoList) {
          handleMapNewsDate(resContent?.result?.newsInfoList);
          handleSetSelectYears(resContent?.result?.newsInfoList);
        }

        setIsMounted(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    IrPressRelease();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSetContentPage(content);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage, selectedYear]);

  const handleSetContentPage = (dataContent) => {
    let data = dataContent?.filter((item) => {
      return item?.tag === ""
    })
    ?.filter((item) => {
      return selectedYear ? item.year === selectedYear : item
    })

    // Set page
    const perPage = 10;
    const maxPage = (data?.length / perPage) || 1;
    const totalItem = data?.length || 1;

    let dataList = [];
    data?.forEach((item, index) => {
      if (
        index >= ((selectedPage-1) * perPage) &&
        index < (selectedPage * perPage)
      ) {
        dataList.push(item);
      }
    });

    let pages = [];
    for(let i = 1; i <= Math.ceil(maxPage); i++) {
      pages.push({
        value: i,
        label: i
      });
    }

    setPaginations(pages);
    setContentPage(dataList);
    setMeta({
      pagination: {
        page: selectedPage,
        pageSize: perPage,
        pageCount: Math.ceil(maxPage),
        total: totalItem
      }
    })
  }

  const handleMapNewsDate = (data) => {
    data?.map((item) => {
      const date = new Date(item?.timestamp * 1000);
      item.year = date.getFullYear();
      item.date = displayDate(date, "D MMM YYYY");
      item.date_be = lang === langTh ? displayDateBuddhistEra(date) : displayDate(date, "D MMM YYYY");

      return item;
    });

    data = sortArray(data, "timestamp", "desc");
    setContent(data);
    handleSetContentPage(data);
  };

  const handleSetSelectYears = (data) => {
    let years = [];
    let yearsData = [];

    data?.map((item) => {
      const date = new Date(item?.timestamp * 1000);
      years.push(date.getFullYear());
      return item;
    });

    years = removeDuplicates(years);
    years?.map((e) =>
      yearsData.push({ value: e, label: lang === langTh ? displayYearBuddhistEra(e) : e })
    );
    setOptionYears(yearsData);
    yearsData[0] && setSelectedYear(yearsData[0].value);
  };

  const onChangeYear = (e) => {
    setSelectedPage(1);
    setSelectedYear(e?.value);
  };

  const onChangePagination = (e) => {
    setSelectedPage(e?.value);
  }

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <SetAnnouncementsStyle>
      <PageHeader
        pageHeaderImage={
          data?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + data?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: ROUTE_PATH.HOME_INVESTOR.LINK, text: translate["menu.home"] },
          { url: "#", text: translate["menu.home_investor_relation"] },
          { url: "#", text: translate["menu.news_and_activities"] },
          {
            url: ROUTE_PATH.SET_ANNOUNCEMENTS.LINK,
            text: data?.attributes?.menu_text,
          },
        ]}
        title={data?.attributes?.menu_text}
      />
      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="set-announcements content-wrapper">
            <p className="set-announcements-description">
              {data?.attributes?.content_description}
            </p>
            <div className="set-announcements-search">
              <div className="search">
                <Input
                  className="input-search"
                  inputSearch
                  type="text"
                  placeholder={translate["common.search_for_documents"]}
                  onChange={handleSearch}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="select-wrapper">
                <span>{translate["common.year"]}</span>
                {isMounted && (
                  <SelectBox
                    placeholder={optionYears[0]?.label}
                    defaultValue={optionYears[0]}
                    options={optionYears}
                    onChange={(e) => onChangeYear(e)}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>
            {contentPage &&
              contentPage?.filter((item) => {
                  return item.headline
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
                })
                ?.map((d, i) => {
                  const link = `https://www.set.or.th/th/market/news-and-alert/newsdetails?id=${d?.id}&symbol=${symbol}`;
                  return (
                    <Link key={d?.id} to={{ pathname: link }} target="_blank">
                      <CardDownloadFile
                        id={d?.id}
                        dateSetAa={d?.date_be}
                        titleSetAa={d?.headline}
                      />
                    </Link>
                  );
                })}

            <div className="set-announcements-footer">
              <div className="search"></div>
              <div className="select-wrapper-page">
                <span> {translate["common.page"]} </span>
                {isMounted && (
                  <SelectBox
                    defaultValue={paginations[0]}
                    value={paginations?.find(r => r.value === selectedPage)}
                    options={paginations}
                    onChange={(e) => onChangePagination(e)}
                    isSearchable={false}
                  />
                )}
                <span> {translate["common.page_of"]} </span>
                {meta?.pagination?.pageCount}
              </div>
            </div>

          </div>
        </div>
      </div>
    </SetAnnouncementsStyle>
  );
};

export default SetAnnouncementsComponent;
