import moment from "moment";
import { isString, isNumber, isDate } from "./";

export const addDays = (date, day) => {
  if (isDate(date) && isNumber(day)) {
    return moment(date).add(day, 'days').toDate();
  }
  return date;
};

export const sortArray = (
  list,
  nameObject = '',
  sortType = 'asc'
) => {
  if (Array.isArray(list) && isString(nameObject) && isString(sortType)) {
    if (list.length > 0) {

      if (nameObject) {
        return list.sort((a, b) => {
          const nameA = isNumber(a[nameObject]) ? a[nameObject] : a[nameObject]?.toUpperCase();
          const nameB = isNumber(b[nameObject]) ? b[nameObject] : b[nameObject]?.toUpperCase();
          if (nameA < nameB) {
            return sortType === 'asc' ? -1 : 1;
          }
          if (nameA > nameB) {
            return sortType === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        return list.sort((a, b) => {
          const nameA = isNumber(a) ? a : a?.toUpperCase();
          const nameB = isNumber(b) ? b : b?.toUpperCase();
          if (nameA < nameB) {
            return sortType === 'asc' ? -1 : 1;
          }
          if (nameA > nameB) {
            return sortType === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return [];
  } else {
    return [];
  }
};

export const copyLink = (link) => {
  if (isString) {
    navigator.clipboard.writeText(link);
  }
}