import FinancialHighlightComponent from "../components/Pages/FinancialHighlights";
import Auth from "../containerWarping/Auth";
const FinancialHighlight = () => {
  return (
    <Auth>
      <FinancialHighlightComponent/>
    </Auth>
  );
};

export default FinancialHighlight;