import styled from "styled-components";
import VARIABLES from "../../../../../../themes/variables";
// Wrapper
// ============================================================
export const ThreeCardStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  .block-card-csr-news {
    overflow: hidden;
    width: 100%;
    max-width: 656px;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    .card-csr-news-img {
      position: relative;
      width: 100%;
      height: 100%;

      .img {
        width: 100%;
        height: 444px;
        object-fit: cover;
      }
    }

    .card-csr-news-content {
      position: relative;
      width: 100%;
      height: 384px;
      padding: 20px 30px 80px;
      align-items: center;
      background: #ffffff;

      .date {
        text-align: end;
        color: #000000;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
      }

      .block-content {
        .content-title {
          margin: 15px 0;
          color: #000000;
          font-size: 28px;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0.56px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .content-body {
          color: ${VARIABLES.COLOR.NEUTRAL18};
          font-family: Anuphan;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0.32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .more {
          position: absolute;
          bottom: 0;
          left: 0;
          padding-left: 27px;
          padding-bottom: 20px;
          width: 100%;
          height: auto;
          ${VARIABLES.TYPOGRAPHYS.BODY._7};
        }
      }
    }
  }

  /* Child element styles
  ------------------------------- */

  /* Modifiers
  ------------------------------- */
  .more {
    cursor: pointer;
    display: flex;
    color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
    ${VARIABLES.TYPOGRAPHYS.BODY._7};
    width: fit-content;
    padding: 10px 10px 10px 0;
    background-color: transparent;
    border-color: transparent;
  }

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
  }

  @media (max-width: 768.98px) {
    .block-card-csr-news {
      .card-csr-news-content {
        .block-content {
          .content-title {
            font-size: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 425.98px) {
    .block-card-csr-news {
      .card-csr-news-content {
        padding: 20px 17px 80px;
      }
    }
  }

  @media (max-width: 375.98px) {
  }

  @media (max-width: 320.98px) {
  }
`;
