import WorkWithUsPageOnlineRegisterComponent from "../components/Pages/WorkWithUsPage/OnlineRegister";
import Auth from "../containerWarping/Auth";
const WorkWithUsPageOnlineRegister = () => {
  return (
    <Auth>
      <WorkWithUsPageOnlineRegisterComponent />
    </Auth>
  );
};

export default WorkWithUsPageOnlineRegister;
