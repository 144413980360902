import SupplyChainManagementComponent from "../components/Pages/SupplyChainManagement";
import Auth from "../containerWarping/Auth";
const SupplyChainManagement = () => {
  return (
    <Auth>
      <SupplyChainManagementComponent />
    </Auth>
  );
};

export default SupplyChainManagement;
