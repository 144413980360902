import styled from 'styled-components'
import VARIABLES from '../../../themes/variables'

// Wrapper
// ============================================================
export const CorporateGovernancePolicyStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  background-image: url('../../asset/media/images/contents/bg-layout-left-bottom.png');
  background-size: cover;
    
  /* Child element styles
  ------------------------------- */
  .page-content {
    background-color: unset;
  }

  .corporate-governance-policy-content {

    .btn-card-download {
      width: 175px;
      ${VARIABLES.TYPOGRAPHYS.BODY._6}
    }

    .charter-wrapper {
      padding: 60px 66px;

      .charter-title {
        margin-bottom: 43px;
        display: flex;
        justify-content: space-between;

        .title {
          ${VARIABLES.TYPOGRAPHYS.TITLE._1};
        }
      }
      
        .card-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 24px;
          justify-items: center;

          @media (max-width: 1320.98px) {
            grid-template-columns: 1fr 1fr 1fr;
          }

          @media (max-width: 1020.98px) {
            grid-template-columns: 1fr 1fr;
          }

          @media (max-width: 720.98px) {
            grid-template-columns: 1fr;
          }

          .card {
            background-color: white;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
            width: 280px;
            height: 100%;
            border-radius: 12px;
            position: relative;
            padding: 28px 25px;

            .card-header {
              background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0px 6px 0px 24px;
              padding: 3px 5px 3px 20px;
              width: 100%;
              max-width: 115px;
              max-height: 36px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .card-body {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;

            .card-body-content {
              margin-bottom: 10px;

              div:first-child {
                margin-bottom: 16px;
              }

              div:last-child {
                color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
                ${VARIABLES.TYPOGRAPHYS.BODY._3};
              }
            }
          }
        }
      }
    }
    .business-ethics-wrapper {
      background-image: linear-gradient(90deg, rgba(2, 47, 134, 0.40) 0%, rgba(2, 47, 134, 0.20) 100%),url('../../asset/media/images/contents/bg-business-ethics.jpg');
      background-size: cover;
      padding: 60px 65px;
      display: flex;
      justify-content: center;

      .card-business-container {
        display: flex;
        max-width: 1920px;
        width: 100%;
        margin: 0 auto;
        justify-content: center;

        @media (max-width: 800.98px) {
          flex-direction: column;
        }
      
        .title {
          margin-right: 84px;
          white-space: nowrap;
          color: white;
          ${VARIABLES.TYPOGRAPHYS.TITLE._1}
          flex: 30%;
          padding-left: 60px;
        }
        .card-business-wrapper {
          flex: 70%;
          /* flex: 1;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; */
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;

          @media (max-width: 800.98px) {
            grid-template-columns: 1fr;
          }

          .card-business {
            position: relative;
            width: 100%;
            margin: 0 auto;
            /* flex: 0 0 calc(50% - 24px); */
            background-color: white;
            border-radius: 10px;
            padding: 28px 28px 80px 28px;

            div:not(:last-child) {
              margin-bottom: 16px;
            }

            .card-business-title {
              ${VARIABLES.TYPOGRAPHYS.BODY._3}
              color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
            }

            .card-business-content {
              ${VARIABLES.TYPOGRAPHYS.BODY._6}
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 9;
              -webkit-box-orient: vertical;
              
              p {
                margin: 0;
              }
            }

            .btn-download-business-wrapper {
              position: absolute;
              bottom: 0;
              transform: translate(0, -28px);
            }
          }
        }
      }
    }
  }
    
  /* Modifiers
  ------------------------------- */
  .btn-card-download {
    &:hover {
      color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      background: ${VARIABLES.COLOR.SECONDARY_LIGHT};

      .icon-download {
        border-radius: 12px;
        border-color:transparent;
        background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
      }
    }
  }

  .card-name {
    position: relative;

    .name {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media (max-width: 991.98px) {
        font-size: 20px;
      }
    }

    .sub-name {
      display: none;
    }

    &:hover {
      .sub-name {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(	94, 172, 27, 0.8);
        color: white;
        width: 150%;
        padding: 15px;
        border-radius: 10px;
        transform: translate(0, 55px);
        z-index: 990;

        &.sub-name-card-business {
          width: 115%;
        }
      }
    }
  }
  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    .corporate-governance-policy-content {
      
    }
  }
  
  @media (max-width: 1024.98px) {
    .corporate-governance-policy-content {
      
    }
  }

  @media (max-width: 820.98px) {
    .corporate-governance-policy-content { 
      .btn-card-download {
        width: 160px;

        .icon-download {
          width: 20px;
          height: 20px;
        }
      }   

      .business-ethics-wrapper {
        background-size: 300%;
        padding: 60px 65px;
        display: flex;
        flex-direction: column;

        .title{
          padding-left: 0 !important;
          margin-bottom: 24px;
        }
      }
    }
  }

  @media (max-width: 425.98px) {
    .corporate-governance-policy-content {
      .charter-wrapper {
        padding: 30px 36px;

        .charter-title {
          .select-wrapper {
            span {
              display: none;
            }
          }
        }
      }

      .business-ethics-wrapper {
        padding: 30px 36px;
        
        .card-business-wrapper {
          flex-direction: column;

          .card-business {
            .btn-download-business-wrapper {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .corporate-governance-policy-content {
      
    }
  }

  @media (max-width: 320.98px) {
    .corporate-governance-policy-content {
      .charter-wrapper {
        padding: 20px 20px;
      }
    }
  }
`
