import axios from "axios";
import JSZip from "jszip";
import { isArray } from "../helpers";

export const SaveFile = async (url, fileName) => {
  return axios({
    url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const href = window.URL.createObjectURL(response.data);

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = fileName;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getBlob = async (url) => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    })
    .then((response) => {
      resolve(response.data);
      // resolve(URL.createObjectURL(response.data));
    })
    .catch((error) => reject(error));
  });
};

export const DownloadZipFile = async (files, filename = '') => {
  if (isArray(files)) {
    //! Get file data
    const promises = files.map(async (url) => {
      const res = await fetch(url);
      const blob = await res.blob();
      return blob;
    })

    const res = await Promise.all(promises);

    //! Create zip of files
    const zip = new JSZip();
    res.forEach((blob, index) => {
      const splitUrl = files[index].split("/");
      const name = splitUrl[splitUrl.length - 1];

      zip.file(`${name}`, blob);
    })

    //! Create readme file
    // const readme = zip.folder('readme');
    // readme.file('readme.txt', 'Created with JSZip');

    const zipFile = await zip.generateAsync({type: 'blob'});

    //! Download zip
    const element = document.createElement('a');
    element.download = filename || 'file.zip';

    const url = URL.createObjectURL(zipFile);
    element.href = url;

    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    element.remove();

    URL.revokeObjectURL(url);

    //! Callback
    return true;
  }
}