import { decrypt, encrypt } from ".";

export const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, encrypt(value));
};

export const getSessionStorage = (name) => {
  if (typeof window !== "undefined") {
    const value = sessionStorage.getItem(name);
    if (value) {
      return decrypt(value);
    }
  }
  return null;
};

export const removeSessionStorage = (name) => {
  sessionStorage.removeItem(name);
};
