import classNames from 'classnames';
import { AnnCardPageStyle } from './styled';

const AnnCardComponent = ({
  className,
  date, 
  name, 
  icon, 
  current,
}) => {

  return (
    <AnnCardPageStyle
      className={classNames(
        'card-ann-wrapper',
        className
      )}
    >
      <div className='card-ann'>
        <div className='ann-icon'>
          <img className='icon' src={icon} alt="icon-ann" />
        </div>
        <div className={`card-ann-container ${current && 'current'}`}>
          <div className='date-ann'>
            {date}
          </div>
          <div className='des-ann'>
            {name}
          </div>
        </div>
      </div>
    </AnnCardPageStyle>
  );
};

export default AnnCardComponent;
