import ManagementStructureComponent from "../components/Pages/ManagementStructure";
import Auth from "../containerWarping/Auth";
const ManagementStructure = () => {
  return (
    <Auth>
      <ManagementStructureComponent />
    </Auth>
  );
};

export default ManagementStructure;
