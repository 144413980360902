import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { FooterStyle } from "./styled";
import { Button, Input, Modal } from "../../Global";
import { FetchFooter, SubscriptionEmail } from "../../../Service/API";
import { ROUTE_PATH } from "../../../constants/routes";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  validateEmail,
  displayYearBuddhistEra,
  displayYear,
} from "../../../helpers";
import { langTh } from "../../../store/lang";

export default function Footer({ id, onClick, className }) {
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const dateNow = new Date();
  const displayYearNow =
    lang === langTh
      ? `พ.ศ. ${displayYearBuddhistEra(dateNow)}`
      : displayYear(dateNow);

  const [Data, setData] = useState(null);
  const [formData, setFormData] = useState({
    name: null,
    surname: null,
    email: "",
  });
  const [openModal, setOpenModal] = useState({
    isOpen: false,
  });

  useEffect(() => {
    const FooterData = async () => {
      const Response = await FetchFooter();
      setData(Response);
    };

    FooterData();
  }, []);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const registerNewsletter = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      alert(translate["footer.message_error.invalid_email"]);
      return;
    }

    try {
      const payload = {
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
      };

      const response = await SubscriptionEmail(payload);
      if (response) {
        setOpenModal({ isOpen: true });
        setFormData({
          name: null,
          surname: null,
          email: "",
        });
      }
    } catch (error) {
      console.error("Error submitting form", error);
      alert(translate["footer.message_error.submitting_form"]);
    }
  };

  let dataMockA = [
    {
      id: 1,
      title: "ขอเชิญประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
      date: "วันจันทร์ที่ 24 เมษายน 2566 เวลา 14.00 น.",
      contact: "โดยประชุมผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
      img: "../../asset/media/images/icons/icon-warning-message-confirmed.svg",
      alt: "icon-warning-message-confirmed",
      test: translate["footer.register_message"],
    },
  ];

  return (
    <FooterStyle
      className={classNames("footer", className)}
      id={id}
      onClick={onClick}
    >
      <div className="footer-inner-wrapper content-wrapper">
        <div className="company-wrapper">
          <div className="company-info">
            <div className="company-name">
              {Data?.data?.attributes?.company_name}
            </div>

            <div className="company-address">
              {Data?.data?.attributes?.company_address}
            </div>
          </div>

          <div className="contact-number">
            <div className="phone-number">
              <div className="label">{translate["footer.phone_number"]}</div>
              <div className="number">
                {Data?.data?.attributes?.company_phone_number
                  .split(",")
                  .map((e, i) => (
                    <React.Fragment key={i}>
                      <a href={`tel:${e}`}>{e}</a>
                      <br />
                      <br />
                    </React.Fragment>
                  ))}
              </div>
            </div>

            <div className="fax-number">
              <div className="label">{translate["footer.fax_number"]}</div>
              <div className="number">
                {Data?.data?.attributes?.company_fax_number
                  .split(",")
                  .map((e, i) => (
                    <React.Fragment key={i}>
                      <a href={`tel:${e}`}>{e}</a>
                      <br />
                      <br />
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>

          <div className="join-us">
            <span className="title">{translate["footer.newsletter"]}</span>
            <div className="email-sumbit">
              <Input
                required
                className="email-input"
                id="joinUs_Email"
                type="email"
                placeholder={translate["footer.enter_email"]}
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              <Button
                type="submit"
                className="medium-secondary submit-email-button"
                onClick={registerNewsletter}
              >
                {translate["footer.register"]}
              </Button>
            </div>
          </div>
        </div>

        <div className="copyright-and-policy">
          <div className="copy-right">
            {`© ${translate["footer.copy_right"]} ${displayYearNow} ${Data?.data?.attributes?.company_name}`}
          </div>

          <div className="policies">
            <Link
              className="link terms-and-conditions"
              to={ROUTE_PATH.TERMS_AND_CONDITIONS.LINK}
              onClick={handleLinkClick}
            >
              {translate["footer.terms_and_conditions"]}
            </Link>

            <Link
              className="link privacy-policy"
              to={ROUTE_PATH.PRIVACY_CENTER.LINK}
              onClick={handleLinkClick}
            >
              {translate["footer.privacy_policy"]}
            </Link>

            <Link
              className="link cookie-policy"
              to={ROUTE_PATH.COOKIE_POLICY.LINK}
              onClick={handleLinkClick}
            >
              {translate["footer.cookie_olicy"]}
            </Link>
          </div>
        </div>
      </div>
      {/* Modal */}
      {openModal.isOpen && (
        <Modal
          modalWarningMessage
          children={dataMockA[0].test}
          srcImage={dataMockA[0].img}
          alt={dataMockA[0].alt}
          onClose={(e) => {
            setOpenModal(e);
          }}
        />
      )}
    </FooterStyle>
  );
}
