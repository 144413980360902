import styled from 'styled-components'
import VARIABLES from '../../../../../themes/variables'

// Wrapper
// ============================================================
export const CardCompanySnapshotPageStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: 100%;
  background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
  padding: 35px;
  border-radius: 12px;
  
  /* Child element styles
  ------------------------------- */
  p {
    margin: 0;
  }

  .card-company-snapshot {
    .card-head,
    .card-body {
      margin-bottom: 20px;
    }

    .card-head {
      font-size: 20px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .card-body {
      display: flex;

      .icon {
        width: 32px;
        height: 32px;
        margin: auto 15px auto 0;
      }

      .name {
        font-size: 24px;
        font-weight: 700;
        color: #FFFFFF;
      }
    }

    .card-footer {
      .file-download {
        cursor: pointer;
        display: flex;
        min-width: 170px;
        /* max-width: 180px; */
        width: fit-content;
        margin-right: 40px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        padding: 6px 11px;
        border-radius: 6px;
        border: 1px solid ${VARIABLES.COLOR.SECONDARY_DEFAULT};
        background: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
    
        .icon {
          margin-right: 8px;
        }
    
        &:hover {
          .icon {
            transition: 1s;
            transform: translate(0, 5px);
          }
        }
      }
    }
  }
  
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 767.98px) {
    .card-company-snapshot {
      .card-head {
        font-size: 16px;
      }

      .card-body {
        .icon {
          width: 24px;
          height: 24px;
        }
         .name {
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .card-company-snapshot {
      .card-footer {
        .file-download {
          min-width: 160px;
          font-size: 14px;

          .icon { 
            &.icon-download {
              width: 18px;
              height: 18px;
            }
          }
        }
      } 
    }
  }
`
