import React, { useState, useEffect } from 'react';
import { ROUTE_PATH } from '../../../constants/routes';
import { SupplyChainManagementStyle } from './styled';
import { PageHeader } from '../../Global';
import { BusinessValueChain } from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useDispatch, useSelector } from 'react-redux';

const SupplyChainManagementComponent = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [DataPage, setDataPage] = useState(null);
  const [Data, setData] = useState(null);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const getManagementSocial = async () => {
      const ResponsePage = await BusinessValueChain('page-title');
      const ResponseContent = await BusinessValueChain('', { "sort[createdAt]": "desc" } );
      setDataPage(ResponsePage.data);
      setData(ResponseContent.data);
    };

    getManagementSocial();
  }, []);

  const ContentSupplyChain = ({ image, title, content, index }) => {
    return (
      <div className='content fluid'>
        <div className="content-section">
          {index % 2 === 0 &&
            image && (
              <img
                className='shape-image'
                src={BASE_URL_UPLOAD + image}
                alt="seaside-forest"
              />
            )
          }
          <div className="dialog">
            <div className="dialog-title">
              {title}
            </div>
            <div className="dialog-content">
              <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
            </div>
          </div>
          {index % 2 !== 0 &&
            image && (
              <img
                className='shape-image'
                src={BASE_URL_UPLOAD + image}
                alt="seaside-forest"
              />
            )
          }
        </div>
      </div>
    )
  }

  return (
    <SupplyChainManagementStyle>

      <PageHeader
        pageHeaderImage={
          DataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD + DataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: '/', text: translate["menu.home"] },
          { url: '#', text: translate["menu.sustainable_development"] },
          { url: ROUTE_PATH.SUPPLY_CHAIN_MANAGEMENT.LINK, text: DataPage?.attributes.menu_text }
        ]}
        title={DataPage?.attributes.menu_text}
      />

      <div className='background-page'></div>

      <div className="page-content-wrapper">
        <div className='page-content fluid'>
          <div className="supply-chain-management-content content-wrapper">
            <div className='title'>
              <ReactMarkdown
                children={DataPage?.attributes.short_description}
                rehypePlugins={[rehypeRaw]}
              />
            </div>

            {Data?.map((e, i) => {
              return (
                <ContentSupplyChain
                  key={i}
                  index={i}
                  image={e?.attributes?.image?.data?.attributes.url}
                  title={e?.attributes?.title}
                  content={e?.attributes?.short_description}
                />
              )
            })}
          </div>
        </div>
      </div>
    </SupplyChainManagementStyle>
  );
};

export default SupplyChainManagementComponent;
