import VisionAndMissionComponent from "../components/Pages/VisionAndMission";
import Auth from "../containerWarping/Auth";
const VisionAndMission = () => {
  return (
    <Auth>
      <VisionAndMissionComponent />
    </Auth>
  );
};

export default VisionAndMission;
