import React, { useEffect, useState } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { SustainableDevelopmentPolicyStyle } from "./styled";
import { PageHeader } from "../../Global";
import SelectBox from "../../Global/SelectBox";
import {
  SustainabilityPolicy,
  SustainabilityPolicyOfDate,
} from "../../../Service/API";
import { getBaseUploadUrl } from "../../../Service/service";
import CardElement from "./components/CardElement";
import ContentTitle from "./components/ContentTitle";
import { sortArray, displayYearBuddhistEra, displayYear } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { langTh } from "../../../store/lang";
import moment from "moment";

const SustainableDevelopmentPolicyComponent = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const BASE_URL_UPLOAD = getBaseUploadUrl();

  const [DataPage, setDataPage] = useState(null);
  const [DataDoc, setDataDoc] = useState(null);
  const [DateOfData, setDateOfData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const defaultOptionYears = [
    { value: moment().year(), label: lang === langTh ? displayYearBuddhistEra(new Date()) : displayYear(new Date()) },
  ];
  const [optionYears, setOptionYears] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    dispatch({ type: "STANDARD" });

    const FetchPagesSustainabilityPolicy = async () => {
      const ResponsePage = await SustainabilityPolicy("page-title", "");
      const ResponseContent = await SustainabilityPolicy(
        "",
        new Date().getFullYear(),
        {
          "sort[createdAt]": "desc"
        }
      );
      const ResponseDate = await SustainabilityPolicyOfDate();

      setDataPage(ResponsePage?.data);
      setDataDoc(ResponseContent?.data);
      setDateOfData(ResponseDate?.data);

      setIsMounted(true);
    };

    FetchPagesSustainabilityPolicy();
  }, []);

  useEffect(() => {
    const FetchPagesSustainabilityPolicy = async () => {
      if (selectedYear) {
        const ResponseContent = await SustainabilityPolicy(
          "",
          selectedYear,
          {
            "sort[createdAt]": "desc"
          }
        );
        setDataDoc(ResponseContent?.data);
      }
    };

    FetchPagesSustainabilityPolicy();
  }, [selectedYear]);

  const onChangeYear = async (e) => {
    setSelectedYear(e?.value);
  };

  useEffect(() => {
    var years = [];
    var OptionYears = [];

    DateOfData?.map((e) =>
      years.push(new Date(e.attributes.policy_date).getFullYear())
    );

    const removeDuplicates = (arr) => {
      years = arr.filter((item, index) => arr.indexOf(item) === index);
    };
    removeDuplicates(years);

    const addYear = lang === langTh ? 543 : 0;
    years.map((e) =>
      OptionYears.push({ value: e, label: (e + addYear).toString() })
    );
    sortArray(OptionYears, "value", "desc");
    // defaultOptionYears[0] && onChangeYear(defaultOptionYears[0]);
    setOptionYears(OptionYears);
    OptionYears[0] && onChangeYear(OptionYears[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DateOfData]);

  return (
    <SustainableDevelopmentPolicyStyle>
      <PageHeader
        pageHeaderImage={
          DataPage?.attributes?.menu_image?.data?.attributes?.url &&
          BASE_URL_UPLOAD +
          DataPage?.attributes?.menu_image?.data?.attributes?.url
        }
        breadcrumb={[
          { url: "/", text: translate["menu.home"] },
          { url: "/", text: translate["menu.sustainable_development"] },
          {
            url: ROUTE_PATH.SUSTAINABLE_DEVELOPMENT_POLICY.LINK,
            text: DataPage?.attributes?.menu_text,
          },
        ]}
        title={DataPage?.attributes?.menu_text}
      />

      <div className="page-content-wrapper">
        <div className="page-content fluid">
          <div className="sustainable-development-policy-content content-wrapper">
            <ContentTitle
              title={DataPage?.attributes?.title}
              description={DataPage?.attributes?.short_description}
              urlFile={DataPage?.attributes?.policy_file?.data?.attributes?.url}
              fileName={
                DataPage?.attributes?.policy_file?.data?.attributes?.name
              }
            />
          </div>
          <div className="sustainable-development-policy-content">
            <div className="content-section">
              <div className="content-wrapper">
                <div className="select-wrapper">
                  <span>{translate["common.year"]}</span>
                  {isMounted && (
                    <SelectBox
                      placeholder={optionYears[0]?.label}
                      defaultValue={optionYears[0]}
                      options={optionYears}
                      onChange={(e) => {
                        onChangeYear(e);
                      }}
                      isSearchable={false}
                    />
                  )}
                </div>
                <div className="card-wrapper">
                  {DataDoc &&
                    DataDoc.map((e, i) => (
                      <CardElement
                        key={i}
                        title={e?.attributes?.title}
                        index={i}
                        urlFile={e?.attributes?.file?.data?.attributes?.url}
                        fileName={e?.attributes?.title}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SustainableDevelopmentPolicyStyle>
  );
};

export default SustainableDevelopmentPolicyComponent;
