import classNames from "classnames";
import { HeaderStyle } from "./styled";
import MenuItem from "./MenuItem";
import { Button, Modal } from "../../Global";
import React, { useState, useEffect } from "react";
import { ROUTE_PATH } from "../../../constants/routes";
import { useLocation, Link } from "react-router-dom";
import { FetchHeader, GetNavbarMenu } from "../../../Service/API";
import { ourBusinessService } from "../../../Service/ourBusinessService";
import { generatePath } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import SelectBox from "../../Global/SelectBox";
import { default as VARIABLES } from "../../../themes/variables";
import { langEn, langTh, setLang } from "../../../store/lang";
import { getBaseUploadUrl, AnnouncementPopups } from "../../../Service/API";
import { isDesktop } from "react-device-detect";
import {
  openNewTab,
  setSessionStorage,
  getSessionStorage,
  scrollToTop,
} from "./../../../helpers";

export default function Header({ id, className, onClick }) {
  const BASE_URL_UPLOAD = getBaseUploadUrl();
  const [Data, setData] = useState(null);
  const [ourBusiness, setOurBusiness] = useState(null);
  const [navbarData, setNavbarData] = useState({});

  const isHome = useSelector((state) => state.counter.isHome);
  const lang = useSelector((state) => state.lang.value);
  const translate = useSelector((state) => state.lang.translate);
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState(null);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
  });

  const changeLang = (e) => {
    dispatch(setLang(e.value));
  };

  const onClickChangeLang = (e) => {
    dispatch(setLang(e));
  };

  var menuOurBusiness = [];
  useEffect(() => {
    const HeaderData = async () => {
      //! Popup announcement
      const getSession = getSessionStorage("announcement-popup");
      if (!getSession) {
        const resAnnouncementPopup = await AnnouncementPopups();
        setModalData(resAnnouncementPopup?.data || []);
      }

      const Response = await FetchHeader();
      const responseOurBusiness = await ourBusinessService().getOurBusiness();
      setData(Response?.data);
      setOurBusiness(responseOurBusiness.data);

      getNavbarMenu();
    };

    HeaderData();
  }, []);

  useEffect(() => {
    if (modalData && modalData?.length > 0) {
      setOpenModal({ isOpen: true });
    }
  }, [modalData]);

  const getNavbarMenu = async () => {
    const Response = await GetNavbarMenu();
    let data = {};
    Response?.data.forEach((item) => {
      for (const [key, value] of Object.entries(item)) {
        if (key === "page_id") {
          const setKey = value.replaceAll("-", "_");
          data[setKey] = item.text;
        }
      }
    });
    setNavbarData(data);
  };

  const currentPage = window.location.pathname;
  const isOnMobile = window.innerWidth;

  let menuItems = [
    {
      page_id: "pages-about-us",
      label: translate["menu.about_us"],
      imgSrc: Data?.attributes?.nav_bar1_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar1_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-homepage-1.png",
      activeMatch: "about",
      url: "#",
      subItems: [
        {
          page_id: "pages-we-are-biotec",
          label: navbarData?.pages_we_are_biotec,
          url: ROUTE_PATH.WE_ARE_BIOTEC.LINK,
          subItems: [],
        },
        {
          page_id: "pages-vision-mission",
          label: navbarData?.pages_vision_mission,
          url: ROUTE_PATH.VISION_AND_MISION.LINK,
          subItems: [],
        },
        {
          page_id: "pages-company-profile",
          label: navbarData?.pages_company_profile,
          url: ROUTE_PATH.COMPANY_PROFILE.LINK,
          subItems: [],
        },
        {
          page_id: "pages-chairman-message",
          label: navbarData?.pages_chairman_message,
          url: ROUTE_PATH.MESSAGE_FROM_CHAIRMAN.LINK,
          subItems: [],
        },
        {
          page_id: "pages-company-and-business-structure",
          label: translate["menu.company_and_business_structure"],
          url: "#",
          subItems: [
            {
              page_id: "pages-business-structure",
              label: navbarData?.pages_business_structure,
              url: ROUTE_PATH.BUSINESS_STRUCTURE.LINK,
              subItems: [],
            },
            {
              page_id: "pages-management-structure",
              label: navbarData?.pages_management_structure,
              url: ROUTE_PATH.MANAGEMENT_STRUCTURE.LINK,
              subItems: [],
            },
            {
              page_id: "pages-associated-company",
              label: navbarData?.pages_associated_company,
              url: ROUTE_PATH.SUBSIDIARY_AND_ASSOCIATED_COMPANIES.LINK,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      page_id: "pages-our-business",
      label: translate["menu.our_business"],
      imgSrc: Data?.attributes?.nav_bar2_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar2_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-homepage-2.png",
      activeMatch: "our-business",
      url: "#",
      subItems: menuOurBusiness,
    },
    {
      page_id: "pages-sustainable-development",
      label: translate["menu.sustainable_development"],
      imgSrc: Data?.attributes?.nav_bar3_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar3_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-homepage-3.png",
      activeMatch: "sustainability",
      url: "#",
      subItems: [
        {
          page_id: "pages-sustainability-policy",
          label: navbarData?.pages_sustainability_policy,
          url: ROUTE_PATH.SUSTAINABLE_DEVELOPMENT_POLICY.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-management-environmental",
          label: navbarData?.pages_management_environmental,
          url: ROUTE_PATH.ENVIRONMENTAL_MANAGEMENT.LINK,
          isRight: true,
          subItems: [],
        },
        {
          page_id: "pages-sustainability-target",
          label: navbarData?.pages_sustainability_target,
          url: ROUTE_PATH.SUSTAINABLE_DEVELOPMENT_GOALS.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-management-social",
          label: navbarData?.pages_management_social,
          url: ROUTE_PATH.SOCIAL_DIMENSION_MANAGEMENT.LINK,
          isRight: true,
          subItems: [],
        },
        {
          page_id: "pages-business-value-chain",
          label: navbarData?.pages_business_value_chain,
          url: ROUTE_PATH.SUPPLY_CHAIN_MANAGEMENT.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-csr-report",
          label: navbarData?.pages_csr_report,
          url: ROUTE_PATH.SOCIAL_RESPONSIBILITY_REPORT.LINK,
          isRight: true,
          subItems: [],
        },
        {
          page_id: "pages-corporate-csr",
          label: navbarData?.pages_corporate_csr,
          url: ROUTE_PATH.NEWS_ACTIVITY_CSR.LINK,
          isLeft: true,
          subItems: [],
        },
      ],
    },
    {
      page_id: "pages-home-investor",
      label: translate["menu.home_investor_relation"],
      imgSrc: null,
      activeMatch: "",
      url: ROUTE_PATH.HOME_INVESTOR.LINK,
      subItems: [],
    },
    {
      page_id: "pages-corporate-governances",
      label: translate["menu.corporate_governances"],
      imgSrc: Data?.attributes?.nav_bar4_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar4_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-homepage-4.png",
      activeMatch: "corporate-governance",
      url: "#",
      subItems: [
        {
          page_id: "pages-charter",
          label: navbarData?.pages_charter,
          url: generatePath(ROUTE_PATH.CORPORATE_GOVERNANCE_POLICY.LINK, {
            id: 1,
          }),
          subItems: [],
        },
        {
          page_id: "pages-business-ethic",
          label: navbarData?.pages_business_ethic,
          url: generatePath(ROUTE_PATH.CORPORATE_GOVERNANCE_POLICY.LINK, {
            id: 2,
          }),
          subItems: [],
        },
        {
          page_id: "pages-corporate-governance",
          label: navbarData?.pages_corporate_governance,
          url: ROUTE_PATH.COMPANY_POLICY.LINK,
          subItems: [],
        },
        {
          page_id: "pages-corparate-document",
          label:
            navbarData?.pages_corparate_document ||
            navbarData?.pages_corporate_document,
          url: ROUTE_PATH.CORPORATE_DOCUMENTS.LINK,
          subItems: [],
        },
        {
          page_id: "pages-corruption-reported",
          label: navbarData?.pages_corruption_reported,
          url: ROUTE_PATH.CHANNELS_RECEIVING_COMPLAINTS.LINK,
          subItems: [],
        },
        {
          page_id: "pages-committee",
          label: translate["menu.committee"],
          url: "#",
          subItems: [
            {
              page_id: "pages-director",
              label: navbarData?.pages_director,
              url: ROUTE_PATH.BOARD_DIRECTORS.LINK,
              subItems: [],
            },
            {
              page_id: "pages-audit",
              label: navbarData?.pages_audit,
              url: ROUTE_PATH.AUDIT_COMMITTEE.LINK,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      page_id: "pages-news-activities",
      label: translate["menu.news_and_activities"],
      imgSrc: Data?.attributes?.nav_bar5_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar5_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-homepage-5.png",
      activeMatch: "news-activity",
      url: "#",
      subItems: [
        {
          page_id: "page-corporate-news",
          label:
            navbarData?.page_corporate_news || navbarData?.pages_corporate_news,
          url: ROUTE_PATH.NEWS_ACTIVITY_COPORATE_NEW.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-corporate-csr",
          label: navbarData?.pages_corporate_csr,
          url: ROUTE_PATH.NEWS_ACTIVITY_CSR.LINK,
          isRight: true,
          subItems: [],
        },
      ],
    },
    {
      page_id: "pages-join-work-with-us",
      label: translate["menu.work_with_us"],
      imgSrc: Data?.attributes?.nav_bar6_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar6_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-homepage-6.png",
      activeMatch: "work-with-us",
      url: "#",
      subItems: [
        {
          page_id: "pages-work-with-us",
          label: navbarData?.pages_work_with_us,
          url: ROUTE_PATH.WORK_WITH_US_PAGE_INTERESTING_POSITION.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-online-register",
          label: translate["menu.online_register"],
          url: ROUTE_PATH.WORK_WITH_US_PAGE_ONLINE_REGISTER.LINK,
          isRight: true,
          subItems: [],
        },
      ],
    },
    {
      page_id: "pages-contact-us",
      label: navbarData?.pages_contact_us,
      activeMatch: "contact-us",
      imgSrc: null,
      url: ROUTE_PATH.CONTACT_US.LINK,
      subItems: [],
    },
  ];

  let menuInvestor = [
    {
      page_id: "",
      label: translate["menu.back_to_home"],
      activeMatch: "/",
      url: ROUTE_PATH.HOME.LINK,
      subItems: [],
      isHide: isDesktop,
    },
    {
      page_id: "pages-home-investor",
      label: translate["menu.home_investor"],
      activeMatch: "/",
      url: ROUTE_PATH.HOME_INVESTOR.LINK,
      subItems: [],
    },
    {
      page_id: "pages-financial-information",
      label: translate["menu.financial_information"],
      imgSrc: Data?.attributes?.nav_bar7_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar7_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-financial-information.png",
      activeMatch: "financial-information",
      url: "#",
      subItems: [
        {
          page_id: "pages-financial-high-light",
          label: navbarData?.pages_financial_high_light,
          url: ROUTE_PATH.FINANCIAL_HIGHLIGHTS.LINK,
          subItems: [],
        },
        {
          page_id: "pages-financial-information-document",
          label: translate["menu.financial_information_document"],
          url: "#",
          isLeft: true,
          subItems: [
            {
              page_id: "pages-financial-summary",
              label: navbarData?.pages_financial_summary,
              url: ROUTE_PATH.QUARTERLY_FINANCIAL_SUMMARY.LINK,
              subItems: [],
            },
            {
              page_id: "pages-financial-annual-report",
              label: navbarData?.pages_financial_annual_report,
              url: ROUTE_PATH.ANNUAL_REPORT.LINK,
              subItems: [],
            },
            {
              page_id: "pages-financial-management-analyze",
              label: navbarData?.pages_financial_management_analyze,
              url: ROUTE_PATH.MANAGEMENT_DISCUSSION_ANALYSIS.LINK,
              subItems: [],
            },
          ],
        },
        {
          page_id: "pages-factsheet",
          label: translate["menu.factsheet"],
          url: "#",
          isRight: true,
          subItems: [],
          outSideUrl: `https://www.set.or.th/${lang}/market/product/stock/quote/BIOTEC/factsheet`,
        },
        {
          page_id: "pages-summary-of-listed-company-information",
          label: translate["menu.summary_of_listed_company_information"],
          url: "#",
          isRight: true,
          subItems: [],
          outSideUrl: `https://www.set.or.th/${lang}/market/product/stock/quote/BIOTEC/price`,
        },
      ],
    },
    {
      page_id: "pages-stock-price-information",
      label: translate["menu.stock_price_information"],
      imgSrc: Data?.attributes?.nav_bar8_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar8_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-stock-price-information.png",
      activeMatch: "stock-price-information",
      url: "#",
      subItems: [
        {
          page_id: "pages-price-chart",
          label: navbarData?.pages_price_chart,
          url: ROUTE_PATH.STOCK_PRICE.LINK,
          subItems: [],
        },
        {
          page_id: "pages-historical-price",
          label: navbarData?.pages_historical_price,
          url: ROUTE_PATH.HISTORICAL_PRICE.LINK,
          subItems: [],
        },
      ],
    },
    {
      page_id: "pages-information-for-shareholders",
      label: translate["menu.information_for_shareholders"],
      imgSrc: Data?.attributes?.nav_bar9_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar9_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-information-shareholders.png",
      activeMatch: "information-shareholders",
      url: "#",
      subItems: [
        {
          page_id: "pages-shareholder-structure",
          label: navbarData?.pages_shareholder_structure,
          url: ROUTE_PATH.SHAREHOLDER_STRUCTURE.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-shareholders-dividend-policy-tb",
          label: navbarData?.pages_shareholders_dividend_policy_tb,
          url: ROUTE_PATH.DAVIDEND_POLICY.LINK,
          isRight: true,
          subItems: [],
        },
        {
          page_id: "pages-shareholder-meeting",
          label: navbarData?.pages_shareholder_meeting,
          url: ROUTE_PATH.SHAREHOLDER_MEETING_DOCUMENT.LINK,
          isLeft: true,
          subItems: [],
        },
        {
          page_id: "pages-shareholders-ir-calendar",
          label: navbarData?.pages_shareholders_ir_calendar,
          url: ROUTE_PATH.INVESTOR_RELATIONS_CALENDAR.LINK,
          isRight: true,
          subItems: [],
        },
      ],
    },
    {
      page_id: "pages-ir-press-release",
      label: navbarData?.pages_ir_press_release,
      activeMatch: "news-activities",
      url: ROUTE_PATH.SET_ANNOUNCEMENTS.LINK,
      subItems: [],
    },
    {
      page_id: "pages-published-documents",
      label: translate["menu.published_document"],
      imgSrc: Data?.attributes?.nav_bar10_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar10_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-publication.png",
      activeMatch: "publication",
      url: "#",
      subItems: [
        {
          page_id: "pages-one-report",
          label: navbarData?.pages_one_report,
          url: ROUTE_PATH.ONE_REPORT.LINK,
          subItems: [],
        },
        {
          page_id: "pages-investor-kit",
          label: navbarData?.pages_investor_kit,
          url: ROUTE_PATH.INVESTOR_KITS.LINK,
          subItems: [],
        },
        {
          page_id: "pages-company-snapshot",
          label: navbarData?.pages_company_snapshot,
          url: ROUTE_PATH.COMPANY_SNAPSHOT.LINK,
          subItems: [],
        },
        {
          page_id: "pages-other-document",
          label: navbarData?.pages_other_document,
          url: ROUTE_PATH.OTHER_DOCUMENTS.LINK,
          subItems: [],
        },
        {
          page_id: "pages-activities-and-presentation-documents",
          label: translate["menu.activities_and_presentation_documents"],
          url: "#",
          subItems: [
            {
              page_id: "pages-company-presentation",
              label: navbarData?.pages_company_presentation,
              url: ROUTE_PATH.COMPANY_INTRODUCTION_VDO.LINK,
              subItems: [],
            },
            {
              page_id: "pages-shareholder-meeting-document",
              label: `${translate["common.video"]}${lang === langEn ? " " : ""
                }${navbarData?.pages_shareholder_meeting}`,
              url: ROUTE_PATH.SHAREHOLDER_MEETING_VDO.LINK,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      page_id: "pages-contact-investor-relation",
      label: translate["menu.contact_investor_relation"],
      imgSrc: Data?.attributes?.nav_bar11_img?.data?.attributes?.url
        ? BASE_URL_UPLOAD +
        Data?.attributes?.nav_bar11_img?.data?.attributes?.url
        : "../asset/media/images/contents/sub-menu-ir-contact.png",
      activeMatch: "investor-relations",
      url: "#",
      subItems: [
        {
          page_id: "pages-ir-contact",
          label: navbarData?.pages_ir_contact,
          url: ROUTE_PATH.INVESTOR_RELATIONS_CONTACT.LINK,
          subItems: [],
        },
        {
          page_id: "pages-subscription",
          label: navbarData?.pages_subscription,
          url: ROUTE_PATH.EMAIL_CONTACT.LINK,
          subItems: [],
        },
      ],
    },
  ];

  ourBusiness?.map((e) =>
    menuOurBusiness.push({
      label: e.attributes.menu_text,
      url: generatePath(ROUTE_PATH.OUR_BUSINESS.LINK, {
        id: e.id,
      }),
      subItems: [],
    })
  );

  const location = useLocation();
  const [openMenu, setOpenMenu] = useState("");
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const [headerOpacity, setHeaderOpacity] = useState(0.05);
  const [fontColorHeader, setFontColorHeader] = useState("white");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      // const documentHeight = document.documentElement.scrollHeight;

      const scrollPercentage = (scrollPosition / viewportHeight) * 100;
      const opacity = Math.min(scrollPercentage / 100 + 0.05, 1);

      setHeaderOpacity(opacity > 0.2 ? 1 : 0.05);
      setFontColorHeader(opacity > 0.2 ? "black" : "white");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      whiteSpace: "nowrap",
      boxShadow: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
      borderColor: state.isFocused
        ? VARIABLES.COLOR.NEUTRAL6
        : VARIABLES.COLOR.NEUTRAL6,
      backgroundColor: "transparent",
      "&:hover": {
        borderColor: VARIABLES.COLOR.NEUTRAL6,
      },
      height: "42px",
      "@media only screen and (min-width: 1601px)": {
        ...base["@media only screen and (min-width: 1601px)"],
        minWidth: "110px",
      },
    }),

    option: (styles, { isSelected }) => {
      return {
        ...styles,
        transition: "0.6s",
        cursor: "pointer",
        borderRadius: "10px",
        backgroundColor: "white",
        color: isSelected ? VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER : "gray",
        fontWeight: isSelected ? "700" : "400",
        "&:hover": {
          borderRadius: "10px",
          // backgroundColor: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
          color: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
        },
      };
    },

    menuList: (styles) => {
      return {
        ...styles,
        borderRadius: "10px",
        paddingLeft: "8px",
        paddingRight: "8px",
        // boxShadow: `0 0 10px 5px ${VARIABLES.COLOR.NEUTRAL12}`,
        maxHeight: "200px",
        "&::-webkit-scrollbar-thumb": {
          background: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
        },
        "&::-webkit-scrollbar-thumb:active": {
          background: VARIABLES.COLOR.PRIMARY_DARKBLUE_HOVER,
        },
        "scroll-behavior": "smooth",
      };
    },

    menuPortal: (styles) => ({ ...styles, zIndex: "1000", marginTop: "-10px" }),
  };

  const language = [
    { value: langTh, label: "ภาษาไทย" },
    { value: langEn, label: "English" },
  ];
  const defaultLang = language.find((d) => d.value === lang);

  const toggleMenu = (label) => {
    setOpenMenu((prevOpenMenu) => (prevOpenMenu === label ? "" : label));
  };

  const handleMobileMenuClick = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const closeModal = () => {
    setSessionStorage("announcement-popup", true);
    setOpenModal({ isOpen: false });
  };

  const closeModalAndOpenLink = (data) => {
    setOpenModal({ isOpen: false });
    data?.attributes?.link && openNewTab(data?.attributes?.link);
  };

  return (
    <HeaderStyle
      className={classNames(
        "header",
        { "is-homepage": location.pathname === "/" },
        { "is-homepage-investor": location.pathname === "/home-investor" },
        className
      )}
      id={id}
      bgHeaderOpacity={headerOpacity}
      fontColorHeader={fontColorHeader}
    >
      <div className="header-wrapper">
        <div
          className={`header-topbar ${!isHome === true ? "header-topbar-investor" : ""
            }`}
        >
          <div className="content-wrapper">
            <div className="header-title">
              <div className="header-topbar-text">
                <a href={`mailto:${Data?.attributes?.ir_email}`}>
                  {!isHome ? (
                    <img
                      className="wh-18"
                      src="../../../asset/media/images/icons/icon-email-white.svg"
                      alt="icon-email-white"
                    />
                  ) : (
                    <>
                      {currentPage === "/" ||
                        currentPage === "/home-investor" ? (
                        fontColorHeader === "white" ? (
                          <img
                            className="wh-18 icon-email"
                            src="../../../asset/media/images/icons/icon-email-white.svg"
                            alt="icon-email-white"
                          />
                        ) : (
                          <img
                            className="wh-18 icon-email"
                            src="../../../../asset/media/images/icons/icon-email.svg"
                            alt="icon-email"
                          />
                        )
                      ) : (
                        <img
                          className="wh-18 icon-email 3"
                          src="../../../../asset/media/images/icons/icon-email.svg"
                          alt="icon-email icon-email"
                        />
                      )}
                    </>
                  )}
                  <span>{Data?.attributes?.ir_email}</span>
                </a>

                <a href={`tel:${Data?.attributes?.tel}`}>
                  {!isHome ? (
                    <img
                      className="wh-18"
                      src="../../../../asset/media/images/icons/icon-phone-white.svg"
                      alt="icon-phone-white"
                    />
                  ) : (
                    <>
                      {currentPage === "/" ||
                        currentPage === "/home-investor" ? (
                        fontColorHeader === "white" ? (
                          <img
                            className="wh-18 icon-phone"
                            src="../../../../asset/media/images/icons/icon-phone-white.svg"
                            alt="icon-phone-white"
                          />
                        ) : (
                          <img
                            className="wh-18 icon-phone"
                            src="../../../../asset/media/images/icons/icon-phone.svg"
                            alt="icon-phone"
                          />
                        )
                      ) : (
                        <img
                          className="wh-18 icon-phone"
                          src="../../../../asset/media/images/icons/icon-phone.svg"
                          alt="icon-phone"
                        />
                      )}
                    </>
                  )}
                  <span>{Data?.attributes?.tel}</span>
                </a>
              </div>
            </div>

            <div className="header-title">
              <div className="header-topbar-text">
                {/* <div className="header-lang">
                <img
                  src={`../../../../asset/media/images/icons/icon-flag-${lang}.svg`}
                  alt=""
                />
                <SelectBox
                  styles={customStyles}
                  className="language"
                  defaultValue={defaultLang}
                  options={language}
                  isSearchable={false}
                  onChange={changeLang}
                />
              </div> */}

                {!isHome ? (
                  <img
                    className="wh-18 icon-homepage-investor icon-homepage"
                    src="../../../../asset/media/images/icons/icon-clock-white.svg"
                    alt=""
                  />
                ) : (
                  <>
                    {currentPage === "/" || currentPage === "/home-investor" ? (
                      fontColorHeader === "white" ? (
                        <img
                          className="wh-18 icon-clock"
                          src="../../../../asset/media/images/icons/icon-clock-white.svg"
                          alt="icon-clock"
                        />
                      ) : (
                        <img
                          className="wh-18 icon-clock"
                          src="../../../../asset/media/images/icons/icon-clock.svg"
                          alt="icon-clock"
                        />
                      )
                    ) : (
                      <img
                        className="wh-18 icon-clock"
                        src="../../../../asset/media/images/icons/icon-clock.svg"
                        alt="icon-clock"
                      />
                    )}
                  </>
                )}
                <span>{Data?.attributes?.business_hours}</span>
                {!isHome && (
                  <div className="btn-back-to-standard">
                    <Link
                      to={ROUTE_PATH.HOME.LINK}
                      className="menu-anchor"
                      onClick={scrollToTop}
                    >
                      {translate["menu.back_to_home"]}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="header-menu content-wrapper">
          <Link to={ROUTE_PATH.HOME.LINK}>
            {isOnMobile <= 1100 ? (
              <img
                className="icon-logo-header"
                src={
                  Data?.attributes?.logo?.data?.attributes?.url
                    ? BASE_URL_UPLOAD +
                    Data?.attributes?.logo?.data?.attributes?.url
                    : "../../../../asset/media/images/icons/icon-logo-BioTec.png"
                }
                alt="logo"
              />
            ) : (
              <>
                {currentPage === "/" || currentPage === "/home-investor" ? (
                  fontColorHeader === "white" ? (
                    <img
                      className="icon-logo-header"
                      src={
                        Data?.attributes?.logo?.data?.attributes?.url
                          ? BASE_URL_UPLOAD +
                          Data?.attributes?.logo?.data?.attributes?.url
                          : "../../../../asset/media/images/icons/icon-logo-BioTec-white.png"
                      }
                      alt="logo"
                    />
                  ) : (
                    <img
                      className="icon-logo-header"
                      src={
                        Data?.attributes?.logo?.data?.attributes?.url
                          ? BASE_URL_UPLOAD +
                          Data?.attributes?.logo?.data?.attributes?.url
                          : "../../../../asset/media/images/icons/icon-logo-BioTec.png"
                      }
                      alt="logo"
                    />
                  )
                ) : (
                  <img
                    className="icon-logo-header"
                    src={
                      Data?.attributes?.logo?.data?.attributes?.url
                        ? BASE_URL_UPLOAD +
                        Data?.attributes?.logo?.data?.attributes?.url
                        : "../../../../asset/media/images/icons/icon-logo-BioTec.png"
                    }
                    alt="logo"
                  />
                )}
              </>
            )}
          </Link>

          <div
            className={classNames(
              "header-items",
              { "is-mobile-open": openMobileMenu },
              className
            )}
          >
            <div className="header-items-inner-wrapper">
              {isHome
                ? menuItems?.filter(r => r.label)?.map((menuItem, index) =>
                (
                  <MenuItem
                    key={index}
                    label={menuItem.label}
                    subItems={menuItem.subItems}
                    url={menuItem.url}
                    imgSrc={menuItem.imgSrc}
                    isOpen={openMenu === menuItem.label}
                    isHide={menuItem?.isHide || false}
                    activeMatch={menuItem.activeMatch}
                    toggleMenu={toggleMenu}
                    onClick={handleMobileMenuClick}
                    onClose={(e) => {
                      setOpenMobileMenu(e);
                    }}
                    isHome={isHome}
                  />
                )
                )
                : menuInvestor?.filter(r => r.label)?.map((menuItem, index) =>
                (
                  <MenuItem
                    key={index}
                    label={menuItem.label}
                    subItems={menuItem.subItems}
                    url={menuItem.url}
                    imgSrc={menuItem.imgSrc}
                    isOpen={openMenu === menuItem.label}
                    isHide={menuItem?.isHide || false}
                    activeMatch={menuItem.activeMatch}
                    toggleMenu={toggleMenu}
                    onClick={handleMobileMenuClick}
                    onClose={(e) => {
                      setOpenMobileMenu(e);
                    }}
                    isHome={isHome}
                  />
                )
                )
              }

              {/* isOnMobile <= 1100 ? (
                <Button
                  onClick={() => onClickChangeLang(lang === "th" ? "en" : "th")}
                  className="header-lang-mobile"
                >
                  <img
                    className="icon-lang"
                    src={`../../../../asset/media/images/icons/icon-flag-${lang}.svg`}
                    alt=""
                  />
                  <p className="text-lang">{lang}</p>
                </Button>
              ) : null */}
            </div>
          </div>

          <Button className="burger-menu" onClick={handleMobileMenuClick}>
            {openMobileMenu ? (
              <img
                src="../../../../asset/media/images/icons/icon-x.svg"
                alt="icon-x"
              />
            ) : (
              <img
                src="../../../../asset/media/images/icons/icon-burger.svg"
                alt="icon-x"
              />
            )}
          </Button>
        </div>
      </div>

      {/* Modal */}
      {openModal.isOpen && (
        <Modal
          modalBanner
          data={modalData[0]}
          srcImage={
            BASE_URL_UPLOAD +
            modalData[0]?.attributes?.image_show?.data?.attributes?.url
          }
          alt={
            modalData[0]?.attributes?.image_show?.data?.attributes
              ?.alternativeText
          }
          onClick={(data) => {
            closeModalAndOpenLink(data);
          }}
          onClose={(data) => closeModal()}
        />
      )}
    </HeaderStyle>
  );
}
