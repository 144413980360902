import styled from "styled-components";
import VARIABLES from "../../../../themes/variables";

// Wrapper
// ============================================================
export const WorkWithUsPageOnlineRegisterStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */
  .online-register {
    background-color: ${VARIABLES.COLOR.NEUTRAL7};
    display: flex;
    /* max-width: 1440px; */
    width: 100%;
    padding: 61px 118px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .oneline-register-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .title {
        ${VARIABLES.TYPOGRAPHYS.TITLE._1}

        .description {
          display: none;
          margin-top: 14px;
          text-align: center;
          ${VARIABLES.TYPOGRAPHYS.BODY._6}
          color:  ${VARIABLES.COLOR.NEUTRAL10}
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;

        select:required:invalid {
          color: gray;
        }
        option[value=""][disabled] {
          display: none;
        }
        option {
          color: black;
        }

        .selection {
          display: flex;
          /* width: 1090px; */
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          background: var(--hit-box, rgba(255, 255, 255, 0));
          gap: 8px;

          .select-control {
            width: 100%;
            background-color: white;
            border-radius: 6px;

            .select__control {
              border-radius: 6px;
              border-color: ${VARIABLES.COLOR.NEUTRAL11};
              border-top: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
              border-left: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
              border-right: 1px solid ${VARIABLES.COLOR.NEUTRAL11};
            }
          }

          .label {
            display: flex;
            padding-bottom: 0px;
            align-items: flex-end;
            gap: 4px;
            align-self: stretch;

            .text {
              display: flex;
              align-items: center;

              span {
                color: red;
                margin-left: 5px;
              }
            }
          }

          .select {
            display: flex;
            padding: 6px 12px;
            flex-direction: column;
            align-items: center;
            align-self: stretch;
            border-radius: 6px;
            border: 1px solid var(--gray-400, #ced4da);
            background: var(--gray-white, #fff);

            .warpper {
              display: flex;
              align-items: center;
              gap: 10px;
              align-self: stretch;
            }

            .input-suffix {
              display: flex;
              align-items: center;
              gap: 10px;
              align-self: stretch;
            }
          }
        }
      }

      .input {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        width: 100%;

        .text-input {
          display: flex;
          /* width: 533px; */
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .label {
            display: flex;
            padding-bottom: 0px;
            align-items: flex-end;
            gap: 4px;
            align-self: stretch;
          }

          .box-input {
            display: flex;
            align-items: center;
            align-self: stretch;

            .field-wrapper {
              width: 100%;
              gap: 8px;
              display: flex;

              .input {
                border-color: ${VARIABLES.COLOR.NEUTRAL11};
                border-radius: 6px;
              }
            }
          }
        }
      }

      .upload {
        display: flex;
        /* width: 1090px; */
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .label {
          display: flex;
          padding-bottom: 0px;
          align-items: flex-end;
          gap: 4px;
          align-self: stretch;
        }

        .box-input {
          display: flex;
          align-items: center;
          align-self: stretch;
        }

        .file-input {
          display: flex;
          align-items: flex-start;
          align-self: stretch;

          input {
            background-color: white;
          }

          input[type="file"]::file-selector-button {
            /* background-color: #fff; */
            color: #000;
            border-radius: 6px 0px 0px 6px;
            border: 1px solid var(--gray-400, #ced4da);
            background: var(--gray-200, #e9ecef);
            padding: 10px 15px;
            /* margin-right: 20px; */
            /* transition: .5s; */
          }

          input[type="file"]::file-selector-button:hover {
            background-color: #eee;
            border: 0px;
            /* border-right: 1px solid #e5e5e5; */
          }

          .field-wrapper {
            width: 100%;
            display: flex;
            gap: 8px;
            .input-wrapper .input {
              padding: 0 0 0 0;
              border-color: ${VARIABLES.COLOR.NEUTRAL11};
              border-radius: 6px;
            }
          }

          .input-add-left {
            position: relative;
            text-align: center;
            cursor: pointer;
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            border-radius: 6px 0px 0px 6px;
            border: 1px solid var(--gray-400, #ced4da);
            background: var(--gray-200, #e9ecef);

            .text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
            }
          }

          .text-inpunt {
            display: flex;
            padding: 6px 12px;
            align-items: center;
            flex: 1 0 0;
            color: var(--body-text-body-color, #212529);
            font-family: Anuphan;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.32px;
            border-radius: 0px 6px 6px 0px;
            border: 1px solid var(--gray-400, #ced4da);
            background: var(--gray-white, #fff);
          }
        }
      }

      .file {
        color: #000;
        font-family: Anuphan;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.32px;
      }

      .checkbox {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        background: var(--hit-box, rgba(255, 255, 255, 0));

        .label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }

      .form-condition-policy {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .checkbox-policy {
          width: 18px;
          margin: 0 12px 0 0;

          .fake-input {
            .fake-checkbox {
              margin: 0;
              border-radius: 6px;
              border-color: ${VARIABLES.COLOR.NEUTRAL1};
            }
          }
        }

        .condition-policy {
          display: flex;
          width: 100%;
          
          .policy-wrapper {
            display: flex;
            .policy {
              margin: 0;
            }
            
            a {
              margin-left: 3px;
              margin-right: 3px;
              text-decoration: underline;
              color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
            }

            @media (max-width: 870.98px) {
              flex-direction: column;
            }
          }
         
        }
      }

      .button {
        display: flex;
        gap: 10px;
      }
    }

    @media (max-width: 660.98px) {
      padding: 36px;
      .oneline-register-content {
        .title {
          .description {
            display: block;
          }
        }
      }
      .input {
        flex-direction: column;
      }
    }
  }
`;
