import NewAndActivityCorporateNewComponent from "../components/Pages/NewAndActivity/CoporateNew";
import Auth from "../containerWarping/Auth";
const NewAndActivityCorporateNew = () => {
  return (
    <Auth>
      <NewAndActivityCorporateNewComponent />
    </Auth>
  );
};

export default NewAndActivityCorporateNew;
