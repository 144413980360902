import BoardDirectorsComponent from "../components/Pages/BoardDirectors";
import Auth from "../containerWarping/Auth";
const BoardDirectors = () => {
  return (
    <Auth>
      <BoardDirectorsComponent />
    </Auth>
  );
};

export default BoardDirectors;