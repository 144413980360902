import styled from "styled-components";
import VARIABLES from "../../../themes/variables";

// Wrapper
// ============================================================
export const BusinessStructurePageStyle = styled.div`
  /* Parent styles
  ------------------------------- */

  /* Child element styles
  ------------------------------- */

 

  .business-lists-table-wrapper {
    max-width: 1350px;
    overflow-x: auto;
    margin: 0 auto;
  }

  .company-symbol {
    white-space: nowrap;
  }
  
  .company_name {
    white-space: nowrap;
  }

  .business-structure {
    max-width: 1350px;
    margin: 0 auto;
    padding: 25px 15px;
  }

  .business-structure-chart {
    width: 100%;

    .img {
      width: 100%;
      cursor: zoom-in;
    }
  }

  .business-lists {
    padding: 30px 0;

    .business-lists-header {
      text-align: center;
      ${VARIABLES.TYPOGRAPHYS.TITLE._1({ size: 32 })}
    }

    .business-lists-table {
      margin-top: 16px;
      width: 100%;
      border-collapse: collapse;

      .company-header {
        background-color: ${VARIABLES.COLOR.PRIMARY_DARKBLUE};
        color: white;
        ${VARIABLES.TYPOGRAPHYS.TITLE._4}
        text-align: left;
      }

      td,
      th {
        padding: 16px;
      }

      td {
        ${VARIABLES.TYPOGRAPHYS.BODY._2}

        &:first-child {
          width: 30%;
          ${VARIABLES.TYPOGRAPHYS.BODY._1}
        }
      }

      tbody {
        tr {
          &:nth-child(2n) {
            td {
              background-color: ${VARIABLES.COLOR.NEUTRAL7};
            }
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  /* @media (max-width: 1439.98px) {
    .business-lists-table-wrapper {
      max-width: 1250px;
    }
  }

  @media (max-width: 1366.98px) {
    .business-lists-table-wrapper {
      max-width: 1150px;
    }
  }

  @media (max-width: 1279.98px) {
    .business-lists-table-wrapper {
      max-width: 1050px;
    }
  }

  @media (max-width: 1024.98px) {
    .business-lists-table-wrapper {
      max-width: 900px;
    }
  }

  @media (max-width: 768.98px) {
    .business-lists-table-wrapper {
      max-width: 700px;
    }
  }

  @media (max-width: 600.98px) {
    .business-lists-table-wrapper {
      max-width: 550px;
    }
  }

  @media (max-width: 575.98px) {
    .business-lists-table-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: 425.98px) {
    .business-lists-table-wrapper {
      max-width: 400px;
    }
  } */
  

`;
