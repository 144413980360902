// reducer.js
const initialState = {
  isHome: true,
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STANDARD':
      return { ...state, isHome: true };
    case 'INVESTOR':
      return { ...state, isHome: false };
    default:
      return state;
  }
};

export default counterReducer;