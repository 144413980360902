import styled from "styled-components";
import VARIABLES from "../../../../../themes/variables";

// Wrapper
// ============================================================
export const CardVDOStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  position: relative;
  width: fit-content;
  padding-bottom: 70px;
  margin: 0 auto;
  /* Child element styles
  ------------------------------- */
  .card-vdo-wrapper {
    
    width: 374px;
    .vdo-wrapper {
      cursor: pointer;
      position: relative;
      width: 374px;
      height: 272px;
      border-radius: 6px;

      .vdo-title-wrapper {
        position: absolute;
        top: 12px;
        left: 12px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        color: white;
        padding: 8px 12px;

        .vdo-title {
          ${VARIABLES.TYPOGRAPHYS.BODY._1({ size: 18 })};
          margin-bottom: 8px;
        }

        .vdo-date {
          ${VARIABLES.TYPOGRAPHYS.BODY._11};
        }
      }

      .film {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          108.43deg,
          #022f86 8.44%,
          rgba(2, 47, 134, 0) 61.81%
        );
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }

      .logo {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: -1px;
        width: 109px;
        height: 38px;
        border-radius: 24px 0px 0px 0px;
        background: #fff;
        padding: 8px 16px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .vdo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        iframe {
          border-radius: 6px;
          display: unset;
          /* pointer-events: none; */
          overflow: hidden;
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }
    }

    hr {
      width: 70%;
      margin-left: 0;
    }

    .title {
      position: relative;
      ${VARIABLES.TYPOGRAPHYS.BODY._3}
      margin-top: 16px;
      margin-bottom: 8px;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .sub-name {
        display: none;
      }

      &:hover {
        .sub-name {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(27, 59, 106, 0.8);            
          color: white;
          width: 110%;
          padding: 15px;
          border-radius: 10px;
          transform: translate(0, 25px);
          z-index: 990;
        }
      }
    }

    .business { 
      position: relative;
      margin-top: 2px;
      ${VARIABLES.TYPOGRAPHYS.CAPTION._3}
      margin-bottom: 10px;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .sub-name {
        display: none;
      }

      &:hover {
        .sub-name {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(27, 59, 106, 0.8);            
          color: white;
          width: 110%;
          padding: 15px;
          border-radius: 10px;
          transform: translate(0, 25px);
          z-index: 990;
        }
      }
    }

    .sub-title {
      position: relative;
      ${VARIABLES.TYPOGRAPHYS.CAPTION._3}
      margin-top: 8px;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .sub-name {
        display: none;
      }

      &:hover {
        .sub-name {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(27, 59, 106, 0.8);            
          color: white;
          width: 110%;
          padding: 15px;
          border-radius: 10px;
          transform: translate(0, 25px);
          z-index: 990;
        }
      }
    }

    hr {
      position: absolute;
      bottom: 60px;
    }
    
    .btn-save {
      position: absolute;
      bottom: 20px;
      width: 200px;
      white-space: nowrap;
      display: flex;
      gap: 12px;
      img {
          background-color: ${VARIABLES.COLOR.SECONDARY_DEFAULT};
          border-radius: 50%;
          padding: 4px
        }
    }
  }
  /* Modifiers
  ------------------------------- */

  /* State
  ------------------------------- */

  /* Media queries
  ------------------------------- */

  @media (max-width: 1024.98px) {
  }

  @media (max-width: 768.98px) {
  }

  @media (max-width: 425.98px) {
    .card-vdo-wrapper {
      cursor: pointer;
      width: 274px;
      .vdo-wrapper {
        width: 274px;
        height: 172px;
      }
    }
  }

  @media (max-width: 375.98px) {

    
  }

  @media (max-width: 320.98px) {
  }

  @media (max-width: 280.98px) {
    .card-vdo-wrapper {
      width: 234px;
      .vdo-wrapper {
        width: 234px;
        height: 140px;
      }
    }
  }
`;
