import styled from 'styled-components';
import VARIABLES from '../../../themes/variables';

// Wrapper
// ============================================================
export const SelectStyle = styled.div`
  /* Parent styles
  ------------------------------- */
  width: auto;
  min-width: 212px;
  display: inline-block;

  /* Child element styles
  ------------------------------- */
  .select__control {
    height: 49px;
    border-color: ${VARIABLES.COLOR.GRAY_1};
  }

  .select__indicator {
    min-height: 0;
  }

  .select__indicators {
    height: 100%;
  }

  .select__indicator-separator {
    width: 0;
  }

  .select__dropdown-indicator {
    color: ${VARIABLES.COLOR.PRIMARY};
  }

  .select__clear-indicator {
    display: none;
    padding: 0;
  }

  .select__placeholder {
    color: ${VARIABLES.COLOR.GRAY_2};
    white-space: nowrap;
  }

  .select--is-disabled {
    .select__control {
      background-color: ${VARIABLES.COLOR.GRAY_3};
      color: ${VARIABLES.COLOR.GRAY_3};
    }

    .select__indicators {
      display: none;
    }
  }

  .select__menu {
    z-index: 99;

    .select__menu-list {
      color: ${VARIABLES.COLOR.PRIMARY};
      //overflow-y: auto;

      .group-head {
      }

      .group-option {
        padding-left: 40px;
      }

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${VARIABLES.COLOR.GRAY_1};
        border-radius: 5px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${VARIABLES.COLOR.PRIMARY};
        border-radius: 5px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${VARIABLES.COLOR.PRIMARY_HOVER};
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  &.search-filter {
    min-width: 315px;

    .select__control {
      flex-direction: row-reverse;
    }

    .select__dropdown-indicator {
      margin-left: 10px;

      svg {
        display: none;
      }

      &::after {
        content: '';
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        background-image: url('../../images/icons/icon-search-primary.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  /* State
  ------------------------------- */
  &:hover {
    .select__clear-indicator {
      display: flex;
      padding: 8px 0 8px 8px;
    }
  }

  /* Media queries
  ------------------------------- */
  @media (max-width: 1440.98px) {
    min-width: 180px;

    .select__control {
      height: 45px;
    }
  }

  @media (max-width: 1280.98px) {
    min-width: 160px;

    .select__control {
      height: 40px;
    }
  }

  @media (max-width: 1024.98px) {
    min-width: 140px;

    .select__control {
      height: 34px;
      min-height: 0%;
    }
  }
`;
